import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactTable from 'react-table'
import _ from 'underscore'
import axios from 'axios'
import { Segment, Button } from 'semantic-ui-react'
import Form from 'react-bootstrap/Form'

import config from '../../../config'
import { getBrandUsers, handleErrors } from '../../../actions/brands'
import AddUser from './AddUser'
import excelExporter from '../../../utils/excelExporter'

class BrandUsers extends Component {
	state = {
		showAddUser: false,
		uploadingCsv: false,
		csvUploadMessages: [],
		pushingUsers: false,
		overwriteExisting: false,
		ignoreMissingStores: false,
	}

	componentDidMount() {
		// const { dispatch, brandId } = this.props

		// dispatch(getBrandUsers(brandId))
	}

	render() {
		const { data } = this.props
		const { showAddUser, csvUploadMessages, uploadingCsv, pushingUsers, overwriteExisting, ignoreMissingStores } = this.state

		return (
			<div>
				{this.props.errors.map(e => (
					<div key={Math.random()} className='text-danger'>
						{e.response && e.response.data && e.response.data.errors}
					</div>
				))}
				<div className='overflow-auto' style={{ maxHeight: '150px' }}>
					{csvUploadMessages.flat().map(e => {
						if(typeof e === 'string') return <div key={e.row_number} className='text-danger'>{e}</div>

						return (
							<div key={e.row_number} className={`${e.status === 'FAIL' ? 'text-danger' : 'text-success'} border-bottom`}>
								<b>ROW</b>&nbsp;
								{e.row_number} {e.status}
								-&nbsp;
								{e.errors && e.errors.constraint_violations && e.errors.constraint_violations.map(msg => msg)}
								{e.errors && e.errors.exception_messages && e.errors.exception_messages.map(msg => msg)}
							</div>
						)
					})}
				</div>
				{showAddUser &&
					<AddUser
						brandId={this.props.brandId}
						showModal={showAddUser}
						onClose={this.handleModalClose}
					/>
				}
				<Button primary onClick={this.handleExportUsers}>
					Export
				</Button>
				{process.env.REACT_APP_STAGE === 'dev' &&
					<Segment className='d-flex justify-content-between'>
						<div className='d-flex'>
							{/*<Button primary size='small' onClick={() => this.setState({ showAddUser: true })}>Add User</Button>*/}
							<div className='custom-file pointer' style={{ width: 'inherit' }}>
							  <input type='file' className='custom-file-input' id='customFile' onChange={this.uploadUsersCSV} />
							  <Button size='small' primary loading={uploadingCsv}>Upload CSV</Button>
							  {false && <i className='fas fa-check text-success'></i>}
							</div>
							<Form.Check
								type='checkbox'
								className='ml-3'
								label='Overwrite Existing Users'
								checked={overwriteExisting}
								onChange={e => this.setState({ overwriteExisting: e.target.checked })}
							/>
							<Form.Check
								type='checkbox'
								className='ml-3'
								label='Ignore Missing Stores'
								checked={ignoreMissingStores}
								onChange={e => this.setState({ ignoreMissingStores: e.target.checked })}
							/>
						</div>
						<Button secondary loading={pushingUsers} onClick={this.handlePushUsers}>
							Push Users
						</Button>
					</Segment>
				}
				<ReactTable
					data={data.users}
					resolveData={data => _.map(data, row => row)}
					columns={[
						{
							Header: '',
							id: 'delete',
							width: 50,
							filterable: false,
							headerClassName: 'table-header',
							className: 'table-column',
							accessor: d => <i className='far fa-trash-alt float-left text-danger pointer' onClick={() => this.deleteUser(d.id)}></i>
						},
						{
							Header: 'User ID',
							headerClassName: 'table-header',
							className: 'table-column',
							id: 'userId',
							accessor: d => (
								<span>
									{d.id}
								</span>
							),
							// width: 100,
							filterMethod: (filter, row) => row[filter.id].props.children === filter.value
						},
						{
							Header: 'Name',
							headerClassName: 'table-header',
							className: 'table-column',
							id: 'name',
							accessor: d => <span>{`${d.first_name} ${d.last_name}`}</span>,
							filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase())
						},
						{
							Header: 'Username',
							headerClassName: 'table-header',
							className: 'table-column',
							id: 'username',
							accessor: d => <span>{d.username}</span>,
							filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase())
						},
						{
							Header: 'Title',
							headerClassName: 'table-header',
							className: 'table-column',
							id: 'title',
							accessor: d => <span>{Array.isArray(d.title) ? d.title.join(' - ') : d.title}</span>,
							filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().startsWith(filter.value.toLowerCase())
						},
						{
							Header: 'Email',
							headerClassName: 'table-header',
							className: 'table-column',
							id: 'email',
							accessor: d => <span>{d.email_address}</span>,
							filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase())
						},
					]}
					defaultSortMethod={(a, b, desc) => {
						// force null and undefined to the bottom
						a = a.props.children === null || a.props.children === undefined ? '' : a.props.children
						b = b.props.children === null || b.props.children === undefined ? '' : b.props.children
						// force any string values to lowercase
						a = typeof a === 'string' ? a.toLowerCase() : a
						b = typeof b === 'string' ? b.toLowerCase() : b
						// Return either 1 or -1 to indicate a sort priority
						if (a > b) {
						  return 1
						}
						if (a < b) {
						  return -1
						}
						// returning 0, undefined or any falsey value will use subsequent sorts or
						// the index as a tiebreaker
						return 0
					}}
					filterable
					className='-striped -highlight table-style'
					minRows={10}
					noDataText={'No users found'}
				/>
			</div>
		)
	}

	handleModalClose = () => {
		const { dispatch, brandId } = this.props

		this.setState(({ showAddUser }) => ({ showAddUser: !showAddUser }))

		dispatch(getBrandUsers(brandId))
	}

	handleExportUsers = () => {
		const { data, brandName } = this.props

		if(!data.users) return

		const headerRow = [
			'first_name',
			'last_name',
			'email_address',
			'role_name',
			'role_description',
			'role_type',
			'title',
			'region_names',
			'store_ids',
			'display_user_profile',
		].map(cell => ({ value: cell, type: 'string'}))

		const d = _.filter(data.users, u => u.email_address !== 'staff@fieldday.app')

		const contentRows = _.map(d, user => {
			return [
				{
					value: user.first_name || '',
					type: 'string'
				},
				{
					value: user.last_name || '',
					type: 'string'
				},
				{
					value: user.email_address || '',
					type: 'string'
				},
				{
					value: user.role_name || '',
					type: 'string'
				},
				{
					value: user.role_description || '',
					type: 'string'
				},
				{
					value: user.role_type || '',
					type: 'string'
				},
				{
					value: user.title || '',
					type: 'string'
				},
				{
					value: user.region_names ? user.region_names.join(';') : '',
					type: 'string'
				},
				{
					value: user.store_ids ? user.store_ids.join(';') : '',
					type: 'string'
				},
				{
					value: user.display_user_profile || '',
					type: 'string'
				},
			]
		})

		contentRows.unshift(headerRow)

		const config = {
		  filename: `${brandName} - Users`,
		  sheet: {
		    data: contentRows
		  }
		}

		excelExporter(config)
	}

	deleteUser = async (userId) => {
		const confirm = window.confirm('Are you sure you want to delete this user?')

		if(!confirm) return

		const { dispatch, brandId } = this.props

		try {
			// await axios.delete(`${config.coreAPI}/user/${brandId}_${userId}`)
			await axios.delete(`${config.coreAPI}/user/${userId}`)

			// setTimeout(() => dispatch(getBrandUsers(brandId)), 1000)
			dispatch(getBrandUsers(brandId))
		} catch(e) {
			console.error(e)
			dispatch(handleErrors(e))
		}
	}

	uploadUsersCSV = async (e) => {
		const file = e.target.files[0]

		if(!file) {
			this.setState({ uploadingCsv: false })

			return
		}

		this.setState({
			csvUploadMessages: [],
			uploadingCsv: true
		})

		const { dispatch, brandId } = this.props
		const { overwriteExisting, ignoreMissingStores } = this.state

		const formData = new FormData()

		formData.append('file', file)
		formData.append('brandId', brandId)
		formData.append('overwriteExisting', overwriteExisting)
		formData.append('ignoreMissingStores', ignoreMissingStores)

		try {
			const response = await axios.post(`${config.coreAPI}/users/csv`, formData, {
				headers: {
					'content-type': 'multipart/form-data'
				}
			})

			dispatch(getBrandUsers(brandId))

			this.setState({
				csvUploadMessages: [response.data],
				uploadingCsv: false
			})

		} catch(e) {
			console.error(e)

			this.setState({
				csvUploadMessages: [e.response.data],
				uploadingCsv: false
			})
		}
	}

	handlePushUsers = async () => {
		const confirm = window.confirm('Are you sure you want to push these users to production?')

		if(!confirm) return

		const { dispatch, brandId } = this.props

		try {
			this.setState({ pushingUsers: true })

			// first do post /coreAPI/users/backup on dev
			await axios.post(`https://dev.core.api.fieldday.app/coreAPI/users/backup?brandIds=${brandId}`)

			const s3FileName = await axios.get(`https://dev.core.api.fieldday.app/coreAPI/users/backup/latest?brandId=${brandId}`)

			await axios.post(`https://core.api.fieldday.app/coreAPI/users/overwrite?brandId=${brandId}&s3FileName=${s3FileName.data}`)

			this.setState({ pushingUsers: false })
			alert('Users Pushed Successfully')
		} catch(e) {
			console.error(e)
			alert('Error! Check the logs.')

			this.setState({
				pushingUsers: false
			}, () => dispatch(handleErrors(e)))
		}
	}
}

function mapStateToProps({ brands }) {
	return {
		data: brands.selectedBrandUsers,
		errors: brands.errors
	}
}

export default connect(mapStateToProps)(BrandUsers)
