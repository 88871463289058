import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

export default function LeadModal({ lead, onClose, onEditLeadAction }) {
	const [notes, setNotes] = useState(lead.notes)

	return (
		<Modal
		  centered
		  size='lg'
		  show={true}
		  onHide={onClose}
		>
		  <Modal.Header closeButton>
		    <Modal.Title></Modal.Title>
		  </Modal.Header>
		  <Modal.Body>
			  <Form.Control
			    as='textarea'
			    style={{ height: '200px' }}
			    value={notes}
			    onChange={e => setNotes(e.target.value)}
			  />
		  </Modal.Body>
		  <Modal.Footer>
		    <Button onClick={() => onEditLeadAction(notes, lead.leadActionId)}>Save</Button>
		  </Modal.Footer>
		</Modal>
	)
}