import moment from 'moment'

import {
	SHOW_LOADING_GIGS,
	HIDE_LOADING_GIGS,
	RECEIVE_GIGS,
	CHANGE_GIGS_START_DATE,
	CHANGE_GIGS_END_DATE,
	CHANGE_GIGS_PAGEFROM,
	CHANGE_GIGS_PAGESIZE,
	CHANGE_GIGS_TABLE_FILTER,
	CHANGE_GIGS_TABLE_SORT,
	SET_GIGS_SELECTED_STATUS,
	SET_GIGS_AMBASSADOR_FILTER
} from '../actions/gigs'

const initialState = {
	startDate: new Date(moment().startOf('week')),
	endDate: new Date(moment().endOf('week')),
	pageFrom: 0,
	pageSize: 20,
	selectedStatus: 'unpaidGigs',
	brandNameSearchString: '',
	titleSearchString: '',
	addressSearchString: '',
	ambassadorSearchString: '',
	sortFieldName: '',
	sortDirection: ''
	// selectedBrand: null,
	// zipCodeSearchString: '',
	// dueDateFromMillis: new Date(moment().startOf('week')).valueOf(),
	// dueDateToMillis: new Date(moment().endOf('week')).valueOf(),
}

export default function gigs(state = initialState, action) {
	switch(action.type) {
		case SHOW_LOADING_GIGS:
			return {
				...state,
				loading: action.loading
			}
		case HIDE_LOADING_GIGS:
			return {
				...state,
				loading: action.loading
			}
		case RECEIVE_GIGS:
			return {
				...state,
				[action.status]: action.gigs
			}
		case CHANGE_GIGS_START_DATE:
			return {
				...state,
				startDate: action.date
			}
		case CHANGE_GIGS_END_DATE:
			return {
				...state,
				endDate: action.date
			}
		case CHANGE_GIGS_PAGEFROM:
			return {
				...state,
				pageFrom: action.pageFrom
			}
		case CHANGE_GIGS_PAGESIZE:
			return {
				...state,
				pageSize: action.pageSize,
				pageFrom: action.pageFrom
			}
		case CHANGE_GIGS_TABLE_FILTER:
			return {
				...state,
				[action.name]: action.value
			}
		case CHANGE_GIGS_TABLE_SORT:
			const sortDirection = action.descSort ? 'desc' : 'asc'

			return {
				...state,
				sortFieldName: action.sortFieldName,
				sortDirection
			}
		case SET_GIGS_SELECTED_STATUS:
			return {
				...state,
				selectedStatus: action.status,
				// selectedBrand: null,
				// selectedBrandStatus: null
			}
		case SET_GIGS_AMBASSADOR_FILTER:
			return {
				...state,
				ambassadorFilter: action.ambassadorFilter
			}
		default:
			return state
	}
}
