const SIC_GROUPS = {
	'All': 'all',
	'Phone – SIC Cluster 1 Hospitality': ['7991','0741','0742','0752','1311','1381','1382','1389','2082','2084','2085','2086','4725','4833','5045','5112','5211','5251','5261','5311','5331','5511','5531','5551','5611','5621','5641','5651','5661','5699','5712','5731','5734','5941','5942','5944','5945','5946','5992','5995','5999','6029','6061','6099','6141','6162','6163','6211','6221','6231','6311','6331','6361','6371','6411','6512','6513','6514','6515','6519','6531','6541','6552','6712','6719','6722','6732','6733','7011','7213','7219','7231','7291','7311','7312','7313','7319','7322','7323','7331','7334','7338','7342','7349','7363','7372','7376','7381','7382','7514','8011','8021','8041','8042','8043','8049','8051','8052','8059','8062','8063','8069','8071','8072','8082','8092','8093','8099','8111','8211','8221','8299','8621','8631','8651','8711','8712','8713','8721','8732','8734','8741','8742','8743'].sort((a, b) => a - b),
	'Street – SIC Cluster 1 Hospitality': ['7991','0741','0742','0752','2082','2084','2085','2086','4725','5045','5112','5211','5251','5261','5311','5331','5511','5531','5551','5611','5621','5641','5651','5661','5699','5712','5731','5734','5941','5942','5944','5945','5946','5992','5995','5999','6029','6061','6099','6141','6162','6163','6311','6361','6512','6513','6514','6515','6519','6531','6541','6552','7231','7291','7334','7338','7342','7349','7363','7514','8011','8021','8041','8042','8043','8049','8071','8211','8221','8621','8631','8711','8712','8713','8721'].sort((a, b) => a - b),
	'Canvassing (all SIC codes except some)': ['99','96','92','7033','04','08','12','16','43','47','51','58','83','359','79','721','737','554','98','95','91','01','05','09','13','17','44','48','52','28','84','75','559','722','738','573','97','94','90','02','06','10','14','41','45','49','54','2082','8051','76','551103','725','527','5932','93','7021','03','07','11','15','42','46','50','56','275','899','78','556','726','533'].sort((a, b) => a - b),
	'All except 58, 70-81, 83-99': [
		'58',
		'70',
		'71',
		'72',
		'73',
		'74',
		'75',
		'76',
		'77',
		'78',
		'79',
		'80',
		'81',
		'83',
		'84',
		'85',
		'86',
		'87',
		'88',
		'89',
		'90',
		'91',
		'92',
		'93',
		'94',
		'95',
		'96',
		'97',
		'98',
		'99',
	],
}

export default SIC_GROUPS
