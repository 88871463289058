import React, { useState, useEffect } from 'react'
import { Col, Row, Form } from 'react-bootstrap'

import FilterRelations from './FilterRelations'

function PhoneFilter({ filterName, onSetRelation, relation, onSetAttribute, onSetValue }) {
	const relationOptions = ['present']

	useEffect(() => {
		onSetAttribute(filterName)
	}, [])

	return (
		<Row>
			<Col>
				<FilterRelations relationOptions={relationOptions} onSelectRelation={onSetRelation} />
			</Col>
			<Col>
				{(relation === 'matches' || relation === 'equals') &&
					<Form.Control onChange={e => onSetValue(e.target.value)} />
				}
			</Col>
		</Row>
	)
}

export default PhoneFilter
