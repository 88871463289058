import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { LinkContainer } from 'react-router-bootstrap'

import logo from '../assets/img/fd-logo.png'
import { useAuth0 } from '../react-auth0-wrapper'
import CampaignModal from './createCampaign/CampaignModal'

export default function Header() {
	const [createCampaign, setCreateCampaign] = useState(false)
	const { isAuthenticated, loginWithRedirect, logout } = useAuth0()

	return (
		<Navbar
			expand='md'
			bg='dark'
			sticky='top'
			className='navbar navbar-expand navbar-dark bg-dark navbar navbar-expand-md navbar-light bg-dark sticky-top'
			style={{ height: '60px' }}
		>
			{createCampaign ?
				<CampaignModal
					showModal={true}
					onClose={() => setCreateCampaign(false)}
				/>
				: null
			}
			<NavLink to='/' className='navbar-brand col-sm-3 col-md-2 mr-0'>
				<img
					src={logo}
					style={{maxWidth: '100%', maxHeight: '100%'}}
					className="d-inline-block align-top"
					alt="Field Day Logo"
				/>
			</NavLink>
			<Col className='d-flex align-items-center'>
				<Nav className='mr-auto'>
					<LinkContainer to="/dashboard">
						<Nav.Link>Dashboard</Nav.Link>
					</LinkContainer>

					<LinkContainer to="/ambassadors">
						<Nav.Link>Ambassadors</Nav.Link>
					</LinkContainer>

					<LinkContainer to="/gigs">
						<Nav.Link>Gigs</Nav.Link>
					</LinkContainer>

					<LinkContainer to="/brands">
						<Nav.Link>Brands</Nav.Link>
					</LinkContainer>

					<LinkContainer to="/training">
						<Nav.Link>Training</Nav.Link>
					</LinkContainer>

					<LinkContainer to="/places">
						<Nav.Link>Places</Nav.Link>
					</LinkContainer>

					<LinkContainer to="/gallery">
						<Nav.Link>Gallery</Nav.Link>
					</LinkContainer>

					{/*<LinkContainer to="/create-campaign">
						<Nav.Link className='btn btn-info ml-3'>Campaign</Nav.Link>
					</LinkContainer>*/}

				</Nav>
				<div className='d-flex'>
					<Button
						variant='info'
						className='font-weight-bold mr-5'
						onClick={() => setCreateCampaign(true)}
					>
						Create Campaign
					</Button>
					<LinkContainer to="/userProfile">
						<Nav.Link>
							<i className='fas fa-user fa-2x text-white pointer'></i>
						</Nav.Link>
					</LinkContainer>
					{!isAuthenticated && (
						<Button
							variant='dark'
							onClick={() => loginWithRedirect({})}
						>
							Log In
						</Button>
					)}
					{isAuthenticated && <Button variant='dark' onClick={() => logout()}>Log Out</Button>}
				</div>
			</Col>
		</Navbar>
	)
}
