import React, { Component, Fragment } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import axios from 'axios'

import { useAuth0 } from '../react-auth0-wrapper'
import PrivateRoute from './PrivateRoute'
import Home from './Home'
import Dashboard from './Dashboard'
import Ambassadors from './Ambassadors'
import Gigs from './Gigs'
import Brands from './Brands'
import Training from './Training'
import Places from './Places'
import Gallery from './Gallery'
import CreateCampaign from './CreateCampaign'
import GigTile from './GigTile'
import Header from '../components/Nav'
import AmbassadorProfile from './AmbassadorProfile'
import UserProfile from './UserProfile'
import TokenGenerator from './TokenGenerator'
import configFile from '../config'

function App() {
  const { loading } = useAuth0()
  let userId

  if(loading) {
    return (
      <div>Loading..</div>
    )
  }

  axios.get(`${configFile.coreAPI}/staff/me`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
    }
  })
    .then(user => userId = user.data.id)
    .catch(e => console.error('Error in getting signed in user object: ', e))

  axios.interceptors.request.use(function (config) {
    const token = localStorage.getItem('AUTH_TOKEN')

    // Do something before request is sent
    // if(config.url.indexOf('fieldDayAdmin') < 0) {
    //   console.log('AXIOS CONFIG: ', config.url)
    // }

    if(token) {
      if(!config.url.includes('symbl')) {
        config.headers.Authorization = `Bearer ${token}`
      }

      if(userId && !config.url.includes('ddnofdks8d') && !config.url.includes('symbl')) { // checks if it's symbl a utility lambda endpoint
        config.headers['staff-user-id'] = userId
      }
    }

    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  })

  return (
    <Router>
      <Fragment>
        <PrivateRoute path='/' component={Header} />
        <Container fluid>
          {/*<Row>*/}
            <Switch>
              <PrivateRoute exact path='/' component={Home} />
              <PrivateRoute path='/dashboard' component={Dashboard} />
              <PrivateRoute path='/ambassadors' component={Ambassadors} />
              <PrivateRoute path='/gigs' component={Gigs} />
              <PrivateRoute path='/brands' component={Brands} />
              <PrivateRoute path='/ambassador/:id' component={AmbassadorProfile} />
              <PrivateRoute path='/gig/:id' component={GigTile} />
              <PrivateRoute path='/training' component={Training} />
              <PrivateRoute path='/places' component={Places} />
              <PrivateRoute path='/gallery' component={Gallery} />
              <PrivateRoute path='/create-campaign' component={CreateCampaign} />
              <PrivateRoute path="/userProfile" component={UserProfile} />
              <PrivateRoute exact path="/auth-token" component={TokenGenerator} />
              <Route render={() => <h1>Page Not Found</h1>}/>
            </Switch>
          {/*</Row>*/}
        </Container>
      </Fragment>
    </Router>
  )
}

export default App;
