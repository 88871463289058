import React, { Component, Fragment } from 'react'
import axios from 'axios'
import Form from 'react-bootstrap/Form'

import config from '../config'

class BrandSelector extends Component {
	state = {
		selectedBrand: '',
		brandsSearchResults: []
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClick)
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClick)
	}

	render() {
		const { brandsSearchResults, selectedBrand } = this.state

		return (
			<Fragment>
				<Form.Control
					placeholder='Brand Name'
					value={selectedBrand}
					onChange={(e) => this.setState({ selectedBrand: e.target.value }, this.handleBrandInputChange)}
				/>
				{brandsSearchResults.length
					? <div className='search-result' ref={node => this.brandsSearchBox = node}>
							{brandsSearchResults.map((brand, i) => (
								<div
									key={i}
									className='search-result-item'
									onClick={() => this.handleBrandClick(brand)}
								>
									{brand.brand_name}
								</div>
								))}
						</div>
					: null
				}
			</Fragment>
		)
	}

	handleClick = (e) => {
		if(this.brandsSearchBox && this.brandsSearchBox.contains(e.target)) {
			// click is inside
			return
		}

		this.setState({
			brandsSearchResults: []
		})
	}

	handleBrandInputChange = async () => {
		if(!this.state.selectedBrand) {
			this.props.onBrandClick(null, null)
			return
		}

		try {
			const response = await axios.get(`${config.coreAPI}/brand/autocomplete?searchString=${this.state.selectedBrand}&pageFrom=0&pageSize=20`)

			this.setState({
				brandsSearchResults: response.data
			})

		} catch(e) {
			console.error(e)
		}
	}

	handleBrandClick = (brand) => {
		const { onBrandClick } = this.props

		this.setState({
			selectedBrand: brand.brand_name,
			brandsSearchResults: []
		}, () => onBrandClick && onBrandClick(brand.brand_name, brand.brand_id))
	}
}

export default BrandSelector
