import React from 'react'
// import { connect } from 'react-redux'

import logo from '../logo.png'

function Loading(loading) {
	// if(!loading) return null

	return (
		<div className="loading-logo-background">
			<div className="loading-logo">
				<img src={logo} alt='' />
			</div>
		</div>
	)
}

// function mapStateToProps(state) {
// 	return {
// 		loading: state.loading
// 	}
// }

// export default connect(mapStateToProps)(Loading)
export default Loading
