import React, { Component } from 'react'
import Button from 'react-bootstrap/Button'

import WYSIWYG from '../WYSIWYG'

class Email extends Component {
	state = {
		subject: '',
		message: ''
	}

	handleInputChange = (name, value) => {
		// const { name, value } = event.target

		this.setState({
			[name]: value
		})
	}

	render() {
		const { recipients, onRemoveRecipient, onSendEmail, sending } = this.props
		// const uniqueRecipients

		return (
			<div className='email-form-container'>
				<b>TO:</b>
				<div className='email-form-recipients'>
					{recipients.map(user => (
						<div key={user.id} className='border-bottom'>
							{onRemoveRecipient &&
								<span className='mr-1'>
									[<i className='fas fa-times text-danger pointer' title='Remove recipient' onClick={() => onRemoveRecipient(user.id)}></i>]
								</span>
							}
							{`${user.ambassador_name} <${user.email_address}>`}
						</div>
					))}
				</div>
				<br/><br/>
				<form>
					<label htmlFor='subject'>Subject</label>
					<br/>
					<input
						value={this.state.subject}
						type='text'
						onChange={e => this.handleInputChange('subject', e.target.value)}
						name='subject'
						required
					/>
					<br />
					<label htmlFor='message'>Message</label>
					<br/>
					{/*<textarea
						rows={15}
						columns={100}
						value={this.state.message}
						type='text'
						onChange={this.handleInputChange}
						name='message'
						required
					/>*/}
					<WYSIWYG
						id={'message'}
						fieldName={'message'}
						defaultValue={this.state.message || ''}
						onUpdateField={this.handleInputChange}
						height={'300px'}
					/>
				</form>
				<Button
					variant='dark'
					onClick={() => onSendEmail(this.state)}
					disabled={this.isDisabled()}
				>
					{sending ? 'Sending..' : 'Send'}
				</Button>
			</div>
		)
	}

	isDisabled = () => {
		const { subject, message } = this.state
		const { recipients, sending } = this.props

		return subject === '' || message === '' || recipients.length === 0 || sending
	}
}

export default Email
