import React, { Fragment, useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import { Dropdown, Label, Segment, Button } from 'semantic-ui-react'
import axios from 'axios'
import { API } from 'aws-amplify'
import moment from 'moment'
import DatePicker from 'react-datepicker'

import config from '../config'

function Gallery() {
	const [brands, setBrands] = useState([])
	const [brandsDropdown, setBrandsDropdown] = useState([])
	const [selectedBrandId, setSelectedBrandId] = useState(null)
	const [campaignsDropdown, setCampaignsDropdown] = useState([])
	const [selectedCampaignId, setSelectedCampaignId] = useState(null)
	const [startDate, setStartDate] = useState(moment().startOf('week').valueOf())
	const [endDate, setEndDate] = useState(moment().endOf('week').valueOf())
	const [allPhotos, setAllPhotos] = useState([])
	const [gettingPhotos, setGettingPhotos] = useState(false)

	useEffect(() => {
		getBrands()
	}, [])

	const getBrands = async () => {
		try {
			const brands = await axios.get(`${config.coreAPI}/brand/all/names?pageFrom=0&pageSize=2000`)
			const dropdownOptions = brands.data.responses[0].results.map(brand => ({
				key: brand.brand_id,
				value: brand.brand_id,
				text: brand.brand_name,
			}))

			setBrands(brands.data.responses[0].results)
			setBrandsDropdown(dropdownOptions)
		} catch(e) {
			console.error(e)
		}
	}

	useEffect(() => {
		getCampaigns()
	}, [selectedBrandId])

	const getCampaigns = async () => {
		if(!selectedBrandId) {
			setCampaignsDropdown([])
			return
		}

		try {
			const campaigns = await axios.get(`${config.coreAPI}/activity/campaigns?brandId=${selectedBrandId}`)
			const dropdownOptions = campaigns.data.map(campaign => ({
				key: campaign.campaign_id,
				value: campaign.campaign_id,
				text: `${campaign.campaign_name || 'N/A'} (${campaign.campaign_id})`,
			}))

			setCampaignsDropdown(dropdownOptions)
		} catch(e) {
			console.error(e)
		}
	}

	const getPhotos = async () => {
		if(!selectedBrandId) {
			alert('You must select a brand')
			return
		}

		setAllPhotos([])
		setGettingPhotos(true)

		try {
			const gigs = await axios.get(`${config.coreAPI}/fieldDayAdmin/activities`, {
				params: {
					brandId: selectedBrandId,
					started: true,
					dueDateFromMillis: startDate,
					dueDateToMillis: endDate,
					campaignId: selectedCampaignId,
					pageSize: 10000
				}
			})

			console.log(gigs.data.responses[0].results)

			if(!gigs.data.responses[0].results.length) {
				setGettingPhotos(false)
			}

			const photos = []
			const photoUrls = []

			gigs.data.responses[0].results.forEach(gig => {
				gig.brand_user_activity.field_day_operation_details.handout_photos && photos.push(gig.brand_user_activity.field_day_operation_details.handout_photos)
				gig.brand_user_activity.field_day_operation_details.materials_photos && photos.push(gig.brand_user_activity.field_day_operation_details.materials_photos)
				gig.brand_user_activity.field_day_operation_details.meeting_spot_photos && photos.push(gig.brand_user_activity.field_day_operation_details.meeting_spot_photos)
			})


			photos.flat().forEach(async (photo, i, arr) => {
				const url = await API.post('getSignedUrl', '/getSignedUrl', {
					body: { key: photo.photo_url }
				})

				photoUrls.push({
					url: url,
					timestamp: arr[i].photo_timestamp,
					path: arr[i].photo_url
				})

				if(i === arr.length - 1) {
					setAllPhotos(photoUrls)
					setGettingPhotos(false)
				}
			})

		} catch(e) {
			console.error(e)
		}
	}

	// console.log('brands: ', brands)
	// console.log('campaignsDropdown: ', campaignsDropdown)
	console.log('allPhotos: ', allPhotos)

	return(
		<Fragment>
			<Segment raised className='mt-3'>
				<Row className='text-center'>
					<Col>
						<Dropdown
							clearable
							search
							selection
							placeholder='Select Brand'
							className='ws-100'
							name='selectedBrand'
							options={brandsDropdown}
							onChange={(e, { name, value }) => setSelectedBrandId(value)}
							// style={{ backgroundColor: '#0797a6' , fontWeight: '700', color: 'white' }}
							// value={gigState.assignedUser}
						/>
					</Col>
					<Col>
						<Dropdown
							clearable
							search
							selection
							placeholder='Select Campaign'
							className='ws-100'
							name='selectedCampaign'
							options={campaignsDropdown}
							onChange={(e, { name, value }) => setSelectedCampaignId(value)}
							// style={{ backgroundColor: '#0797a6' , fontWeight: '700', color: 'white' }}
							// value={gigState.assignedUser}
						/>
					</Col>
					<Col>
						<DatePicker
							selectsStart
							placeholderText='Select Start Date'
							selected={startDate}
							onChange={date => setStartDate(date.valueOf())}
							startDate={startDate}
							endDate={endDate}
							customInput={<Form.Control />}
						/>
						&nbsp;TO&nbsp;
						<DatePicker
							selectsEnd
							placeholderText='Select End Date'
							selected={endDate}
							onChange={date => setEndDate(date.valueOf())}
							endDate={endDate}
							minDate={startDate}
							customInput={<Form.Control />}
						/>
					</Col>
					<Col>
						<Button onClick={getPhotos} loading={gettingPhotos}>
							Get All Photos
						</Button>
					</Col>
				</Row>
			</Segment>
			<hr />
			<h3>Total: {allPhotos.length}</h3>
			<div className='d-flex flex-wrap justify-content-center'>
				{allPhotos.map((photo, i) => (
					<div key={i} className='m-3 d-flex' style={{ width: '400px', height: '400px' }}>
						<img src={photo.url} className='m-auto' style={{ maxWidth: '100%', maxHeight: '100%' }} />
					</div>
				))}
			</div>
		</Fragment>
	)
}

export default Gallery
