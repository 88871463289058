import moment from 'moment'

import {
	RECEIVE_APPLIED_GIGS,
	RECEIVE_UNASSIGNED_GIGS,
	RECEIVE_UNFULFILLED_GIGS,
	RECEIVE_CANCELED_GIGS,
	RECEIVE_UNCONFIRMED_GIGS,
	RECEIVE_CONFIRMED_GIGS,
	RECEIVE_ASSIGNED_GIGS,
	RECEIVE_UNPAID_GIGS,
	RECEIVE_COMPLETED_GIGS,
	RECEIVE_UPCOMING_GIGS,
	RECEIVE_IN_REVIEW_GIGS,
	RECEIVE_ALL_GIGS_FOR_BRAND,
	CHANGE_DASHBOARD_START_DATE,
	CHANGE_DASHBOARD_END_DATE,
	SET_DASHBOARD_SELECTED_GIG_STATUS,
	CHANGE_MASTER_TOGGLE,
	CHANGE_DASHBOARD_PAGEFROM,
	CHANGE_DASHBOARD_PAGESIZE,
	CHANGE_DASHBOARD_TABLE_FILTER,
	CHANGE_DASHBOARD_TABLE_SORT,
	RECEIVE_DASHBOARD_ACTIVE_BRANDS,
	SET_DASHBOARD_SELECTED_BRAND,
	RECEIVE_ALL_BRAND_GIGS,
	SET_DASHBOARD_AMBASSADOR_FILTER,
	CHANGE_DASHBOARD_GIGS_VIEW
} from '../actions/dashboard'

const initialState = {
	startDate: new Date(moment().startOf('week')),
	endDate: new Date(moment().endOf('week')),
	masterToggle: true,
	pageFrom: 0,
	pageSize: 20,
	selectedGigStatus: 'appliedGigs',
	selectedBrand: null,
	brandNameSearchString: '',
	titleSearchString: '',
	campaignIdSearchString: '',
	addressSearchString: '',
	zipCodeSearchString: '',
	ambassadorSearchString: '',
	sortFieldName: '',
	sortDirection: '',
	activeBrands: [],
	gigsView: 'tableView'
}

export default function dashboard(state = initialState, action) {
	switch (action.type) {
		case RECEIVE_APPLIED_GIGS:
			return {
				...state,
				appliedGigs: action.gigs
			}
		case RECEIVE_UNASSIGNED_GIGS:
			return {
				...state,
				unassignedGigs: action.gigs
			}
		case RECEIVE_UNFULFILLED_GIGS:
			return {
				...state,
				unfulfilledGigs: action.gigs
			}
		case RECEIVE_CANCELED_GIGS:
			return {
				...state,
				canceledGigs: action.gigs
			}
		case RECEIVE_UNCONFIRMED_GIGS:
			return {
				...state,
				unconfirmedGigs: action.gigs
			}
		case RECEIVE_CONFIRMED_GIGS:
			return {
				...state,
				confirmedGigs: action.gigs
			}
		case RECEIVE_ASSIGNED_GIGS:
			return {
				...state,
				assignedGigs: action.gigs
			}
		case RECEIVE_UNPAID_GIGS:
			return {
				...state,
				unpaidGigs: action.gigs
			}
		case RECEIVE_COMPLETED_GIGS:
			return {
				...state,
				completedGigs: action.gigs
			}
		case RECEIVE_UPCOMING_GIGS:
			return {
				...state,
				upcomingGigs: action.gigs
			}
		case RECEIVE_IN_REVIEW_GIGS:
			return {
				...state,
				inReviewGigs: action.gigs
			}
		case RECEIVE_ALL_GIGS_FOR_BRAND:
			return {
				...state,
				allGigsForBrand: action.gigs
			}
		case CHANGE_DASHBOARD_START_DATE:
			return {
				...state,
				startDate: action.date
			}
		case CHANGE_DASHBOARD_END_DATE:
			return {
				...state,
				endDate: action.date
			}
		case SET_DASHBOARD_SELECTED_GIG_STATUS:
			return {
				...state,
				selectedGigStatus: action.status,
				selectedBrand: null,
				// selectedBrandStatus: null
			}
		case CHANGE_MASTER_TOGGLE:
			return {
				...state,
				masterToggle: !action.masterToggle
			}
		case CHANGE_DASHBOARD_PAGEFROM:
			return {
				...state,
				pageFrom: action.pageFrom
			}
		case CHANGE_DASHBOARD_PAGESIZE:
			return {
				...state,
				pageSize: action.pageSize,
				pageFrom: action.pageFrom
			}
		case CHANGE_DASHBOARD_TABLE_FILTER:
			return {
				...state,
				[action.name]: action.value
			}
		case CHANGE_DASHBOARD_TABLE_SORT:
			const sortDirection = action.descSort ? 'desc' : 'asc'

			return {
				...state,
				sortFieldName: action.sortFieldName,
				sortDirection
			}
		case RECEIVE_DASHBOARD_ACTIVE_BRANDS:
			return {
				...state,
				activeBrands: action.activeBrands
			}
		case SET_DASHBOARD_SELECTED_BRAND:
			return {
				...state,
				selectedBrand: action.brandName,
				selectedGigStatus: action.status
				// selectedBrandStatus: `${action.brandName} - ${action.status}`,
			}
		case SET_DASHBOARD_AMBASSADOR_FILTER:
			return {
				...state,
				ambassadorFilter: action.ambassadorFilter
			}
		case CHANGE_DASHBOARD_GIGS_VIEW:
			return {
				...state,
				gigsView: action.gigsView
			}
		// case RECEIVE_ALL_BRAND_GIGS:
		// 	return {
		// 		...state,
		// 		selectedBrandGigs: action.gigs
		// 	}
		default:
			return state
	}
}
