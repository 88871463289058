import React, { useState, useEffect } from 'react'
import { Col, Row, Form } from 'react-bootstrap'

import FilterRelations from './FilterRelations'

function ZipFilter({ filterName, onSetRelation, relation, onSetAttribute, onSetValue }) {
	// const relationOptions = ['matches', 'equals', 'in', 'present', 'missing']
	const relationOptions = ['in', 'present', 'missing']

	useEffect(() => {
		onSetAttribute(filterName)
	}, [])

	return (
		<Row>
			<Col>
				<FilterRelations relationOptions={relationOptions} onSelectRelation={onSetRelation} />
			</Col>
			<Col>
				{(relation === 'matches' || relation === 'equals' || relation === 'in') &&
					// <Form.Control onChange={e => onSetValue(e.target.value)} />
					<Form.Control onChange={e => onSetValue(e.target.value.split(','))} />
				}
			</Col>
		</Row>
	)
}

export default ZipFilter
