import {
	RECEIVE_AMBASSADORS,
	CHANGE_PAGESIZE,
	CHANGE_PAGE,
	SORT_COLUMN,
	TOGGLE_FILTERS,
	UPDATE_STATUS,
	UPDATE_TAGS,
	UPDATE_FILTER_INPUT,
	RECEIVE_SIGNED_URL,
	SET_AMBASSADORS_VIEW,
	RESET_FILTERS
} from '../actions/ambassadors'

const initialState = {
	view: 'table',
	pageIndex: 0,
	pageSize: 20,
	showAdvancedFilters: false,
	filters: {
		approved: true,
		searchString: '',
		zipCodes: false,
		radius: false,
		zipCodeInput: '',
		radiusInMiles: '',
		waitlisted: false,
		tags: [],
		dmaNames: ''
	}
}

export default function ambassadors(state = initialState, action) {
	switch (action.type) {
		case RECEIVE_AMBASSADORS:
			return {
				...state,
				...action.ambassadors
			}
		case SET_AMBASSADORS_VIEW:
			return {
				...state,
				view: action.view
			}
		case CHANGE_PAGESIZE:
			return {
				...state,
				pageIndex: action.pageIndex,
				pageSize: action.pageSize
			}
		case CHANGE_PAGE:
			return {
				...state,
				pageIndex: action.pageIndex
			}
		case SORT_COLUMN:
			return {
				...state,
				sortField: action.sortField,
				sortDirection: action.sortDirection
			}
		case TOGGLE_FILTERS:
			return {
				...state,
				showAdvancedFilters: action.open
			}
		case UPDATE_STATUS:
			return {
				...state,
				filters: {
					...initialState.filters,
					approved: action.approved,
					rejected: action.rejected,
				}
			}
		case UPDATE_TAGS:
			return {
				...state,
				filters: {
					...state.filters,
					tags: state.filters.tags.concat([action.tag])
				}
			}
		case UPDATE_FILTER_INPUT:
			return {
				...state,
				filters: {
					...state.filters,
					[action.name]: action.value
				}
			}
		case RESET_FILTERS:
			return {
				...state,
				filters: {
					...initialState.filters,
					approved: state.filters.approved
				}
			}
		case RECEIVE_SIGNED_URL:
			const results = [...state.results]
			const newState = Object.assign([...results], {[action.index]: Object.assign(results[action.index], {profileImage: action.urls.profileImage, profileVideo: action.urls.profileVideo})})

			return {
				...state,
				results: newState
			}
		default:
			return state
	}
}
