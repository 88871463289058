import React, { Fragment, useState, useEffect } from 'react'
import { Col, Row, Form } from 'react-bootstrap'

import FilterRelations from './FilterRelations'

function GeoFilter({ filterName, onSetRelation, relation, onSetAttribute, onSetValue, value }) {
	const relationOptions = ['geo_distance']
	const [fromStore, setFromStore] = useState(false)

	const handleCheckboxClick = () => {
		const newState = { ...value }
		delete newState.postal_codes

		onSetValue({ ...newState })
		setFromStore(!fromStore)
	}

	return (
		<Fragment>
			<Row className='mb-5'>
				<Col>
					<Form.Check
						type='checkbox'
						label='Distance from stores'
						onChange={handleCheckboxClick}
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					<FilterRelations relationOptions={relationOptions} onSelectRelation={onSetRelation} />
				</Col>
				<Col>
					<Form.Control
						type='number'
						placeholder='Distance in miles'
						onChange={e => onSetValue({
							...value,
							distance: e.target.value + 'mi'
						})}
					/>
				</Col>
				<Col>
					{!fromStore &&
						<Form.Control
							required
							placeholder='Zip code'
							onChange={e => onSetValue({
								...value,
								postal_codes: e.target.value.split(',')
							})}
						/>
					}
				</Col>
			</Row>
		</Fragment>
	)
}

export default GeoFilter
