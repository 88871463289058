import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment-timezone'
import { API } from 'aws-amplify'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

import config from '../config'

class AmbassadorCard extends Component {
	state = {
		currentIndex: 0,
		visuals: []
	}

	componentDidMount() {
		this.getInitialData()
	}

	render() {
		const { applicant, onAccept, onReject, accepted, applicationDetails } = this.props
		const { visuals, gigsAssociated, currentIndex } = this.state

		return (
			<Card style={{ width: '22rem' }}>
				<div className='card-img-top-container'>
					{currentIndex === 0
						? <video src={visuals[currentIndex]} style={{height: '10vw'}} controls />
						: <Card.Img variant='top' src={visuals[currentIndex].signedUrl} style={{height: '10vw'}} />
					}
					{currentIndex > 0 &&
						<div className='card-img-top-leftarrow' onClick={this.handlePrev}>
							<i className='fas fa-chevron-left'></i>
						</div>
					}
					{currentIndex < visuals.length - 1 &&
						<div className='card-img-top-rightarrow' onClick={this.handleNext}>
							<i className='fas fa-chevron-right'></i>
						</div>
					}
				</div>
				<Card.Body>
					<Card.Title>
						<Link to={`/ambassador/${applicant.id}`}>{applicant.ambassador_name}</Link>
					</Card.Title>
					<Card.Text className='overflow-auto' style={{maxHeight: '100px'}}>
						<b>Applied On: </b>{moment(applicationDetails.ambassador_application_timestamp * 1000).format('MMM Do, YYYY')}
					</Card.Text>
					<Card.Text className='overflow-auto' style={{maxHeight: '100px'}}>
						<b>Bio: </b>{applicant.bio}
					</Card.Text>
					<Card.Text className='overflow-auto' style={{maxHeight: '100px'}}>
						<b>Experience: </b>{applicant.experience}
					</Card.Text>
					<Card.Text className='overflow-auto' style={{maxHeight: '100px'}}>
						<b>Gigs Completed: </b>{applicant.total_gigs_completed}
					</Card.Text>
					<Card.Text className='overflow-auto' style={{maxHeight: '100px'}}>
						<b>Gigs Associated: </b>{gigsAssociated && gigsAssociated.total}
					</Card.Text>
					<Card.Text className='overflow-auto' style={{maxHeight: '100px'}}>
						<b>Ambassador Since: </b>{applicant.created_date && moment(applicant.created_date * 1000).format('MMM Do, YYYY')}
					</Card.Text>
				</Card.Body>
				<Card.Footer className='text-center'>
					{accepted
						? <b style={{color: '#007bff'}}>Assigned</b>
						: <div className='d-flex justify-content-around'>
								<Button variant='success' onClick={onAccept.bind(this, applicant.id)}>Accept</Button>
								<Button variant='danger' onClick={onReject.bind(this, applicant.id)}>Reject</Button>
							</div>
					}
				</Card.Footer>
			</Card>
		)
	}

	getInitialData = () => {
		const { applicant } = this.props
		const videoUrl = applicant.registration_video_url && this.getAWSUrl(applicant.registration_video_url)
		const gigsAssociated = axios.get(`${config.coreAPI}/fieldDayAdmin/activities/appliedBy/${applicant.id}?pageFrom=0&pageSize=1`)

		videoUrl && videoUrl.then(res => this.setState({
			visuals: this.state.visuals.concat([res], applicant.profile_photos)
		}))

		gigsAssociated.then(res => this.setState({
			gigsAssociated: res.data.responses[0]
		}))
	}

	getAWSUrl = (key) => {
		return API.post('getSignedUrl', '/getSignedUrl', {
			body: { key }
		})
	}

	handlePrev = () => {
		const { currentIndex, visuals } = this.state

		this.setState({
			currentIndex: currentIndex > 0 ? currentIndex - 1 : visuals.length - 1
		})
	}

	handleNext = () => {
		const { currentIndex, visuals } = this.state
		const { applicant } = this.props
		const imgUrl =
			applicant.profile_photos &&
			currentIndex >= 0 &&
			currentIndex < applicant.profile_photos.length &&
			!visuals[currentIndex + 1].signedUrl &&
			this.getAWSUrl(visuals[currentIndex + 1].photo_url)

		imgUrl
		? imgUrl.then(res => {
				const newVisualsState = [...this.state.visuals]
				newVisualsState[currentIndex + 1].signedUrl = res

				this.setState({
					currentIndex: currentIndex < visuals.length - 1 ? currentIndex + 1 : 0,
					visuals: newVisualsState
				})
			})
		: this.setState({
			currentIndex: currentIndex < visuals.length - 1 ? currentIndex + 1 : 0
		})
	}
}

export default AmbassadorCard
