import { reduce } from 'underscore'

import {
	HANDLE_ERRORS,
	RESET_STATE,
	SET_BRANDS_VIEW,
	RECEIVE_BRANDS,
	RECEIVE_BRANDS_EXPENSES,
	RECEIVE_BRANDS_ACTIVITY_STATS,
	RECEIVE_SELECTED_BRAND,
	RECEIVE_BRAND_LOGO,
	RECEIVE_BRAND_STORES,
	RECEIVE_BRAND_USERS,
	RECEIVE_EMAIL_LISTS,
	RECEIVE_BRAND_STORE_GIGS,
	RECEIVE_BRAND_STORE_GIG_STATS
	// RECEIVE_BRAND_TOTAL_SPEND
} from '../actions/brands'

const initialState = {
	errors: [],
	view: 'brandsOverview',
	brands: null,
	selectedBrand: null
}

export default function brands(state = initialState, action) {
	switch (action.type) {
		case HANDLE_ERRORS:
			return {
				...state,
				errors: state.errors.concat([action.error])
			}
		case RESET_STATE:
			return initialState
		case SET_BRANDS_VIEW:
			return {
				...state,
				view: action.view
			}
		case RECEIVE_BRANDS:
			return {
				...state,
				brands: action.brands
			}
		case RECEIVE_BRANDS_EXPENSES:
			return {
				...state,
				brandsExpenses: reduce(action.brandsExpenses, (accum, el) => {
					accum.totalAmountInCents = accum.totalAmountInCents + el.total_in_cents
					accum[el.brand_id] = el

					return accum
				}, { totalAmountInCents: 0 })
			}
		case RECEIVE_BRANDS_ACTIVITY_STATS:
			return {
				...state,
				brandsActivityStats: reduce(action.brandsActivityStats, (accum, el, key, val) => {
					accum.businessesVisitedTotal = accum.businessesVisitedTotal + el.businesses_visited
					accum.materialsHandedOutTotal = accum.materialsHandedOutTotal + el.materials_handed_out
					accum.leadsTotal = accum.leadsTotal + el.business_card_count
					accum.totalGigs = accum.totalGigs + el.total_gigs
					accum.finishedGigs = accum.finishedGigs + el.total_gigs_finished
					accum.inProgressGigs = accum.inProgressGigs + el.total_gigs_in_progress
					accum.remainingGigs = accum.remainingGigs + el.total_gigs_not_started

					accum[el.brand_id] = el

					return accum
				}, {
					businessesVisitedTotal: 0,
					materialsHandedOutTotal: 0,
					leadsTotal: 0,
					totalGigs: 0,
					finishedGigs: 0,
					inProgressGigs: 0,
					remainingGigs: 0,
				})
			}
		case RECEIVE_SELECTED_BRAND:
			return {
				...state,
				selectedBrand: action.brand,
				view: 'brandProfile'
			}
		case RECEIVE_BRAND_LOGO:
			return {
				...state,
				selectedBrandLogo: action.url
			}
		case RECEIVE_BRAND_STORES:
			return {
				...state,
				selectedBrandStores: reduce(action.stores.stores, (accum, el) => {
					accum[el.store_id] = el
					return accum
				}, {})
			}
		case RECEIVE_BRAND_STORE_GIGS:
			const newSelectedBrandStores = Object.assign({}, state.selectedBrandStores)

			newSelectedBrandStores[action.storeId] = {
				...newSelectedBrandStores[action.storeId],
				totalGigs: action.gigs.total,
			}

			return {
				...state,
				selectedBrandStores: newSelectedBrandStores
			}
		case RECEIVE_BRAND_STORE_GIG_STATS:
			const newSelectedBrandStores2 = Object.assign({}, state.selectedBrandStores)

			newSelectedBrandStores2[action.storeId] = {
				...newSelectedBrandStores2[action.storeId],
				stats: action.stats,
			}

			return {
				...state,
				selectedBrandStores: newSelectedBrandStores2
			}
		case RECEIVE_BRAND_USERS:
			return {
				...state,
				selectedBrandUsers: action.users
			}
		case RECEIVE_EMAIL_LISTS:
			return {
				...state,
				emailLists: action.lists
			}
		// case RECEIVE_BRAND_TOTAL_SPEND:
		// 	return {
		// 		...state,
		// 		totalSpend: action.totalSpend
		// 	}
		default:
			return state
	}
}
