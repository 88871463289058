import React, { Fragment, useState, useEffect } from 'react'
import axios from 'axios'
import moment from 'moment-timezone'
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import { Dropdown } from 'semantic-ui-react'
import { map } from 'underscore'

import config from '../../config'

function GigDetail({ gigId, brandId, isPhoneGig, leadActions }) {
	const [gig, setGig] = useState(null)
	const [brand, setBrand] = useState(null)
	const [usersDropdown, setUsersDropdown] = useState([])
	const [editMode, setEditMode] = useState(false)
	const [totalSeconds, setTotalSeconds] = useState(0)
console.log("LAAAAA: ", leadActions)
	useEffect(() => {
		getGig()
		getUsers()
		getTotalSeconds()
	}, [])

	const getGig = async () => {
		try {
			const response = await axios.get(`${config.coreAPI}/fieldDayAdmin/activity/${gigId}`)
			const gig = { ...response.data.responses[0].results[0].brand_user_activity }

			gig.hours = gig.field_day_management.field_day_remuneration.max_hours
			gig.rate = gig.field_day_management.field_day_remuneration.ambassador_remuneration_rate_per_hour
			gig.created_by_userName = gig.created_by_user.username
			gig.assigned_to_userName = gig.assigned_to_user.username

			setGig(gig)
			setBrand(response.data.responses[0].results[0].associated_brand)
		} catch(e) {
			console.error(e)
		}
	}

	const getUsers = async () => {
		if(!brandId) return

		try {
			const res = await axios.get(`${config.coreAPI}/users?brandId=${brandId}&pageSize=10000`)

			const usersDropdown = map(res.data.users, (user, i) => ({
				key: user.id,
				value: user.username,
				text: `${user.first_name} ${user.last_name}`,
			}))

			setUsersDropdown(usersDropdown)
		} catch(e) {
			console.error(e)
		}
	}

	const getTotalSeconds = () => {
		const total = leadActions.reduce((accum, el) => {console.log(el.duration)
			if(el.duration) {
				return accum += el.duration
			}

			return accum += 0
		}, 0)

		setTotalSeconds(total)
	}

	const handleInputChange = (e, n, v) => {
		const name = n || e.target.name
		const value = v || e.target.value
		const newState = { ...gig }

		newState[name] = value

		setGig(newState)
	}

	const handleSave = async () => {
		const updateAllChildren = gig.activity_group && gig.activity_group.group_master

		try {
			const body = { ...gig }

			await axios({
				url: `${config.coreAPI}/fieldDayAdmin/activity/${gig.id}`,
				method: 'patch',
				params: {
					hours: gig.hours,
					rate: gig.rate,
				},
			})

			await axios({
				method: 'patch',
				headers: { 'content-type': 'application/json' },
				url: `${config.coreAPI}/fieldDayAdmin/activity/${gig.id}/update`,
				params: {
					updateChildren: updateAllChildren
				},
				data: JSON.stringify({
					address: body.address,
					city: body.city,
					state: body.state,
					postal_code: body.postal_code,
				})
			})

			await axios({
				method: 'post',
				headers: { 'content-type': 'application/json' },
				url: `${config.coreAPI}/activity/${gig.id}`,
				data: {
					assigned_to_userName: gig.assigned_to_userName,
					created_by_userName: gig.created_by_userName,
				}
			})

			setEditMode(false)
			getGig()
		} catch(e) {
			alert('error in saving')
			console.error(e)
		}

	}


	if(!gig) return <em>loading..</em>

	return (
		<Fragment>
			<h4 className='border-bottom border-info text-center'>
				Gig Detail
				<i className='fas fa-edit pointer ml-3 mr-2' onClick={() => setEditMode(!editMode)}></i>
				{editMode && <Button variant='dark' size='sm' onClick={handleSave}>Save</Button>}
			</h4>

			<div className=''>
				{editMode
					? <InputGroup>
							<InputBox
								name='address'
								value={gig.address}
								onChange={handleInputChange}
							/>
							<InputBox
								name='city'
								value={gig.city}
								onChange={handleInputChange}
							/>
							<InputBox
								name='state'
								value={gig.state}
								onChange={handleInputChange}
							/>
							<InputBox
								name='postal_code'
								value={gig.postal_code}
								onChange={handleInputChange}
							/>
						</InputGroup>
					: <div>
							<b>Location: </b>
							{gig.address}, {gig.city}, {gig.state} {gig.postal_code}
						</div>
				}
			</div>

			<div className='d-flex '>
				<b>Shift Duration: </b>
				<Col md={3}>
					{editMode
						? <InputBox
								name='hours'
								value={gig.hours || gig.field_day_management.field_day_remuneration.max_hours}
								onChange={handleInputChange}
							/>
						: <div>
								{gig.field_day_management.field_day_remuneration.max_hours}
							</div>
					}
				</Col>
			</div>

			<div className='d-flex '>
				<b>Hourly Rate: </b>
				<Col md={3}>
					{editMode
						? <InputBox
								name='rate'
								value={gig.rate || gig.field_day_management.field_day_remuneration.ambassador_remuneration_rate_per_hour}
								onChange={handleInputChange}
							/>
						: <div>
								${gig.field_day_management.field_day_remuneration.ambassador_remuneration_rate_per_hour}
							</div>
					}
				</Col>
			</div>

			<div className='d-flex '>
				<b>Created by brand user: </b>
				<Col md={5}>
					{editMode
						? <DropDownMenu
								name='created_by_userName'
								value={gig.created_by_userName || `${gig.created_by_user.first_name} ${gig.created_by_user.last_name}`}
								onChange={handleInputChange}
								options={usersDropdown}
							/>
						: <div>{gig.created_by_user.first_name} {gig.created_by_user.last_name}</div>
					}
				</Col>
			</div>

			<div className='d-flex '>
				<b>Assigned to brand user: </b>
				<Col md={5}>
					{editMode
						? <DropDownMenu
								name='assigned_to_userName'
								value={gig.assigned_to_userName || `${gig.assigned_to_user.first_name} ${gig.assigned_to_user.last_name}`}
								onChange={handleInputChange}
								options={usersDropdown}
							/>
						: <div>{gig.assigned_to_user.first_name} {gig.assigned_to_user.last_name}</div>
					}
				</Col>
			</div>

			<div className=''><b>Started: </b>
				{gig.field_day_operation_details.started_gig_timestamp &&
					moment(gig.field_day_operation_details.started_gig_timestamp * 1000)
					.tz(gig.time_zone)
					.format('MM/DD/YY - h:mm A')
				}&nbsp;
				{gig.field_day_operation_details.started_gig_timestamp && gig.time_zone_human}
			</div>

			<div className=''><b>Ended: </b>
				{gig.field_day_operation_details.finished_gig_timestamp &&
					moment(gig.field_day_operation_details.finished_gig_timestamp * 1000)
					.tz(gig.time_zone)
					.format('MM/DD/YY - h:mm A')
				}&nbsp;
				{gig.field_day_operation_details.finished_gig_timestamp && gig.time_zone_human}
			</div>

			<div className='d-flex'>
				<b>Total time worked:</b>&nbsp;
				<div>
					{gig.field_day_operation_details.started_gig_timestamp &&
						gig.field_day_operation_details.finished_gig_timestamp &&
						new Date((gig.field_day_operation_details.finished_gig_timestamp - gig.field_day_operation_details.started_gig_timestamp) * 1000).toISOString().substr(11, 8)
					}
				</div>
			</div>

			<div className='d-flex'>
				<b>Total time on phone:</b>&nbsp;
				<div>{totalSeconds && new Date(totalSeconds * 1000).toISOString().substr(11, 8)}</div>
			</div>

			{brand && isPhoneGig &&
				<PhoneGigStats
					gig={gig}
					brand={brand}
				/>
			}
		</Fragment>
	)
}

function InputBox({ name, value, onChange }) {
	return (
		<Form.Control
			placeholder={name}
			name={name}
			value={value}
			onChange={onChange}
		/>
	)
}

function DropDownMenu ({ name, value, options, onChange }) {
	return (
		<Dropdown
			// clearable
			search
			selection
			placeholder='Select User'
			// className='ws-100'
			name={name}
			options={options}
			value={value}
			onChange={(e, { name, value }) => name && value && onChange(null, name, value)}
		/>
	)
}

function PhoneGigStats({ gig, brand }) {
	const [totalCalls, setTotalCalls] = useState(null)
	const [callsOver10s, setCallsOver10s] = useState(null)
	const [callsOver30s, setCallsOver30s] = useState(null)
	const [emailsCollected, setEmailsCollected] = useState(null)
	const [emailsSent, setEmailsSent] = useState(null)
	const [emailsDelivered, setEmailsDelivered] = useState(null)
	const [emailsOpen, setEmailsOpen] = useState(null)
	const [emailsClick, setEmailsClick] = useState(null)

	useEffect(() => {
		getCallsStats()
		getContactsStats()
		getSendGridStats()
	}, [])

	const getCallsStats = async () => {
		try {
			const callsStats = await axios.get(`${config.coreAPI}/lead-actions/stats?activityId=${gig.id}`)
			const allCalls = callsStats.data.lead_action_call_statuses.reduce((accum, el) => accum += el.count, 0)
			// const completedCalls = callsStats.data.lead_action_call_statuses.filter(status => status.call_status === 'completed')

			// setTotalCalls(completedCalls[0] ? completedCalls[0].count : '')
			setTotalCalls(allCalls)
			setCallsOver10s(callsStats.data.lead_action_calls_over_10_seconds)
			setCallsOver30s(callsStats.data.lead_action_calls_over_30_seconds)
		} catch(e) {
			console.error(e)
		}
	}

	const getContactsStats = async () => {
		try {
			const contactsStats = await axios.get(`${config.coreAPI}/contact/stats?activityId=${gig.id}`)

			setEmailsCollected(contactsStats.data.phone_gig_contacts_with_emails)
		} catch(e) {
			console.error(e)
		}
	}

	const getSendGridStats = async () => {
		try {
			const stats = await axios.get(`https://ddnofdks8d.execute-api.us-west-2.amazonaws.com/dev/getSendGridStats`, {
			// const sgStat = await axios.get(`https://api.sendgrid.com/v3/categories/stats`, {
				// headers: { 'Authorization': `Bearer ${gigsData[i].associated_brand.sendgrid_api_key}`, },
				params: {
					start_date: moment(gig.due_date_millis).format('YYYY-MM-DD'),
					categories: `Gig ID: ${gig.id}`,
					limit: 1000,
					aggregated_by: 'day',
					sendgrid_api_key: brand.sendgrid_api_key
				}
			})

			console.log("SG::::< ", stats)
			setEmailsSent(stats.data[1].reduce((accum, el) => accum += el.stats[0].metrics.requests , 0))
			setEmailsDelivered(stats.data[1].reduce((accum, el) => accum += el.stats[0].metrics.delivered , 0))
			setEmailsOpen(stats.data[1].reduce((accum, el) => accum += el.stats[0].metrics.opens , 0))
			setEmailsClick(stats.data[1].reduce((accum, el) => accum += el.stats[0].metrics.clicks , 0))
		} catch(e) {
			console.error(e)
		}
	}

	console.log('totalCalls: ', totalCalls)
	console.log('emailsCollected: ', emailsCollected)

	return (
		<Fragment>
			<div className='d-flex'>
				<b>Total Calls:</b>&nbsp;
				<div>{totalCalls}</div>
			</div>

			<div className='d-flex'>
				<b>Calls over 10s:</b>&nbsp;
				<div>{callsOver10s}</div>
			</div>

			<div className='d-flex'>
				<b>Calls over 30s:</b>&nbsp;
				<div>{callsOver30s}</div>
			</div>

			<div className='d-flex'>
				<b>Connection Rate:</b>&nbsp;
				<div>{callsOver30s && totalCalls ? `${(callsOver30s / totalCalls * 100).toFixed(0)}%` : ''}</div>
			</div>

			<div className='d-flex'>
				<b>Emails Collected:</b>&nbsp;
				<div>{emailsCollected}</div>
			</div>

			<div className='d-flex'>
				<b>Emails Sent:</b>&nbsp;
				<div>{emailsSent}</div>
			</div>

			<div className='d-flex'>
				<b>Emails Delivered:</b>&nbsp;
				<div>{emailsDelivered}</div>
			</div>

			<div className='d-flex'>
				<b>Email Opens:</b>&nbsp;
				<div>{emailsOpen}</div>
			</div>

			<div className='d-flex'>
				<b>Email Open Rate:</b>&nbsp;
				<div>{emailsOpen && emailsDelivered ? `${(emailsOpen / emailsDelivered * 100).toFixed(0)}%` : ''}</div>
			</div>

			<div className='d-flex'>
				<b>Email Clicks:</b>&nbsp;
				<div>{emailsClick}</div>
			</div>

			<div className='d-flex'>
				<b>Email Click Rate:</b>&nbsp;
				<div>{emailsClick && emailsDelivered ? `${(emailsClick / emailsDelivered * 100).toFixed(0)}%` : ''}</div>
			</div>

		</Fragment>
	)
}

export default GigDetail
