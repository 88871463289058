import React, { useState } from 'react'
import { Modal, Col, Button } from 'react-bootstrap'

import PlaceTypeFilter from './PlaceTypeFilter'
import BusinessTypeIdFilter from './BusinessTypeIdFilter'
import SicCodesFilter from './SicCodesFilter'
import PrimarySicCodeFilter from './PrimarySicCodeFilter'
import EmployeeCountFilter from './EmployeeCountFilter'
import PostalCodeFilter from './PostalCodeFilter'
import ZipFilter from './ZipFilter'
import CityFilter from './CityFilter'
import StateFilter from './StateFilter'
import EmailFilter from './EmailFilter'
import PhoneFilter from './PhoneFilter'
import GeoFilter from './GeoFilter'
import HeadquartersIdFilter from './HeadquartersIdFilter'

function FilterModal({ showModal, onClose, filterName, onFilterChange }) {
	const [relation, setRelation] = useState('')
	const [attribute, setAttribute] = useState('')
	const [value, setValue] = useState(null)

	const addFilter = () => {
		if(!relation) {
			alert('You must select a relation')
			return
		}

		onFilterChange({
			relation,
			attribute,
			value,
		})

		onClose()
	}
console.log('value: ', value)
console.log('relation: ', relation)
console.log('attribute: ', attribute)
	return (
		<Modal
			centered
			// size='lg'
			// dialogClassName='modal-50w'
			show={showModal}
			onHide={onClose}
		>
			<Modal.Header closeButton>
				<Modal.Title>Filter by {filterName}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{filterName === 'place_type' &&
					<PlaceTypeFilter
						filterName={filterName}
						onSetRelation={setRelation}
						onSetAttribute={setAttribute}
						onSetValue={setValue}
						value={value}
					/>
				}
				{filterName === 'business_type_ids' &&
					<BusinessTypeIdFilter
						filterName={filterName}
						onSetRelation={setRelation}
						onSetAttribute={setAttribute}
						onSetValue={setValue}
						relation={relation}
					/>
				}
				{filterName === 'headquarters_id' &&
					<HeadquartersIdFilter
						filterName={filterName}
						onSetRelation={setRelation}
						onSetAttribute={setAttribute}
						onSetValue={setValue}
						relation={relation}
					/>
				}
				{filterName === 'sic_code_ids' &&
					<SicCodesFilter
						filterName={filterName}
						onSetRelation={setRelation}
						onSetAttribute={setAttribute}
						onSetValue={setValue}
						relation={relation}
					/>
				}
				{filterName === 'primary_sic_code_id' &&
					<PrimarySicCodeFilter
						filterName={filterName}
						onSetRelation={setRelation}
						onSetAttribute={setAttribute}
						onSetValue={setValue}
						relation={relation}
					/>
				}
				{filterName === 'estimated_location_employee_count' &&
					<EmployeeCountFilter
						filterName={filterName}
						onSetRelation={setRelation}
						onSetAttribute={setAttribute}
						onSetValue={setValue}
						relation={relation}
					/>
				}
				{filterName === 'postal_code' &&
					<PostalCodeFilter
						filterName={filterName}
						onSetRelation={setRelation}
						onSetAttribute={setAttribute}
						onSetValue={setValue}
						relation={relation}
					/>
				}
				{filterName === 'zip' &&
					<ZipFilter
						filterName={filterName}
						onSetRelation={setRelation}
						onSetAttribute={setAttribute}
						onSetValue={setValue}
						relation={relation}
					/>
				}
				{filterName === 'city' &&
					<CityFilter
						filterName={filterName}
						onSetRelation={setRelation}
						onSetAttribute={setAttribute}
						onSetValue={setValue}
						relation={relation}
					/>
				}
				{filterName === 'state' &&
					<StateFilter
						filterName={filterName}
						onSetRelation={setRelation}
						onSetAttribute={setAttribute}
						onSetValue={setValue}
						relation={relation}
					/>
				}
				{filterName === 'contacts.email' &&
					<EmailFilter
						filterName={filterName}
						onSetRelation={setRelation}
						onSetAttribute={setAttribute}
						onSetValue={setValue}
						relation={relation}
					/>
				}
				{filterName === 'phone' &&
					<PhoneFilter
						filterName={filterName}
						onSetRelation={setRelation}
						onSetAttribute={setAttribute}
						onSetValue={setValue}
						relation={relation}
					/>
				}
				{filterName === 'geo_distance' &&
					<GeoFilter
						filterName={filterName}
						onSetRelation={setRelation}
						onSetAttribute={setAttribute}
						onSetValue={setValue}
						value={value}
						relation={relation}
					/>
				}
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={addFilter}>Filter</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default FilterModal
