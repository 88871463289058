import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactTable from 'react-table'
import { each, map, isObject } from 'underscore'
import moment from 'moment'

import { getStoreGigs, getGigStats } from '../../../actions/brands'

class CampaignOverview extends Component {
	componentDidMount() {
		const { dispatch, brand, stores } = this.props
		// const storeIds = map(stores, store => store.store_id)
		// dispatch(getStoreGigs(brand.id, storeIds))

		each(stores, store => dispatch(getStoreGigs(brand.id, store.store_id)))

	}

	render() {
		const { stores } = this.props

		const tableStyle = {
			fontSize: '13px',
			fontWeight: '100',
			border: '1px solid #C1C1C1',
			color: 'black'
		}

		const headerStyle = {
			borderRight: 'none',
			fontWeight: '600',
			backgroundColor: '#f1f1f1',
			padding: '8px',
			textAlign: 'center',
			borderBottom: '1px solid #C1C1C1'
		}

		const columnStyle = {
			padding: '8px',
			textAlign: 'center',
			borderRight: '1px solid #C1C1C1'
		}

		if(!stores) return <div>Loading..</div>

		return (
			<div>
				<h2>Campaign Overview</h2>
				<hr />
				<ReactTable
					data={stores}
					resolveData={data => map(data, row => row)}
					collapseOnDataChange={false}
					getTdProps={(state, rowInfo, column, instance) => {
						return {
							onClick: (e, handleOriginal) => {
								const { dispatch, brand } = this.props
								const pageFrom = 0
								const pageSize = 10000
								const expanded = Object.keys(state.expanded)

								if(rowInfo.original.totalGigs === 0) {
									handleOriginal()
									return
								}

								for(let i = 0; i < expanded.length; i++) {
									if(expanded.includes(rowInfo.nestingPath[0] + '') && state.expanded[rowInfo.nestingPath[0]] !== false) {
										handleOriginal()
										return
									}
								}

								dispatch(getGigStats(brand.id, rowInfo.original.store_id, pageFrom, pageSize))

								if(handleOriginal) {
									handleOriginal()
								}
							}
						}
					}}
					columns={[
						{
							Header: 'Store ID',
							id: 'storeId',
							accessor: d => <span>{d.store_id}</span>,
							width: 100,
							headerStyle: headerStyle,
							style: columnStyle,
							filterMethod: (filter, row) => row[filter.id].props.children === Number(filter.value)
						},
						{
							Header: 'Store Name',
							id: 'storeName',
							accessor: d => <span>{d.store_name}</span>,
							headerStyle: headerStyle,
							style: columnStyle,
							filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase())
						},
						{
							Header: 'Address',
							id: 'address',
							accessor: d => <span>{d.address}, {d.city} {d.state}</span>,
							headerStyle: headerStyle,
							style: columnStyle,
							filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase())
						},
						{
							Header: 'Gigs',
							id: 'gigs',
							accessor: d => <span>{d.totalGigs}</span>,
							headerStyle: headerStyle,
							style: columnStyle,
							filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase())
						},
					]}
					defaultSortMethod={(a, b, desc) => {
						// force null and undefined to the bottom
						a = a.props.children === null || a.props.children === undefined ? '' : a.props.children
						b = b.props.children === null || b.props.children === undefined ? '' : b.props.children
						// force any string values to lowercase
						a = typeof a === 'string' ? a.toLowerCase() : a
						b = typeof b === 'string' ? b.toLowerCase() : b
						// Return either 1 or -1 to indicate a sort priority
						if (a > b) {
						  return 1
						}
						if (a < b) {
						  return -1
						}
						// returning 0, undefined or any falsey value will use subsequent sorts or
						// the index as a tiebreaker
						return 0
					}}
					filterable
					className="-striped -highlight"
					minRows={10}
					style={tableStyle}
					noDataText={'No stores found'}
					SubComponent={row => {
						if(stores[row.original.store_id].totalGigs === 0) {
							return <em style={{ padding: '20px' }}>There are no gigs associated with this store</em>
						}

						return (
							<div style={{ padding: '20px' }}>
								<br />
								<br />
								<ReactTable
									data={stores[row.original.store_id].stats}
									columns={[
										{
											Header: 'Title',
											id: 'gigTitle',
											headerStyle: headerStyle,
											style: columnStyle,
											accessor: d => <span>{d.gigTitle}</span>,
											filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase())
										},
										{
											Header: 'Date',
											id: 'dueDate',
											headerStyle: headerStyle,
											style: columnStyle,
											accessor: d => <span>{moment(d.dueDate).format('MM/DD/YY')}</span>,
											filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase())
										},
										{
											Header: 'Hours Completed',
											id: 'hoursCompleted',
											headerStyle: headerStyle,
											style: columnStyle,
											accessor: d => <span>{d.master && d.master.hours_completed}</span>,
											filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase())
										},
										{
											Header: 'Hours Left',
											id: 'hoursLeft',
											headerStyle: headerStyle,
											style: columnStyle,
											accessor: d => <span>{d.master && d.master.hours_left}</span>,
											filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase())
										},
										{
											Header: 'Leads',
											id: 'leads',
											headerStyle: headerStyle,
											style: columnStyle,
											accessor: d => <span>{d.master && d.master.business_cards_collected}</span>,
											filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase())
										},
										{
											Header: 'Businesses Visited',
											id: 'businessesVisited',
											headerStyle: headerStyle,
											style: columnStyle,
											accessor: d => <span>{d.master && d.master.businesses_visited}</span>,
											filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase())
										},
										{
											Header: 'Materials Distributed',
											id: 'materialsDistributed',
											headerStyle: headerStyle,
											style: columnStyle,
											accessor: d => <span>{d.master && d.master.materials_handed_out}</span>,
											filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase())
										}
									]}
									defaultSortMethod={(a, b, desc) => {
										// force null and undefined to the bottom
										a = a.props.children === null || a.props.children === undefined ? '' : a.props.children
										b = b.props.children === null || b.props.children === undefined ? '' : b.props.children
										// force any string values to lowercase
										a = typeof a === 'string' ? a.toLowerCase() : a
										b = typeof b === 'string' ? b.toLowerCase() : b
										// Return either 1 or -1 to indicate a sort priority
										if (a > b) {
										  return 1
										}
										if (a < b) {
										  return -1
										}
										// returning 0, undefined or any falsey value will use subsequent sorts or
										// the index as a tiebreaker
										return 0
									}}
									filterable
									className="-striped -highlight"
									defaultPageSize={10}
									style={tableStyle}
									noDataText={'No stores found'}
								/>
							</div>
						)
					}}
				/>
			</div>
		)
	}
}

function mapStateToProps({ brands }) {
	return {
		view: brands.view,
		brand: brands.selectedBrand,
		stores: brands.selectedBrandStores
	}
}

export default connect(mapStateToProps)(CampaignOverview)
