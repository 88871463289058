import React, { Component } from 'react'
import { API } from 'aws-amplify'
import axios from 'axios'

class TextMessage extends Component {
	state = {
		message: '',
		messages: []
	}

	componentDidMount() {
		this.getSmsHistory()

		const intervalId = setInterval(this.getSmsHistory, 2000)

		this.setState({ intervalId })
	}

	componentWillUnmount() {
		clearInterval(this.state.intervalId)
	}

	render() {
		const { messages } = this.state

		return (
			<div className='text-message-container'>
				<div className='text-message-chat'>
					{messages.map(msg => <div key={msg.sid}>{msg.body}</div>)}
				</div>
				<div className='text-message-input'>
					<textarea
						rows={7}
						cols={80}
						name='message'
						value={this.state.message}
						onChange={this.handleInputChange}
					>
					</textarea>
				</div>
				<button className='btn-blue' onClick={this.handleTextSend}>
					Send
				</button>
			</div>
		)
	}

	getSmsHistory = async () => {
		const toNumber = '+1' + this.props.recipients[0].number.replace(/\D/g, '')

		try {
			const history = await axios.get(`http://localhost:1337/smsHistory?toNumber=${toNumber}`)

			console.log(history)
			this.setState({ messages: history.data.messages })
		} catch(e) {
			console.error(e)
		}
	}

	handleInputChange = (e) => {
		const name = e.target.name
		const value = e.target.value

		this.setState({
			[name]: value
		})
	}

	handleTextSend = async () => {
		try {
			await API.post('sendSms', '/sendSms', {
				body: {
					message: this.state.message,
					recipients: this.props.recipients.map(user => user.number)
					// recipients: this.props.recipients.map(user => '+1' + user.number.replace(/\D/g, ''))
				}
			})

			// this.setState({
			// 	chat: this.state.chat.concat([this.state.message]),
			// 	message: ''
			// })
		} catch(e) {
			console.log(e)
			alert('message NOT sent')
		}
	}
}

export default TextMessage
