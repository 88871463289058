import React, { useState } from 'react'
import axios from 'axios'
import { Modal, Form, Col, Button } from 'react-bootstrap'

import config from '../../../config'
import UserForm from './UserForm'
import { getBrandUsers } from '../../../actions/brands'

function AddUser({ brandId, showModal, onClose }) {
	const [validated, setValidated] = useState(false)
	const [numOfUsers, setNumOfUsers] = useState(1)
	const [adding, setAdding] = useState(false)
	const [error, setError] = useState('')
	const [users, setUsers] = useState([
		{
		  first_name: '',
		  last_name: '',
		  email_address: '',
		  password: '',
		  displayUserProfile: true,
		  brand_roles_data: [
		    {
		      brand_id: 0,
		      role_name: '',
		      role_type: '',
		      region_names: [],
		      store_ids: []
		    }
		  ]
		}
	])

	const userFormComponents = []

	for(let i = 0; i < numOfUsers; i++) {
		userFormComponents.push(
			<UserForm
				key={i}
				index={i}
				values={users[i]}
				onChange={handleInputChange}
				onRemoveUser={handleRemoveUser}
			/>
		)
	}
console.log(users)
	return (
		<Modal
			size='lg'
			centered
			dialogClassName='modal-70w'
			show={showModal}
			onHide={onClose}
		>
			<Modal.Header closeButton>
				<Modal.Title>Add New Users</Modal.Title>
			</Modal.Header>
			<Form noValidate validated={validated} onSubmit={handleSubmit}>
				<Modal.Body>
					{userFormComponents}
					<Button className='mt-2 ml-1' onClick={handleAddNewUser}>+</Button>
				</Modal.Body>
				<Modal.Footer>
					<span className='text-danger'>{error}</span>
					<Button type='submit' disabled={adding}>{adding ? 'Adding..' : 'Add'}</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	)

	function handleAddNewUser() {
		const newState = [...users].concat([
			{
			  first_name: '',
			  last_name: '',
			  email_address: '',
			  password: '',
			  displayUserProfile: true,
			  brand_roles_data: [
			    {
			      brand_id: 0,
			      role_name: '',
			      role_type: '',
			      region_names: [],
			      store_ids: []
			    }
			  ]
			}
		])

		setNumOfUsers(numOfUsers + 1)
		setUsers(newState)
	}

	function handleRemoveUser(index) {
		const newState = [...users]

		newState.splice(index, 1)

		setNumOfUsers(numOfUsers - 1)
		setUsers(newState)
	}

	function handleInputChange(index, event) {console.log('INPUT: ', index, event.target)
		const { name, value } = event.target
		const newState = [...users]

		newState[index][name] = value

		setUsers(newState)
	}

	async function handleSubmit(event) {
		const form = event.currentTarget
		const userParams = [...users]

		setError('')
		setAdding(true)

		if(form.checkValidity() === false) {
			event.preventDefault()
			event.stopPropagation()
			setValidated(true)
			setAdding(false)
		} else {
			event.preventDefault()

			try {
				// if(autoGenerateId) {
				if(false) {
					// storeParams.forEach(store => store.generate_store_id = true)

					// await axios.post(`${config.coreAPI}/stores`, {
					// 	brand_id: brandId,
					// 	stores: storeParams
					// })

				} else {
					await axios.post(`${config.coreAPI}/users`, {
						users: userParams
					})
				}

				setAdding(false)
				onClose()

			} catch(e) {
				console.error(e)
				setAdding(false)

				if(e.response && e.response.data && e.response.data.message) {
					setError(e.response.data.message)
				} else if(e.response && e.response.data && e.response.data.errors) {
					setError(e.response.data.errors.map(err => (
						<div>
							<div>{err}</div>
						</div>
					)))
				} else {
					setError('Error! Check the logs.')
				}
			}
		}
	}
}

export default AddUser
