import React, { Fragment, useState, useEffect } from 'react'
import axios from 'axios'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import BsForm from 'react-bootstrap/Form'
import { Segment, Button, Form } from 'semantic-ui-react'
import { filter, map } from 'underscore'

import config from '../../config'
import WYSIWYG from '../WYSIWYG'

export default function PhoneGigConfig({ gigId, isPhoneGig }) {
	const [editMode, setEditMode] = useState(false)
	const [brand, setBrand] = useState(null)
	const [gig, setGig] = useState(null)
	const [updateChildren, setUpdateChildren] = useState(true)

	useEffect(() => {
		getGig()
	}, [])

	const getGig = async () => {
		try {
			const response = await axios.get(`${config.coreAPI}/fieldDayAdmin/activity/${gigId}`)

			setGig(response.data.responses[0].results[0].brand_user_activity)
		} catch(e) {
			console.error(e)
		}
	}

	const handleInputChange = (name, value) => {
		const newState = { ...gig }

		newState[name] = value

		setGig(newState)
	}

	const handleSaveChanges = async () => {
		const updateAllChildren = gig.activity_group && gig.activity_group.group_master ? updateChildren : false

		try {
			const body = { ...gig }

			await axios({
				method: 'patch',
				headers: { 'content-type': 'application/json' },
				url: `${config.coreAPI}/fieldDayAdmin/activity/${gig.id}/update`,
				params: {
					updateChildren: updateAllChildren
				},
				data: JSON.stringify({
					description: body.description,
					best_practices: body.best_practices,
					brand_details: body.brand_details,
					script: body.script,
				})
			})

			getGig()
			alert('Successfull')
			setEditMode(false)
		} catch(e) {
			alert('error in saving')
			console.error(e)
		}
	}

	return (
		<Fragment>
			<h4>
				Gig Description
				<i className='fas fa-edit pointer ml-3 mr-2' onClick={() => setEditMode(!editMode)}></i>
				{editMode &&
					<Button
						primary
						size='mini'
						loading={false}
						onClick={handleSaveChanges}
					>
						Save
					</Button>
				}
			</h4>
			{gig &&
				<Fragment>
					{gig.activity_group && gig.activity_group.group_master &&
						<Row>
							<Col>
								<BsForm.Check
									disabled={!editMode}
									type='checkbox'
									label='Update all child gigs'
									className='mb-4'
									checked={updateChildren ? true : false}
									onChange={e => setUpdateChildren(e.target.checked)}
								/>
							</Col>
						</Row>
					}
					<Row>
						<Col>
							<TextBox
								title='Description'
								name='description'
								value={gig.description}
								onInputChange={handleInputChange}
								editMode={editMode}
							/>
							{isPhoneGig &&
								<Fragment>
									<TextBox
										title='Best Practices'
										name='best_practices'
										value={gig.best_practices}
										onInputChange={handleInputChange}
										editMode={editMode}
									/>
									<TextBox
										title='Brand Details'
										name='brand_details'
										value={gig.brand_details}
										onInputChange={handleInputChange}
										editMode={editMode}
									/>
									<TextBox
										title='Script'
										name='script'
										value={gig.script}
										onInputChange={handleInputChange}
										editMode={editMode}
									/>
								</Fragment>
							}
						</Col>
					</Row>
				</Fragment>
			}
		</Fragment>
	)
}

function TextBox({ title, name, value, onInputChange, editMode }) {

	return (
		<Segment raised className='p-0 mb-1 mt-0'>
			<div className='text-center text-white font-weight-bold' style={{ backgroundColor: '#f49e23' }}>{title}</div>
			{editMode
				? <WYSIWYG
		  		  id={name}
		  		  onUpdateField={onInputChange}
		  		  fieldName={name}
		  		  defaultValue={value || ''}
		  		  height={'150px'}
		  		/>
				: <textarea
						className='w-100'
						rows={5}
						name={name}
						value={value || ''}
						// onChange={e => onInputChange(name, e.target.value)}
						disabled
						style={{
							border: 'none',
							resize: 'none'
						}}
					/>
			}
		</Segment>
	)
}
