import React, { Component } from 'react'
import axios from 'axios'
import ReactTable from 'react-table'
import { findIndex } from 'underscore'

import config from '../../config'

class ContactFormsTable extends Component {
	state = {
		contacts: []
	}

	componentDidMount() {
		this.getContacts()
	}

	render() {
		const { contacts } = this.state

		return (
			<ReactTable
				data={contacts}
				className='-striped -highlight table-style'
				defaultPageSize={10}
				filterable
				columns={[
					{
						Header: ' ',
						id: 'hotLead',
						headerClassName: 'table-header',
						className: 'table-column',
						width: 35,
						filterable: false,
						accessor: d => d.top_lead
							? <i className='fas fa-minus pointer' title='Remove Hot Lead' onClick={() => this.setTopLead(false, d.id)}></i>
							: <i className='fas fa-plus pointer' title='Mark Hot Lead' onClick={() => this.setTopLead(true, d.id)}></i>
					},
					{
						Header: 'Name',
						id: 'contactName',
						headerClassName: 'table-header',
						className: 'table-column',
						filterMethod: (filter, row) => row[filter.id].props.children === Number(filter.value),
						accessor: d => (
							<span>
								{d.top_lead && <i className='fas fa-fire-alt fa-lg mr-2' title='Hot Lead!' style={{ color: '#e25822' }}></i>}
								{d.contact_name}
							</span>
						)
					},
					{
						Header: 'Company',
						id: 'companyName',
						headerClassName: 'table-header',
						className: 'table-column',
						filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase()),
						accessor: d => <span>{d.company_name}</span>
					},
					{
						Header: 'Title',
						id: 'title',
						headerClassName: 'table-header',
						className: 'table-column',
						filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase()),
						accessor: d => <span>{d.contact_title}</span>
					},
					{
						Header: 'Email',
						id: 'email',
						headerClassName: 'table-header',
						className: 'table-column',
						filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase()),
						accessor: d => <span>{d.contact_email}</span>
					},
					{
						Header: 'Phone',
						id: 'phone',
						headerClassName: 'table-header',
						className: 'table-column',
						filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase()),
						accessor: d => <span>{d.phone_1}</span>
					},
					{
						Header: 'Number of Employees',
						id: 'numberOfEmployees',
						headerClassName: 'table-header',
						className: 'table-column',
						filterMethod: (filter, row) => row[filter.id].props.children === Number(filter.value),
						accessor: d => <span>{d.employee_size}</span>
					},
					{
						Header: 'Notes',
						id: 'notes',
						headerClassName: 'table-header',
						className: 'table-column',
						filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase()),
						accessor: d => (
							<textarea
								rows={3}
								style={{
									width: '100%',
									border: 'none',
									backgroundColor: 'inherit',
									coloe: 'inherit'
								}}
							>
								{d.notes}
							</textarea>
						)
					},
				]}
				defaultSortMethod={(a, b, desc) => {
					// force null and undefined to the bottom
					a = a.props.children === null || a.props.children === undefined ? '' : a.props.children
					b = b.props.children === null || b.props.children === undefined ? '' : b.props.children
					// force any string values to lowercase

					a = typeof a === 'string' ? a.toLowerCase().replace(/,/g, '').replace(/\$/g, '') : a
					b = typeof b === 'string' ? b.toLowerCase().replace(/,/g, '').replace(/\$/g, '') : b

					if(Number(a) || Number(b)) {
						a = Number(a)
						b = Number(b)
					}

					// Return either 1 or -1 to indicate a sort priority
					if (a > b) {
					  return 1
					}
					if (a < b) {
					  return -1
					}
					// returning 0, undefined or any falsey value will use subsequent sorts or
					// the index as a tiebreaker
					return 0
				}}
			/>
		)
	}

	getContacts = async () => {
		const { gigId, onError } = this.props

		try {
			const gig = await axios.get(`${config.coreAPI}/fieldDayAdmin/activity/${gigId}`)

			gig.data.responses[0].results[0].brand_user_activity.field_day_operation_details.contact_forms.forEach(async (form) => {
				const contact = await axios.get(`${config.coreAPI}/contact/${form.contact_id}`)

				this.setState({ contacts: this.state.contacts.concat(contact.data.responses[0].results[0]) })
			})
		} catch(e) {
			console.error(e)
			onError(e)
		}
	}

	setTopLead = async (status, contactId) => {
		const { gigId, onError } = this.props
		const params = new URLSearchParams()

		params.append('contactIds', contactId)
		params.append('isTopLead', status)

		try {
			await axios({
				method: 'post',
				url: `${config.coreAPI}/fieldDayAdmin/activity/${gigId}/topLeads`,
				data: params,
				headers: { 'content-type': 'application/x-www-form-urlencoded' }
			})

			const newContact = await axios.get(`${config.coreAPI}/contact/${contactId}`)

			const updatedIndex = findIndex(this.state.contacts, contact => contact.id === contactId)
			const newState = [...this.state.contacts]

			newState[updatedIndex] = newContact.data.responses[0].results[0]

			this.setState({ contacts: newState })

		} catch(e) {
			console.error(e)
			onError(e)
		}
	}
}

export default ContactFormsTable
