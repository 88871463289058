import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import _ from 'underscore'
import ReactTable from 'react-table'
import { API } from "aws-amplify"
import axios from 'axios'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import Alert from 'react-bootstrap/Alert'
import InputGroup from 'react-bootstrap/InputGroup'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'

import config from '../config'
import Loading from '../components/Loading'
// import Modal from '../containers/Modal'
import Email from '../components/shared/Email'
import Slideshow from '../components/Slideshow'
import TextMessage from '../components/shared/TextMessage'
import GigActions from '../components/GigActions'
import EmailTraining from '../components/shared/EmailTraining'
import PayAmbassador from '../components/ambassadorProfile/PayAmbassador'
import AmbassadorScoreTable from '../components/ambassadorProfile/AmbassadorScoreTable'
import ExportTable from '../components/ambassadorProfile/ExportTable'
import Calendar from '../components/ambassadorProfile/Calendar'
import RejectAmbassador from '../components/ambassadorProfile/RejectAmbassador'
// import SideBar from './SideBar'

class AmbassadorProfile extends Component {
	state = {
		loading: false,
		processing: false,
		done: false,
		errors: [],
		tableLoading: false,
		currentImg: 0,
		gigsStatus: 'appliedTo',
		showModal: false,
		modalName: null,
		notesEditMode: false,
		editedFields: {},
		internalNotes: '',
		ambassadorGigs: {}
	}

	componentDidMount() {
		const ambassadorId = this.props.match.params.id
		const { gigsStatus } = this.state

		this.getAmbassador(ambassadorId)
		this.getAmbassadorGigs(ambassadorId, gigsStatus)
		this.getPaymentCategories()
		this.getTotalEarnings()
		this.getLateNoShowEvents()
	}

	render() {
		const {
			ambassador,
			loading,
			ambassadorPhotos,
			ambassadorVideo,
			ambassadorGigs,
			notesEditMode,
			internalNotes,
			gigsStatus,
			showModal,
			modalName,
			clickedGig,
			done,
			errors,
			paymentCategories,
			totalEarnings,
			lateNoShowEvents,
			ambassadorQuizScores
		} = this.state

		const gigsColumns = [
			'GIG',
			'BRAND',
			'DUE DATE',
			'COMPLETED DATE',
			'HOURS',
			'EARNINGS',
			'BUSINESS CARDS',
			'BONUS'
		]

		const paymentsColumns = [
			'TITLE',
			'CATEGORY',
			'GIG',
			'BRAND',
			'AMOUNT',
			'STATUS',
			'ARRIVE ON'
		]
console.log(this.state)
		return (
			<Fragment>
				<Col lg={9} className='m-auto'>
				{loading
					? <Loading />
					: <Card>
							{done && <Alert variant={'success'}>Successful</Alert>}
							{errors.map((err, i) => <Alert key={i} variant={'danger'}>{err}</Alert>)}
							{showModal &&
								<Modal
									show={this.state.showModal}
									onHide={this.handleCloseModal}
									size={modalName === 'gigActions' ? '' : 'lg'}
									dialogClassName={modalName === 'gigActions' || modalName === 'calendar' ? 'modal-60w' : ''}
									aria-labelledby="contained-modal-title-vcenter"
									centered
								>
									{modalName === 'email' &&
										<Fragment>
											<Modal.Header closeButton>
												<Modal.Title id="contained-modal-title-vcenter">
													Email Form
												</Modal.Title>
											</Modal.Header>
											<Modal.Body>
												<Email
													recipients={[ambassador]}
													onSendEmail={this.handleSendEmail}
												/>
											</Modal.Body>
										</Fragment>
									}
									{modalName === 'photos' &&
										<Fragment>
											<Modal.Header closeButton>
												<Modal.Title id="contained-modal-title-vcenter">
													Ambassador Photos
												</Modal.Title>
											</Modal.Header>
											<Modal.Body>
												<Slideshow
													items={_.filter(ambassadorPhotos, item => !item.includes('registrationVideo'))}
													slideNumber={this.state.currentImg}
													onDeletePhoto={this.handleDeletePhoto}
													onSetPhotoAsDefault={this.handleSetPhotoAsDefault}
													profilePhotos={this.state.ambassador.profile_photos || []}
													// onClose={this.handleCloseModal}
												/>
											</Modal.Body>
										</Fragment>
									}
									{modalName === 'textMessage' &&
										<Fragment>
											<Modal.Header closeButton>
												<Modal.Title id="contained-modal-title-vcenter">
													Text Message
												</Modal.Title>
											</Modal.Header>
											<Modal.Body>
												<TextMessage
													recipients={[
														{
															name: ambassador.ambassador_name,
															number: ambassador.phone,
															displayImage: ambassadorPhotos[0]
														}
													]}
												/>
											</Modal.Body>
										</Fragment>
									}
									{modalName === 'gigActions' &&
										<Fragment>
											<Modal.Header closeButton>
												<Modal.Title id='contained-modal-title-vcenter'>
													Gig Actions
												</Modal.Title>
											</Modal.Header>
											<Modal.Body>
												<GigActions gig={clickedGig} />
											</Modal.Body>
										</Fragment>
									}
									{modalName === 'training' &&
										<Fragment>
											<Modal.Header closeButton>
												<Modal.Title id="contained-modal-title-vcenter">
													Training
												</Modal.Title>
											</Modal.Header>
											<Modal.Body>
												<EmailTraining
													ambassadors={[ambassador]}
												/>
											</Modal.Body>
										</Fragment>
									}
									{modalName === 'calendar' &&
										<Fragment>
											<Modal.Header closeButton>
												<Modal.Title id="contained-modal-title-vcenter">Gigs Calendar</Modal.Title>
											</Modal.Header>
											<Modal.Body>
												<Calendar data={ambassadorGigs && ambassadorGigs.results}/>
											</Modal.Body>
										</Fragment>
									}
									{modalName === 'rejectAmbassador' &&
										<Fragment>
											<Modal.Header closeButton>
												<Modal.Title id="contained-modal-title-vcenter">Reject Ambassador</Modal.Title>
											</Modal.Header>
											<Modal.Body>
												<RejectAmbassador ambassadorId={ambassador.id}/>
											</Modal.Body>
										</Fragment>
									}
								</Modal>
							}
							<Row noGutters>
								<Col lg={3} md={12}>
									{ambassadorPhotos &&
										<Pictures
											mediaUrls={ambassadorPhotos}
											index={this.state.currentImg}
											onImgCaptionClick={this.handleImgCaptionClick}
											onImgClick={this.handleShowModal}
										/>
									}
									{ambassador &&
										<AmbassadorInfo
											ambassador={ambassador}
											onUpdateAmbassadorData={this.getAmbassadorInfo}
											paymentCategories={paymentCategories}
											onSuccess={this.handleSuccess}
											onError={this.handleErrors}
											totalEarnings={totalEarnings}
										/>
									}
								</Col>
								{ambassador && 
									<Col lg={9} md={12} className='ambassador-profile-right-pane'>
										<h1 className='center-text'>
											{ambassador.ambassador_name}&nbsp;&nbsp;&nbsp;
											{!ambassador.approved &&
												<Fragment>
													<Button variant='success' onClick={this.handleApproveAmbassador}>Approve</Button>
													{!ambassador.rejected && <Button className='ml-3' variant='danger' onClick={() => this.handleShowModal('rejectAmbassador')}>Reject</Button>}
													{ambassador.rejected && <Button className='ml-3' onClick={this.handleUnreject}>Un-reject</Button>}
												</Fragment>
											}
											<i
												className="far fa-trash-alt fa-xs text-danger float-right mr-2 mt-2 pointer"
												onClick={this.handleDeleteAmbassador}
												title='Delete Ambassador'
											>
											</i>
										</h1>
										<div style={{fontWeight: '100'}} className='center-text'>
											<span className='pointer' style={{color: '#337AB7'}} onClick={() => this.handleShowModal('email')}>{ambassador.email_address}</span>
											&nbsp;|&nbsp;
											<span>{ambassador.phone}</span>
										</div>
										<Col style={{fontWeight: '100', margin: '10px'}} className='center-text'>
											<Address
												ambassadorId={ambassador.id}
												street_address={ambassador.street_address}
												city={ambassador.city}
												state={ambassador.state}
												zip={ambassador.zip_code}
												onUpdateAmbassadorData={this.getAmbassadorInfo}
												onSuccess={this.handleSuccess}
												onError={this.handleErrors}
											/>
										</Col>
										<div style={{display: 'flex', justifyContent: 'space-around', margin: 'auto', width: '45%'}} className='center-text'>
											<Button variant='primary' onClick={() => this.handleShowModal('email')}>Email</Button>
											<Button variant='primary' onClick={() => this.handleShowModal('textMessage')}>Text</Button>
											<Button variant='primary' onClick={() => this.handleShowModal('training')}>Send Training</Button>
											<Button variant='primary' onClick={this.handleResetPassword}>Reset Password</Button>
										</div>
										<Col style={{marginBottom: '40px', marginTop: '20px'}} className='center-text'>
											<video src={ambassadorVideo && ambassadorVideo} style={{maxWidth: '100%', maxHeight: '400px'}} controls />
											<div className='d-flex justify-content-around mt-3'>
												<div className='custom-file'>
												  <input type='file' className='custom-file-input' id='customFile' onChange={this.handleUploadVideo} />
												  <Button>Upload Video</Button>
												  {this.state.uploadingVideo && <em>Uploading..</em>}
												</div>
												{ambassadorVideo &&
													<Button variant='danger' onClick={this.handleRejectVideo}>
														Reject&nbsp;Video
													</Button>
												}
												<div className='custom-file'>
												  <input type='file' className='custom-file-input' id='customFile' onChange={this.handleUploadPhotos} />
												  <Button>Upload Photos</Button>
												  {this.state.uploadingPhotos && <em>Uploading..</em>}
												</div>
											</div>
										</Col>
										<Col style={{marginBottom: '40px'}}>
											<div className='label-text'>ABOUT</div>
											<p>{ambassador.bio}</p>
										</Col>
										<Col style={{marginBottom: '40px'}}>
											<div className='label-text'>EXPERIENCE</div>
											<p>{ambassador.experience}</p>
										</Col>
										<Col style={{marginBottom: '40px'}}>
											<div className='label-text'>TAGS</div>
											<Tags
												tags={ambassador.tags}
												ambassadorId={ambassador.id}
												onError={this.handleErrors}
												onSuccess={this.handleSuccess}
												onUpdateAmbassadorData={this.getAmbassadorInfo}
											/>
										</Col>
										<Col style={{marginBottom: '40px'}}>
											<div className='label-text'>INTERNAL NOTES</div>
											{notesEditMode
												? <Form>
														<Form.Control
															as='textarea'
															rows='7'
															name='notes'
															value={internalNotes && internalNotes.body}
															onChange={this.handleNotesInput}
														/>
													</Form>
												: <p>{internalNotes && internalNotes.body}</p>
											}
										</Col>
										<Col>
											{!notesEditMode && <Button variant='primary' onClick={() => this.setState({ notesEditMode: !notesEditMode })}>Edit</Button>}
											{notesEditMode && <Button variant='danger' onClick={() => this.setState({ notesEditMode: !notesEditMode })}>Cancel</Button>}
											{notesEditMode && <Button variant='primary' onClick={this.handleSaveNotes}>Save</Button>}
										</Col>
										<Col>
											<div style={{marginBottom: '15px', marginTop: '40px', display: 'flex', justifyContent: 'space-between'}}>
												<div className='d-flex'>
													<span className='label-text mr-2'>GIGS</span>
													{gigsStatus !== 'scores' &&
														<Fragment>
															<i
																title='Calendar'
																className='far fa-calendar-alt fa-lg float-right pointer mr-2'
																onClick={() => this.handleShowModal('calendar')}
															>
															</i>
															<ExportTable
																columns={gigsStatus === 'payments' ? paymentsColumns : gigsColumns}
																data={ambassadorGigs && ambassadorGigs.results}
																lateNoShowEvents={gigsStatus === 'payments' ? lateNoShowEvents : []}
																filename={gigsStatus}
															/>
														</Fragment>
													}
												</div>
												<div style={{display: 'flex', width: '450px', fontSize: '13px', justifyContent: 'space-around'}}>
													<div
														className='pointer'
														onClick={() => this.handleGigStatusChange('appliedTo')}
														style={{color: `${gigsStatus === 'appliedTo' ? '#337AB7' : '#7A7A7A'}`}}
													>
														Applied To {gigsStatus === 'appliedTo' ? `(${ambassadorGigs.total ? ambassadorGigs.total : 0})` : ''}
													</div>
													|
													<div
														className='pointer'
														onClick={() => this.handleGigStatusChange('completed')}
														style={{color: `${this.state.gigsStatus === 'completed' ? '#337AB7' : '#7A7A7A'}`}}
													>
														Completed {gigsStatus === 'completed' ? `(${ambassadorGigs.total ? ambassadorGigs.total : 0})` : ''}
													</div>
													|
													<div
														className='pointer'
														onClick={() => this.handleGigStatusChange('upcoming')}
														style={{color: `${this.state.gigsStatus === 'upcoming' ? '#337AB7' : '#7A7A7A'}`}}
													>
														Upcoming {gigsStatus === 'upcoming' ? `(${ambassadorGigs.total ? ambassadorGigs.total : 0})` : ''}
													</div>
													|
													<div
														className='pointer'
														onClick={() => this.handleGigStatusChange('inProgress')}
														style={{color: `${this.state.gigsStatus === 'inProgress' ? '#337AB7' : '#7A7A7A'}`}}
													>
														In Progress {gigsStatus === 'inProgress' ? `(${ambassadorGigs.total ? ambassadorGigs.total : 0})` : ''}
													</div>
													|
													<div
														className='pointer'
														onClick={() => this.handleGigStatusChange('payments')}
														style={{color: `${gigsStatus === 'payments' ? '#337AB7' : '#7A7A7A'}`}}
													>
														Payments {gigsStatus === 'payments' ? `(${ambassadorGigs.total ? ambassadorGigs.total : 0})` : ''}
													</div>
													|
													<div
														className='pointer'
														onClick={() => this.handleGigStatusChange('scores')}
														style={{color: `${gigsStatus === 'scores' ? '#337AB7' : '#7A7A7A'}`}}
													>
														Scores {gigsStatus === 'scores' ? `(${ambassadorQuizScores && ambassadorQuizScores.length})` : ''}
													</div>
												</div>
											</div>
											{ambassadorGigs &&
												<GigsTable
													gigs={ambassadorGigs}
													lateNoShowEvents={lateNoShowEvents}
													loading={this.state.tableLoading}
													onGigClick={this.handleGigClick}
													tab={gigsStatus}
												/>
											}
											{gigsStatus === 'scores' &&
												<AmbassadorScoreTable
													scores={ambassadorQuizScores}
													loading={this.state.tableLoading}
												/>
											}
										</Col>
									</Col>
								}
							</Row>
						</Card>
				}
				</Col>
			</Fragment>
		)
	}

	getAWSUrl = async (key) => {
		try {
			const result = await API.post('getSignedUrl', '/getSignedUrl', {
				body: { key }
			})

			return result
		}
		catch(e) {
			console.error('Error in getting signed url: ' + e)
		}
	}

	getAmbassador = async (id) => {
		this.setState({ loading: true })

		try {
			const result = await axios.get(`${config.coreAPI}/fieldDayAdmin/ambassador/${id}`)
			const ambassador = result.data.responses[0].results[0]
			const notesResults = await axios.get(`${config.coreAPI}/notes/ambassador/${id}`)
			const ambassadorVideo = ambassador.registration_video_url && await this.getAWSUrl(ambassador.registration_video_url)
			const photosResult =  ambassador.profile_photos && ambassador.profile_photos.map(async (img) => await this.getAWSUrl(img.photo_url))
			const ambassadorPhotos = []

			if(photosResult) {
				for(const photo of photosResult) {
					ambassadorPhotos.push(await photo)
				}
			}

			this.setState({
				loading: false,
				ambassador,
				internalNotes: notesResults.data.responses[0].results[0] ,
				ambassadorVideo,
				ambassadorPhotos,
			})
		} catch(e) {
			console.error('Error in getAmbassador: ', e.response.data.message)

			this.setState({
				loading: false
			}, () => this.handleErrors(e))
		}
	}

	getAmbassadorGigs = async (id, status) => {
		let statusParams = ''
		const pageParams = 'pageFrom=0&pageSize=10000&noMasters=true'

		this.setState({ tableLoading: true })

		if(status === 'completed') {
			statusParams = 'completed=true&accepted=true'
		}

		if(status === 'upcoming') {
			statusParams = 'accepted=true&started=false'
		}

		if(status === 'inProgress') {
			statusParams = 'accepted=true&started=true&completed=false'
		}

		const params = [pageParams, statusParams].join('&')

		try {
			const result = status === 'payments'
				? await axios.get(`${config.coreAPI}/fieldDayAdmin/payments?ambassadorId=${id}&pageFrom=0&pageSize=10000`)
				: await axios.get(`${config.coreAPI}/fieldDayAdmin/activities/appliedBy/${id}?${params}`)

			this.setState({
				ambassadorGigs: result.data.responses[0],
				tableLoading: false
			})
		} catch(e) {
			console.error('Error in getAmbassadorGigs: ', e)

			this.setState({
				tableLoading: false
			}, () => this.handleErrors(e))
		}
	}

	handleResetPassword = async () => {
		const ambassadorId = this.props.match.params.id

		try {
			await axios.post(`${config.coreAPI}/fieldDayAdmin/ambassador/${ambassadorId}/resetPassword`)
			alert('An email with a link to reset password was sent to the ambassador')
		} catch(e) {
			this.handleErrors(e)
		}
	}

	getAmbassadorQuizScores = async (ambassadorId) => {
		this.setState({ tableLoading: true })

		try {
			const results = await axios.get(`${config.coreAPI}/training/results?ambassadorId=${ambassadorId}&pageFrom=0&pageSize=1000`)

			this.setState({
				ambassadorGigs: null,
				ambassadorQuizScores: results.data,
				tableLoading: false
			})
		} catch(e) {
			this.setState({ tableLoading: false }, () => this.handleErrors(e))
		}
	}

	getAmbassadorInfo = async () => {
		const ambassadorId = this.props.match.params.id

		try {
			const ambassador = await axios.get(`${config.coreAPI}/fieldDayAdmin/ambassador/${ambassadorId}`)

			this.setState({
				ambassador: ambassador.data.responses[0].results[0]
			})
		} catch(e) {
			this.props.handleErrors(e)

			// this.setState({
			// 	errors: this.state.errors.includes(e.response.data.message) ? [...this.state.errors] : this.state.errors.concat([`${e.response.data.message}`])
			// })

			// setTimeout(() => this.setState({
			// 		errors: []
			// 	}), 5000)
		}
	}

	getPaymentCategories = async () => {
		try {
			const categories = await axios.get(`${config.coreAPI}/fieldDayAdmin/payments/categories`)

			this.setState({ paymentCategories: categories.data })
		} catch(e) {
			this.handleErrors(e)
		}
	}

	getTotalEarnings = async () => {
		const ambassadorId = this.props.match.params.id

		try {
			const totalEarnings = await axios.get(`${config.coreAPI}/fieldDayAdmin/ambassador/${ambassadorId}/earnings`)

			this.setState({ totalEarnings: totalEarnings.data })
		} catch(e) {
			console.error(e)
			this.handleErrors(e)
		}
	}

	getLateNoShowEvents = async () => {
		const ambassadorId = this.props.match.params.id
		const tags = 'LATE|NO SHOW'

		try {
			const result = await axios.get(`${config.coreAPI}/ambassadorEvents?ambassadorId=${ambassadorId}&tags=${tags}&pageFrom=0&pageSize=10000&noMasters=true`)

			this.setState({
				lateNoShowEvents: result.data.responses[0].results
			})
		} catch(e) {
			console.error(e)
			this.handleErrors(e)
		}
	}

	handleUploadVideo = async (e) => {
		e.preventDefault()

		const ambassadorId = this.props.match.params.id
		const video = e.target.files[0]
		const formData = new FormData()

		formData.append('file', video)

		try {
			this.setState({ uploadingVideo: true })

			await axios({
				method: 'post',
				url: `${config.coreAPI}/fieldDayAdmin/ambassador/${ambassadorId}/uploadRegistrationVideo`,
				headers: { "Content-Type": "multipart/form-data" },
				data: formData
			})

			this.setState({ uploadingVideo: false })
			this.getAmbassador(ambassadorId)
			this.handleSuccess()
		} catch(e) {
			console.error(e)
			this.handleErrors(e)
		}
	}

	handleUploadPhotos = async (e) => {
		e.preventDefault()

		const ambassadorId = this.props.match.params.id
		const photo = e.target.files[0]
		const formData = new FormData()

		formData.append('file', photo)

		try {
			this.setState({ uploadingPhotos: true })

			await axios({
				method: 'post',
				url: `${config.coreAPI}/fieldDayAdmin/ambassador/${ambassadorId}/photo`,
				headers: { "Content-Type": "multipart/form-data" },
				data: formData
			})

			this.setState({ uploadingPhotos: false })
			this.getAmbassador(ambassadorId)
			this.handleSuccess()
		} catch(e) {
			console.error(e)
			this.handleErrors(e)
		}
	}

	handleDeletePhoto = async (photoId) => {
		const ambassadorId = this.props.match.params.id

		try {
			await axios({
				method: 'delete',
				url: `${config.coreAPI}/fieldDayAdmin/ambassador/${ambassadorId}/photo/${photoId}`,
			})

			this.getAmbassador(ambassadorId)
			this.handleSuccess()
		} catch(e) {
			console.error(e)
			this.handleErrors(e)
		}
	}

	handleSetPhotoAsDefault = async (photoId) => {
		const ambassadorId = this.props.match.params.id

		try {
			await axios({
				method: 'post',
				url: `${config.coreAPI}/fieldDayAdmin/ambassador/${ambassadorId}/photo/${photoId}/setDefault`,
			})

			this.getAmbassador(ambassadorId)
			this.handleSuccess()
		} catch(e) {
			console.error(e)
			this.handleErrors(e)
		}
	}

	handleRejectVideo = async () => {
		const ambassadorId = this.props.match.params.id

		try {
			await axios.post(`${config.coreAPI}/fieldDayAdmin/ambassador/${ambassadorId}/rejectVideo`)

			this.getAmbassador(ambassadorId)
			this.handleSuccess()
		} catch(e) {
			this.handleErrors(e)
		}
	}

	handleShowModal = (modalName) => {
		this.setState({
			showModal: true,
			modalName
		})
	}

	handleCloseModal = () => {
		const ambassadorId = this.props.match.params.id
		const status = this.state.gigsStatus

		this.setState({
			showModal: false
		}, () => {
			if(this.state.modalName === 'gigActions') {
				this.getAmbassadorGigs(ambassadorId, status)
			}
		})
	}

	handleUnreject = async () => {
		const ambassadorId = this.props.match.params.id

		try {
			await axios.post(`${config.coreAPI}/fieldDayAdmin/ambassador/${ambassadorId}/undoRejection`)

			alert('Un-rejected successfully')

		} catch(e) {
			this.setState({
				errors: this.state.errors.includes(e.response.data.message) ? [...this.state.errors] : this.state.errors.concat([`${e.response.data.message}`])
			})

			setTimeout(() => this.setState({
				errors: []
			}), 5000)
		}
	}

	handleApproveAmbassador = async () => {
		const ambassadorId = this.props.match.params.id

		try {
			await axios.post(`${config.coreAPI}/fieldDayAdmin/ambassador/${ambassadorId}/approve`)

			this.setState({
				done: true
			})

			setTimeout(() => this.setState({
				done: false
			}), 3000)

		} catch(e) {
			this.setState({
				errors: this.state.errors.includes(e.response.data.message) ? [...this.state.errors] : this.state.errors.concat([`${e.response.data.message}`])
			})

			setTimeout(() => this.setState({
				errors: []
			}), 3000)
		}
	}

	handleDeleteAmbassador = async () => {
		const confirm = window.confirm('Are you sure you want to delete this ambassador?')

		if(confirm) {
			try {
				await axios.delete(`${config.coreAPI}/fieldDayAdmin/ambassador/${this.props.match.params.id}`)

				this.handleSuccess()
			} catch(e) {
				console.log(e)
				this.handleErrors(e)
			}
		}
	}

	handleGigStatusChange = (status) => {
		const ambassadorId = this.props.match.params.id

		this.setState({
			gigsStatus: status,
			ambassadorGigs: {}
		}, () => {
			if(status === 'scores') {
				this.getAmbassadorQuizScores(ambassadorId)
			} else {
				this.getAmbassadorGigs(ambassadorId, status)
			}
		})
	}

	handleGigClick = (gig) => {
		if(this.state.gigsStatus === 'payments') {
			this.getGig(gig)
			return
		}

		this.setState({
			showModal: !this.state.gigModalOpen,
			modalName: 'gigActions',
			clickedGig: gig
		})
	}

	getGig = async (gig) => {console.log(gig)
		try {
			const response = await axios.get(`${config.coreAPI}/fieldDayAdmin/activity/${gig.activity_id}`)

			this.setState({
				showModal: !this.state.gigModalOpen,
				modalName: 'gigActions',
				clickedGig: response.data.responses[0].results[0]
			})

		} catch(e) {
			console.log(e)
			this.handleErrors(e)
		}
	}

	handleImgCaptionClick = (index) => {
		this.setState({
			currentImg: index
		})
	}

	handleSendEmail = ({ subject, message }) => {
		const recipients = [{ email: this.state.ambassador.email_address }]
		const body = {
			subject,
			message,
			recipients
		}

		console.log(body)
		API.post('sendEmail','/sendEmail', { body })
		 .then(() => {
		 	alert('Email sent successfully')
		 	this.handleCloseModal()
		 })
		 .catch(e => alert('There was an error sending email'))
	}

	handleNotesInput = (e) => {
		this.setState({
			internalNotes: {
				...this.state.internalNotes,
				body: e.target.value
			}
		})
	}

	handleSaveNotes = async () => {
		const ambassadorId = this.props.match.params.id

		try {
			await axios.post(
				`${config.coreAPI}/notes/ambassador/${ambassadorId}?subject=notes`,
				this.state.internalNotes.body,
				{
					headers: { 'Content-Type': 'application/json' }
				}
			)

			this.setState({
				notesEditMode: false,
				internalNotes: {
					...this.state.internalNotes,
				}
			})
		} catch(e) {
			console.error('Error in saving notes: ', e)
		}
	}

	handleSuccess = () => {
		window.scrollTo(0, 0)

		this.setState({
			done: true
		})

		setTimeout(() => this.setState({
				done: false
			}), 3000)
	}

	handleErrors = (e) => {
		window.scrollTo(0, 0)

		this.setState({
			errors: this.state.errors.includes(e.response.data.message) ? [...this.state.errors] : this.state.errors.concat([`${e.response.data.message}`])
		})

		setTimeout(() => this.setState({
			errors: []
		}), 10000)
	}
}

function mapStateToProps(state, { match }) {
	const { id } = match.params

	return {
		// loading: state.ambassadors.ambassador ? false : true,
		// ambassador: state.ambassadors.ambassador,
		id
	}
}

class Address extends Component {
	state = {
		editMode: false,
		processing: false,
		done: false,
		errors: [],
		street_address: this.props.street_address,
		city: this.props.city,
		state: this.props.state,
		zip: this.props.zip,
	}

	render() {
		const { editMode, processing, done, errors, street_address, city, state, zip } = this.state

		return (
			<div className='d-flex align-items-center justify-content-center'>
				{editMode
					? <InputGroup>
							<Form.Control
								placeholder='Street Address'
								name='street_address'
								value={street_address}
								onChange={this.handleInputChange}
							/>
							<Form.Control
								placeholder='City'
								name='city'
								value={city}
								onChange={this.handleInputChange}
							/>
							<Form.Control
								placeholder='State'
								name='state'
								value={state}
								onChange={this.handleInputChange}
							/>
							<Form.Control
								placeholder='Zip'
								name='zip'
								value={zip}
								onChange={this.handleInputChange}
							/>
						</InputGroup>
					: <div>{street_address} {city}, {state} {zip}</div>
				}
				&nbsp;<i className="fas fa-edit pointer" title='Edit' onClick={() => this.setState({ editMode: !this.state.editMode })}></i>&nbsp;
				{editMode && <i className="far fa-save pointer" title='Save' onClick={this.handleSaveAddress}></i>}&nbsp;
				{processing && <Spinner animation='border' variant='info' size='sm' />}&nbsp;
				{done && <i className="fas fa-check" style={{color: '#28a745'}}></i>}&nbsp;
				{errors.map((err, i) => <span key={i} style={{color: 'red'}}>{err}</span>)}
			</div>
		)
	}

	handleInputChange = (event) => {
		const { name, value } = event.target

		this.setState({
			[name]: value
		})
	}

	handleSaveAddress = async () => {
		const { ambassadorId, onUpdateAmbassadorData, onSuccess, onError } = this.props
		const { street_address, city, state, zip } = this.state

		try {
			this.setState({ processing: true })

			await axios.patch(`${config.coreAPI}/fieldDayAdmin/ambassador/${ambassadorId}/address`, {
				street_address,
				city,
				state,
				zip,
			})

			this.setState({
				processing: false,
				editMode: false,
				done: true,
			}, () => {
				onUpdateAmbassadorData()
				onSuccess()
			})

		} catch(e) {
			this.setState({
				processing: false,
				editMode: false,
				street_address: this.props.street_address,
				city: this.props.city,
				state: this.props.state,
				zip: this.props.zip,
			}, () => onError(e))
		}
	}
}

class Tags extends Component {
	state = {
		editMode: false,
		tagInput: '',
		tagsList: []
	}

	componentDidMount() {
		this.getTagsList()
	}

	render() {
		const { tags } = this.props
		const { editMode, tagInput, tagsList } = this.state

		// if(!tags) return null

		return (
			<div>
				{tags && tags.map((tag, i) => (
					<span key={i}>
						{editMode &&
							<i
								className="fas fa-times-circle fa-sm pointer"
								title='Delete'
								onClick={() => this.handleDeleteTag(tag)}
							>
							</i>
						}
						{tag}
						{i < tags.length - 1 && ', '}
					</span>
				))}
				<br />
				{editMode &&
					<Col lg={7} className='no-gutter'>
						<InputGroup size="sm" className='mb-2 mt-2'>
							<Form.Control
								placeholder='add more'
								value={tagInput}
								onChange={e => this.setState({ tagInput: e.target.value })}
							/>
							<DropdownButton as={InputGroup.Append} title='Existing Tags' variant='outline-secondary'>
								{tagsList.map((tag, i) => (
									<Dropdown.Item key={i} onClick={() => this.setState({ tagInput: tag })}>{tag}</Dropdown.Item>
								))}
							</DropdownButton>
							<InputGroup.Append>
								<Button variant='dark' onClick={this.handleSaveTag}>Save</Button>
							</InputGroup.Append>
						</InputGroup>
					</Col>
				}
				<Button onClick={() => this.setState({ editMode: !editMode })}>Edit</Button>
			</div>
		)
	}

	getTagsList = async () => {
		const { onError } = this.props

		try {
			const tags = await axios.get(`${config.coreAPI}/fieldDayAdmin/ambassador/tags`)

			this.setState({
				tagsList: tags.data
			})
		} catch(e) {
			onError(e)
		}
	}

	handleSaveTag = async () => {
		const { ambassadorId, onUpdateAmbassadorData, onError, onSuccess } = this.props
		const { tagInput } = this.state

		try {
			await axios.post(`${config.coreAPI}/fieldDayAdmin/ambassador/${ambassadorId}/tag?tag=${tagInput}`)

			this.setState({
				tagInput: ''
			}, () => {
				onSuccess()
				onUpdateAmbassadorData()
			})

		} catch(e) {
			onError(e)
		}
	}

	handleDeleteTag = async (tag) => {
		const { ambassadorId, onUpdateAmbassadorData, onError, onSuccess } = this.props

		try {
			await axios.delete(`${config.coreAPI}/fieldDayAdmin/ambassador/${ambassadorId}/tag?tag=${tag}`)

			onSuccess()
			onUpdateAmbassadorData()
		} catch(e) {
			onError(e)
		}
	}
}

function Pictures({ mediaUrls, index, onImgCaptionClick, onImgClick }) {
	const images = _.filter(mediaUrls, item => !item.includes('registrationVideo'))
	const img = {
		backgroundImage: `url(${images[index]})`,
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover'
	}

	return (
		<div className='ambassador-profile-img-container'>
			<div onClick={() => onImgClick('photos')} className='ambassador-profile-img pointer' style={img}></div>
			<div className='ambassador-profile-picture-counter'>
				{images.map((img, i) => (
					<i
						key={i}
						className={`${index === i ? 'fas fa-circle pointer' : 'far fa-circle pointer'}`}
						onClick={() => onImgCaptionClick(i)}
					>
					</i>
				))}
			</div>
		</div>
	)
}

class AmbassadorInfo extends Component {
	state = {
		// ambassadorLocation: '',
		processing: false,
		done: false,
		payAmbassador: false,
		selectedPaymentCategory: 'Categories'
	}

	componentDidMount() {
		// this.getAmbassadorLocation()
	}

	render() {
		const { ambassador, paymentCategories, totalEarnings } = this.props
		const { processing, done, stripeLink, payAmbassador, selectedPaymentCategory } = this.state

		return (
			<div className='ambassador-profile-info'>
				<Row className='mb-4'>
					<Col lg={5} className='text-right font-weight-bold'>TOTAL EARNINGS</Col>
					<Col lg={7}>${totalEarnings / 100}</Col>
				</Row>
				<Row className='mb-4'>
					<Col lg={5} className='text-right font-weight-bold'>EMAIL</Col>
					<Col lg={7}>
						<InputField
							key='email'
							name='email_address'
							value={ambassador.email_address}
							onSave={this.handleSaveInput}
							processing={processing}
							done={done}
						/>
					</Col>
				</Row>
				<Row className='mb-4'>
					<Col lg={5} className='text-right font-weight-bold'>PHONE</Col>
					<Col lg={7}>
						<InputField
							key='phone'
							// name='phone'
							value={ambassador.phone}
							onSave={this.handleSavePhone}
							processing={processing}
							done={done}
						/>
					</Col>
				</Row>
				<Row className='mb-4'>
					<Col lg={5} className='text-right font-weight-bold'>RATING</Col>
					<Col lg={7}>{ambassador.rating}</Col>
				</Row>
				<Row className='mb-4'>
					<Col lg={5} className='text-right font-weight-bold'>LOCATION</Col>
					<Col lg={7}>{ambassador.city}, {ambassador.state}</Col>
				</Row>
				<Row className='mb-4'>
					<Col lg={5} className='text-right font-weight-bold'>ID</Col>
					<Col lg={7}>{ambassador.id}</Col>
				</Row>
				<Row className='mb-4'>
					<Col lg={5} className='text-right font-weight-bold'>STATUS</Col>
					<Col lg={7}>{ambassador.approved ? 'Approved' : 'Pending'}</Col>
				</Row>
				<Row className='mb-4'>
					<Col lg={5} className='text-right font-weight-bold'>AGE</Col>
					<Col lg={7}>{ambassador.date_of_birth && moment().diff(ambassador.date_of_birth, 'years')}</Col>
				</Row>
				<Row className='mb-4'>
					<Col lg={5} className='text-right font-weight-bold'>JOINED</Col>
					<Col lg={7}>{ambassador.created_date && moment(ambassador.created_date * 1000).format('MMM Do, YYYY')}</Col>
				</Row>
				<Row className='mb-4'>
					<Col lg={5} className='text-right font-weight-bold'>STRIPE</Col>
					<Col lg={7}>{ambassador.stripe_profile ? 'Connected' : 'Not Connected'}</Col>
				</Row>
				<Row className='mb-4'>
					<Col lg={5} className='text-right font-weight-bold'>STRIPE LINK</Col>
					<Col lg={7}>
						{stripeLink
							? stripeLink
							: <Button size='sm' onClick={this.generateStripeLink}>
									{processing ? 'Working..' : 'Generate'}
								</Button>
						}
					</Col>
				</Row>
				<Row className='mb-4'>
					<Col lg={payAmbassador ? 12 : 5} className={`${payAmbassador ? 'text-center' : 'text-right'} font-weight-bold`}>PAY</Col>
					<Col lg={payAmbassador ? 12 : 7}>
						{payAmbassador
							? <PayAmbassador
									ambassador={this.props.ambassador}
									paymentCategories={paymentCategories}
									onUpdateAmbassadorData={this.props.onUpdateAmbassadorData}
									onSuccess={this.props.onSuccess}
									onError={this.props.onError}
									onTogglePay={this.handleTogglePay}
								/>
							: <Button variant='success' size='sm' onClick={this.handleTogglePay}>Pay</Button>
						}
					</Col>
				</Row>
				<Row className='mb-4'>
					<Col lg={5} className='text-right font-weight-bold'>SHIRT SIZE</Col>
					<Col lg={7}>{ambassador.shirt_size}</Col>
				</Row>
				<Row className='mb-4'>
					<Col lg={5} className='text-right font-weight-bold'>HAS CAR</Col>
					<Col lg={7}>{ambassador.has_car ? 'Yes' : 'No'}</Col>
				</Row>
				<Row className='mb-4'>
					<Col lg={5} className='text-right font-weight-bold'>EDUCATION</Col>
					<Col lg={7}>{ambassador.education}</Col>
				</Row>
				<Row className='mb-4'>
					<Col lg={5} className='text-right font-weight-bold'>RESUME</Col>
					<Col lg={7}>View Resume</Col>
				</Row>
				<Row className='mb-4 d-flex justify-content-center'>
					{ambassador.waitlisted
						? <Button variant='dark' onClick={() => this.handleWaitlist(false)}>Remove from Waitlist</Button>
						: <Button variant='dark' onClick={() => this.handleWaitlist(true)}>Add to Waitlist</Button>
					}
				</Row>
			</div>
		)
	}

	handleTogglePay = () => {
		this.setState(({ payAmbassador }) => ({ payAmbassador: !payAmbassador }))
	}

	// getAmbassadorLocation = () => {
	// 	const zipCode = this.props.ambassador.zip_code

	// 	if(!zipCode) return

	// 	fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${zipCode}&key=${googleApiKey}`)
	// 		.then(res => res.json())
	// 		.then(result => {
	// 			this.setState({
	// 				ambassadorLocation: result.results[0].formatted_address
	// 			})
	// 		})
	// 		.catch( err => console.error(err))
	// }

	handleSaveInput = async (value, name) => {
		const ambassadorId = this.props.ambassador.id

		try {
			await axios.patch(`${config.coreAPI}/fieldDayAdmin/ambassador/${ambassadorId}`, {
				[name]: value
			})

			this.setState({
				processing: false,
				done: true
			}, () => {
				this.props.onUpdateAmbassadorData()
				this.props.onSuccess()
			})

		} catch(e) {
			this.setState({
				processing: false
			}, () => this.props.onError(e))
		}
	}

	handleSavePhone = async (phone) => {
		const ambassadorId = this.props.ambassador.id

		try {
			this.setState({ processing: true })

			const response = await axios.patch(`${config.coreAPI}/fieldDayAdmin/ambassador/${ambassadorId}/phone?phone=${phone}`)

			if(response.status === 200 || response.status === 204) {
				this.setState({
					processing: false,
					done: true
				}, () => {
					this.props.onUpdateAmbassadorData()
					this.props.onSuccess()
				})
			}
		} catch(e) {
			this.setState({
				processing: false
			}, () => this.props.onError(e))
		}
	}

	generateStripeLink = async () => {
		const ambassadorId = this.props.ambassador.id

		try {
			this.setState({ processing: true })

			const response = await axios.get(`${config.coreAPI}/fieldDayAdmin/ambassador/${ambassadorId}/stripeLoginUrl`)

			this.setState({
				processing: false,
				stripeLink: response.data
			})
		} catch(e) {
			this.setState({
				processing: false
			}, () => this.props.onError(e))
		}
	}

	// handlePayAmbassador = async () => {
	// 	const { ambassador, onSuccess, onError, onUpdateAmbassadorData } = this.props
	// 	const amount = this.payInput.value
	// 	const title = this.payTitle.value
	// 	const description = this.payDescription.value
	// 	const category = this.state.selectedPaymentCategory

	// 	if(!amount) return

	// 	const confirm = window.confirm(`Are you sure you want to pay $${amount} to ${ambassador.ambassador_name}?`)
	// 	const ambassadorId = ambassador.id

	// 	if(confirm) {
	// 		try {
	// 			await axios.post(`${config.coreAPI}/fieldDayAdmin/ambassador/${ambassadorId}/pay`, {}, {
	// 				params: {
	// 					amount,
	// 					category,
	// 					title,
	// 					description,
	// 					// brandId,
	// 					// storeId,
	// 					// activityId
	// 				}
	// 			})

	// 			this.setState({
	// 				payAmbassador: false
	// 			}, () => {
	// 				onSuccess()
	// 				onUpdateAmbassadorData()
	// 			})

	// 		} catch(e) {
	// 			onError(e)
	// 		}
	// 	}
	// }

	handleWaitlist = async (waitlisted) => {
		const { ambassador, onSuccess, onError, onUpdateAmbassadorData } = this.props

		try {
			await axios.post(`${config.coreAPI}/fieldDayAdmin/ambassador/${ambassador.id}/waitlist?waitlisted=${waitlisted}`)

			onSuccess()
			onUpdateAmbassadorData()
		} catch(e) {
			onError(e)
		}
	}
}

class InputField extends Component {
	state = {
		editMode: false,
	}

	render() {
		const { type = 'text', value, processing } = this.props
		const { editMode } = this.state

		return (
			<div>
				{editMode
					? <Form.Control
							type={type}
							defaultValue={value}
							ref={(input) => this.input = input}
						/>
					: value
				}
				&nbsp;<i className="fas fa-edit pointer" title='Edit' onClick={this.handleEditClick}></i>&nbsp;
				{editMode && !processing && <i className="far fa-save pointer" title='Save' onClick={this.handleSaveClick}></i>}&nbsp;
			</div>
		)
	}

	// to be added
	// {processing && <Spinner animation='border' variant='info' size='sm' />}&nbsp;
	// {done && <i className="fas fa-check" style={{color: '#28a745'}}></i>}&nbsp;

	handleEditClick = () => {
		this.setState({ editMode: !this.state.editMode })
	}

	handleSaveClick = () => {
		const { value } = this.input

		this.setState({
			editMode: false
		}, () => this.props.onSave(value, this.props.name))
	}
}

function GigsTable({ gigs, lateNoShowEvents, loading, onGigClick, tab }) {
	const tableStyle = {
		fontSize: '13px',
		fontWeight: '100',
		border: '1px solid #C1C1C1'
	}

	const headerStyle = {
		borderRight: 'none',
		fontWeight: '600',
		backgroundColor: '#f1f1f1',
		padding: '8px',
		textAlign: 'center',
		borderBottom: '1px solid #C1C1C1'
	}

	const columnStyle = {
		padding: '8px',
		textAlign: 'center',
		borderRight: '1px solid #C1C1C1'
	}

	// to be refactored and combined into one table
	if(tab === 'payments') {
		return (
			<ReactTable
				data={gigs.results}
				columns={[
					{
						Header: 'TITLE',
						id: 'title',
						accessor: d => {
							const lateNoShowEvent = lateNoShowEvents && _.find(lateNoShowEvents, event => event.activity_id === d.activity_id)

							return (
								<div className='pointer' onClick={() => d.category === 'ACTIVITY' && onGigClick(d)}>
									{d.title}
									{lateNoShowEvent ? ` (${lateNoShowEvent.tags.join(' & ')})` : ''}
								</div>
							)
						},
						headerStyle: headerStyle,
						style: columnStyle
					},
					{
						Header: 'CATEGORY',
						id: 'category',
						accessor: d => <div className='pointer' onClick={() => d.category === 'ACTIVITY' && onGigClick(d)}>{d.category && d.category.toLowerCase()}</div>,
						headerStyle: headerStyle,
						style: columnStyle
					},
					{
						Header: 'GIG',
						id: 'gig',
						accessor: d => <div className='pointer' onClick={() => d.category === 'ACTIVITY' && onGigClick(d)}>{d.activity_title}</div>,
						headerStyle: headerStyle,
						style: columnStyle
					},
					{
						Header: 'BRAND',
						id: 'brand',
						accessor: d => <div className='pointer' onClick={() => d.category === 'ACTIVITY' && onGigClick(d)}>{d.brand_name}</div>,
						headerStyle: headerStyle,
						style: columnStyle
					},
					{
						Header: 'AMOUNT',
						id: 'amount',
						accessor: d => <div className='pointer' onClick={() => d.category === 'ACTIVITY' && onGigClick(d)}>${(d.amount_in_cents / 100).toFixed(2)}</div>,
						headerStyle: headerStyle,
						style: columnStyle
					},
					{
						Header: 'STATUS',
						id: 'status',
						accessor: d => <div className='pointer' onClick={() => onGigClick(d)}>{d.status && d.status.toLowerCase()}</div>,
						headerStyle: headerStyle,
						style: columnStyle
					},
					{
						Header: 'ARRIVE ON',
						id: 'arriveOn',
						accessor: d => (
							<div className='pointer' onClick={() => onGigClick(d)}>
								{d.payout_arrival_timestamp_millis ? moment(d.payout_arrival_timestamp_millis).format('MM/DD/YYYY') : ''}
							</div>
							),
						headerStyle: headerStyle,
						style: columnStyle
					},
				]}
				defaultSortMethod={(a, b, desc) => {
					// force null and undefined to the bottom
					a = a.props.children === null || a.props.children === undefined ? '' : a.props.children
					b = b.props.children === null || b.props.children === undefined ? '' : b.props.children
					// force any string values to lowercase
					a = typeof a === 'string' ? a.toLowerCase() : a
					b = typeof b === 'string' ? b.toLowerCase() : b
					// Return either 1 or -1 to indicate a sort priority
					if (a > b) {
					  return 1
					}
					if (a < b) {
					  return -1
					}
					// returning 0, undefined or any falsey value will use subsequent sorts or
					// the index as a tiebreaker
					return 0
				}}
				className="-striped -highlight"
				defaultPageSize={8}
				showPageSizeOptions={false}
				style={tableStyle}
				noDataText={'No gigs found'}
				loading={loading}
			/>
		)
	}

	return (
		<div>
			<ReactTable
				data={gigs.results}
				columns={[
					{
						Header: 'GIG',
						id: 'gig',
						accessor: d => {
							const lateNoShowEvent = lateNoShowEvents && _.find(lateNoShowEvents, event => event.activity_id === d.brand_user_activity.id)

							return (
								<div className='pointer' onClick={() => onGigClick(d)}>
									{d.brand_user_activity.title}
									{lateNoShowEvent ? ` (${lateNoShowEvent.tags.join(' & ')})` : ''}
								</div>
							)
						},
						headerStyle: headerStyle,
						style: columnStyle
					},
					{
						Header: 'BRAND',
						id: 'brand',
						accessor: d => <div className='pointer' onClick={() => onGigClick(d)}>{d.associated_brand ? d.associated_brand.brand_name : ''}</div>,
						headerStyle: headerStyle,
						style: columnStyle
					},
					{
						Header: 'DUE',
						id: 'due',
						accessor: d => <div className='pointer' onClick={() => onGigClick(d)}>{moment(d.brand_user_activity.due_date_millis).format('MM/DD/YYYY')}</div>,
						headerStyle: headerStyle,
						style: columnStyle
					},
					{
						Header: 'COMPLETED',
						id: 'completed',
						accessor: d => <div className='pointer' onClick={() => onGigClick(d)}>{d.brand_user_activity.field_day_operation_details.finished_gig_timestamp ? moment(d.brand_user_activity.field_day_operation_details.finished_gig_timestamp * 1000).format('MM/DD/YYYY') : ''}</div>,
						headerStyle: headerStyle,
						style: columnStyle
					},
					{
						Header: 'HOURS',
						id: 'hours',
						accessor: d => <div className='pointer' onClick={() => onGigClick(d)}>{d.brand_user_activity.field_day_management.field_day_remuneration.max_hours}</div>,
						headerStyle: headerStyle,
						style: columnStyle
					},
					{
						Header: 'EARNINGS',
						id: 'earnings',
						accessor: d => <div className='pointer' onClick={() => onGigClick(d)}>{`$${d.brand_user_activity.field_day_operation_details.ambassador_remuneration_to_pay ? Number(d.brand_user_activity.field_day_operation_details.ambassador_remuneration_to_pay).toFixed(2) : 0}`}</div>,
						headerStyle: headerStyle,
						style: columnStyle
					},
					{
						Header: 'BIZ CARDS',
						id: 'bizCards',
						accessor: d => <div className='pointer' onClick={() => onGigClick(d)}>{d.brand_user_activity.field_day_operation_details.business_card_photos ? d.brand_user_activity.field_day_operation_details.business_card_photos.length : ''}</div>,
						headerStyle: headerStyle,
						style: columnStyle
					},
					{
						Header: 'BONUS',
						id: 'bonusEligibility',
						width: 75,
						accessor: d => (
							<div className='pointer' onClick={() => onGigClick(d)}>
								{d.brand_user_activity.field_day_management.field_day_remuneration.bonus_eligible
									? <i className='fas fa-check-square text-success'></i>
									: <i className='fas fa-window-close text-danger'></i>
								}
							</div>
						),
						headerStyle: headerStyle,
						style: columnStyle
					},
				]}
				defaultSortMethod={(a, b, desc) => {
					// force null and undefined to the bottom
					a = a.props.children === null || a.props.children === undefined ? '' : a.props.children
					b = b.props.children === null || b.props.children === undefined ? '' : b.props.children
					// force any string values to lowercase
					a = typeof a === 'string' ? a.toLowerCase() : a
					b = typeof b === 'string' ? b.toLowerCase() : b
					// Return either 1 or -1 to indicate a sort priority
					if (a > b) {
					  return 1
					}
					if (a < b) {
					  return -1
					}
					// returning 0, undefined or any falsey value will use subsequent sorts or
					// the index as a tiebreaker
					return 0
				}}
				className="-striped -highlight"
				defaultPageSize={8}
				showPageSizeOptions={false}
				style={tableStyle}
				noDataText={'No gigs found'}
				loading={loading}
			/>
		</div>
	)
}

export default connect(mapStateToProps)(AmbassadorProfile)
