import _ from 'underscore'
import JSZip from 'jszip'
import FileSaver from 'file-saver'
const escape = _.escape

const CELL_TYPE_STRING = 'string'
const CELL_TYPE_NUMBER = 'number'
const validTypes = [CELL_TYPE_STRING, CELL_TYPE_NUMBER]

const MISSING_KEY_FILENAME = 'Zipclex config missing property filename'
const INVALID_TYPE_FILENAME = 'Zipclex filename can only be of type string'
const INVALID_TYPE_SHEET = 'Zipcelx sheet data is not of type array'
const INVALID_TYPE_SHEET_DATA = 'Zipclex sheet data childs is not of type array'

const WARNING_INVALID_TYPE = 'Invalid type supplied in cell config, falling back to "string"'

const childValidator = (array) => {
  return array.every(item => Array.isArray(item))
}

const validator = (config) => {
  if (!config.filename) {
    console.error(MISSING_KEY_FILENAME)
    return false
  }

  if (typeof config.filename !== 'string') {
    console.error(INVALID_TYPE_FILENAME)
    return false
  }

  if (!Array.isArray(config.sheet.data)) {
    console.error(INVALID_TYPE_SHEET)
    return false
  }

  if (!childValidator(config.sheet.data)) {
    console.error(INVALID_TYPE_SHEET_DATA)
    return false
  }

  return true
}

const generateColumnLetter = (colIndex) => {
  if (typeof colIndex !== 'number') {
    return ''
  }

  const prefix = Math.floor(colIndex / 26)
  const letter = String.fromCharCode(97 + (colIndex % 26)).toUpperCase()
  if (prefix === 0) {
    return letter
  }
  return generateColumnLetter(prefix - 1) + letter
}

const generatorCellNumber = (index, rowNumber) => (
  `${generateColumnLetter(index)}${rowNumber}`
)

const generatorStringCell = (index, value, rowIndex) => (`<c r="${generatorCellNumber(index, rowIndex)}" t="inlineStr"><is><t>${escape(value)}</t></is></c>`)

const generatorNumberCell = (index, value, rowIndex) => (`<c r="${generatorCellNumber(index, rowIndex)}"><v>${value}</v></c>`)

const formatCell = (cell, index, rowIndex) => {
  if (validTypes.indexOf(cell.type) === -1) {
    console.warn(WARNING_INVALID_TYPE)
    cell.type = CELL_TYPE_STRING
  }

  return (
    cell.type === CELL_TYPE_STRING
    ? generatorStringCell(index, cell.value, rowIndex)
    : generatorNumberCell(index, cell.value, rowIndex)
  )
}

const formatRow = (row, index) => {
  // To ensure the row number starts as in excel.
  const rowIndex = index + 1
  const rowCells = row
  .map((cell, cellIndex) => formatCell(cell, cellIndex, rowIndex))
  .join('')

  return `<row r="${rowIndex}">${rowCells}</row>`
}

const generatorRows = rows => (
  rows
  .map((row, index) => formatRow(row, index))
  .join('')
)

const workbookXML = `<?xml version="1.0" encoding="UTF-8" standalone="yes"?>
<workbook xmlns="http://schemas.openxmlformats.org/spreadsheetml/2006/main" xmlns:r="http://schemas.openxmlformats.org/officeDocument/2006/relationships" xmlns:mx="http://schemas.microsoft.com/office/mac/excel/2008/main" xmlns:mc="http://schemas.openxmlformats.org/markup-compatibility/2006" xmlns:mv="urn:schemas-microsoft-com:mac:vml" xmlns:x14="http://schemas.microsoft.com/office/spreadsheetml/2009/9/main" xmlns:x14ac="http://schemas.microsoft.com/office/spreadsheetml/2009/9/ac" xmlns:xm="http://schemas.microsoft.com/office/excel/2006/main"><workbookPr/><sheets><sheet state="visible" name="Sheet1" sheetId="1" r:id="rId3"/></sheets><definedNames/><calcPr/></workbook>`

const workbookXMLRels = `<?xml version="1.0" ?>
<Relationships xmlns="http://schemas.openxmlformats.org/package/2006/relationships">
<Relationship Id="rId3" Target="worksheets/sheet1.xml" Type="http://schemas.openxmlformats.org/officeDocument/2006/relationships/worksheet"/>
</Relationships>`

const rels = `<?xml version="1.0" encoding="UTF-8" standalone="yes"?>
<Relationships xmlns="http://schemas.openxmlformats.org/package/2006/relationships"><Relationship Id="rId1" Type="http://schemas.openxmlformats.org/officeDocument/2006/relationships/officeDocument" Target="xl/workbook.xml"/></Relationships>`

const contentTypes = `<?xml version="1.0" ?>
<Types xmlns="http://schemas.openxmlformats.org/package/2006/content-types">
<Default ContentType="application/xml" Extension="xml"/>
<Default ContentType="application/vnd.openxmlformats-package.relationships+xml" Extension="rels"/>
<Override ContentType="application/vnd.openxmlformats-officedocument.spreadsheetml.worksheet+xml" PartName="/xl/worksheets/sheet1.xml"/>
<Override ContentType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.main+xml" PartName="/xl/workbook.xml"/>
</Types>`

const templateSheet = `<?xml version="1.0" ?>
<worksheet xmlns="http://schemas.openxmlformats.org/spreadsheetml/2006/main" xmlns:mc="http://schemas.openxmlformats.org/markup-compatibility/2006" xmlns:mv="urn:schemas-microsoft-com:mac:vml" xmlns:mx="http://schemas.microsoft.com/office/mac/excel/2008/main" xmlns:r="http://schemas.openxmlformats.org/officeDocument/2006/relationships" xmlns:x14="http://schemas.microsoft.com/office/spreadsheetml/2009/9/main" xmlns:x14ac="http://schemas.microsoft.com/office/spreadsheetml/2009/9/ac" xmlns:xm="http://schemas.microsoft.com/office/excel/2006/main"><sheetData>{placeholder}</sheetData></worksheet>`

const generateXMLWorksheet = (rows) => {
  const XMLRows = generatorRows(rows)
  return templateSheet.replace('{placeholder}', XMLRows)
}

const zipcelx = (config) => {
  if (!validator(config)) {
    throw new Error('Validation failed.')
  }

  const zip = new JSZip()
  const xl = zip.folder('xl')
  xl.file('workbook.xml', workbookXML)
  xl.file('_rels/workbook.xml.rels', workbookXMLRels)
  zip.file('_rels/.rels', rels)
  zip.file('[Content_Types].xml', contentTypes)

  const worksheet = generateXMLWorksheet(config.sheet.data)
  xl.file('worksheets/sheet1.xml', worksheet)

  return zip.generateAsync({
    type: 'blob',
    mimeType:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  }).then((blob) => {
    FileSaver.saveAs(blob, `${config.filename}.xlsx`)
  })
}

export default zipcelx
export { generateXMLWorksheet }