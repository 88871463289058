import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactTable from 'react-table'
import _ from 'underscore'
import axios from 'axios'
import { Segment, Button } from 'semantic-ui-react'
import Form from 'react-bootstrap/Form'

import config from '../../../config'
import { getBrandStores, handleErrors } from '../../../actions/brands'
import AddStore from './AddStore'
import excelExporter from '../../../utils/excelExporter'

class BrandStores extends Component {
	state = {
		showAddStore: false,
		uploadingCsv: false,
		csvUploadMessages: [],
		pushingStores: false
	}

	componentDidMount() {
		// const { dispatch, brandId } = this.props

		// dispatch(getBrandStores(brandId))
	}

	render() {
		const { data } = this.props
		const { showAddStore, csvUploadMessages, uploadingCsv, pushingStores, overwriteExisting } = this.state

		console.log('csvUploadMessages: ', csvUploadMessages)

		return (
			<div>
				{this.props.errors.map(e => (
					<div key={Math.random()} className='text-danger'>
						{e.response && e.response.data && e.response.data.errors}
					</div>
				))}
				<div className='overflow-auto' style={{ maxHeight: '150px' }}>
					{csvUploadMessages.flat().map(e => {
						if(typeof e === 'string') return <div key={e.row_number} className='text-danger'>{e}</div>

						return (
							<div key={e.row_number} className={`${e.status === 'FAILE' ? 'text-danger' : 'text-success'} border-bottom`}>
								<b>ROW</b>&nbsp;
								{e.row_number} {e.status}
								-&nbsp;
								{e.errors && e.errors.exception_messages && e.errors.exception_messages.map(msg => msg)}
								{e.errors && e.errors.constraint_violations && e.errors.constraint_violations.map(msg => msg)}
							</div>
						)
					})}
				</div>
				{showAddStore &&
					<AddStore
						brandId={this.props.brandId}
						showModal={showAddStore}
						onClose={this.handleModalClose}
					/>
				}
				<Button primary onClick={this.handleExportStores}>
					Export
				</Button>
				{process.env.REACT_APP_STAGE === 'dev' &&
					<Segment className='d-flex justify-content-between'>
						<div className='d-flex'>
							{/*<Button primary size='small' onClick={() => this.setState({ showAddStore: true })}>Add Store</Button>*/}
							<div className='custom-file pointer' style={{ width: 'inherit' }}>
							  <input type='file' className='custom-file-input' id='customFile' onChange={this.uploadStoresCSV} />
							  <Button size='small' primary loading={uploadingCsv}>Upload CSV</Button>
							  {false && <i className='fas fa-check text-success'></i>}
							</div>
							<Form.Check
								type='checkbox'
								className='ml-3'
								label='Overwrite Existing Stores'
								checked={overwriteExisting}
								onChange={e => this.setState({ overwriteExisting: e.target.checked })}
							/>
						</div>
						<Button secondary loading={pushingStores} onClick={this.handlePushStores}>
							Push Stores
						</Button>
					</Segment>
				}
				<ReactTable
					data={data}
					resolveData={data => _.map(data, row => row)}
					columns={[
						{
							Header: '',
							id: 'delete',
							width: 50,
							filterable: false,
							headerClassName: 'table-header',
							className: 'table-column',
							accessor: d => <i className='far fa-trash-alt float-left text-danger pointer' onClick={() => this.deleteStore(d.store_id)}></i>
						},
						{
							Header: 'Store ID',
							id: 'storeId',
							accessor: d => (
								<span>
									{d.store_id}
								</span>
							),
							width: 100,
							headerClassName: 'table-header',
							className: 'table-column',
							filterMethod: (filter, row) => row[filter.id].props.children === Number(filter.value)
						},
						{
							Header: 'Store Name',
							id: 'storeName',
							accessor: d => <span>{d.store_name}</span>,
							headerClassName: 'table-header',
							className: 'table-column',
							filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase())
						},
						{
							Header: 'Store Alias',
							id: 'storeAlias',
							accessor: d => <span>{d.store_alias}</span>,
							headerClassName: 'table-header',
							className: 'table-column',
							filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase())
						},
						{
							Header: 'Address',
							id: 'address',
							accessor: d => <span>{d.address}</span>,
							headerClassName: 'table-header',
							className: 'table-column',
							filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase())
						},
						{
							Header: 'City',
							id: 'city',
							accessor: d => <span>{d.city}</span>,
							headerClassName: 'table-header',
							className: 'table-column',
							filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().startsWith(filter.value.toLowerCase())
						},
						{
							Header: 'State',
							id: 'state',
							accessor: d => <span>{d.state}</span>,
							headerClassName: 'table-header',
							className: 'table-column',
							filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase())
						},
						{
							Header: 'Zip Code',
							id: 'zipCode',
							accessor: d => <span>{d.zip}</span>,
							headerClassName: 'table-header',
							className: 'table-column',
							filterMethod: (filter, row) => row[filter.id].props.children === filter.value
						},
					]}
					defaultSortMethod={(a, b, desc) => {
						// force null and undefined to the bottom
						a = a.props.children === null || a.props.children === undefined ? '' : a.props.children
						b = b.props.children === null || b.props.children === undefined ? '' : b.props.children
						// force any string values to lowercase
						a = typeof a === 'string' ? a.toLowerCase() : a
						b = typeof b === 'string' ? b.toLowerCase() : b
						// Return either 1 or -1 to indicate a sort priority
						if (a > b) {
						  return 1
						}
						if (a < b) {
						  return -1
						}
						// returning 0, undefined or any falsey value will use subsequent sorts or
						// the index as a tiebreaker
						return 0
					}}
					filterable
					className='-striped -highlight table-style'
					minRows={10}
					noDataText={'No stores found'}
				/>
			</div>
		)
	}

	handleModalClose = () => {
		const { dispatch, brandId } = this.props

		this.setState(({ showAddStore }) => ({ showAddStore: !showAddStore }))

		dispatch(getBrandStores(brandId))
	}

	handleExportStores = () => {
		const { data, brandName } = this.props

		const headerRow = [
			'brand_name',
			'factual_names',
			'store_id',
			'store_name',
			'store_alias',
			'street_address',
			'street_address_2',
			'city',
			'state',
			'postal_code',
			'country',
			'phone',
			'region_name',
			'dma_name',
			'latitude',
			'longitude',
			'store_open_date',
			'store_close_date',
			'store_rank',
			'quote',
			'brand_default_store_trade_area',
		].map(cell => ({ value: cell, type: 'string'}))

		const contentRows = _.map(data, store => {
			return [
				{
					value: brandName || '',
					type: 'string'
				},
				{
					value: brandName || '',
					type: 'string'
				},
				{
					value: store.store_id || '',
					type: 'number'
				},
				{
					value: store.store_name || '',
					type: 'string'
				},
				{
					value: store.store_alias || '',
					type: 'string'
				},
				{
					value: store.address || '',
					type: 'string'
				},
				{
					value: store.street_address_2 || '',
					type: 'string'
				},
				{
					value: store.city || '',
					type: 'string'
				},
				{
					value: store.state || '',
					type: 'string'
				},
				{
					value: store.zip || '',
					type: 'string'
				},
				{
					value: 'us',
					type: 'string'
				},
				{
					value: store.phone || '',
					type: 'string'
				},
				{
					value: store.region_name || '',
					type: 'string'
				},
				{
					value: store.dma_name || '',
					type: 'string'
				},
				{
					value: store.loc_point[1] || '',
					type: 'number'
				},
				{
					value: store.loc_point[0] || '',
					type: 'number'
				},
				{
					value: store.store_open_date,
					type: 'string'
				},
				{
					value: store.store_close_date,
					type: 'string'
				},
				{
					value: store.store_rank || '',
					type: 'string'
				},
				{
					value: store.store_close_date || '',
					type: 'string'
				},
				{
					value: store.quote || '',
					type: 'string'
				},
				{
					value: store.brand_default_store_trade_area || '',
					type: 'string'
				},
			]
		})

		contentRows.unshift(headerRow)

		const config = {
		  filename: `${brandName} - Locations`,
		  sheet: {
		    data: contentRows
		  }
		}

		excelExporter(config)
	}

	deleteStore = async (storeId) => {
		const confirm = window.confirm('Are you sure you want to delete this store?')

		if(!confirm) return

		const { dispatch, brandId } = this.props

		try {
			await axios.delete(`${config.coreAPI}/store/${brandId}_${storeId}`)

			dispatch(getBrandStores(brandId))
		} catch(e) {
			console.error(e)
			dispatch(handleErrors(e))
		}
	}

	uploadStoresCSV = async (e) => {
		const file = e.target.files[0]

		if(!file) {
			this.setState({ uploadingCsv: false })

			return
		}

		this.setState({
			csvUploadMessages: [],
			uploadingCsv: true
		})

		const { dispatch, brandId } = this.props
		const { overwriteExisting } = this.state

		const formData = new FormData()

		formData.append('file', file)
		formData.append('brandId', brandId)
		formData.append('overwriteExisting', overwriteExisting)

		try {
			const response = await axios.post(`${config.coreAPI}/stores/csv`, formData, {
				headers: {
					'content-type': 'multipart/form-data'
				}
			})

			dispatch(getBrandStores(brandId))

			this.setState({
				csvUploadMessages: [response.data],
				uploadingCsv: false
			})

		} catch(e) {
			console.error(e)

			this.setState({
				csvUploadMessages: [e.response.data],
				uploadingCsv: false
			})
		}
	}

	handlePushStores = async () => {
		const confirm = window.confirm('Are you sure you want to push these stores to production?')

		if(!confirm) return

		const { dispatch, brandId } = this.props

		try {
			this.setState({ pushingStores: true })

			await axios.post(`https://dev.core.api.fieldday.app/coreAPI/stores/backup?brandIds=${brandId}`)

			const s3FileName = await axios.get(`https://dev.core.api.fieldday.app/coreAPI/stores/backup/latest?brandId=${brandId}`)

			await axios.post(`https://core.api.fieldday.app/coreAPI/stores/overwrite?brandId=${brandId}&s3FileName=${s3FileName.data}`)

			this.setState({ pushingStores: false })
			alert('Stores Pushed Successfully')
		} catch(e) {
			console.error(e)
			alert('Error! Check the logs.')

			this.setState({
				pushingStores: false
			}, () => dispatch(handleErrors(e)))
		}
	}
}

function mapStateToProps({ brands }) {
	return {
		data: brands.selectedBrandStores,
		errors: brands.errors
	}
}

export default connect(mapStateToProps)(BrandStores)
