import React from 'react'
import ReactDOM from 'react-dom'
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import Amplify from 'aws-amplify'

// import * as serviceWorker from './serviceWorker'
import 'semantic-ui-css/semantic.min.css'
import './index.css'
import './styles/table.css'
import './styles/modal.css'
import { Auth0Provider } from './react-auth0-wrapper'
import authConfig from './auth_config.json'
import App from './containers/App'
import reducer from './reducers'
import middleware from './middleware'
import config from './config'

const sharedProps = {
	endpoint: config.apiGateway.URL,
	region: config.apiGateway.REGION
}

Amplify.configure({
	Auth: {
		identityPoolId: config.cognito.IDENTITY_POOL_ID,
		region: 'us-west-2'
	},
	API: {
		endpoints: [
			{
				name: 'getSignedUrl',
				...sharedProps
			},
			{
				name: 'sendEmail',
				...sharedProps
			},
			{
				name: 'sendSms',
				...sharedProps
			}
		]
	},
	Storage: {
		region: config.s3.REGION,
		bucket: config.s3.BUCKET,
		identityPoolId: config.cognito.IDENTITY_POOL_ID
	}
})

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  )
}

const store = createStore(reducer, middleware)

ReactDOM.render(
	<Provider store={store}>
		<Auth0Provider
			domain={authConfig.domain}
			client_id={authConfig.clientId}
			redirect_uri={window.location.origin}
			audience={config.audience}
			onRedirectCallback={onRedirectCallback}
		>
			<App />
		</Auth0Provider>
	</Provider>,
	document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister()
