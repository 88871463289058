import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import axios from 'axios'
import { compose, withProps, withStateHandlers } from 'recompose'
import { GoogleMap, Marker, withGoogleMap, withScriptjs, InfoWindow } from 'react-google-maps'
import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

import clusterIcon from '../../clusterIcon.png'
import markerIcon from '../../markerIcon.png'
import config from '../../config'

class AmbassadorsMap extends Component {
	state = {
		allAmbassadors: [],
		loading: false
	}

	render() {
		const { allAmbassadors, loading } = this.state
		const ambassadors = allAmbassadors.total ? allAmbassadors.results : this.props.ambassadors

		return (
			<div>
				<Button className='mt-3 mb-3' variant='primary' onClick={this.getAllAmbassadors}>All Ambassadors</Button>&nbsp;
				{allAmbassadors.total && <span>Total: {allAmbassadors.total}</span>}
				{loading && <Spinner animation='border' variant='info' size='sm' />}
				{/*allAmbassadors.total && <Button variant='dark' onClick={this.updatePhoneNumbers}>Update Phone Numbers</Button>*/}
				<MapComponent
					isMarkerShown
					markers={ambassadors.filter(ambassador => ambassador.loc_point)}
				/>
			</div>
		)
	}

	getAllAmbassadors = () => {
		const { approved } = this.props.filters

		this.setState({ loading: true })

		axios.get(`${config.coreAPI}/fieldDayAdmin/ambassadors?approved=${approved}&pageFrom=0&pageSize=1000000`)
			.then(res => this.setState({
				allAmbassadors: res.data.responses[0],
				loading: false
			}))
	}

	// updatePhoneNumbers = async () => {
	// 	const ambassadors = this.state.allAmbassadors.results

	// 	try {
	// 		for(let i = 0; i < ambassadors.length; i++) {
	// 			if(ambassadors[i].phone) {
	// 				const oldPhone = ambassadors[i].phone.slice().replace(/ /g, '').trim()
	// 				let newPhone = ambassadors[i].phone.replace(/-/g, '').replace(/\(/g, '').replace(/\)/g, '').replace(/ /g, '').replace(/\+/g, '')

	// 				newPhone = newPhone[0] === '1' ? newPhone.slice(1) : newPhone
	// 				newPhone = newPhone.trim()

	// 				if(newPhone !== oldPhone && newPhone.length === 10 && newPhone !== '7037284772') {
	// 					const result = await axios.patch(`${config.coreAPI}/fieldDayAdmin/ambassador/${ambassadors[i].id}/phone?phone=${newPhone}`)
	// 				}
	// 			}
	// 		}

	// 	} catch(e) {
	// 		console.log(e)
	// 		console.log(e.response.data.message)
	// 	}
	// }
}

function mapStateToProps({ ambassadors }) {
	return {
		ambassadors: ambassadors.results,
		filters: ambassadors.filters
	}
}

const MapComponent = compose(
	withProps({
		googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${config.GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
		loadingElement: <div style={{ height: `100%` }} />,
		containerElement: <div style={{ height: `1000px` }} />,
		mapElement: <div style={{ height: `100%` }} />,
	}),
	withStateHandlers(() => ({
		isOpen: false,
		infoWindowId: '',
		didMount: false
	}), {
		onToggleOpen: ({ isOpen }) => (payload) => ({
			didMount: true,
			isOpen: !isOpen,
			infoWindowId: payload.id,
      singleMarkerBound: { lat: payload.loc_point[1], lng: payload.loc_point[0] }
		})
	}),
	// withHandlers({
	// 	onMarkerClustererClick: () => (markerClusterer) => {
	// 		const clickedMarkers = markerClusterer.getMarkers()
	// 		console.log(`Current clicked markers length: ${clickedMarkers.length}`)
	// 		console.log(clickedMarkers)
	// 	}
	// }),
	withScriptjs,
	withGoogleMap
)(props => {
	const bounds = new window.google.maps.LatLngBounds()

	props.markers.map((marker, i) => bounds.extend({lat: marker.loc_point[1], lng: marker.loc_point[0]}))

	return (
		<GoogleMap
			ref={map => map && !props.isOpen && !props.didMount && map.fitBounds(bounds)}
			// defaultZoom={4}
			// defaultCenter={{ lat: props.markers[0].loc_point[1], lng: props.markers[0].loc_point[0] }}
		>
			<MarkerClusterer
	      // onClick={props.onMarkerClustererClick}
	      averageCenter
	      enableRetinaIcons
	      gridSize={60}
	      styles={[
	      	{
	      		textColor: 'white',
	      		textSize: 16,
	      		height: 32,
	      		width: 29,
	      		url: clusterIcon,
	      	}
	      ]}
			>
				{props.markers.map((marker, i) => {
					const onClick = props.onToggleOpen.bind(this, marker)

					return (
						<Marker
							key={marker.id}
							icon={{url: markerIcon}}
							onClick={onClick}
							position={{ lat: marker.loc_point[1], lng: marker.loc_point[0] }}
						>
							{(props.isOpen && props.infoWindowId === marker.id) &&
							  <InfoWindow onCloseClick={onClick}>
							    <AmbassadorInfo user={marker} />
							  </InfoWindow>
							}
						</Marker>
					)
				})}
				{/*props.isMarkerShown && <Marker position={{ lat: -34.397, lng: 150.644 }} />*/}
			</MarkerClusterer>
		</GoogleMap>
	)
})

function AmbassadorInfo({ user }) {
	const imgStyles = {
		backgroundImage: `url(${user.profileImage})`,
		height: '80px',
		width: '80px',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		marginRight: '10px'
	}

	return (
		<div className='ambassadors-map-info-window'>
			<div style={imgStyles}>{!user.profileImage && <em>No Image</em>}</div>
			<div style={{textAlign: 'left'}}>
				<Link to={`/ambassador/${user.id}`}><b>{user.ambassador_name}</b></Link>
				<div>{user.city}, {user.state}</div>
				<div style={{color: '#1f77b4'}}><i className="fas fa-star fa-xs"></i><i className="fas fa-star fa-xs"></i><i className="fas fa-star fa-xs"></i><i className="fas fa-star fa-xs"></i><i className="fas fa-star fa-xs"></i></div>
			</div>
		</div>
	)
}

export default connect(mapStateToProps)(AmbassadorsMap)
