import React from 'react'
import { compose, withProps, withStateHandlers, lifecycle } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps'

const googleApiKey = 'AIzaSyBzt4U31ue3BGvUsNj0v4MSKjm87sQLSzc'

const GeoTrackingMap= compose(
  withStateHandlers(() => ({
    isOpen: false,
    infoWindowId: ''
  }), {
    onToggleOpen: ({ isOpen }) => (payLoad) => ({
      didMount: true,
      isOpen: !isOpen,
      infoWindowId: payLoad.id,
      singleMarkerBound: { lat: payLoad.lat, lng: payLoad.lng }
    })
  }),
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap,
  lifecycle({
    componentDidMount() {
      this.setState({
        didMount: true
      })
    }
  })
)((props) => {
  const bounds = new window.google.maps.LatLngBounds()
  const icon = {
    path: window.google.maps.SymbolPath.CIRCLE,
    scale: 6,
    fillColor: "#0699a8",
    fillOpacity: 1,
    strokeWeight: 1,
    strokeColor: 'red'
  }

  // props.singleMarkerBound ?
  //   bounds.extend({lat: props.singleMarkerBound.lat, lng: props.singleMarkerBound.lng}) :
  //   props.geoPoints.map((marker, i) => bounds.extend({lat: marker.lat, lng: marker.lng}))

  props.geoPoints.map((marker, i) => bounds.extend({lat: marker.lat, lng: marker.lng}))

  const getLabel = (i, length) => {
    if(i === 0) return 'F'
    if(i === length - 1) return 'L'
    return ''
  }

  return (
    <GoogleMap
      ref={map => map && !props.isOpen && !props.didMount && map.fitBounds(bounds)}
      // defaultZoom={18}
      // defaultCenter={props.geoPoints[0]}
    >
      {props.isMarkerShown && props.geoPoints.map((marker, i) => {
        const onClick = props.onToggleOpen.bind(this, marker)

        // console.log(new Date(marker.timestamp).toString())

        return (
          <Marker
            key={i}
            position={marker}
            icon={i === 0 || i === props.geoPoints.length - 1 ? null : icon}
            onClick={onClick}
            label={getLabel(i, props.geoPoints.length)}
          >
            {(props.isOpen && props.infoWindowId === i) &&
              <InfoWindow onCloseClick={onClick}>
                <GeoAddress lat={marker.lat} lng={marker.lng} timestamp={marker.timestamp} />
              </InfoWindow>
            }
          </Marker>
        )
    })}
    </GoogleMap>
  )
})

class GeoAddress extends React.Component {
  constructor(props) {
    super(props)

    this.state = { address: '' }
  }

  componentDidMount() {
    new window.google.maps.Geocoder().geocode({'location': {lat: this.props.lat, lng: this.props.lng}}, (results, status) => {
      if(status === 'OK') {
        if(results[0]) {
          this.setState({ address: results[0].formatted_address })
        } else {
          this.setState({ address: 'No results were found' })
        }
      } else {
        this.setState({ address: 'Geocoder failed due to: ' + status })
      }
    })
  }

  render() {
    return (
      <div>
        <div>
          {this.state.address}
        </div>
        <div>
          {new Date(this.props.timestamp).toString()}
        </div>
      </div>
    )
  }
}

export default GeoTrackingMap
