import React, { Fragment } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
// import Button from 'react-bootstrap/Button'
import { Segment } from 'semantic-ui-react'
import BsForm from 'react-bootstrap/Form'
import { Dropdown, Form, Button, List, Image, Transition } from 'semantic-ui-react'
import { map } from 'underscore'

import { InputField } from './TargetSettings'
import WYSIWYG from '../WYSIWYG'

export default function ProgramDetails({
	selectedExecution,
	programDetails,
	onProgramDetailsChange,
	emailCampaignDetails,
	onEmailCampaignDetailsChange,
	selectedBrand
}) {

	if(!selectedBrand) return <h3 className='text-center'>Select a brand first</h3>

	const counter = emailCampaignDetails.dynamicEmailTemplateData.length

	const handleProgramDetailsInputChange = (name, value) => {
		onProgramDetailsChange({
			...programDetails,
			[name]: value
		})
	}

	const handleEmailCampaignDetailsInputChange = (e, i) => {
		const { name, value, type, checked } = e.target

		if(name === 'key') {
			const state = [...emailCampaignDetails.dynamicEmailTemplateData]

			state[i][0] = value

			onEmailCampaignDetailsChange({
				...emailCampaignDetails,
				'dynamicEmailTemplateData': state
			})

		} else if(name === 'val') {
			const state = [...emailCampaignDetails.dynamicEmailTemplateData]

			state[i][1] = value

			onEmailCampaignDetailsChange({
				...emailCampaignDetails,
				'dynamicEmailTemplateData': state
			})

		} else {
			onEmailCampaignDetailsChange({
				...emailCampaignDetails,
				[name]: type === 'checkbox' ? checked : value
			})
		}
	}

	const handleAdd = (i) => {
		onEmailCampaignDetailsChange({
			...emailCampaignDetails,
			'dynamicEmailTemplateData': [
				...emailCampaignDetails.dynamicEmailTemplateData,
				['', '']
			]
		})
	}

	const handleRemove = (i) => {
		const newState = [...emailCampaignDetails.dynamicEmailTemplateData]

		newState.pop()

		onEmailCampaignDetailsChange({
			...emailCampaignDetails,
			'dynamicEmailTemplateData': newState
		})
	}
console.log(programDetails)
	return (
		<div>
			<Row>
				<Col>
					<InputField
						title={'Hubspot deal name'}
						type='text'
						name={'hubspot_deal_name'}
						value={programDetails.hubspot_deal_name}
						onInputChange={handleProgramDetailsInputChange}
					/>
				</Col>
				<Col>
					<InputField
						title={'Hubspot contract amount'}
						type='number'
						name={'hubspot_contract_amount'}
						value={programDetails.hubspot_contract_amount}
						onInputChange={handleProgramDetailsInputChange}
					/>
				</Col>
			</Row>
			<hr />
			<Row>
				<Col>
					<TextBox
						title='Description'
						name='description'
						value={programDetails.description}
						onInputChange={handleProgramDetailsInputChange}
					/>
					{selectedExecution === 'PHONE' &&
						<Fragment>
							<TextBox
								title='Best Practices'
								name='best_practices'
								value={programDetails.best_practices}
								onInputChange={handleProgramDetailsInputChange}
							/>
							<TextBox
								title='Brand Details'
								name='brand_details'
								value={programDetails.brand_details}
								onInputChange={handleProgramDetailsInputChange}
							/>
							<TextBox
								title='Script'
								name='script'
								value={programDetails.script}
								onInputChange={handleProgramDetailsInputChange}
							/>
							<TextBox
								title='Voicemail Script'
								name='voicemailScript'
								value={programDetails.voicemailScript}
								onInputChange={handleProgramDetailsInputChange}
							/>
							<hr />
							<div>
								<BsForm.Check
									type='checkbox'
									name={'send_follow_up_email'}
									label='Send Follow Up Email'
									className='mb-4'
									checked={emailCampaignDetails.send_follow_up_email ? true : false}
									onChange={e => {
										if(!selectedBrand.sendgrid_api_key && !emailCampaignDetails.send_follow_up_email) {
											alert("This brand does not have a SendGrid API key. Make sure you generate one on brand's profile page before you create the campaign.")
											return
										}

										handleEmailCampaignDetailsInputChange(e)
									}}
								/>
								<Row>
									{emailCampaignDetails.send_follow_up_email &&
										<Fragment>
											<Col>
												<Form.Input
													fluid
													name='emailTemplateId'
													value={emailCampaignDetails.emailTemplateId}
													onChange={handleEmailCampaignDetailsInputChange}
													label='Template ID'
												/>
												<Form.Input
													fluid
													name='sender_name'
													value={emailCampaignDetails.sender_name}
													onChange={handleEmailCampaignDetailsInputChange}
													label='Sender Name'
												/>
												<Form.Input
													fluid
													name='sender_email_address'
													value={emailCampaignDetails.sender_email_address}
													onChange={handleEmailCampaignDetailsInputChange}
													label='Sender Email Address'
												/>
												<Form.Input
													fluid
													name='email_subject'
													value={emailCampaignDetails.email_subject}
													onChange={handleEmailCampaignDetailsInputChange}
													label='Email Subject'
												/>
											</Col>
											<Col>
												<h5>Dynamic Template Data</h5>
												<Button.Group className='mb-3'>
												  <Button
												    // disabled={emailCampaignDetails.dynamicEmailTemplateData.length === 0}
												    icon='minus'
												    onClick={handleRemove}
												  />
												  <Button
												    // disabled={items.length === users.length}
												    icon='plus'
												    onClick={() => handleAdd(counter)}
												  />
												</Button.Group>
											  {map(emailCampaignDetails.dynamicEmailTemplateData, (keyValPair, i) => (
											  	  <Row className='mb-1'>
											  	  	<Col>
												  	  	<Form.Input fluid name='key' value={keyValPair[0]} onChange={e => handleEmailCampaignDetailsInputChange(e, i)} placeholder='Key' />
											  	  	</Col>
											  	  	<Col>
												  	  	<Form.Input fluid name='val' value={keyValPair[1]} onChange={e => handleEmailCampaignDetailsInputChange(e, i)} placeholder='Value' />
											  	  	</Col>
											  	  </Row>
											  ))}
											</Col>
										</Fragment>
									}
								</Row>
							</div>
						</Fragment>
					}
				</Col>
			</Row>
			<div className='d-flex justify-content-end'>
				<Button>
					Create
				</Button>
			</div>
		</div>
	)
}

function TextBox({ title, name, value, onInputChange }) {
	return (
		<Segment raised className='p-0 mb-1 mt-0'>
			<div className='text-center text-white font-weight-bold' style={{ backgroundColor: '#f49e23' }}>{title}</div>
				<WYSIWYG
	  		  id={name}
	  		  onUpdateField={onInputChange}
	  		  fieldName={name}
	  		  defaultValue={value || ''}
	  		  height={'100px'}
	  		/>
		</Segment>
	)
}
