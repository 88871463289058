import React, { Component } from 'react'

class WYSIWYG extends Component {
	state = {
		that: this
	}

	componentDidMount() {
		document.querySelector('body').setAttribute('onLoad', this._iFrameOn())
	}

	componentWillUnmount() {
		window.frames[`richTextField_${this.props.id}`].document.removeEventListener('keyup', this._saveRichTextField, false)
	}

	render() {
		const { height, id } = this.props
		const that = this

		return(
			<div onLoad={this._iFrameOn}>
				<form name={`myForm-${id}`} id={`myForm-${id}`}>
					<div className='pull-right'>
						<button style={{marginRight: '5px'}} className='btn-info fa fa-bold' type='button' onClick={() => this._iBold(that)} title='Bold'></button>
						<button style={{marginRight: '5px'}} className='btn-info fa fa-italic' type='button' onClick={() => this._iItalic(that)} title='Italic'></button>
						<button style={{marginRight: '5px'}} className='btn-info fa fa-underline' type='button' onClick={() => this._iUnderline(that)} title='Underline'></button>
						<button style={{marginRight: '5px'}} className='btn-info fa' type='button' onClick={() => this._iFontSize(that)} title='Font Size'>Font Size</button>
						<button style={{marginRight: '5px'}} className='btn-info fa' type='button' onClick={() => this._iForeColor(that)} title='Color'>Font Color</button>
						<button style={{marginRight: '5px'}} className='btn-info fa' type='button' onClick={() => this._ibackColor(that)} title='Color'>Background Color</button>
						{/*<button style={{marginRight: '5px'}} className='btn-info fa' type='button' onClick={() => this._iHorizontalRule(that)} title='Horizontal Line'>HR</button>*/}
						<button style={{marginRight: '5px'}} className='btn-info fa fa-list-ol' type='button' onClick={() => this._iOrderedList(that)} title='Ordered List'></button>
						<button style={{marginRight: '5px'}} className='btn-info fa fa-list-ul' type='button' onClick={() => this._iUnorderedList(that)} title='Unordered List'></button>
						<button style={{marginRight: '5px'}} className='btn-info fa fa-link' type='button' onClick={() => this._iLink(that)} title='Link'></button>
					</div>
					<textarea style={{display: 'none'}} name={`myTextArea-${id}`} id={`myTextArea-${id}`} cols='100' rows='14' />
					<iframe
						name={`richTextField_${id}`}
						id={`richTextField_${id}`}
						style={{
							border: '#000000 1px solid',
							width: '100%',
							height: height ? height : '300px'
						}}
					>
					</iframe>
					<br/>
					<br/>
				</form>
			</div>
		)
	}

	_iFrameOn() {
		const { id, defaultValue } = this.props

		window.frames[`richTextField_${this.props.id}`].document.designMode = 'on'
		window.frames[`richTextField_${id}`].document.body.innerHTML = defaultValue
		window.frames[`richTextField_${id}`].document.addEventListener('keyup', this._saveRichTextField.bind(this), false)
	}

	_iBold(that) {
		window.frames[`richTextField_${that.props.id}`].document.execCommand('bold', false, null)
		that._saveRichTextField()
	}

	_iItalic(that) {
		window.frames[`richTextField_${that.props.id}`].document.execCommand('italic', false, null)
		that._saveRichTextField()
	}

	_iUnderline(that) {
		window.frames[`richTextField_${that.props.id}`].document.execCommand('underline', false, null)
		that._saveRichTextField()
	}

	_iFontSize(that) {
		const size = prompt('Enter a size between 1 and 7 (Default size is 3)', '')

		window.frames[`richTextField_${that.props.id}`].document.execCommand('FontSize', false, size)
		that._saveRichTextField()
	}

	_iForeColor(that) {
		const color = prompt('Enter a valid color name or hexadecimal color value', '')

		window.frames[`richTextField_${that.props.id}`].document.execCommand('ForeColor', false, color)
		that._saveRichTextField()
	}

	_ibackColor(that) {
		const color = prompt('Enter a valid color name or hexadecimal color value', '')

		window.frames[`richTextField_${that.props.id}`].document.execCommand('backColor', false, color)
		that._saveRichTextField()
	}

	_iHorizontalRule(that) {
		window.frames[`richTextField_${that.props.id}`].document.execCommand('inserthorizontalrule', false, null)
		that._saveRichTextField()
	}

	_iOrderedList(that) {
		window.frames[`richTextField_${that.props.id}`].document.execCommand('InsertOrderedList', false, 'newOL')
		that._saveRichTextField()
	}

	_iUnorderedList(that) {
		window.frames[`richTextField_${that.props.id}`].document.execCommand('InsertUnorderedList', false, 'newUL')
		that._saveRichTextField()
	}

	_iLink(that) {
		const link = prompt('Enter the URL:', 'http://')

		window.frames[`richTextField_${that.props.id}`].document.execCommand('CreateLink', false, link)
		that._saveRichTextField()
	}

	_iImage(that) {
		const imgSrc = prompt('Enter image location', '')

		if(imgSrc !== null) {
			window.frames[`richTextField_${that.props.id}`].document.execCommand('insertimage', false, imgSrc)
		}
	}

	_saveRichTextField() {
		const { id } =  this.props
		const theForm = document.querySelector(`#myForm-${id}`)
		theForm.elements[`myTextArea-${id}`].value = window.frames[`richTextField_${id}`].document.body.innerHTML
		const fieldValue = theForm.elements[`myTextArea-${id}`].value

		this.props.onUpdateField(this.props.fieldName, fieldValue.replace(/<div>/g, '<br/>').replace(/<\/div>/g, ''))
	}
}

export default WYSIWYG;
