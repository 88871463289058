import React, { Component, Fragment } from 'react'
import axios from 'axios'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Dropdown from 'react-bootstrap/Dropdown'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

import config from '../../config'

class EmailTraining extends Component {
	state = {
		brands: [],
		selectedBrandName: null,
		selectedTrainingPlan: null,
		trainingPlans: []
	}

	componentDidMount() {
		this.getBrands()
	}

	render() {
		const { brands, selectedBrandName, trainingPlans, selectedTrainingPlan, selectedTrainingPlanId, sending, sent } = this.state
		const { ambassadors } = this.props

		return (
			<Col>
				<Row>
					<Col>
						<b>Brand</b>
						<Dropdown>
							<Dropdown.Toggle variant='outline-dark'>{selectedBrandName || 'Select'}</Dropdown.Toggle>
							<Dropdown.Menu style={{maxHeight: '300px', overflow: 'auto'}}>
								{brands.map((brand, i) => (
									<Dropdown.Item
										key={i}
										onClick={() => this.handleBrandSelect(brand)}
									>
										{brand.brand_name}
									</Dropdown.Item>
								))}
							</Dropdown.Menu>
						</Dropdown>
					</Col>
					<Col>
						{selectedBrandName &&
							<Fragment>
								<b>Training Plans</b>
								<Dropdown>
									<Dropdown.Toggle variant='outline-dark'>{selectedTrainingPlan || 'Select'}</Dropdown.Toggle>
									<Dropdown.Menu style={{maxHeight: '300px', overflow: 'auto'}}>
										{trainingPlans.map((plan, i) => (
											<Dropdown.Item
												key={i}
												onClick={() => this.handleTrainingSelect(plan)}
											>
												{plan.name}
											</Dropdown.Item>
										))}
									</Dropdown.Menu>
								</Dropdown>
							</Fragment>
						}
					</Col>
				</Row>
				<hr />
				<Row className='d-flex flex-column flex-nowrap' style={{maxHeight: '300px', overflow: 'auto'}}>
					<Col lg={12}><b>Recipients:</b></Col>
					{ambassadors.map((ambassador, i) => <Col key={i}>{ambassador.ambassador_name} {`<${ambassador.email_address}>`}</Col>)}
				</Row>
				<hr />
				<Col>
					{sending && <Spinner animation='border' variant='info' size='sm' />}&nbsp;
					{sent && <em className='text-success'>Training Sent</em>}&nbsp;
					<Button
						className='float-right'
						variant='info'
						disabled={!selectedTrainingPlanId}
						onClick={this.handleEmailTraining}
					>
						Send
					</Button>
				</Col>
			</Col>
		)
	}

	getBrands = async () => {
		try {
			const brands = await axios.get(`${config.coreAPI}/brand/all/names?pageFrom=0&pageSize=1000`)

			this.setState({
				brands: brands.data.responses[0].results.sort((a, b) => (a.brand_name > b.brand_name) ? 1 : -1)
			})
		} catch(e) {
			console.error(e)
		}
	}

	handleBrandSelect = (brand) => {
		this.setState({
			selectedBrandName: brand.brand_name,
			selectedBrandId: brand.id
		}, () => this.getTrainingPlans())
	}

	getTrainingPlans = async () => {
		const { selectedBrandId } = this.state

		this.setState({
			selectedTrainingPlan: null,
			selectedTrainingPlanId: null
		})

		try {
			const trainings = await axios.get(`${config.coreAPI}/fieldDayAdmin/training/plans?brandId=${selectedBrandId}&pageFrom=0&pageSize=1000`)

			this.setState({
				trainingPlans: trainings.data
			})
		} catch(e) {
			console.error(e)
		}
	}

	handleTrainingSelect = (plan) => {
		this.setState({
			selectedTrainingPlan: plan.name,
			selectedTrainingPlanId: plan.id
		})
	}

	handleEmailTraining = () => {
		const { ambassadors } = this.props

		ambassadors.forEach(ambassador => this.emailTraining(ambassador.id, ambassador.ambassador_name))
	}

	emailTraining = async (ambassadorId, ambassadorName) => {
		const { selectedTrainingPlanId } = this.state

		this.setState({ sending: true })

		try {
			await axios.post(`${config.coreAPI}/training/email?trainingPlanId=${selectedTrainingPlanId}&ambassadorId=${ambassadorId}`)

			this.setState({
				sending: false,
				sent: true
			})

		} catch(e) {
			console.error(e)
			alert(`${ambassadorName} - ${e.response.data.message}`)
		}
	}
}

export default EmailTraining
