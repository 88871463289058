import React, { Fragment } from 'react'
import ReactTable from 'react-table'
import Button from 'react-bootstrap/Button'
import { map } from 'underscore'

import excelExporter from '../../utils/excelExporter'

function PhoneTargetList({ targetList, gigTitle }) {
	if(!targetList) return null

	const handleExport = () => {
		const filename = `Target List - ${gigTitle}`

		const columns = [
  		'Business',
  		'Address',
  		'City',
  		'State',
  		'Zip',
  		'Phone',
  		'Contact Name',
  		'Contact Title',
  		'SIC Codes',
  		'SIC Categories',
  	]

		const headerRow = map(columns, cell => ({ value: cell, type: 'string'}))

		const contentRows = map(targetList, place => ([
  		{
  			value: place.company_name,
  			type: 'string'
  		},
  		{
  			value: place.company_address,
  			type: 'string'
  		},
  		{
  			value: place.city,
  			type: 'string'
  		},
  		{
  			value: place.state,
  			type: 'string'
  		},
  		{
  			value: place.postal_code,
  			type: 'string'
  		},
  		{
  			value: place.phone_1,
  			type: 'string'
  		},
  		{
  			value: place.contact_name,
  			type: 'string'
  		},
  		{
  			value: place.contact_title,
  			type: 'string'
  		},
  		{
  			value: place.category_codes ? place.category_codes.map(category => category.category_code_id).join(' - ') : '',
  			type: 'string'
  		},
  		{
  			value: place.category_codes ? place.category_codes.map(category => category.category_code_description).join('; ') : '',
  			type: 'string'
  		},
  	]))

  	contentRows.unshift(headerRow)

		const config = {
		  filename,
		  sheet: {
		    data: contentRows
		  }
		}

		excelExporter(config)
  }

	return (
		<Fragment>
			<div className='d-flex align-items-center justify-content-between mb-3'>
				<h3>Targets</h3>
				<Button onClick={handleExport}>
					Export
				</Button>
			</div>
			<ReactTable
				data={targetList}
				className='-striped -highlight table-style'
				noDataText={'No places found'}
				defaultPageSize={10}
				showPageSizeOptions={false}
				filterable
				columns={[
					{
						Header: 'Business',
						id: 'business',
						headerClassName: 'table-header',
						className: 'table-column',
						filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase()),
						accessor: d => <span className='text-left'>{d.company_name}</span>
					},
					{
						Header: 'Address',
						id: 'address',
						headerClassName: 'table-header',
						className: 'table-column',
						filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase()),
						accessor: d => <span className='text-left'>{`${d.company_address}, ${d.city}, ${d.state} ${d.postal_code}`}</span>
					},
					{
						Header: 'Phone',
						id: 'phone',
						headerClassName: 'table-header',
						className: 'table-column',
						filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase()),
						accessor: d => <span className='text-left'>{d.phone_1}</span>
					},
					// {
					// 	Header: 'Employee Size',
					// 	id: 'employeeSize',
					// 	headerClassName: 'table-header',
					// 	className: 'table-column',
					// 	filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase()),
					// 	accessor: d => <span className='text-left'>{d.estimated_location_employee_count}</span>
					// },
				]}
				defaultSortMethod={(a, b, desc) => {
					// force null and undefined to the bottom
					a = a.props.children === null || a.props.children === undefined ? '' : a.props.children
					b = b.props.children === null || b.props.children === undefined ? '' : b.props.children
					// force any string values to lowercase

					a = typeof a === 'string' ? a.toLowerCase().replace(/,/g, '').replace(/\$/g, '') : a
					b = typeof b === 'string' ? b.toLowerCase().replace(/,/g, '').replace(/\$/g, '') : b

					if(Number(a) || Number(b)) {
						a = Number(a)
						b = Number(b)
					}

					// Return either 1 or -1 to indicate a sort priority
					if (a > b) {
					  return 1
					}
					if (a < b) {
					  return -1
					}
					// returning 0, undefined or any falsey value will use subsequent sorts or
					// the index as a tiebreaker
					return 0
				}}
			/>
		</Fragment>
	)
}

export default PhoneTargetList
