import React, { Fragment, useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import FullCalendar from '@fullcalendar/react'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
// import timeGridPlugin from '@fullcalendar/timegrid'
// import dayGridPlugin from '@fullcalendar/daygrid'
// import listPlugin from '@fullcalendar/list'
import { map, find } from 'underscore'
import moment from 'moment'
import { API } from 'aws-amplify'

export default function GigsCalendar({ start, end, gigs, onGigClick }) {
	const [brandColors, setBrandColors] = useState(null)
	const calendarRef = useRef(null)

	if(!gigs) return null

	useEffect(() => {
		const colors = gigs.results.reduce((accum, gig) => {
			accum[gig.brand_user_activity.brand_id] = randomColorGenerator()

			return accum
		}, {})

		setBrandColors(colors)
	}, [])

	const randomColorGenerator = () => {
		const letters = '0123456789ABCDEF'
		let color = '#'

		for(let i = 0; i < 6; i++) {
			color += letters[Math.floor(Math.random() * 16)]
		}

		return color
	}

	const resourceAreaColumns = [
    {
      group: true,
      field: 'brand',
      headerContent: 'Brand',
      cellContent: (arg) => {
      	return arg.fieldValue ? <h3 className='text-pre-wrap'>{arg.fieldValue}</h3> : <h3 className='text-pre-wrap'>{arg.groupValue}</h3>
      }
    },
    {
      field: 'gig',
      headerContent: 'Gig',
      width: '50%'
    },
    {
      field: 'ambassador',
      headerContent: 'Ambassador'
    },
    {
      field: 'hours',
      headerContent: 'Hours',
      width: '55px',
    }
  ]

	const resources = map(gigs.results, gig => {
		const assignedAmbassador = find(gig.applied_ambassadors, amb => amb.id === gig.brand_user_activity.field_day_management.field_day_workflow.accepted_ambassador_id)

		return {
			id: gig.brand_user_activity.id,
			// brand: <Brand key={gig.brand_user_activity.id} brandName={gig.brand_user_activity.brand_name} brandLogo={gig.associated_brand.brand_logo_url} /> ,
			brand: gig.brand_user_activity.brand_name,
			gig: <div className='pointer text-pre-wrap' onClick={() => onGigClick(gig)}>{gig.brand_user_activity.title}</div>,
			ambassador: assignedAmbassador ? <Link className='font-italic' to={`/ambassador/${assignedAmbassador.id}`}>{assignedAmbassador.ambassador_name}</Link> : null,
			hours: gig.brand_user_activity.field_day_management.field_day_remuneration.max_hours
		}
	})

	const events = map(gigs.results, gig => ({
  	id: gig.brand_user_activity.id,
  	classNames: ['pointer'],
  	title: `${moment(gig.brand_user_activity.due_date_millis).format('ha')}-${moment(gig.brand_user_activity.due_date_millis).add(gig.brand_user_activity.field_day_management.field_day_remuneration.max_hours, 'hours').format('ha')} ${gig.brand_user_activity.execution_type === 'STREET' ? '<FEET>' : '<PHONE>'}`,
  	start: gig.brand_user_activity.due_date_millis,
  	end: moment(gig.brand_user_activity.due_date_millis).add(gig.brand_user_activity.field_day_management.field_day_remuneration.max_hours, 'hours').valueOf(),
  	resourceId: gig.brand_user_activity.id,
  	color: brandColors && brandColors[gig.brand_user_activity.brand_id],
  	// textColor: 'red'
  	extendedProps: { gig }
  }))

	console.log('brandColors: ', brandColors)
	console.log('CALENDAR GIGS: ', gigs)
	console.log('resources: ', resources)
	console.log('events: ', events)

	return (
		<div>
			<FullCalendar
				schedulerLicenseKey='CC-Attribution-NonCommercial-NoDerivatives'
				ref={calendarRef => {
					if(!calendarRef) return

					const calendarApi = calendarRef.getApi()

					calendarApi.gotoDate(moment(start).valueOf())
				}}
				plugins={[ resourceTimelinePlugin ]}
				headerToolbar={{
		      left: 'today prev,next',
		      center: 'title',
		      right: 'resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth',
		    }}
				initialView='resourceTimelineWeek'
				aspectRatio={1.5}
				// resourceAreaWidth={'40%'}
				slotMinTime={'08:00:00'}
		    slotMaxTime={'18:00:00'}
		    eventClick={e => onGigClick(e.event.extendedProps.gig)}
		    resourceAreaColumns={resourceAreaColumns}
		    events={events}
				resources={resources}
			/>
		</div>
	)
}

function Brand({ brandName, brandLogo }) {
	const [logoUrl, setLogoUrl] = useState(null)

	useEffect(() => {
		getLogoUrl()
	}, [])

	const getLogoUrl = async () => {
		try {
			// const brandLogo = await getGigAssetsUrl(key.data)
			const result = await API.post('getSignedUrl', '/getSignedUrl', {
				body: { key: brandLogo }
			})

			setLogoUrl(result)
		} catch(e) {
			console.error(e)
		}
	}

	return (
		<div className='d-flex'>
			<div className='mr-2' style={{ width: '40px', height: '40px' }}>
				<img className='img-fit' src={logoUrl} />
			</div>
			<b>{brandName}</b>
		</div>
	)
}

// return (
// 	<Fragment>
// 		{brandColors
// 			? <FullCalendar
// 					ref={calendarRef => {
// 						if(!calendarRef) return

// 						const calendarApi = calendarRef.getApi()

// 						calendarApi.gotoDate(moment(start).valueOf())
// 					}}
// 					plugins={[ timeGridPlugin, dayGridPlugin, listPlugin ]}
// 					initialView='timeGridWeek'
// 					eventClick={e => onGigClick(e.event.extendedProps.gig)}
// 					headerToolbar={{
// 	          left: 'prev,next today',
// 	          right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
// 	          center: 'title',
// 	        }}
// 					events={map(gigs.results, gig => ({
// 						id: gig.brand_user_activity.id,
// 						classNames: ['pointer'],
// 						title: `${gig.brand_user_activity.brand_name.toUpperCase()} - ${gig.brand_user_activity.title}`,
// 						date: gig.brand_user_activity.due_date_millis,
// 						color: brandColors[gig.brand_user_activity.brand_id],
// 						// textColor: 'red'
// 						extendedProps: { gig }
// 					}))}
// 					// editable={true}
// 					// droppable={true}
// 		      // eventDrop={function (info) { eventChanged(info, id); }}
// 				/>
// 			: null
// 		}
// 	</Fragment>
// )
