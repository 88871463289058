import React from 'react'
import { Form, Button } from 'react-bootstrap'

function UserForm({ index, values, onChange, onRemoveUser }) {
	return (
		<Form.Row className='justify-content-around align-items-center border-bottom border-secondary mb-3'>
			<Form.Group>
				<Form.Label>First Name</Form.Label>
				<Form.Control
					required
				  type='string'
				  name='first_name'
				  value={values.first_name}
				  onChange={e => onChange(index, e)}
				/>
				<Form.Control.Feedback type='invalid'>Required</Form.Control.Feedback>
			</Form.Group>
			<Form.Group>
				<Form.Label>Last Name</Form.Label>
				<Form.Control
					required
				  type='text'
				  name='last_name'
				  value={values.last_name}
				  onChange={e => onChange(index, e)}
				/>
				<Form.Control.Feedback type='invalid'>Required</Form.Control.Feedback>
			</Form.Group>
			<Form.Group>
				<Form.Label>Email</Form.Label>
				<Form.Control
					required
				  type='text'
				  name='email_address'
				  value={values.email_address}
				  onChange={e => onChange(index, e)}
				/>
				<Form.Control.Feedback type='invalid'>Required</Form.Control.Feedback>
			</Form.Group>
			<Form.Group>
				<Form.Label>Password</Form.Label>
				<Form.Control
					required
				  type='text'
				  name='password'
				  value={values.password}
				  onChange={e => onChange(index, e)}
				/>
				<Form.Control.Feedback type='invalid'>Required</Form.Control.Feedback>
			</Form.Group>
			<Form.Group>
				<Form.Label>Display User Profile</Form.Label>
				<Form.Control
					required
					custom='true'
				  as='select'
				  name='displayUserProfile'
				  value={values.displayUserProfile}
				  onChange={e => onChange(index, e)}
				>
					<option value={true}>Yes</option>
					<option value={false}>No</option>
				</Form.Control>
				<Form.Control.Feedback type='invalid'>Required</Form.Control.Feedback>
			</Form.Group>
			<Form.Group>
				<Form.Label>Associated Brands</Form.Label>
				<Form.Control
					required
				  type='text'
				  name='brand_id'
				  value={values.brand_id}
				  onChange={e => onChange(index, e)}
				/>
				<Form.Control.Feedback type='invalid'>Required</Form.Control.Feedback>
			</Form.Group>
			<Form.Group>
				<Form.Label>Role Name</Form.Label>
				<Form.Control
					required
				  type='text'
				  name='role_name'
				  value={values.role_name}
				  onChange={e => onChange(index, e)}
				/>
				<Form.Control.Feedback type='invalid'>Required</Form.Control.Feedback>
			</Form.Group>
			<Form.Group>
				<Form.Label>Role Type</Form.Label>
				<Form.Control
					required
					custom='true'
				  as='select'
				  name='role_type'
				  value={values.role_type}
				  onChange={e => onChange(index, e)}
				>
					<option>CXO</option>
					<option>CMO</option>
					<option>RMM</option>
					<option>SM</option>
					<option>FO</option>
					<option>EMP</option>
				</Form.Control>
				<Form.Control.Feedback type='invalid'>Required</Form.Control.Feedback>
			</Form.Group>
			<Form.Group>
				<Form.Label>Stores</Form.Label>
				<Form.Control
					required
				  type='text'
				  name='store_ids'
				  value={values.region_name}
				  onChange={e => onChange(index, e)}
				/>
				<Form.Control.Feedback type='invalid'>Required</Form.Control.Feedback>
			</Form.Group>
			<Form.Group>
				<Form.Label>Region</Form.Label>
				<Form.Control
					required
				  type='text'
				  name='region_name'
				  value={values.region_name}
				  onChange={e => onChange(index, e)}
				/>
				<Form.Control.Feedback type='invalid'>Required</Form.Control.Feedback>
			</Form.Group>
			{index !== 0 && <i className='fas fa-minus-circle text-danger pointer' onClick={() => onRemoveUser(index)}></i>}
		</Form.Row>
	)
}

export default UserForm
