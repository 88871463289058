import React, { Fragment, Component } from 'react'
import { connect } from 'react-redux'
import { Storage } from 'aws-amplify'
import axios from 'axios'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Form from 'react-bootstrap/Form'
import { Button as ButtonS } from 'semantic-ui-react'

import config from '../../../config'
import BrandStores from './BrandStores'
import BrandUsers from './BrandUsers'
// import InfoGroup from './InfoGroup'
import { handleBrandSelect, handleErrors } from '../../../actions/brands'

class BrandProfile extends Component {
	state = {
		selectedTab: 'info',
	}

	componentDidMount() {
		this.getLatestBrandBackUp()
	}

	render() {
		const { brand, errors } = this.props
		const {
			selectedTab,
			uploadingBrandLogo,
			uploadingStoreProfileLogo,
			brandLogoUploaded,
			brandStoreLogoUploaded,
			creatingSendGridUser,
			generatingApiKey,
			pushingBrand,
			brandBackUpExists
		} = this.state

		console.log("BRAND PROFILE PROPS", brand)

		return (
			<div>
				{errors.map(e => (
					<div key={Math.random()} className='text-danger'>
						{e.response && e.response.data && e.response.data.errors}
					</div>
				))}
				<div className='d-flex justify-content-between'>
					<h2>Brand Overview</h2>
					<ButtonGroup className='mr-2' size='sm'>
						<Button variant={`outline-dark ${selectedTab === 'info' && 'active'}`} onClick={() => this.handleTabChange('info')}>Info</Button>
						<Button variant={`outline-dark ${selectedTab === 'stores' && 'active'}`} onClick={() => this.handleTabChange('stores')}>Stores</Button>
						<Button variant={`outline-dark ${selectedTab === 'users' && 'active'}`} onClick={() => this.handleTabChange('users')}>Users</Button>
					</ButtonGroup>
				</div>
				<hr />
				{selectedTab === 'info' &&
					<div>
						<Row>
							<Col lg={2} className='font-weight-bold'>Activity Custom Fields</Col>
							<Col>
								{brand.activity_custom_fields && brand.activity_custom_fields.map((field, i) => <span key={i}>{field.name}</span>)}
							</Col>
						</Row>
						<Row>
							<Col lg={2} className='font-weight-bold'>Default Activity Questions</Col>
							<Col>
								{brand.activity_questions && brand.activity_questions.map((q, i) => <span key={i}>{q.name}</span>)}
							</Col>
						</Row>
						<Row className='mb-3'>
							<Col lg={2} className='font-weight-bold'>Activity Route Settings</Col>
							<Col>
								{brand.activity_route_settings && brand.activity_route_settings.map((setting, i) => <span key={i}>{setting.tag_name}</span>)}
							</Col>
						</Row>
						<Row className='mb-3'>
							<Col lg={2} className='font-weight-bold'>Brand Logo</Col>
							<Col md={1}>
							<div className='custom-file'>
							  <input type='file' className='custom-file-input' id='customFile' onChange={this.uploadBrandLogo} />
							  <ButtonS primary loading={uploadingBrandLogo}>Upload</ButtonS>
							  {brandLogoUploaded && <i className='fas fa-check text-success'></i>}
							</div>
							</Col>
						</Row>
						<Row className='mb-3'>
							<Col lg={2} className='font-weight-bold'>Brand Logo for Store Profile</Col>
							<Col md={1}>
								<div className='custom-file'>
								  <input type='file' className='custom-file-input' id='customFile' onChange={this.uploadStoreProfileLogo} />
								  <ButtonS primary loading={uploadingStoreProfileLogo}>Upload</ButtonS>
								  {brandStoreLogoUploaded && <i className='fas fa-check text-success'></i>}
								</div>
							</Col>
						</Row>
						<Row className='mb-3'>
							<Col lg={2} className='font-weight-bold'>Default Store Image</Col>
							<Col>
							<div className='custom-file'>
							  <input type='file' className='custom-file-input' id='customFile' onChange={this.uploadStoreImage} />
							  <Button size='sm'>Upload</Button>
							</div>
							</Col>
						</Row>
						<Row className='mb-3'>
							<Col lg={2} className='font-weight-bold'>Default User Image</Col>
							<Col>
							<div className='custom-file'>
							  <input type='file' className='custom-file-input' id='customFile' onChange={this.uploadUserImage} />
							  <Button size='sm'>Upload</Button>
							</div>
							</Col>
						</Row>
						<Row className='mb-3'>
							<Col lg={2} className='font-weight-bold'>Business Card Scanner</Col>
							<Col>
								<ButtonS primary onClick={this.handleBusinessCardScannerSetting}>
									{this.getScannerButtonText()}
								</ButtonS>
							</Col>
						</Row>
						<Row className='mb-3'>
							<Col lg={2} className='font-weight-bold'>Contact Form</Col>
							<Col>
								<ButtonS primary onClick={this.handleContactFormSetting}>
									{this.getFormsButtonText()}
								</ButtonS>
							</Col>
						</Row>
						{process.env.REACT_APP_STAGE === 'prod' &&
							<Fragment>
								<Row className='mb-3'>
									<Col md={2} className='font-weight-bold'>Sendgrid Subuser</Col>
									<Col md={4}>
										{brand.sendgrid_username
											? brand.sendgrid_username
											: <div>
													<Form.Group>
														<Form.Label>Subuser name</Form.Label>
														<Form.Control
															placeholder='Use Brand Name. e.g. FieldDayBrand ( no spaces, no special characters except _ )'
															name='sendGridSubuserUserName'
															value={this.state.sendGridSubuserUserName}
															onChange={this.hadnleInputChange}
														/>
													</Form.Group>
													<Form.Group>
														<Form.Label>Subuser Email</Form.Label>
														<Form.Control
															placeholder='e.g. "<brandName>@localstoreidentity.com"'
															name='sendGridSubuserEmail'
															value={this.state.sendGridSubuserEmail}
															onChange={this.hadnleInputChange}
														/>
													</Form.Group>
													<ButtonS loading={creatingSendGridUser} primary onClick={this.handleCreateSendGridSubuser}>Create</ButtonS>
												</div>
										}
									</Col>
								</Row>
								<Row className='mb-3'>
									<Col md={2} className='font-weight-bold'>Sendgrid API Key</Col>
									<Col>
										{!brand.sendgrid_username || brand.sendgrid_api_key
											? 'API key already generated, or there is no Sendgrid subuser yet'
											: <ButtonS loading={generatingApiKey} primary onClick={this.handleGenerateSendGridApiKey}>Generate</ButtonS>
										}
									</Col>
								</Row>
							</Fragment>
						}
						<hr />
						{/*<InfoGroup />*/}
						{process.env.REACT_APP_STAGE === 'dev' &&
							<div>
								{brandBackUpExists
									? <h3 className='text-center'>Brand is Already Pushed</h3>
									: <div className='d-flex justify-content-center'>
											<ButtonS loading={pushingBrand} secondary onClick={this.handlePushBrand}>Push Brand</ButtonS>
										</div>
								}
							</div>
						}
					</div>
				}
				{selectedTab === 'stores' && <BrandStores brandId={brand.brand_id} brandName={brand.brand_name} />}
				{selectedTab === 'users' && <BrandUsers brandId={brand.brand_id} brandName={brand.brand_name} />}
			</div>
		)
	}

	getLatestBrandBackUp = async () => {
		try {
			const backup = await axios.get(`https://core.api.fieldday.app/coreAPI/brand/backup/latest?brandId=${this.props.brand.brand_id}`)

			if(backup.data) {
				this.setState({ brandBackUpExists: true })
			}
		} catch(e) {
			console.error(e)
		}
	}

	handleTabChange = (tab) => {
		this.setState({
			selectedTab: tab
		})
	}

	hadnleInputChange = (e) => {
		const { name, value } = e.target

		this.setState({ [name]: value })
	}

	getScannerButtonText = () => {
		const fieldDaySettings = this.props.brand.field_day_settings

		if(!fieldDaySettings) return 'N/A'
		if(fieldDaySettings.business_card_scan_disabled === true) return 'Disabled'
		if(fieldDaySettings.business_card_scan_disabled === false) return 'Enabled'
		if(!fieldDaySettings.business_card_scan_disabled) return 'N/A'
	}

	getFormsButtonText = () => {
		const fieldDaySettings = this.props.brand.field_day_settings

		if(!fieldDaySettings) return 'N/A'
		if(fieldDaySettings.contact_form_disabled === true) return 'Disabled'
		if(fieldDaySettings.contact_form_disabled === false) return 'Enabled'
		if(!fieldDaySettings.contact_form_disabled) return 'N/A'
	}

	uploadBrandLogo = async (e) => {
		e.preventDefault()

		const { brand } = this.props
		const file = e.target.files[0]

		if(!file) return

		this.setState({
			uploadingBrandLogo: true,
			brandLogoUploaded: false
		})

		try {
			const brandLogo = await Storage.put('brand-logo.png', file, {
				contentType: file.type,
				customPrefix: {
					public: `brands/${brand.brand_id}/logos/`,
					protected: `brands/${brand.brand_id}/logos/`,
					private: `brands/${brand.brand_id}/logos/`
				}
			})

			await axios.post(`${config.coreAPI}/brand/${brand.brand_id}/configuration/updatebyfields`, {
				name_value_map: {
					brandLogoUrl: `brands/${brand.brand_id}/logos/brand-logo.png`
				}
			})

			this.setState({
				uploadingBrandLogo: false,
				brandLogoUploaded: true
			})

		} catch(e) {
			this.setState({ uploadingBrandLogo: false })
			console.error(e)
			alert('ERROR')
		}
	}

	uploadStoreProfileLogo = async (e) => {
		e.preventDefault()

		const { brand } = this.props
		const file = e.target.files[0]

		if(!file) return

		this.setState({
			uploadingStoreProfileLogo: true,
			brandStoreLogoUploaded: false
		})

		try {
			const brandStoreLogo = await Storage.put('store-pic.png', file, {
				contentType: file.type,
				customPrefix: {
					public: `brands/${brand.brand_id}/logos/`,
					protected: `brands/${brand.brand_id}/logos/`,
					private: `brands/${brand.brand_id}/logos/`
				}
			})

			await axios.post(`${config.coreAPI}/brand/${brand.brand_id}/configuration/updatebyfields`, {
				name_value_map: {
					brandDefaultStorePicUrl: `brands/${brand.brand_id}/logos/store-pic.png`
				}
			})

			this.setState({
				uploadingStoreProfileLogo: false,
				brandStoreLogoUploaded: true
			})

		} catch(e) {
			this.setState({ uploadingStoreProfileLogo: false })
			console.error(e)
			alert('ERROR')
		}
	}

	// UN-COMMENT FOR WHEN CUSTOM LOGOS NEEDED
	uploadStoreImage = async (e) => {
		e.preventDefault()

		const { brand } = this.props
		const file = e.target.files[0]

		if(!file) return

		this.setState({ uploading: true })

		try {
			const storePic = await Storage.put('store-pic.png', file, {
				contentType: file.type,
				customPrefix: {
					public: `brands/${brand.brand_id}/defaults/`,
					protected: `brands/${brand.brand_id}/defaults/`,
					private: `brands/${brand.brand_id}/defaults/`
				}
			})

			await axios.post(`${config.coreAPI}/brand/${brand.brand_id}/configuration/updatebyfields`, {
				name_value_map: {
					brandDefaultStorePicUrl: `brands/${brand.brand_id}/logos/store-pic.png`
				}
			})

			this.setState({ uploading: false })
			console.log('stored: ', storePic)
		} catch(e) {
			this.setState({ uploading: false })
			console.error(e)
			alert('ERROR')
		}
	}

	uploadUserImage = async (e) => {
		e.preventDefault()

		const { brand } = this.props
		const file = e.target.files[0]

		if(!file) return

		this.setState({ uploading: true })

		try {
			const userPic = await Storage.put('user-pic.png', file, {
				contentType: file.type,
				customPrefix: {
					public: `brands/${brand.brand_id}/defaults/`,
					protected: `brands/${brand.brand_id}/defaults/`,
					private: `brands/${brand.brand_id}/defaults/`
				}
			})

			this.setState({ uploading: false })
			console.log('stored: ', userPic)
		} catch(e) {
			this.setState({ uploading: false })
			console.error(e)
			alert('ERROR')
		}
	}

	handleBusinessCardScannerSetting = async () => {
		const { dispatch, brand } = this.props

		try {
			await axios.patch(`${config.coreAPI}/brand/${brand.brand_id}/fieldDaySettings`, {
				business_card_scan_disabled: brand.field_day_settings && brand.field_day_settings.business_card_scan_disabled ? false : true
			})

			dispatch(handleBrandSelect(brand))
		} catch(e) {
			console.error(e)
		}
	}

	handleContactFormSetting = async () => {
		const { dispatch, brand } = this.props
		try {
			await axios.patch(`${config.coreAPI}/brand/${brand.brand_id}/fieldDaySettings`, {
				contact_form_disabled: brand.field_day_settings && brand.field_day_settings.contact_form_disabled ? false : true
			})

			dispatch(handleBrandSelect(brand))
		} catch(e) {
			console.error(e)
		}
	}

	handleCreateSendGridSubuser = async () => {
		const { dispatch, brand } = this.props
		const { sendGridSubuserUserName, sendGridSubuserEmail } = this.state

		try {
			this.setState({ creatingSendGridUser: true })

			const sendGridRes = await axios.post(`https://ddnofdks8d.execute-api.us-west-2.amazonaws.com/dev/createSendGridSubuser`, {
				username: sendGridSubuserUserName,
				email: sendGridSubuserEmail
			})

			const brandData = await axios.post(`${config.coreAPI}/brand/${brand.brand_id}/configuration/updatebyfields`, {
				name_value_map: {
					sendgridUsername: sendGridRes.data[1].username
				}
			})

			alert('Successful')

			dispatch(handleBrandSelect(brand))

			this.setState({ creatingSendGridUser: false })
		} catch(e) {
			alert('Error in creating Subuser: ' + (e.response && e.response.data && e.response.data.response && JSON.stringify(e.response.data.response.body.errors)))
			this.setState({ creatingSendGridUser: false })
			console.error(e)
		}
	}

	handleGenerateSendGridApiKey = async () => {
		const { dispatch, brand } = this.props

		try {
			this.setState({ generatingApiKey: true })

			const response = await axios.post(`https://ddnofdks8d.execute-api.us-west-2.amazonaws.com/dev/generateSendGridApiKey`, {
				name: 'MAIL_SEND',
				onBehalfOf: brand.sendgrid_username
			})

			const brandData = await axios.post(`${config.coreAPI}/brand/${brand.brand_id}/configuration/updatebyfields`, {
				name_value_map: {
					sendgridApiKey: response.data[1].api_key
				}
			})

			alert('Successful')

			dispatch(handleBrandSelect(brand))

			this.setState({ generatingApiKey: false })
		} catch(e) {
			alert('Error in generating API key')
			this.setState({ generatingApiKey: false })
			console.error(e)
		}
	}

	handlePushBrand = async () => {
		const confirm = window.confirm('Are you sure you want to push this brand to production?')

		if(!confirm) return

		const { dispatch, brand } = this.props

		try {
			this.setState({ pushingBrand: true })

			await axios.post(`https://dev.core.api.fieldday.app/coreAPI/brand/backup?brandIds=${brand.brand_id}`)

			const s3FileName = await axios.get(`https://dev.core.api.fieldday.app/coreAPI/brand/backup/latest?brandId=${brand.brand_id}`)

			await axios.post(`https://core.api.fieldday.app/coreAPI/brand/overwrite?brandId=${brand.brand_id}&s3FileName=${s3FileName.data}`)

			this.setState({ pushingBrand: false })
			alert('Brand Pushed Successfully')
			// DO getBrand()
		} catch(e) {
			console.error(e)
			alert('Error! Check the logs.')

			this.setState({
				pushingBrand: false
			}, () => dispatch(handleErrors(e)))
		}
	}
}

function mapStateToProps({ brands }) {
	return {
		view: brands.view,
		brand: brands.selectedBrand,
		stores: brands.selectedBrandStores,
		errors: brands.errors
	}
}

export default connect(mapStateToProps)(BrandProfile)
