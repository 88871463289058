import React, { Fragment, useState } from 'react'
import axios from 'axios'
import excelExporter from '../../utils/excelExporter'
import moment from 'moment'
import { map, find } from 'lodash'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

import config from '../../config'

export default function LeadsExporter({ props }) {
	const [exportingLeadsExcel, setExportingLeadsExcel] = useState(false)
	const [exportingStreetExcel, setExportingStreetExcel] = useState(false)
	// const [gigs, setGigs] = useState(null)
	const [phoneGigs, setPhoneGigs] = useState(null)
	const [streetGigs, setStreetGigs] = useState(null)
	const [contacts, setContacts] = useState(null)
	const [leadActions, setLeadActions] = useState(null)
	// console.log('LeadsExporter: ', props)

	const getGigs = (exportStreet) => {
		setExportingLeadsExcel(true)

		const {
			startDate,
			endDate,
			brandNameSearchString,
			titleSearchString,
			campaignIdSearchString,
			addressSearchString,
			sortFieldName,
			sortDirection,
			selectedGigStatus,
			selectedBrand,
			masterToggle
		} = props

		const unfulfilledParams = `dueDateFromMillis=${moment(startDate).valueOf()}&dueDateToMillis=${Date.now()}&pageFrom=0&pageSize=10000&noMasters=${masterToggle}&noChildren=${!masterToggle}`
		const filters = {
			dueDateFromMillis: moment(startDate).valueOf(),
			dueDateToMillis: moment(endDate).valueOf(),
			pageFrom: 0,
			pageSize: 10000,
			noMasters: masterToggle,
			noChildren: !masterToggle
		}

		if(brandNameSearchString) filters.brandNameSearchString = brandNameSearchString
		if(titleSearchString) filters.titleSearchString = titleSearchString
		if(campaignIdSearchString) filters.campaignId = campaignIdSearchString
		if(addressSearchString) filters.addressSearchString = addressSearchString
		if(sortFieldName) filters.sortFieldName = sortFieldName
		if(sortDirection) filters.sortDirection = sortDirection
		if(selectedBrand) filters.brandNameSearchString = selectedBrand

		const params = map(filters, (val, key) => `${key}=${val}`).join('&')

		if(selectedGigStatus === 'appliedGigs') {
			axios.get(`${config.coreAPI}/fieldDayAdmin/activities?applied=true&accepted=false&${params}`)
				.then(appliedGigs => {
					const phoneGigs = []
					const streetGigs = []

					appliedGigs.data.responses[0].results.forEach(gig => {
						if(gig.brand_user_activity.execution_type === 'PHONE') {
							phoneGigs.push(gig)
						}

						if(gig.brand_user_activity.execution_type === 'STREET') {
							streetGigs.push(gig)

						}
					})



					if(exportStreet) {
						// for street gigs export only
						setStreetGigs(streetGigs)
						getStreetContacts(streetGigs)
					} else {
						// for phone gigs export only
						setPhoneGigs(phoneGigs)
						getPhoneContacts(phoneGigs)
						getLeadActions(phoneGigs)
					}
				})
		}

		if(selectedGigStatus === 'unassignedGigs') {
			axios.get(`${config.coreAPI}/fieldDayAdmin/activities?accepted=false&${params}`)
				.then(unassignedGigs => {
					const phoneGigs = []
					const streetGigs = []

					unassignedGigs.data.responses[0].results.forEach(gig => {
						if(gig.brand_user_activity.execution_type === 'PHONE') {
							phoneGigs.push(gig)
						}

						if(gig.brand_user_activity.execution_type === 'STREET') {
							streetGigs.push(gig)

						}
					})



					if(exportStreet) {
						// for street gigs export only
						setStreetGigs(streetGigs)
						getStreetContacts(streetGigs)
					} else {
						// for phone gigs export only
						setPhoneGigs(phoneGigs)
						getPhoneContacts(phoneGigs)
						getLeadActions(phoneGigs)
					}
				})
		}

		if(selectedGigStatus === 'unfulfilledGigs') {
			axios.get(`${config.coreAPI}/fieldDayAdmin/activities?accepted=true&started=false&${unfulfilledParams}`)
				.then(unfulfilledGigs => {
					const phoneGigs = []
					const streetGigs = []

					unfulfilledGigs.data.responses[0].results.forEach(gig => {
						if(gig.brand_user_activity.execution_type === 'PHONE') {
							phoneGigs.push(gig)
						}

						if(gig.brand_user_activity.execution_type === 'STREET') {
							streetGigs.push(gig)

						}
					})



					if(exportStreet) {
						// for street gigs export only
						setStreetGigs(streetGigs)
						getStreetContacts(streetGigs)
					} else {
						// for phone gigs export only
						setPhoneGigs(phoneGigs)
						getPhoneContacts(phoneGigs)
						getLeadActions(phoneGigs)
					}
				})
		}

		if(selectedGigStatus === 'canceledGigs') {
			axios.get(`${config.coreAPI}/fieldDayAdmin/activities/cancelled?ignoreAssigned=true&${params}`)
				.then(canceledGigs => {
					const phoneGigs = []
					const streetGigs = []

					canceledGigs.data.responses[0].results.forEach(gig => {
						if(gig.brand_user_activity.execution_type === 'PHONE') {
							phoneGigs.push(gig)
						}

						if(gig.brand_user_activity.execution_type === 'STREET') {
							streetGigs.push(gig)

						}
					})



					if(exportStreet) {
						// for street gigs export only
						setStreetGigs(streetGigs)
						getStreetContacts(streetGigs)
					} else {
						// for phone gigs export only
						setPhoneGigs(phoneGigs)
						getPhoneContacts(phoneGigs)
						getLeadActions(phoneGigs)
					}
				})
		}

		if(selectedGigStatus === 'unconfirmedGigs') {
			axios.get(`${config.coreAPI}/fieldDayAdmin/activities?smsConfirmed=false&${params}`)
				.then(unconfirmedGigs => {
					const phoneGigs = []
					const streetGigs = []

					unconfirmedGigs.data.responses[0].results.forEach(gig => {
						if(gig.brand_user_activity.execution_type === 'PHONE') {
							phoneGigs.push(gig)
						}

						if(gig.brand_user_activity.execution_type === 'STREET') {
							streetGigs.push(gig)

						}
					})



					if(exportStreet) {
						// for street gigs export only
						setStreetGigs(streetGigs)
						getStreetContacts(streetGigs)
					} else {
						// for phone gigs export only
						setPhoneGigs(phoneGigs)
						getPhoneContacts(phoneGigs)
						getLeadActions(phoneGigs)
					}
				})
		}

		if(selectedGigStatus === 'confirmedGigs') {
			axios.get(`${config.coreAPI}/fieldDayAdmin/activities?smsConfirmed=true&${params}`)
				.then(confirmedGigs => {
					const phoneGigs = []
					const streetGigs = []

					confirmedGigs.data.responses[0].results.forEach(gig => {
						if(gig.brand_user_activity.execution_type === 'PHONE') {
							phoneGigs.push(gig)
						}

						if(gig.brand_user_activity.execution_type === 'STREET') {
							streetGigs.push(gig)

						}
					})



					if(exportStreet) {
						// for street gigs export only
						setStreetGigs(streetGigs)
						getStreetContacts(streetGigs)
					} else {
						// for phone gigs export only
						setPhoneGigs(phoneGigs)
						getPhoneContacts(phoneGigs)
						getLeadActions(phoneGigs)
					}
				})
		}

		if(selectedGigStatus === 'assignedGigs') {
			axios.get(`${config.coreAPI}/fieldDayAdmin/activities?accepted=true&completed=false&${params}`)
				.then(assignedGigs => {
					const phoneGigs = []
					const streetGigs = []

					assignedGigs.data.responses[0].results.forEach(gig => {
						if(gig.brand_user_activity.execution_type === 'PHONE') {
							phoneGigs.push(gig)
						}

						if(gig.brand_user_activity.execution_type === 'STREET') {
							streetGigs.push(gig)

						}
					})



					if(exportStreet) {
						// for street gigs export only
						setStreetGigs(streetGigs)
						getStreetContacts(streetGigs)
					} else {
						// for phone gigs export only
						setPhoneGigs(phoneGigs)
						getPhoneContacts(phoneGigs)
						getLeadActions(phoneGigs)
					}
				})
		}

		if(selectedGigStatus === 'unpaidGigs') {
			axios.get(`${config.coreAPI}/fieldDayAdmin/activities?finished=true&paid=false&${params}`)
				.then(unpaidGigs => {
					const phoneGigs = []
					const streetGigs = []

					unpaidGigs.data.responses[0].results.forEach(gig => {
						if(gig.brand_user_activity.execution_type === 'PHONE') {
							phoneGigs.push(gig)
						}

						if(gig.brand_user_activity.execution_type === 'STREET') {
							streetGigs.push(gig)

						}
					})



					if(exportStreet) {
						// for street gigs export only
						setStreetGigs(streetGigs)
						getStreetContacts(streetGigs)
					} else {
						// for phone gigs export only
						setPhoneGigs(phoneGigs)
						getPhoneContacts(phoneGigs)
						getLeadActions(phoneGigs)
					}
				})
		}

		if(selectedGigStatus === 'completedGigs') {
			axios.get(`${config.coreAPI}/fieldDayAdmin/activities?started=true&finished=true&${params}`)
				.then(completedGigs => {
					const phoneGigs = []
					const streetGigs = []

					completedGigs.data.responses[0].results.forEach(gig => {
						if(gig.brand_user_activity.execution_type === 'PHONE') {
							phoneGigs.push(gig)
						}

						if(gig.brand_user_activity.execution_type === 'STREET') {
							streetGigs.push(gig)

						}
					})



					if(exportStreet) {
						// for street gigs export only
						setStreetGigs(streetGigs)
						getStreetContacts(streetGigs)
					} else {
						// for phone gigs export only
						setPhoneGigs(phoneGigs)
						getPhoneContacts(phoneGigs)
						getLeadActions(phoneGigs)
					}
				})
		}

		if(selectedGigStatus === 'inReviewGigs') {
			axios.get(`${config.coreAPI}/fieldDayAdmin/activities?hasReview=true&paid=false&completed=false&${params}`)
				.then(inReviewGigs => {
					const phoneGigs = []
					const streetGigs = []

					inReviewGigs.data.responses[0].results.forEach(gig => {
						if(gig.brand_user_activity.execution_type === 'PHONE') {
							phoneGigs.push(gig)
						}

						if(gig.brand_user_activity.execution_type === 'STREET') {
							streetGigs.push(gig)

						}
					})



					if(exportStreet) {
						// for street gigs export only
						setStreetGigs(streetGigs)
						getStreetContacts(streetGigs)
					} else {
						// for phone gigs export only
						setPhoneGigs(phoneGigs)
						getPhoneContacts(phoneGigs)
						getLeadActions(phoneGigs)
					}
				})
		}

		if(selectedGigStatus === 'upcomingGigs') {
			axios.get(`${config.coreAPI}/fieldDayAdmin/activities?finished=false&completed=false&${params}`)
				.then(upcomingGigs => {
					const phoneGigs = []
					const streetGigs = []

					upcomingGigs.data.responses[0].results.forEach(gig => {
						if(gig.brand_user_activity.execution_type === 'PHONE') {
							phoneGigs.push(gig)
						}

						if(gig.brand_user_activity.execution_type === 'STREET') {
							streetGigs.push(gig)

						}
					})



					if(exportStreet) {
						// for street gigs export only
						setStreetGigs(streetGigs)
						getStreetContacts(streetGigs)
					} else {
						// for phone gigs export only
						setPhoneGigs(phoneGigs)
						getPhoneContacts(phoneGigs)
						getLeadActions(phoneGigs)
					}
				})
		}

		if(selectedGigStatus === 'allGigsForBrand') {
			axios.get(`${config.coreAPI}/fieldDayAdmin/activities?${params}`)
				.then(allGigsForBrand => {
					const phoneGigs = []
					const streetGigs = []

					allGigsForBrand.data.responses[0].results.forEach(gig => {
						if(gig.brand_user_activity.execution_type === 'PHONE') {
							phoneGigs.push(gig)
						}

						if(gig.brand_user_activity.execution_type === 'STREET') {
							streetGigs.push(gig)

						}
					})



					if(exportStreet) {
						// for street gigs export only
						setStreetGigs(streetGigs)
						getStreetContacts(streetGigs)
					} else {
						// for phone gigs export only
						setPhoneGigs(phoneGigs)
						getPhoneContacts(phoneGigs)
						getLeadActions(phoneGigs)
					}
				})
		}
	}

	const getPhoneContacts = async (gigs) => { // gets phone contacts only
		const contactsPromises = gigs.map(gig => axios.get(`${config.coreAPI}/contact?activityId=${gig.brand_user_activity.id}&pageFrom=0&pageSize=10000`))

		Promise.all(contactsPromises).then(contactsRes => {
			const gigsState = [ ...gigs ]

			contactsRes.forEach((contacts, i) => {
				gigs[i].contacts = contacts.data.responses[0].results
			})

			setPhoneGigs(gigsState)
			setContacts(true)
		})
	}

	const getStreetContacts = async (gigs) => {
		const contactsPromises = gigs.map(gig => axios.get(`${config.coreAPI}/contact?activityId=${gig.brand_user_activity.id}&pageFrom=0&pageSize=10000`))

		Promise.all(contactsPromises).then(contactsRes => {
			const gigsState = [ ...gigs ]

			contactsRes.forEach((contacts, i) => {
				gigs[i].contacts = contacts.data.responses[0].results
			})

			setStreetGigs(gigsState)
			setContacts(true)
		})
	}

	const getLeadActions = async (gigs) => {
		const leadActionsPromises = gigs.map(gig => axios.get(`${config.coreAPI}/lead-actions?activity_id=${gig.brand_user_activity.id}&page_from=0&page_size=10000`))

		Promise.all(leadActionsPromises).then(leadActionsRes => {
			const gigsState = [ ...gigs ]

			leadActionsRes.forEach((leadActions, i) => {
				gigs[i].leadActions = leadActions.data.responses[0].results
			})

			setPhoneGigs(gigsState)
			setLeadActions(true)
		})
	}

	const exportAsExcel = (data) => {
		console.log(data)
		const rowsData = data.map(gig => {
			return gig.leadActions.map(leadAction => {
				const associatedContact = find(gig.contacts, contact => contact.id === leadAction.associated_entity_id)

				return {
					brandName: gig.brand_user_activity.brand_name,
					campaignName: gig.brand_user_activity.campaign ? gig.brand_user_activity.campaign.campaign_name : '',
					campaignId: gig.brand_user_activity.campaign ? gig.brand_user_activity.campaign.campaign_id : '',
					gigTitle: gig.brand_user_activity.title || '',
					gigId: gig.brand_user_activity.id || '',
					storeName: gig.brand_user_activity.stores ? gig.brand_user_activity.stores[0].store_name : '',
					storeId: gig.brand_user_activity.stores ? gig.brand_user_activity.stores[0].store_id : '',
					companyName: (associatedContact && associatedContact.company_name) || '',
					contactName: (associatedContact && associatedContact.contact_name) || '',
					contactTitle: (associatedContact && associatedContact.contact_title) || '',
					personReached: leadAction.person_reached_name || '',
					contactEmail: (associatedContact && associatedContact.contact_email) || '',
					employeeSize: associatedContact && associatedContact.employee_size >= 0 ? Number(associatedContact.employee_size) : '',
					companyAddress: (associatedContact && associatedContact.company_address) || '',
					city: (associatedContact && associatedContact.city) || '',
					state: (associatedContact && associatedContact.state) || '',
					zip: (associatedContact && associatedContact.postal_code) || '',
					phone: (associatedContact && associatedContact.phone_1) || '',
					fax: (associatedContact && associatedContact.phone_3) || '', // phone_3 is being used for fax number for now
					date: leadAction.action_date ? moment(leadAction.action_date).tz(gig.brand_user_activity.time_zone).format('MM/DD/YYYY') : '',
					time: leadAction.action_date ? moment(leadAction.action_date).tz(gig.brand_user_activity.time_zone).format('HH:mm:ss') : '',
					callDuration: leadAction.duration || 0,
					leadScore: (associatedContact && associatedContact.lead_score) || '',
					// hotLead: (associatedContact && associatedContact.top_lead) || '',
					frequencyOfNeed: (associatedContact && associatedContact.frequency_of_need) || '',
					sampling: associatedContact && associatedContact.questions && associatedContact.questions.filter(q => q.question === 'SAMPLING' && q.answer === 'true').length ? 'Yes' : '',
					catering: associatedContact && associatedContact.questions && associatedContact.questions.filter(q => q.question === 'CATERING' && q.answer === 'true').length ? 'Yes' : '',
					notes: leadAction.notes || '',
					ambassadorName: getAmbassador(gig) ? getAmbassador(gig).ambassador_name : '',
					primarySicCode: associatedContact && associatedContact.primary_category_code ? associatedContact.primary_category_code.category_code_id : '',
					primarySicCategory: associatedContact && associatedContact.primary_category_code ? associatedContact.primary_category_code.category_code_description : '',
					sicCodes: associatedContact && associatedContact.category_codes ? associatedContact.category_codes.map(category => category.category_code_id).join(' - ') : '',
					sicCategories: associatedContact && associatedContact.category_codes ? associatedContact.category_codes.map(category => category.category_code_description).join('; ') : '',
					callRecording: leadAction.recording_url || '',
				}
			})
		}).flat()

		console.log('ROW DATA 1: ', rowsData)

		const headerRow = [
			'Brand',
			'Campaign Name',
			'Campaign ID',
			'Gig Title',
			'Gig ID',
			'Store Name',
			'Store ID',
			'Business',
			'Contact Name',
			'Contact Title',
			'Person Reached',
			'Email',
			'Employee Size',
			'Address',
			'City',
			'State',
			'Zip',
			'Phone',
			'Fax',
			'Date',
			'Time',
			'Call Duration',
			'Lead Score',
			// 'Hot Lead',
			'Frequency of Need',
			'Sampling',
			'Catering',
			'Notes',
			'Ambassador Name',
			'Primary SIC Code',
			'Primary SIC Category',
			'SIC Codes',
			'SIC Categories',
			'Call Recording'
		].map(cell => ({ value: cell, type: 'string'}))

		const contentRows = map(rowsData, row => {
			return [
				{
					value: row.brandName,
					type: 'string'
				},
				{
					value: row.campaignName,
					type: 'string'
				},
				{
					value: row.campaignId || '',
					type: 'number'
				},
				{
					value: row.gigTitle,
					type: 'string'
				},
				{
					value: row.gigId,
					type: 'string'
				},
				{
					value: row.storeName,
					type: 'string'
				},
				{
					value: row.storeId || '',
					type: 'number'
				},
				{
					value: row.companyName,
					type: 'string'
				},
				{
					value: row.contactName,
					type: 'string'
				},
				{
					value: row.contactTitle,
					type: 'string'
				},
				{
					value: row.personReached,
					type: 'string'
				},
				{
					value: row.contactEmail,
					type: 'string'
				},
				{
					value: row.employeeSize || '',
					type: 'number'
				},
				{
					value: row.companyAddress,
					type: 'string'
				},
				{
					value: row.city,
					type: 'string'
				},
				{
					value: row.state,
					type: 'string'
				},
				{
					value: row.zip,
					type: 'string'
				},
				{
					value: row.phone,
					type: 'string'
				},
				{
					value: row.fax,
					type: 'string'
				},
				{
					value: row.date,
					type: 'string'
				},
				{
					value: row.time,
					type: 'string'
				},
				{
					value: row.callDuration,
					type: 'number'
				},
				{
					value: row.leadScore || '',
					type: 'number'
				},
				// {
				// 	value: row.hotLead ? 'YES' : '',
				// 	type: 'string'
				// },
				{
					value: row.frequencyOfNeed,
					type: 'string'
				},
				{
					value: row.sampling,
					type: 'string'
				},
				{
					value: row.catering,
					type: 'string'
				},
				{
					value: row.notes,
					type: 'string'
				},
				{
					value: row.ambassadorName,
					type: 'string'
				},
				{
					value: row.primarySicCode,
					type: 'string'
				},
				{
					value: row.primarySicCategory,
					type: 'string'
				},
				{
					value: row.sicCodes,
					type: 'string'
				},
				{
					value: row.sicCategories,
					type: 'string'
				},
				{
					value: row.callRecording,
					type: 'string'
				},
			]
		})

		contentRows.unshift(headerRow)

		console.log('PHONE leads contentRows: ', contentRows)
		const config = {
		  filename: `${props.selectedGigStatus} - Phone Leads (${moment(props.startDate).format('MM_DD_YYYY')} - ${moment(props.endDate).format('MM_DD_YYYY')})`,
		  sheet: {
		    data: contentRows
		  }
		}

		excelExporter(config)
		setExportingLeadsExcel(false)
	}

	const exportStreetLeads = (data) => {
		console.log('streetGigs: ', data)

		const rowsData = data.map(gig => {
			return gig.contacts.map(contact => {
				const result = {
					brandName: gig.brand_user_activity.brand_name,
					campaignName: gig.brand_user_activity.campaign ? gig.brand_user_activity.campaign.campaign_name : '',
					campaignId: gig.brand_user_activity.campaign ? gig.brand_user_activity.campaign.campaign_id : '',
					gigTitle: gig.brand_user_activity.title || '',
					gigId: gig.brand_user_activity.id || '',
					storeName: gig.brand_user_activity.stores ? gig.brand_user_activity.stores[0].store_name : '',
					storeId: gig.brand_user_activity.stores ? gig.brand_user_activity.stores[0].store_id : '',
					companyName: contact.company_name || '',
					contactName: contact.contact_name || '',
					contactTitle: contact.contact_title || '',
					contactEmail: contact.contact_email || '',
					website: contact.company_website || '',
					employeeSize: contact && contact.employee_size >= 0 ? Number(contact.employee_size) : '',
					companyAddress: contact.company_address || '',
					city: contact.city || '',
					state: contact.state || '',
					zip: contact.postal_code || '',
					phone1: contact.phone_1 || '',
					phone2: contact.phone_2 || '',
					phone3: contact.phone_3 || '',
					date: contact.created_date ? moment(contact.created_date).tz(gig.brand_user_activity.time_zone).format('MM/DD/YYYY') : '',
					time: contact.created_date ? moment(contact.created_date).tz(gig.brand_user_activity.time_zone).format('HH:mm:ss') : '',
					hotLead: contact.top_lead ? 'YES' : '',
					ambassadorName: getAmbassador(gig) ? getAmbassador(gig).ambassador_name : '',
					notes: contact.notes || '',
					questions: contact.questions
					// primarySicCode: contact && contact.primary_category_code ? contact.primary_category_code.category_code_id : '',
					// primarySicCategory: contact && contact.primary_category_code ? contact.primary_category_code.category_code_description : '',
					// sicCodes: contact && contact.category_codes ? contact.category_codes.map(category => category.category_code_id).join(' - ') : '',
					// sicCategories: contact && contact.category_codes ? contact.category_codes.map(category => category.category_code_description).join('; ') : '',
				}

				return result
			})
		}).flat()

		console.log('ROW DATA 1: ', rowsData)

		const headerRow = [
			'Brand',
			'Campaign Name',
			'Campaign ID',
			'Gig Title',
			'Gig ID',
			'Store Name',
			'Store ID',
			'Business',
			'Contact Name',
			'Contact Title',
			'Email',
			'Website',
			'Employee Size',
			'Address',
			'City',
			'State',
			'Zip',
			'Phone 1',
			'Phone 2',
			'Phone 3',
			'Date',
			'Time',
			'Hot Lead',
			'Ambassador Name',
			'Notes',
			'Questions',
			// 'Primary SIC Code',
			// 'Primary SIC Category',
			// 'SIC Codes',
			// 'SIC Categories',
		].map(cell => ({ value: cell, type: 'string'}))

		const contentRows = map(rowsData, row => {
			const results = [
				{
					value: row.brandName,
					type: 'string'
				},
				{
					value: row.campaignName,
					type: 'string'
				},
				{
					value: row.campaignId || '',
					type: 'number'
				},
				{
					value: row.gigTitle,
					type: 'string'
				},
				{
					value: row.gigId,
					type: 'string'
				},
				{
					value: row.storeName,
					type: 'string'
				},
				{
					value: row.storeId || '',
					type: 'number'
				},
				{
					value: row.companyName,
					type: 'string'
				},
				{
					value: row.contactName,
					type: 'string'
				},
				{
					value: row.contactTitle,
					type: 'string'
				},
				{
					value: row.contactEmail,
					type: 'string'
				},
				{
					value: row.website,
					type: 'string'
				},
				{
					value: row.employeeSize || '',
					type: 'number'
				},
				{
					value: row.companyAddress,
					type: 'string'
				},
				{
					value: row.city,
					type: 'string'
				},
				{
					value: row.state,
					type: 'string'
				},
				{
					value: row.zip,
					type: 'string'
				},
				{
					value: row.phone1,
					type: 'string'
				},
				{
					value: row.phone2,
					type: 'string'
				},
				{
					value: row.phone3,
					type: 'string'
				},
				{
					value: row.date,
					type: 'string'
				},
				{
					value: row.time,
					type: 'string'
				},
				{
					value: row.hotLead,
					type: 'number'
				},
				{
					value: row.ambassadorName,
					type: 'string'
				},
				{
					value: row.notes,
					type: 'string'
				},
				// {
				// 	value: row.primarySicCode,
				// 	type: 'string'
				// },
				// {
				// 	value: row.primarySicCategory,
				// 	type: 'string'
				// },
				// {
				// 	value: row.sicCodes,
				// 	type: 'string'
				// },
				// {
				// 	value: row.sicCategories,
				// 	type: 'string'
				// },
			]

			const questions = row.questions
				? row.questions.map(e => ({
					value: `${e.question} - ${e.answer}`,
					type: 'string'
				}))
				: []

			return results.concat(questions)
		})

		contentRows.unshift(headerRow)

		console.log('STREET leads contentRows: ', contentRows)
		const config = {
		  filename: `${props.selectedGigStatus} - Street Leads (${moment(props.startDate).format('MM_DD_YYYY')} - ${moment(props.endDate).format('MM_DD_YYYY')})`,
		  sheet: {
		    data: contentRows
		  }
		}

		excelExporter(config)
		setExportingLeadsExcel(false)
	}

	const getAmbassador = (gig) => {
		const result = find(gig.applied_ambassadors, amb => amb.id === gig.brand_user_activity.field_day_management.field_day_workflow.accepted_ambassador_id)

		return result ? result : ''
	}

	if(phoneGigs && contacts && leadActions) {
		exportAsExcel(phoneGigs)

		setPhoneGigs(null)
		setContacts(null)
		setLeadActions(null)

	}

	if(streetGigs && contacts) {
		exportStreetLeads(streetGigs)

		setStreetGigs(null)
		setContacts(null)
	}

	return (
		<Fragment>
			<Button variant='info' onClick={() => getGigs(false)}>Export Phone Leads</Button>&nbsp;&nbsp;
			<Button variant='info' onClick={() => getGigs(true)}>Export Street Leads</Button>&nbsp;&nbsp;
			{exportingLeadsExcel && <Spinner animation='border' variant='info' size='sm' />}
		</Fragment>
	)
}
