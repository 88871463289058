import axios from 'axios'

// TODO; could possibly use the same endpoint for both
export async function getAmbassadorAssetsUrl(key) {
	if(!key) return

	try {
		const response = process.env.REACT_APP_STAGE === 'dev'
			? await axios.get(`https://dev.fieldday.app:3065/fieldDay/ambassadors/presigned?key=${key}&emailReport=true&token=d2FKN5IgrwZBipgvkOUlJSNubFyhVdDu`)
			: await axios.get(`https://admin.fieldday.app/fieldDay/ambassadors/presigned?key=${key}&emailReport=true&token=d2FKN5IgrwZBipgvkOUlJSNubFyhVdDu`)

		return response.data
	} catch(e) {
		console.error(e)
	}
}

// TODO; could possibly use the same endpoint for both ^
export async function getGigAssetsUrl(key) {
	if(!key) return

	try {
		const response = process.env.REACT_APP_STAGE === 'dev'
			? await axios.get(`https://dev.fieldday.app:3065/fieldDay/gigs/presigned?key=${key}&emailReport=false&token=d2FKN5IgrwZBipgvkOUlJSNubFyhVdDu`)
			: await axios.get(`https://admin.fieldday.app/fieldDay/gigs/presigned?key=${key}&emailReport=false&token=d2FKN5IgrwZBipgvkOUlJSNubFyhVdDu`)

		return response.data
	} catch(e) {
		console.error(e)
	}
}
