import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default function LeadModal({ lead, selectedTranscription, onClose }) {
	const [transcription, setTranscription] = useState(null)

	useEffect(() => {
		getTranscription()
	}, [])

	const getTranscription = async () => {console.log(lead)
		// const appID = 
		// const appSecret = 

		const token = await axios.post('https://api.symbl.ai/oauth2/token:generate', {
      type: 'application',
      appId: '6f3162696a6c75675273685035574a4d61723734313332556b49635743766c4e',
      appSecret: '6953553663735a494777475872336c71385a7033326c596a745142436d554d596a6f4f316f744252333437666b4251516b753874794e6b34384b2d346a72374b',
    })

		const result = await axios({
      method: 'get',
      url: `https://api.symbl.ai/v1/conversations/${selectedTranscription}/messages?sentiment=true`,
      headers: {
        Authorization: `Bearer ${token.data.accessToken}`,
        'Content-type': 'application/json'
      },
    })

    setTranscription(result.data)
	}

	console.log(transcription)

	return (
		<Modal
		  centered
		  size='lg'
		  show={true}
		  onHide={onClose}
		>
		  <Modal.Header closeButton>
		    <Modal.Title></Modal.Title>
		  </Modal.Header>
		  <Modal.Body style={{ height: '500px', overflow: 'auto' }}>
			  {transcription && transcription.messages
			  	? transcription.messages.map(msg => {
			  		if(msg.from && msg.from.name === 'Call Recipient') {
			  			return (
			  				<Row className='border-bottom'>
			  					<Col md={3} className='text-danger font-weight-bold'>{msg.from.name}:</Col>
			  					<Col>{msg.text}</Col>
			  				</Row>
			  			)
			  		}

			  		if(msg.from && msg.from.name === 'Brand Ambassador') {
			  			return (
			  				<Row className='border-bottom'>
			  					<Col md={3} className='text-primary font-weight-bold'>{msg.from.name}:</Col>
			  					<Col>{msg.text}</Col>
			  				</Row>
			  			)
			  		}
			  	})
			  	: <em>transcribing..</em>
			  }
		  </Modal.Body>
		</Modal>
	)
}