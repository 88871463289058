import axios from 'axios'
// import { API } from 'aws-amplify'
import _ from 'underscore'
import moment from 'moment'

import config from '../config'

export const SHOW_LOADING_GIGS = 'SHOW_LOADING_GIGS'
export const HIDE_LOADING_GIGS = 'HIDE_LOADING_GIGS'
export const RECEIVE_GIGS = 'RECEIVE_GIGS'
export const CHANGE_GIGS_START_DATE = 'CHANGE_GIGS_START_DATE'
export const CHANGE_GIGS_END_DATE = 'CHANGE_GIGS_END_DATE'
export const CHANGE_GIGS_PAGEFROM = 'CHANGE_GIGS_PAGEFROM'
export const CHANGE_GIGS_PAGESIZE = 'CHANGE_GIGS_PAGESIZE'
export const CHANGE_GIGS_TABLE_FILTER = 'CHANGE_GIGS_TABLE_FILTER'
export const CHANGE_GIGS_TABLE_SORT = 'CHANGE_GIGS_TABLE_SORT'
export const SET_GIGS_SELECTED_STATUS = 'SET_GIGS_SELECTED_STATUS'
export const SET_GIGS_AMBASSADOR_FILTER = 'SET_GIGS_AMBASSADOR_FILTER'

export function showLoading() {
	return {
		type: SHOW_LOADING_GIGS,
		loading: true
	}
}

export function hideLoading(action) {
	return {
		type: HIDE_LOADING_GIGS,
		loading: false
	}
}

function handleErrors(error) {
	return {
		// type: HANDLE_ERRORS,
		// error
	}
}

function getParams(state, unfulfilledGigs) {
	const {
		startDate,
		endDate,
		pageFrom,
		pageSize,
		brandNameSearchString,
		titleSearchString,
		addressSearchString,
		sortFieldName,
		sortDirection,
		selectedBrand,
		ambassadorFilter
	} = state

	const filters = {
		dueDateFromMillis: moment(startDate).valueOf(),
		dueDateToMillis: unfulfilledGigs ? Date.now() : moment(endDate).valueOf(),
		pageFrom: pageFrom * pageSize,
		pageSize,
		noMasters: true
	}

	if(brandNameSearchString) filters.brandNameSearchString = brandNameSearchString
	if(titleSearchString) filters.titleSearchString = titleSearchString
	if(addressSearchString) filters.addressSearchString = addressSearchString
	if(sortFieldName) filters.sortFieldName = sortFieldName
	if(sortDirection) filters.sortDirection = sortDirection
	if(selectedBrand) filters.brandNameSearchString = selectedBrand
	if(ambassadorFilter) filters.ambassadorId = ambassadorFilter

	return _.map(filters, (val, key) => `${key}=${val}`).join('&')
}

export function getUnpaidGigs() {
	return (dispatch, getState) => {
		const state = getState().gigs
		const params = getParams(state)

		dispatch(showLoading())

		return axios.get(`${config.coreAPI}/fieldDayAdmin/activities?finished=true&paid=false&${params}`)
			.then(res => dispatch(receiveGigs(res.data.responses[0], 'unpaidGigs')))
			.catch(e => dispatch(handleErrors(e)))
			.then(() =>  dispatch(hideLoading()))
	}
}

export function getInProgressGigs() {
	return (dispatch, getState) => {
		const state = getState().gigs
		const params = getParams(state)

		dispatch(showLoading())

		return axios.get(`${config.coreAPI}/fieldDayAdmin/activities?accepted=true&started=true&finished=false&${params}`)
			.then(res => dispatch(receiveGigs(res.data.responses[0], 'inProgressGigs')))
			.catch(e => dispatch(handleErrors(e)))
			.then(() =>  dispatch(hideLoading()))
	}
}

export function getInReviewGigs() {
	return (dispatch, getState) => {
		const state = getState().gigs
		const params = getParams(state)

		dispatch(showLoading())

		return axios.get(`${config.coreAPI}/fieldDayAdmin/activities?hasReview=true&paid=false&completed=false&${params}`)
			.then(res => dispatch(receiveGigs(res.data.responses[0], 'inReviewGigs')))
			.catch(e => dispatch(handleErrors(e)))
			.then(() =>  dispatch(hideLoading()))
	}
}

export function getCompletedGigs() {
	return (dispatch, getState) => {
		const state = getState().gigs
		const params = getParams(state)

		dispatch(showLoading())

		return axios.get(`${config.coreAPI}/fieldDayAdmin/activities?started=true&finished=true&completed=true&paid=true&${params}`)
			.then(res => dispatch(receiveGigs(res.data.responses[0], 'completedGigs')))
			.catch(e => dispatch(handleErrors(e)))
			.then(() =>  dispatch(hideLoading()))
	}
}

export function getCompletionApprovedGigs() {
	return (dispatch, getState) => {
		const state = getState().gigs
		const params = getParams(state)

		dispatch(showLoading())

		return axios.get(`${config.coreAPI}/fieldDayAdmin/activities?started=true&finished=true&completed=true&paid=false&${params}`)
			.then(res => dispatch(receiveGigs(res.data.responses[0], 'completionApprovedGigs')))
			.catch(e => dispatch(handleErrors(e)))
			.then(() =>  dispatch(hideLoading()))
	}
}

function receiveGigs(gigs, status) {
	return {
		type: RECEIVE_GIGS,
		gigs,
		status
	}
}

export function handleStartDateChange(date) {
	return {
		type: CHANGE_GIGS_START_DATE,
		date
	}
}

export function handleEndDateChange(date) {
	return {
		type: CHANGE_GIGS_END_DATE,
		date
	}
}

export function handleGigStatusClick(status) {
	return {
		type: SET_GIGS_SELECTED_STATUS,
		status
	}
}

export function handlePageChange(pageFrom) {
	return {
		type: CHANGE_GIGS_PAGEFROM,
		pageFrom
	}
}

export function handlePageSizeChange(pageFrom, pageSize, total) {
	const numOfPages = total <= pageSize ? 1 : Math.ceil(total / pageSize)
	const newPageIndex = numOfPages <= pageFrom ? numOfPages - 1 : pageFrom

	return {
		type: CHANGE_GIGS_PAGESIZE,
		pageFrom: newPageIndex,
		pageSize
	}
}

export function handleTableFilterChange(name, value) {
	return {
		type: CHANGE_GIGS_TABLE_FILTER,
		name,
		value
	}
}

export function handleTableSortChange(sortFieldName, descSort) {
	return {
		type: CHANGE_GIGS_TABLE_SORT,
		sortFieldName,
		descSort
	}
}

export function handleAmbassadorFilter(ambassadorId) {
	return {
		type: SET_GIGS_AMBASSADOR_FILTER,
		ambassadorFilter: ambassadorId
	}
}
