import axios from 'axios'
import { API } from 'aws-amplify'
import _ from 'underscore'

import config from '../config'

export const HANDLE_ERRORS = 'HANDLE_ERRORS'
export const RESET_STATE = 'RESET_STATE'
export const SET_BRANDS_VIEW = 'SET_BRANDS_VIEW'
export const GET_BRANDS = 'GET_BRANDS'
export const RECEIVE_BRANDS = 'RECEIVE_BRANDS'
export const RECEIVE_BRANDS_EXPENSES = 'RECEIVE_BRANDS_EXPENSES'
export const RECEIVE_BRANDS_ACTIVITY_STATS = 'RECEIVE_BRANDS_ACTIVITY_STATS'
export const RECEIVE_SELECTED_BRAND = 'RECEIVE_SELECTED_BRAND'
export const RECEIVE_BRAND_LOGO = 'RECEIVE_BRAND_LOGO'
export const RECEIVE_BRAND_STORES = 'RECEIVE_BRAND_STORES'
export const RECEIVE_BRAND_STORE_GIGS = 'RECEIVE_BRAND_STORE_GIGS'
export const RECEIVE_BRAND_STORE_GIG_STATS = 'RECEIVE_BRAND_STORE_GIG_STATS'
export const RECEIVE_BRAND_USERS = 'RECEIVE_BRAND_USERS'
export const RECEIVE_EMAIL_LISTS = 'RECEIVE_EMAIL_LISTS'
// export const RECEIVE_BRAND_TOTAL_SPEND = 'RECEIVE_BRAND_TOTAL_SPEND'

export function handleErrors(error) {
	return {
		type: HANDLE_ERRORS,
		error
	}
}

async function getAWSUrl(key) {
	if(!key) return

	try {
		const result = await API.post('getSignedUrl', '/getSignedUrl', {
			body: { key }
		})

		return result
	}
	catch(e) {
		console.error(e)
		// dispatch(handleErrors(e))
	}
}

export function resetState() {
	return {
		type: RESET_STATE
	}
}

export function setView(view) {
	return {
		type: SET_BRANDS_VIEW,
		view
	}
}

export function getBrands() {
	return (dispatch, getState) => {
		return axios.get(`${config.coreAPI}/brand/all/names?pageFrom=0&pageSize=2000`)
			.then(res => 
				// dispatch(receiveBrands(res.data.responses[0].results.sort((a, b) => (a.brand_name > b.brand_name) ? 1 : -1)))
				dispatch(receiveBrands(res.data.responses[0]))
			)
			.catch(e => dispatch(handleErrors(e)))
	}
}

function receiveBrands(brands) {
	return {
		type: RECEIVE_BRANDS,
		brands
	}
}

export function getBrandsExpenses(startDate, endDate) {
	const startParam = startDate ? `createdFromMillis=${startDate.valueOf()}` : ''
	const endParam = endDate ? `createdToMillis=${endDate.valueOf()}` : ''

	return (dispatch, getState) => {
		return axios.get(`${config.coreAPI}/fieldDayAdmin/payments/totals?${startParam}${startDate && endDate ? '&' : ''}${endParam}`)
			.then(res => dispatch(receiveBrandsExepenses(res.data)))
			.catch(e => dispatch(handleErrors(e)))
	}
}

function receiveBrandsExepenses(brandsExpenses) {
	return {
		type: RECEIVE_BRANDS_EXPENSES,
		brandsExpenses
	}
}

export function getBrandsActivityStats(startDate, endDate) {
	const startParam = startDate ? `dueDateFromMillis=${startDate.valueOf()}` : ''
	const endParam = endDate ? `dueDateToMillis=${endDate.valueOf()}` : ''

	return (dispatch, getState) => {
		return axios.get(`${config.coreAPI}/activities/statsByBrand?${startParam}${startDate && endDate ? '&' : ''}${endParam}`)
			.then(res => dispatch(receiveBrandsActivityStats(res.data)))
			.catch(e => dispatch(handleErrors(e)))
	}
}

function receiveBrandsActivityStats(brandsActivityStats) {
	return {
		type: RECEIVE_BRANDS_ACTIVITY_STATS,
		brandsActivityStats
	}
}

export function handleBrandSelect(selectedBrand) {
	return (dispatch, getState) => {
		// TODO; switch to a lighter call if not everything on the response is needed
		return axios.get(`${config.coreAPI}/brand/${selectedBrand.brand_id}`)
			.then(res => {
				dispatch(receiveSelectedBrand(res.data.responses[0].results[0]))
				dispatch(getBrandLogo(res.data.responses[0].results[0].brand_logo_url))
				dispatch(getBrandStores(res.data.responses[0].results[0].brand_id))
				dispatch(getBrandUsers(res.data.responses[0].results[0].brand_id))
				dispatch(getEmailLists(res.data.responses[0].results[0].brand_id))
				// dispatch(getTotalSpend(res.data.responses[0].results[0].brand_id))
			})
			.catch(e => dispatch(handleErrors(e)))
	}
}

function receiveSelectedBrand(brand) {
	return {
		type: RECEIVE_SELECTED_BRAND,
		brand
	}
}

function getBrandLogo(path) {
	return (dispatch, getState) => {
		return getAWSUrl(path)
			.then(url => dispatch(receiveBrandLogo(url)))
			.catch(e => {
				console.error(e)
				dispatch(handleErrors(e))
			})
	}
}

function receiveBrandLogo(url) {
	return {
		type: RECEIVE_BRAND_LOGO,
		url
	}
}

export function getBrandStores(id) {
	return (dispatch, getState) => {
		return axios.get(`${config.coreAPI}/stores?brandId=${id}&pageFrom=0&pageSize=10000`)
			.then(res => dispatch(receiveBrandStores(res.data)))
			.catch(e => {
				console.error(e)
				dispatch(handleErrors(e))
			})
	}
}

function receiveBrandStores(stores) {
	return {
		type: RECEIVE_BRAND_STORES,
		stores
	}
}

export function getBrandUsers(id) {
	return (dispatch, getState) => {
		return axios.get(`${config.coreAPI}/users?brandId=${id}&pageFrom=0&pageSize=10000`)
			.then(res => dispatch(receiveBrandUsers(res.data)))
			.catch(e => {
				console.error(e)
				dispatch(handleErrors(e))
			})
	}
}

function receiveBrandUsers(users) {
	return {
		type: RECEIVE_BRAND_USERS,
		users
	}
}

export function getStoreGigs(brandId, storeId, pageFrom, pageSize) {
	// const ids = storeIds.join('|')\
	const pageParams = `pageFrom=${pageFrom >= 0 ? pageFrom : 0}&pageSize=${pageSize >= 0 ? pageSize : 0}`

	return (dispatch,getState) => {
		return axios.get(`${config.coreAPI}/fieldDayAdmin/activities?brandId=${brandId}&storeIds=${storeId}&noMasters=false&noChildren=true&${pageParams}`)
			.then(res => dispatch(receiveStoreGigs(storeId, res.data.responses[0])))
			.catch(e => {
				console.error(e)
				dispatch(handleErrors(e))
			})
	}
}

function receiveStoreGigs(storeId, gigs) {
	return {
		type: RECEIVE_BRAND_STORE_GIGS,
		storeId,
		gigs
	}
}

export function getGigStats(brandId, storeId, pageFrom, pageSize) {
	const pageParams = `pageFrom=${pageFrom >= 0 ? pageFrom : 0}&pageSize=${pageSize >= 0 ? pageSize : 0}`

	return (dispatch, getState) => {
		return axios.get(`${config.coreAPI}/fieldDayAdmin/activities?brandId=${brandId}&storeIds=${storeId}&noMasters=false&noChildren=true&${pageParams}`)
			.then(async (res) => {
				const statsResults = []
				const statsPromises = _.map(res.data.responses[0].results, gig => axios.get(`${config.coreAPI}/activities/statsByMaster?masterId=${gig.brand_user_activity.id}`))

				for(let i = 0; i < statsPromises.length; i++) {
					const result = await statsPromises[i]
					statsResults.push({
						gigId: res.data.responses[0].results[i].brand_user_activity.id,
						gigTitle: res.data.responses[0].results[i].brand_user_activity.title,
						dueDate: res.data.responses[0].results[i].brand_user_activity.due_date_millis,
						...result.data
					})
				}

				dispatch(receiveGigStats(statsResults, storeId))
			})
			.catch(e => {
				console.error(e)
				dispatch(handleErrors(e))
			})
	}
}

function receiveGigStats(stats, storeId) {
	return {
		type: RECEIVE_BRAND_STORE_GIG_STATS,
		storeId,
		stats
	}
}

export function getEmailLists(brandId) {
	return (dispatch, getState) => {
		return axios.get(`${config.coreAPI}/lists/emailLists?brandId=${brandId}&pageFrom=0&pageSize=10000`)
			.then(res => dispatch(receiveEmailLists(res.data)))
			.catch(e => {
				console.error(e)
				dispatch(handleErrors(e))
			})
	}
}

function receiveEmailLists(lists) {
	return {
		type: RECEIVE_EMAIL_LISTS,
		lists
	}
}
