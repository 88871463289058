import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'
import ListGroup from 'react-bootstrap/ListGroup'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
// import Alert from 'react-bootstrap/Alert'
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Modal from 'react-bootstrap/Modal'

import config from '../config'
import SideBar from '../components/SideBar'
import TrainingForm from '../components/TrainingForm'

class Training extends Component {
	state = {
		errors: [],
		brands: [],
		trainingPlans: [],
		trainingResults: [],
		showModal: false,
		modalName: null
	}

	componentDidMount() {
		this.getBrands()
		this.getTrainingPlans()
	}

	render() {
		const { brands, selectedBrand, trainingPlans, selectedTraining, trainingResults, showModal, modalName } = this.state

		return (
			<Row>
				<SideBar/>
				<Col>
					<Card>
						{showModal &&
							<Modal
								show={showModal}
								onHide={this.handleCloseModal}
								size={'lg'}
								// dialogClassName={'modal-60w'}
								// dialogClassName={modalName === 'gigActions' ? 'modal-60w' : ''}
								// aria-labelledby="contained-modal-title-vcenter"
								centered
								scrollable
							>
								{modalName === 'trainingForm' &&
									<Fragment>
										<Modal.Header closeButton>
											<Modal.Title>
												Training Form
											</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											<TrainingForm selectedTraining={selectedTraining} onCloseModal={this.handleCloseModal} />
										</Modal.Body>
									</Fragment>
								}
							</Modal>
						}
						<Card.Body>
							<div className='d-flex align-items-center justify-content-between'>
								<Card.Title>Training Plans</Card.Title>
								<Button variant='info' title='Create New Training' onClick={this.handleOpenModal}>+</Button>
							</div>
							<hr />
							<div className='d-flex align-items-center'>
								<Dropdown>
									<Dropdown.Toggle variant='outline-dark'>{selectedBrand ? selectedBrand.brand_name : 'Select Brand'}</Dropdown.Toggle>
									<Dropdown.Menu style={{maxHeight: '350px', overflow: 'auto'}}>
										<Dropdown.Item key={0} onClick={() => this.setState({ selectedBrand: null }, () => this.getTrainingPlans())}>-- ALL --</Dropdown.Item>
										{brands.map(brand => (
											<Dropdown.Item
												key={brand.brand_id}
												onClick={() => this.setState({ selectedBrand: brand }, () => this.getTrainingPlans())}
											>
												{brand.brand_name}
											</Dropdown.Item>
										))}
									</Dropdown.Menu>
								</Dropdown>
								<span className='ml-3'>Total: {trainingPlans.length}</span>
							</div>
							<Row className='mt-3'>
								<Col lg={4} style={{maxHeight: '500px', overflow: 'auto'}}>
									<TrainingsList
										trainingPlans={trainingPlans}
										selectedTraining={selectedTraining}
										onClick={this.handleTrainingClick}
										onEdit={this.handleEditTraining}
										onDelete={this.handleDeleteTraining}
									/>
								</Col>
								<Col lg={8} style={{maxHeight: '500px', overflow: 'auto'}}>
									{!trainingResults.length
										? <em>No Data</em>
										: <TrainingTable data={trainingResults} minScore={selectedTraining && selectedTraining.quiz.min_score} />
									}
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		)
	}

	handleErrors = (e) => {
		this.setState(({ errors }) => ({
			errors: errors.includes(e.response.data.message) ? [...errors] : errors.concat([e.response.data.message])
		}))
	}

	getBrands = async () => {
		try {
			const brands = await axios.get(`${config.coreAPI}/brand/withTraining`)

			this.setState({
				brands: brands.data.responses[0].results.sort((a, b) => (a.brand_name > b.brand_name) ? 1 : -1)[0]
			})
		} catch(e) {
			console.error(e)
		}
	}

	getTrainingPlans = async () => {
		const { selectedBrand } = this.state
		const brandId = selectedBrand && selectedBrand.brand_id ? `&brandId=${selectedBrand.brand_id}` : ''

		try {
			const trainingPlans = await axios.get(`${config.coreAPI}/fieldDayAdmin/training/plans?pageFrom=0&pageSize=1000${brandId}`)

			this.setState({
				trainingPlans: trainingPlans.data
			})
		} catch(e) {
			this.handleErrors(e)
		}
	}

	handleTrainingClick = async (training) => {
		this.setState({ selectedTraining: training })

		try {
			const trainingResults = await axios.get(`${config.coreAPI}/training/results?trainingPlanId=${training.id}&pageFrom=0&pageSize=10000`)

			this.setState({
				trainingResults: trainingResults.data
			})

		} catch(e) {
			this.handleErrors(e)
		}
	}

	handleOpenModal = () => {
		this.setState({
			showModal: true,
			modalName: 'trainingForm',
			selectedTraining: null
		})
	}

	handleCloseModal = () => {
		this.setState({
			showModal: false,
			modalName: null,
			selectedTraining: null
		}, () => {
			setTimeout(() => this.getTrainingPlans(), 1000)
		})
	}

	handleEditTraining = (training) => {
		this.setState({
			showModal: true,
			modalName: 'trainingForm',
			selectedTraining: training
		})
	}

	handleDeleteTraining = async (training) => {
		const yes = window.confirm('Are you sure you want to delete this training plan?')

		if(yes) {
			try {
				await axios.delete(`${config.coreAPI}/fieldDayAdmin/training/plan?trainingPlanId=${training.id}`)

				setTimeout(() => this.getTrainingPlans(), 1000)

			} catch(e) {
				this.handleErrors(e)
			}
		}
	}
}

function TrainingsList({ trainingPlans, selectedTraining, onClick, onEdit, onDelete }) {
	return (
		<ListGroup>
			{trainingPlans.map(training => (
				<ListGroup.Item className='d-flex justify-content-between' key={training.id}>
					<div>
						<span className='pointer mr-1' onClick={() => onClick(training)}>{training.name}</span>
						<i className='fas fa-edit pointer text-primary fa-sm' title='Edit' onClick={() => onEdit(training)}></i>&nbsp;&nbsp;
						<i className='far fa-trash-alt pointer text-danger fa-sm' title='Delete' onClick={() => onDelete(training)}></i>
					</div>
					<span>{selectedTraining && training.id === selectedTraining.id && '>'}</span>
				</ListGroup.Item>
			))}
		</ListGroup>
	)
}

class TrainingTable extends Component {
	render() {
		const headers = ['Ambassador Name', 'Min Score', 'Attempts', 'Highest Score', 'Pass']
		const { data, minScore } = this.props

		return (
			<Table responsive>
				<thead>
					<tr>
						{headers.map(header => <th key={header} className='border-top-0'>{header}</th>)}
					</tr>
				</thead>
				<tbody>
					{data.map(item => (
						<tr key={item.id}>
							<td><Link to={`/ambassador/${item.ambassador_id}`}>{item.ambassador_name}</Link></td>
							<td>{minScore}</td>
							<td>
								<OverlayTrigger
									placement='left'
									overlay={<Tooltip>{item.quiz_results.map((result, i) => <div key={i}>Attempt {i + 1}: {result.score}</div>)}</Tooltip>}
								>
									<span className='d-inline-block pointer'>
										{item.quiz_results.length}
									</span>
								</OverlayTrigger>
							</td>
							<td>{Math.max(Math.max(...item.quiz_results.map(result => result.score)))}</td>
							<td>{item.training_complete? <i className='fas fa-check-square text-success'></i> : <i className='fas fa-window-close text-danger'></i>}</td>
						</tr>
					))}
				</tbody>
			</Table>
		)
	}
}

export default Training
