import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { map } from 'underscore'

function Calendar({ data }) {
	if(!data) return null
console.log(data)
	return (
		<FullCalendar
		  plugins={[ dayGridPlugin ]}
		  initialView="dayGridMonth"
		  events={map(data, gig => ({
		  	title: gig.brand_user_activity.title,
		  	date: gig.brand_user_activity.due_date_millis
		  }))}
		/>
	)
}

export default Calendar
