import React, { useState, useEffect } from 'react'
import { Col, Row, Form } from 'react-bootstrap'

import FilterRelations from './FilterRelations'

function PlaceTypeFilter({ filterName, onSetRelation, onSetAttribute, onSetValue, value }) {
	const relationOptions = ['in']
	const attributes = ['branch', 'headquarters', 'independent', 'individual', 'kiosk']

	useEffect(() => {
		onSetAttribute(filterName)
	}, [])

	const handleAttributeSelect = (e, attribute) => {
		const { checked } = e.target

		if(checked) {
			if(!value) {
				onSetValue([...new Set([].concat([attribute]))])
			} else {
				onSetValue([...new Set(value.concat([attribute]))])
			}
		} else {
			onSetValue(value.filter(val => val !== attribute))
		}
	}

	return (
		<Row>
			<Col>
				<FilterRelations relationOptions={relationOptions} onSelectRelation={onSetRelation} />
			</Col>
			<Col>
				{attributes.map(attribute => (
					<div key={attribute}>
						<Form.Check
							type='checkbox'
							label={attribute}
							// checked={value && value.includes(attribute)}
							onChange={e => handleAttributeSelect(e, attribute)}
						/>
					</div>
				))}
			</Col>
		</Row>
	)
}

export default PlaceTypeFilter
