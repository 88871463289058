import React, { Component } from 'react'
// import { connect } from 'react-redux'
import Col from 'react-bootstrap/Col'

// import BrandsSideBar from './brands/BrandsSideBar'
// import {
// 	getBrands
// } from '../actions/sidebar'

class SideBar extends Component {
	state = {
		toggleOpen: true
	}

	// componentDidMount() {
	// 	this.props.dispatch(getBrands())
	// }

	render() {
		const { toggleOpen } = this.state
		// const view = this.props.location.pathname
		// const sideBarViews = ['/dashboard', '/ambassadors', '/gigs', '/brands', '/training']

		// if(!sideBarViews.includes(view)) return null

		// console.log("SIDEBAR PROPS: ", this.props)
		// console.log("SIDEBAR STATE: ", this.state)

		if(!toggleOpen) {
			return <i
				className='fas fa-chevron-right float-right fa-2x pointer pt-3 position-fixed'
				onClick={this.handleToggle}
				style={{ zIndex: '100' }}
				>
			</i>
		}

		return (
			<Col lg={1} className='sidebar-container pl-0 pr-0'>
				<div className='text-right mb-2 pr-3'><i className='fas fa-chevron-left pointer' onClick={this.handleToggle}></i></div>
				{this.props.children}
			</Col>
		)
	}

	handleToggle = () => {
		this.setState(({ toggleOpen }) => ({ toggleOpen: !toggleOpen }))
	}
}

export function SideBarMenuItem({ icon, title, onClick }) {
	return (
		<div className='mb-3'>
			{icon}&nbsp;&nbsp;
			<span className='font-weight-bold pointer' style={{ fontSize: '15px'}} onClick={onClick}>{title}</span>
		</div>
	)
}

// function mapStateToProps(state) {
// 	// console.log('SIDEBAR mapStateToProps: ', state)
// 	return {
// 		activeTab: state.activeTab
// 	}
// }

export default SideBar
// export default connect(mapStateToProps)(SideBar)
