// src/components/PrivateRoute.js

import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { useAuth0 } from '../react-auth0-wrapper'

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0()

  if(isAuthenticated) {
    console.log('isAuthenticated: ', isAuthenticated)
    getToken().then(token => localStorage.setItem('AUTH_TOKEN', token))
  }

  useEffect(() => {
    if (loading || isAuthenticated) {
      return
    }
    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: path }
      })
    }
    fn()
  }, [loading, isAuthenticated, loginWithRedirect, path])

  const render = props => isAuthenticated === true ? <Component {...props} /> : null

  return <Route path={path} render={render} {...rest} />
}

const getToken = async () => {
  const { getTokenSilently } = useAuth0()

  try {
    const token = await getTokenSilently({
      audience: 'core',
      scope: 'admin'
    })

    // await axios({
    //         method: 'post',
    //         url: `${config.coreAPI}/fieldDayAdmin/training/plan/materials?trainingPlanId=${trainingPlanId}`,
    //         data: formData,
    //         config: { headers: {'Content-Type': 'multipart/form-data' } }
    //       })

    // const response = await axios({
    //   method: 'get',
    //   url: `https://dev.core.api.fieldday.app/coreAPI/fieldDayAdmin/activities`,
    //   // headers: {
    //   //   Authorization: `Bearer ${token}`
    //   // }
    // })

    // console.log('TOKEN: ', token)
    // console.log('res: ', response)
    return token
  } catch(e) {
    console.error(e)
  }
}

export default PrivateRoute
