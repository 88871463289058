import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import _ from 'underscore'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

import config from '../../config'
// import { getTotalSpend } from '../../actions/brands'

class PerformanceOverview extends Component {
	state = {
		filters: {
			createdFromMillis: new Date(moment().startOf('week')),
			createdToMillis: new Date(moment().endOf('week')),
			byBrandId: true,
			byStoreId: false,
			paymentCategories: null,
			brandId: this.props.brandId,
			storeIds: null
		},
		totalSpend: []
	}

	componentDidMount() {
		this.getTotalSpend()
	}

	render() {
		const { createdFromMillis, createdToMillis } = this.state.filters
		console.log('PER state: ', this.state)

		return (
			<div>
				<h2>Performance Overview</h2>
				<hr />
				<Col className='text-right'>
					<DatePicker
						selected={createdFromMillis}
						selectsStart
						startDate={createdFromMillis}
						endDate={createdToMillis}
						onChange={this.handleStartDateChange}
						showMonthDropdown
						showYearDropdown
						dropdownMode='select'
					/>
					&nbsp;TO&nbsp;
					<DatePicker
						selected={createdToMillis}
						selectsEnd
						startDate={createdFromMillis}
						endDate={createdToMillis}
						onChange={this.handleEndDateChange}
						minDate={createdFromMillis}
						showMonthDropdown
						showYearDropdown
						dropdownMode='select'
					/>
				</Col>
				<Col>
					<Card style={{ width: '18rem' }}>
						<Card.Body>
							<Card.Title className='text-center'>
								Total: {this.state.totalSpend.length ? this.state.totalSpend.total_in_cents / 100 : 'n/a'}
							</Card.Title>
							<div className='border-bottom'>
							</div>
						</Card.Body>
					</Card>
				</Col>
			</div>
		)
	}

	getTotalSpend = async () => {
		const params = _.reduce(this.state.filters, (accum, val, key) => {
			if(val) {
				accum.push(`${key}=${key === 'createdFromMillis' || key === 'createdToMillis' ? moment(val).valueOf() : val}`)
			}

			return accum
		}, []).join('&')

		try {
			const totalSpend = await axios.get(`${config.coreAPI}/fieldDayAdmin/payments/totals?${params}`)

			this.setState({
				totalSpend: totalSpend.data
			})
		} catch(e) {
			console.error(e)
		}
	}

	handleStartDateChange = (startDate) => {
		this.setState({ createdFromMillis: startDate })
	}

	handleEndDateChange = (endDate) => {
		// const params = _.map(param => )

		this.setState({ createdToMillis: endDate }, () => {
			this.getTotalSpend()
		})
	}
}

// function mapStateToProps({ brands }) {
// 	return {
// 		brandId: brands.brandId,
// 		totalSpend: brands.totalSpend
// 	}
// }

// export default connect(mapStateToProps)(PerformanceOverview)
export default PerformanceOverview
