import React, { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import { Dropdown, Label, Segment, Button } from 'semantic-ui-react'
import { map, filter } from 'underscore'

export default function Targets({ gigState, onInputChange, gettingTargetCount }) {
	const [addedSicCode, setAddedSicCode] = useState('')
	const [excludedSicCode, setExcludedSicCode] = useState('')

	const totalCount = gigState.selectedStores.reduce((accum, store) => store.targetsCount ? accum + store.targetsCount : 0, 0)

	const targetSicCodes = {
		'All': 'all',
		'Phone – SIC Cluster 1 Hospitality': [7991,'0741','0742','0752',1311,1381,1382,1389,2082,2084,2085,2086,4725,4833,5045,5112,5211,5251,5261,5311,5331,5511,5531,5551,5611,5621,5641,5651,5661,5699,5712,5731,5734,5941,5942,5944,5945,5946,5992,5995,5999,6029,6061,6099,6141,6162,6163,6211,6221,6231,6311,6331,6361,6371,6411,6512,6513,6514,6515,6519,6531,6541,6552,6712,6719,6722,6732,6733,7011,7213,7219,7231,7291,7311,7312,7313,7319,7322,7323,7331,7334,7338,7342,7349,7363,7372,7376,7381,7382,7514,8011,8021,8041,8042,8043,8049,8051,8052,8059,8062,8063,8069,8071,8072,8082,8092,8093,8099,8111,8211,8221,8299,8621,8631,8651,8711,8712,8713,8721,8732,8734,8741,8742,8743],
		'Street – SIC Cluster 1 Hospitality': [7991,'0741','0742','0752',2082,2084,2085,2086,4725,5045,5112,5211,5251,5261,5311,5331,5511,5531,5551,5611,5621,5641,5651,5661,5699,5712,5731,5734,5941,5942,5944,5945,5946,5992,5995,5999,6029,6061,6099,6141,6162,6163,6311,6361,6512,6513,6514,6515,6519,6531,6541,6552,7231,7291,7334,7338,7342,7349,7363,7514,8011,8021,8041,8042,8043,8049,8071,8211,8221,8621,8631,8711,8712,8713,8721],
		'Canvassing (all SIC codes except some)': ['99','96','92','7033','04','08','12','16','43','47','51','58','83','359','79','721','737','554','98','95','91','01','05','09','13','17','44','48','52','28','84','75','559','722','738','573','97','94','90','02','06','10','14','41','45','49','54','2082','8051','76','551103','725','527','5932','93','7021','03','07','11','15','42','46','50','56','275','899','78','556','726','533'].sort((a, b) => a - b),
		'All except 58, 70-81, 83-99': [
			'58',
			'70',
			'71',
			'72',
			'73',
			'74',
			'75',
			'76',
			'77',
			'78',
			'79',
			'80',
			'81',
			'83',
			'84',
			'85',
			'86',
			'87',
			'88',
			'89',
			'90',
			'91',
			'92',
			'93',
			'94',
			'95',
			'96',
			'97',
			'98',
			'99',
		],
	}

	const targetGroupsDropdown = [
		'All',
		'Phone – SIC Cluster 1 Hospitality',
		'Street – SIC Cluster 1 Hospitality',
		'Canvassing (all SIC codes except some)',
		'All except 58, 70-81, 83-99',
	].map(name => ({
		key: name,
		value: name,
		text: name,
	}))

	// const targetsDropdown = map(targetSicGroups, (sicCodes, groupName) => ({
	// 	key: groupName,
	// 	value: sicCodes,
	// 	text: groupName,
	// }))

	return (
		<Segment raised style={{ height: '260px', overflowX: 'hidden' }}>
			<Label size='large' color='orange' ribbon>
				Targets
			</Label>
			<Row className='mt-1'>
				<Col>
					<div className='input-box'>
						<span className='pl-2'>Min Employee</span>
						<Form.Control
							type='number'
							name={'targetsEmployeeSize'}
							value={gigState.targetsEmployeeSize || ''}
							onChange={e => onInputChange('targetsEmployeeSize', e.target.value)}
						/>
					</div>
				</Col>
				<Col>
					<div className='input-box'>
						<span className='pl-2'>Radius</span>
						<Form.Control
							type='number'
							name={'targetsRadius'}
							value={gigState.targetsRadius || ''}
							onChange={e => onInputChange('targetsRadius', e.target.value)}
						/>
					</div>
				</Col>
				<Col>
					<div className='input-box'>
						<span className='pl-2'>Max Targets Per Location</span>
						<Form.Control
							type='number'
							name={'maxTargets'}
							value={gigState.maxTargets || ''}
							onChange={e => onInputChange('maxTargets', e.target.value)}
						/>
					</div>
				</Col>
				<Col>
					<Form.Check
						type='checkbox'
						label='With Phone'
						checked={gigState.withPhone ? true : false}
						onChange={e => onInputChange('withPhone', e.target.checked)}
					/>
				</Col>
			</Row>
			<Row className='mt-1'>
				<Col>
					<div className='input-box'>
						<div className='pl-2 pr-2 pt-1 d-flex justify-content-between'>
							<span>Add SIC Code</span>
							<i
								className='fas fa-plus pointer'
								onClick={() => {
									if(!addedSicCode) return

									onInputChange('addedSicCodes', gigState.addedSicCodes.concat(addedSicCode.split(',')))
									setAddedSicCode('')
								}}
							>
							</i>
							<i
								className='fas fa-minus pointer'
								title='Remove all'
								onClick={() => onInputChange('addedSicCodes', [])}
							>
							</i>
						</div>
						<Form.Control
							value={addedSicCode}
							onChange={e => setAddedSicCode(e.target.value)}
						/>
					</div>
					<div style={{ maxHeight: '120px', overflow: 'auto' }}>
						{gigState.addedSicCodes.map(code =>
							<div key={code + Math.random()} className='d-flex justify-content-between'>
								<span>{code}</span>
								<i
									className='fas fa-minus pointer text-danger'
									onClick={() => onInputChange('addedSicCodes', gigState.addedSicCodes.filter(c => c !== code))}
								>
								</i>
							</div>
						)}
					</div>
				</Col>
				<Col>
					<div className='input-box'>
						<div className='pl-2 pr-2 pt-1 d-flex justify-content-between'>
							<span>Exclude SIC Code</span>
							<i
								className='fas fa-plus pointer'
								onClick={() => {
									if(!excludedSicCode) return

									onInputChange('excludedSicCodes', gigState.excludedSicCodes.concat(excludedSicCode.split(',')))
									setExcludedSicCode('')
								}}
							>
							</i>
							<i
								className='fas fa-minus pointer'
								title='Remove all'
								onClick={() => onInputChange('excludedSicCodes', [])}
							>
							</i>
						</div>
						<Form.Control
							value={excludedSicCode}
							onChange={e => setExcludedSicCode(e.target.value)}
						/>
					</div>
					<div style={{ maxHeight: '120px', overflow: 'auto' }}>
						{gigState.excludedSicCodes.map(code =>
							<div key={code + Math.random()} className='d-flex justify-content-between'>
								<span>{code}</span>
								<i
									className='fas fa-minus pointer text-danger'
									onClick={() => onInputChange('excludedSicCodes', gigState.excludedSicCodes.filter(c => c !== code))}
								>
								</i>
							</div>
						)}
					</div>
				</Col>
			</Row>
			<hr />
			<Row>
				<Col>
					<div className='mb-1 mt-1'>
						<Dropdown
							clearable
							search
							selection
							placeholder='Select Target Group'
							className='w-100'
							style={{ backgroundColor: '#0797a6' , fontWeight: '700', color: 'white' }}
							// name='targetSicCategories'
							// name='selectedTargetGroup'
							options={targetGroupsDropdown}
							// value={gigState.targetSicCategories || ''}
							// value={gigState.selectedTargetGroup || ''}
							// value={filter(targetSicCodes, (name, val) => val === )}
							onChange={(e, { name, value }) => {
								if(!value) {
									onInputChange({
										sicGroup: [],
										excludedSicCodes: []
									})
								} else if(value.includes('except')) {
									onInputChange({
										excludedSicCodes: targetSicCodes[value],
										sicGroup: []
									})
								} else {
									onInputChange({
										sicGroup: targetSicCodes[value],
										excludedSicCodes: []
									})
								}
							}}
						/>
					</div>
				</Col>
			</Row>
			<Row>
				<Col>
					<Button
						inverted
						className='w-100'
						size='mini'
						color='orange'
						loading={gettingTargetCount}
						onClick={() => {
							if(!gigState.targetsEmployeeSize) return alert('Set Employee Size')
							if(!gigState.targetsRadius) return alert('Set Radius')

							onInputChange('getTargets', Math.random())
						}}
					>
						Get Target Count
					</Button>
				</Col>
			</Row>
			<Row>
				<Col className='d-flex flex-column text-center'>
					<div className='mt-2'>
						There are a total of
						<b>&nbsp;{totalCount}&nbsp;</b>
						targets near
						<b>&nbsp;{gigState.selectedStores ? gigState.selectedStores.length : 0}&nbsp;</b>
						stores
					</div>
					<div className='mb-2'>
						Exporting {gigState.maxTargets || 0} targets per store will cost <b>${(gigState.maxTargets * gigState.selectedStores.length * 0.04).toFixed(2)}</b> total
					</div>
					<div>
						<Form.Check
							type='checkbox'
							label='Use these targets'
							checked={gigState.useInfoGroupTargets ? true : false}
							onChange={e => onInputChange('useInfoGroupTargets', e.target.checked)}
						/>
					</div>
				</Col>
			</Row>
		</Segment>
	)
}
