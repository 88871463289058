import React from 'react'
import { map } from 'underscore'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { Dropdown } from 'semantic-ui-react'

function Stores({ stores, onSelectStores, selectedStores, selectedBrand }) {
	if(!selectedBrand) return <h3 className='text-center'>You must select a brand first</h3>

	const options = map(stores, (store, i) => ({
		key: store.store_id,
		value: store,
		text: `${store.store_id}- ${store.address}, ${store.city} ${store.state}`,
	}))

	return (
		<div>
			<Row>
				<Col className='d-flex justify-content-center'>
					<Dropdown
						clearable
						search
						selection
						// multiple
						placeholder='Select Stores'
						className='w-50'
						name='selectedStores'
						options={options}
						value={''}
						onChange={(e, { name, value }) => onSelectStores([...new Set(selectedStores.concat([value]))])}
					/>
				</Col>
				<Col md={2} className='d-flex justify-content-end'>
					<Button onClick={() => onSelectStores(map(stores, store => store))}>
						Select All Stores
					</Button>
				</Col>
			</Row>
			<Row className='font-weight-bold border-bottom border-4 mt-4 mb-3'>
				<Col md={1}>Store ID</Col>
				<Col md={3}>Store Name</Col>
				<Col>Address</Col>
			</Row>
			<Row>
				<Col>
					{selectedStores.map(store =>
						<Row className='border-bottom mb-2' key={store.store_id}>
							<Col md={1}>{store.store_id}</Col>
							<Col md={3}>{store.store_name}</Col>
							<Col>{store.address}, {store.city} {store.state}</Col>
							<Col
								className='d-flex justify-content-end text-danger pointer'
								onClick={() => onSelectStores(selectedStores.filter(s => s.store_id !== store.store_id))}
							>
								X
							</Col>
						</Row>
					)}
				</Col>
			</Row>
		</div>
	)
}

export default Stores
