import React, { Fragment, useState, useEffect } from 'react'
import axios from 'axios'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import BsForm from 'react-bootstrap/Form'
import { Segment, Button, Form } from 'semantic-ui-react'
import { filter, map } from 'underscore'

import config from '../../config'
import WYSIWYG from '../WYSIWYG'

export default function PhoneGigEmailTemplate({ gigId }) {
	const [editMode, setEditMode] = useState(false)
	const [brand, setBrand] = useState(null)
	const [gig, setGig] = useState(null)
	const [updateChildren, setUpdateChildren] = useState(true)
	const [dynamicEmailTemplateData, setDynamicEmailTemplateData] = useState([])
	// const templateData = gig.dynamicEmailTemplateData
	// const counter = gig.dynamicEmailTemplateData.length

	useEffect(() => {
		getGig()
	}, [])

	const getGig = async () => {
		try {
			const response = await axios.get(`${config.coreAPI}/fieldDayAdmin/activity/${gigId}`)
			const gig = response.data.responses[0].results[0].brand_user_activity

			getBrand(response.data.responses[0].results[0].brand_user_activity.brand_id)

			setGig(response.data.responses[0].results[0].brand_user_activity)
			setDynamicEmailTemplateData(
				gig && gig.sendgrid_data && gig.sendgrid_data.template_data
					? map(gig.sendgrid_data.template_data, (v, k) => ([k, v]))
					: []
			)
		} catch(e) {
			console.error(e)
		}
	}

	const getBrand = async (brandId) => {
		try {
			const response = await axios.get(`${config.coreAPI}/brand/${brandId}`)

			setBrand(response.data.responses[0].results[0])
		} catch(e) {
			console.error(e)
			// alert('error in getting brand')
		}
	}

	const handleInputChange = (name, value) => {
		const newState = { ...gig }

		newState[name] = value

		setGig(newState)
	}

	const handleAdd = (i) => {
		setDynamicEmailTemplateData([ ...dynamicEmailTemplateData, ['', ''] ])
		// onInputChange('dynamicEmailTemplateData', [
		// 	...gig.dynamicEmailTemplateData,
		// 	['', '']
		// ])
	}

	const handleRemove = () => {
		const newState = [ ...dynamicEmailTemplateData ]

		newState.pop()

		setDynamicEmailTemplateData(newState)
	}

	const handleDynamicTemplateDataChange = (e, i) => {
		const { name, value } = e.target

		if(name === 'key') {
			const state = [ ...dynamicEmailTemplateData ]

			state[i][0] = value

			setDynamicEmailTemplateData(state)

		}

		if(name === 'val') {
			const state = [ ...dynamicEmailTemplateData ]

			state[i][1] = value

			setDynamicEmailTemplateData(state)

		}
	}

	const handleSaveChanges = async () => {
		const updateAllChildren = gig.activity_group && gig.activity_group.group_master ? updateChildren : false

		if(gig.send_follow_up_email && (!brand.sendgrid_api_key || !brand.sendgrid_username)) {
			alert("SendGrid is not set up for this brand. Please make sure a SendGrid username and API are generated on brand's profile page before setting up follow up email")
			return
		}

		if(gig.send_follow_up_email && (!gig.sendgrid_data.template_id || !gig.sendgrid_data.sender_email_address)) {
			alert('Template ID and Sender Email Address are required to send follow up emails')
			return
		}

		try {
			const body = { ...gig }

			if(body.sendgrid_data) {
				body.sendgrid_data.template_data = dynamicEmailTemplateData.length
					? dynamicEmailTemplateData.reduce((accum, el) => {
							accum[el[0]] = el[1]
							return accum
						}, {})
					: null
			}

			// Not Neccessary
			// if(!body.sendgrid_data) {
			// 	body = {
			// 		sendgrid_data: {
			// 			template_data: dynamicEmailTemplateData.length
			// 				? dynamicEmailTemplateData.reduce((accum, el) => {
			// 						accum[el[0]] = el[1]
			// 						return accum
			// 					}, {})
			// 				: null
			// 		}
			// 	}
			// }


			await axios({
				method: 'patch',
				headers: { 'content-type': 'application/json' },
				url: `${config.coreAPI}/fieldDayAdmin/activity/${gig.id}/update`,
				params: {
					updateChildren: updateAllChildren
				},
				data: JSON.stringify({
					send_follow_up_email: body.send_follow_up_email,
					sendgrid_data: body.sendgrid_data,
				})
			})

			getGig()
			alert('Successfull')
			setEditMode(false)
		} catch(e) {
			alert('error in saving')
			console.error(e)
		}
	}

	// console.log('PhoneGigConfig GIG: ', gig)
	// console.log('PhoneGigConfig BRAND: ', brand)

	return (
		<Fragment>
			<h4>
				Email Template
				<i className='fas fa-edit pointer ml-3 mr-2' onClick={() => setEditMode(!editMode)}></i>
				{editMode &&
					<Button
						primary
						size='mini'
						loading={false}
						onClick={handleSaveChanges}
					>
						Save
					</Button>
				}
			</h4>
			{gig &&
				<Fragment>
					{gig.activity_group && gig.activity_group.group_master &&
						<Row>
							<Col>
								<BsForm.Check
									disabled={!editMode}
									type='checkbox'
									label='Update all child gigs'
									className='mb-4'
									checked={updateChildren ? true : false}
									onChange={e => setUpdateChildren(e.target.checked)}
								/>
							</Col>
						</Row>
					}
					<Row>
						<Col>
							<BsForm.Check
								disabled={!editMode}
								type='checkbox'
								label='Send Follow Up Email (Phone Gigs Only)'
								className='mb-4'
								checked={gig.send_follow_up_email ? true : false}
								onChange={e => handleInputChange('send_follow_up_email', e.target.checked)}
							/>
							<Form.Input
								fluid
								disabled={!editMode}
								name='emailTemplateId'
								label='Template ID'
								value={gig.sendgrid_data ? gig.sendgrid_data.template_id : ''}
								onChange={(e) => {
									const newState = { ...gig.sendgrid_data }

									newState.template_id = e.target.value.trim()
									handleInputChange('sendgrid_data', newState)
								}}
							/>
							<Form.Input
								fluid
								disabled={!editMode}
								name='sender_name'
								value={gig.sendgrid_data ? gig.sendgrid_data.sender_name : ''}
								label='Sender Name'
								onChange={(e) => {
									const newState = { ...gig.sendgrid_data }

									newState.sender_name = e.target.value
									handleInputChange('sendgrid_data', newState)
								}}
							/>
							<Form.Input
								fluid
								disabled={!editMode}
								name='sender_email_address'
								value={gig.sendgrid_data ? gig.sendgrid_data.sender_email_address : ''}
								label='Sender Email Address'
								onChange={(e) => {
									const newState = { ...gig.sendgrid_data }

									newState.sender_email_address = e.target.value
									handleInputChange('sendgrid_data', newState)
								}}
							/>
							<Form.Input
								fluid
								disabled={!editMode}
								name='email_subject'
								value={gig.sendgrid_data ? gig.sendgrid_data.email_subject : ''}
								label='Email Subject'
								onChange={(e) => {
									const newState = { ...gig.sendgrid_data }

									newState.email_subject = e.target.value
									handleInputChange('sendgrid_data', newState)
								}}
							/>
							<Form.Input
								fluid
								disabled={!editMode}
								name='reply_to'
								value={gig.sendgrid_data ? gig.sendgrid_data.reply_to : ''}
								label='Reply To'
								onChange={(e) => {
									const newState = { ...gig.sendgrid_data }

									newState.reply_to = e.target.value
									handleInputChange('sendgrid_data', newState)
								}}
							/>
							<div className='mt-3' style={{ height: '220px', overflow: 'auto' }}>
								<h5>Dynamic Template Data</h5>
								<Button.Group className='mb-3'>
								  <Button
								    disabled={dynamicEmailTemplateData.length === 0 || !editMode}
								    icon='minus'
								    onClick={handleRemove}
								  />
								  <Button
								  	disabled={!editMode}
								    icon='plus'
								    onClick={() => handleAdd(dynamicEmailTemplateData.length)}
								  />
								</Button.Group>
							  {map(dynamicEmailTemplateData, (keyValPair, i) => (
							  	  <Row className='mb-1' key={i}>
							  	  	<Col>
								  	  	<Form.Input
								  	  		fluid
								  	  		disabled={!editMode}
								  	  		name='key'
								  	  		value={keyValPair[0]}
								  	  		onChange={e => handleDynamicTemplateDataChange(e, i)}
								  	  		placeholder='Key'
								  	  	/>
							  	  	</Col>
							  	  	<Col>
								  	  	<Form.Input
								  	  		fluid
								  	  		disabled={!editMode}
								  	  		name='val'
								  	  		value={keyValPair[1]}
								  	  		onChange={e => handleDynamicTemplateDataChange(e, i)}
								  	  		placeholder='Value'
								  	  	/>
							  	  	</Col>
							  	  </Row>
							  ))}
							</div>
						</Col>
					</Row>
				</Fragment>
			}
		</Fragment>
	)
}
