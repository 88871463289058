import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment-timezone'
import _ from 'underscore'
import DatePicker from 'react-datepicker'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
// import CardDeck from 'react-bootstrap/CardDeck'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'

import config from '../config'
import AmbassadorCard from './AmbassadorCard'

class GigActions extends Component {
	state = {
		errors: [],
		toggleOpen: false,
		loading: true,
		actionErrors: []
	}

	componentDidMount() {
		this.getGigFamily()
	}

	render() {
		const { masterGig, childGigs, selectedGig, toggleOpen, loading, errors, working, done, actionErrors, editTitle } = this.state

		return (
			<div>
				{loading
					? <div className='text-center'>
							<Spinner
								animation='border'
								style={{width: '5rem', height: '5rem'}}
								variant='info'
							/>
						</div>
					: <Row>
							{errors.map((err, i) => <span key={i} style={{color: 'red'}}>{err}</span>)}
							<Col lg={5} style={{ maxHeight: '400px', overflow: 'auto' }}>
								{masterGig &&
									<Col className='mb-2 d-flex align-items-center'>
										<i className={`${toggleOpen ? 'fas fa-caret-down' : 'fas fa-caret-right'} pointer`} onClick={this.handleToggle}></i>&nbsp;
										<div
											style={{backgroundColor: `${!editTitle && selectedGig && selectedGig.brand_user_activity.id === masterGig.brand_user_activity.id ? '#ffc107' : 'white'}`}}
											className='actions-gig-title pointer pt-2 pb-1 pr-4'
											onClick={() => this.handleGigClick(masterGig, 'isMaster')}
										>
											{editTitle && selectedGig && selectedGig.brand_user_activity.id === masterGig.brand_user_activity.id
												? <Fragment>
														<Form.Control
															defaultValue={masterGig.brand_user_activity.title}
															ref={input => this.titleEdit = input}
														/>
													</Fragment>
												: <b>{masterGig.brand_user_activity.title} {masterGig.brand_user_activity.activity_group && '(Master Gig)'}</b>
											}&nbsp;
											{<i className="fas fa-edit pointer" title='Edit' onClick={() => this.setState({ editTitle: !editTitle })}></i>}&nbsp;
											{editTitle && <i className='far fa-save pointer' title='Save' onClick={this.handleSaveGigTitle}></i>}
										</div>
									</Col>
								}
								{!childGigs && toggleOpen && <em>No child gigs</em>}
								{childGigs && toggleOpen &&
									childGigs.map(gig => (
										<Col key={gig.activity_id} className={`d-flex align-items-center mb-1 ${gig.ambassador_finished ? 'text-success' : ''}`}>
											<div
												style={{backgroundColor: `${!editTitle && selectedGig && selectedGig.brand_user_activity.id === gig.activity_id ? '#ffc107' : 'white'}`}}
												className='actions-gig-title pointer pl-4 pt-2 pb-2 pr-4'
												onClick={() => this.handleGigClick(gig)}
											>
												{editTitle && selectedGig && selectedGig.brand_user_activity.id === gig.activity_id
													? <Fragment>
															<Form.Control
																defaultValue={gig.title}
																ref={input => this.titleEdit = input}
															/>
														</Fragment>
													: gig.title
												}&nbsp;
												{(selectedGig && selectedGig.brand_user_activity.id) === gig.activity_id &&
													<Fragment>
														<i className='fas fa-edit pointer' title='Edit Title' onClick={() => this.setState({ editTitle: !editTitle })}></i>
														&nbsp;&nbsp;
														<i className='far fa-copy' title='Duplicate Gig' onClick={() => this.handleDuplicateChild(selectedGig.brand_user_activity.id)}></i>
													</Fragment>
												}&nbsp;
												{editTitle && selectedGig.brand_user_activity.id === gig.activity_id &&
													<i className="far fa-save pointer" title='Save' onClick={this.handleSaveGigTitle}></i>
												}
											</div>
										</Col>
									))
								}
							</Col>
							<Col>
								{selectedGig &&
									<div>
										<Row className='m-2'>
											<Col lg={2} className='gig-actions-title'><b>ID</b></Col>
											<Col>
												{(selectedGig.brand_user_activity.activity_group && !selectedGig.brand_user_activity.activity_group.group_master)
													|| !selectedGig.brand_user_activity.activity_group
													? <Link to={`/gig/${selectedGig.brand_user_activity.id}`}>{selectedGig.brand_user_activity.id}</Link>
													: <Link to={`/gig/${selectedGig.brand_user_activity.id}`}>{selectedGig.brand_user_activity.id}</Link>
												}
											</Col>
										</Row>

										{(selectedGig.brand_user_activity.activity_group && !selectedGig.brand_user_activity.activity_group.group_master)
											|| !selectedGig.brand_user_activity.activity_group
											? <Row className='m-2'>
													<Col lg={2} className='gig-actions-title'><b>Status</b></Col>
													{!selectedGig.brand_user_activity.field_day_operation_details.started_gig && <Col>Not Started</Col>}
													{selectedGig.brand_user_activity.field_day_operation_details.started_gig
														&& !selectedGig.brand_user_activity.field_day_operation_details.finished_gig
														&& <Col>In Progress</Col>
													}
													{selectedGig.brand_user_activity.field_day_operation_details.started_gig
														&& selectedGig.brand_user_activity.field_day_operation_details.finished_gig
														&& <Col>Finished</Col>
													}
												</Row>
											: null
										}

										<Row className='m-2'>
											<Col lg={2} className='gig-actions-title'><b>Due Date</b></Col>
											<Col>
												<DateComponent
													date={selectedGig.brand_user_activity.due_date_millis}
													activityId={selectedGig.brand_user_activity.id}
													timeZone={selectedGig.brand_user_activity.time_zone}
													timeZoneHuman={selectedGig.brand_user_activity.time_zone_human}
													onRefreshGig={this.getSelectedGig}
													key={selectedGig.brand_user_activity.id}
													hideEdit={this.hideEditDate()}
												/>
											</Col>
										</Row>
										<Row className='m-2'>
											<Col lg={2} className='gig-actions-title'><b>Location</b></Col>
											<Col>
												<Address
													gig={selectedGig.brand_user_activity}
													key={selectedGig.brand_user_activity.id}
													onRefreshGig={this.getSelectedGig}
												/>
											</Col>
										</Row>
										<Row className='m-2'>
											<Col lg={2} className='gig-actions-title'><b>$/hr</b></Col>
											<Col>
												<HourPay
													value={selectedGig.brand_user_activity.field_day_management.field_day_remuneration.ambassador_remuneration_rate_per_hour}
													name='rate'
													activityId={selectedGig.brand_user_activity.id}
													onRefreshGig={this.getSelectedGig}
													key={selectedGig.brand_user_activity.id}
												/>
											</Col>
										</Row>
										<Row className='m-2'>
											<Col lg={2} className='gig-actions-title'><b>Hours</b></Col>
											<Col>
												<HourPay
													value={selectedGig.brand_user_activity.field_day_management.field_day_remuneration.max_hours}
													name='hours'
													activityId={selectedGig.brand_user_activity.id}
													onRefreshGig={this.getSelectedGig}
													key={selectedGig.brand_user_activity.id}
												/>
											</Col>
										</Row>
										{/*<Row className='m-2'>
											<Col lg={2} className='gig-actions-title'><b>$ Due</b></Col>
											<Col>
												<HourPay
													value={selectedGig.brand_user_activity.field_day_operation_details.ambassador_remuneration_to_pay}
													name='payment'
													activityId={selectedGig.brand_user_activity.id}
													onRefreshGig={this.getSelectedGig}
													key={selectedGig.brand_user_activity.id}
												/>
											</Col>
										</Row>*/}
										<Row className='m-2 d-flex align-items-center'>
											<Col lg={2} className='gig-actions-title'><b>Ambassador</b></Col>
											<Col>
												<AssignedAmbassador
													gig={selectedGig.brand_user_activity}
													ambassadors={selectedGig.applied_ambassadors}
													onRefreshGig={this.getSelectedGig}
													key={selectedGig.brand_user_activity.id}
												/>
											</Col>
										</Row>
										<Row>
											<Col lg={2}>
												<DropdownButton variant='outline-dark' title='More Actions'>
													<Dropdown.Item onClick={this.handleStartGig}>Start gig</Dropdown.Item>
													<Dropdown.Item onClick={this.handleUnstartGig}>Unstart gig</Dropdown.Item>
													<Dropdown.Item onClick={this.handleEndGig}>End gig</Dropdown.Item>
													<Dropdown.Item onClick={this.handleDeleteGig}>Delete gig</Dropdown.Item>
												</DropdownButton>
											</Col>
											<Col>
												{working && <Spinner animation='border' variant='info' size='sm' />}
												{done && <i className='fas fa-check text-success'></i>}
												{actionErrors.map((err, i) => <span key={i} style={{color: 'red'}}>{err}</span>)}
											</Col>
										</Row>
									</div>
								}
							</Col>
						</Row>
				}
				<hr />
				<Row className='d-flex p-4'>
					{selectedGig &&
						<Applicants
							applicants={selectedGig.applied_ambassadors}
							gig={selectedGig.brand_user_activity}
							onRefreshGig={this.getSelectedGig}
						/>
					}
				</Row>
			</div>
		)
	}

	getGigFamily = (gig) => {
		if(
			this.props.gig.brand_user_activity.activity_group &&
			!this.props.gig.brand_user_activity.activity_group.group_master &&
			this.props.gig.brand_user_activity.activity_group.group_master_id
		) {
			this.getMasterGig(this.props.gig.brand_user_activity.activity_group.group_master_id)
		} else if (
				this.props.gig.brand_user_activity.activity_group &&
				this.props.gig.brand_user_activity.activity_group.group_master
			) {
			this.getMasterGig(this.props.gig.brand_user_activity.id)
		} else {
			this.setState({
				masterGig: this.props.gig,
				loading: false
			})
		}
	}

	getMasterGig = async (activityId) => {
		try {
			const masterGig = await axios.get(`${config.coreAPI}/fieldDayAdmin/activity/${activityId}`)

			this.setState({
				masterGig: masterGig.data.responses[0].results[0],
				childGigs: masterGig.data.responses[0].results[0].brand_user_activity.activity_group && masterGig.data.responses[0].results[0].brand_user_activity.activity_group.child_activities,
				loading: false
			})
		} catch(e) {
			console.error(e)
			this.setState({
				errors: this.state.errors.concat([`${e.response.data.message}`]),
				loading: false
			})
		}
	}

	handleToggle = () => {
		this.setState({
			toggleOpen: !this.state.toggleOpen
		})
	}

	handleGigClick = async (gig, isMaster) => {
		let masterGigId

		try {
			if(isMaster) {
				masterGigId = gig.brand_user_activity.id

				this.setState({
					toggleOpen: !this.state.toggleOpen
				})
			}

			const selectedGig = await axios.get(`${config.coreAPI}/fieldDayAdmin/activity/${masterGigId ? masterGigId : gig.activity_id}`)

			this.setState({
				selectedGig: selectedGig.data.responses[0].results[0],
			})
		} catch(e) {
			console.error(e)
			// this.setState({
			// 	errors: this.state.errors.includes(e.response.data.message) ? [...this.state.errors] : this.state.errors.concat([`${e.response.data.message}`]),
			// })
		}
	}

	getSelectedGig = async () => {
		const gig = this.state.selectedGig.brand_user_activity

		try {
			const selectedGig = await axios.get(`${config.coreAPI}/fieldDayAdmin/activity/${gig.id}`)

			this.setState({
				selectedGig: selectedGig.data.responses[0].results[0]
			})
		} catch(e) {
			this.setState({
				errors: this.state.errors.includes(e.response.data.message) ? [...this.state.errors] : this.state.errors.concat([`${e.response.data.message}`])
			})
		}
	}

	handleErrors = (e) => {
		this.setState(({ actionErrors }) => ({
			actionErrors: actionErrors.includes(e.response.data.message) ? [...actionErrors] : actionErrors.concat([`${e.response.data.message}`])
		}))
	}

	handleSaveGigTitle = async () => {
		const inputValue = this.titleEdit.value
		const selectedGig = this.state.selectedGig

		try {
			await axios.post(`${config.coreAPI}/activity/${selectedGig.brand_user_activity.id}`, { title: inputValue })

			this.setState({
				editTitle: false
			}, () => {
				if(this.state.selectedGig.brand_user_activity.id === this.state.masterGig.brand_user_activity.id) {
					this.getMasterGig(this.state.masterGig.brand_user_activity.id)
				} else {
					this.getGigFamily()
				}
			})

		} catch(e) {
			this.handleErrors(e)
		}
	}

	handleDuplicateChild = async (gigId) => {
		try {
			await axios.post(`${config.coreAPI}/activity/${gigId}/duplicateChild`)

			this.getGigFamily()
		} catch(e) {
			console.error(e)
			this.handleErrors(e)
		}
	}

	hideEditDate = () => {
		const selectedGig = this.state.selectedGig.brand_user_activity

		if(!selectedGig.activity_group) return false
		if(selectedGig.activity_group.group_master) return true
		if(!selectedGig.activity_group.group_master) return false
	}

	handleStartGig = async () => {
		const gigId = this.state.selectedGig.brand_user_activity.id
		const confirmAction = window.confirm('Are you sure you want to start this gig?')

		if(confirmAction) {
			this.setState({ working: true })

			try {
				await axios.post(`${config.coreAPI}/fieldDayAdmin/activity/${gigId}/start`)

				this.setState({
					working: false,
					done: true
				})
			} catch(e) {
				this.setState({ working: false }, () => this.handleErrors(e))
			}
		}
	}

	handleUnstartGig = async () => {
		const gigId = this.state.selectedGig.brand_user_activity.id
		const confirmAction = window.confirm('Are you sure you want to unstart this gig?')

		if(confirmAction) {
			this.setState({ working: true })

			try {
				await axios.post(`${config.coreAPI}/fieldDayAdmin/activity/${gigId}/unstart`)

				this.setState({
					working: false,
					done: true
				})
			} catch(e) {
				this.setState({ working: false }, () => this.handleErrors(e))
			}
		}
	}

	handleEndGig = async () => {
		const gigId = this.state.selectedGig.brand_user_activity.id
		const confirmAction = window.confirm('Are you sure you want to finish this gig?')

		if(confirmAction) {
			this.setState({ working: true })

			try {
				await axios.post(`${config.coreAPI}/fieldDayAdmin/activity/${gigId}/finish`)

				this.setState({
					working: false,
					done: true
				})
			} catch(e) {
				this.setState({ working: false }, () => this.handleErrors(e))
			}
		}
	}

	handleDeleteGig = async () => {
		const gig = this.state.selectedGig.brand_user_activity
		const confirmAction = window.confirm('Are you sure you want to delete this gig?')

		if(confirmAction) {
			this.setState({ working: true })

			try {
				await axios.delete(`${config.coreAPI}/activity/${gig.id}`)

				this.setState({
					working: false,
					done: true
				}, () => this.getGigFamily())
			} catch(e) {
				this.setState({ working: false }, () => this.handleErrors(e))
			}
		}
	}
}

class DateComponent extends Component {
	state = {
		processing: false,
		done: false,
		editMode: false,
		displayDate: null,
		actualDate: this.props.date,
		errors: []
	}

	componentDidMount() {
		this.calculateDisplayDate(this.props.date)
	}

	render() {
		const { editMode, processing, displayDate, done, errors } = this.state
		const { hideEdit, date, timeZone, timeZoneHuman } = this.props

		return (
			<div>
				{editMode
					? <DatePicker
							selected={displayDate}
							onChange={this.handleDateChange}
							showTimeSelect
							timeFormat='h:mma'
							timeIntervals={15}
							dateFormat="MMMM d, yyyy h:mm aa"
							timeCaption="time"
						/>
					// : this.getActualDate()
					: `${moment(date).tz(timeZone).format('MM/DD/YY - h:mm A')} (${timeZoneHuman})`
				}
				&nbsp;{!hideEdit && <i className="fas fa-edit pointer" title='Edit' onClick={() => this.setState({ editMode: !editMode })}></i>}&nbsp;
				{editMode && <i className="far fa-save pointer" title='Save' onClick={this.handleSaveDate}></i>}
				{processing && <Spinner animation='border' variant='info' size='sm' />}
				{done && <i className="fas fa-check" style={{color: '#28a745'}}></i>}
				{errors.map((err, i) => <span key={i}>{err}</span>)}
			</div>
		)
	}

	calculateDisplayDate = (date) => {
		const actualDate = moment(date).utcOffset()
		const systemDate = moment.tz.zone(this.props.timeZone).utcOffset(date) * -1
		const timezoneOffset = systemDate - actualDate
		const fixedForDateObj = timezoneOffset >= 0 ? moment(date).add(timezoneOffset, 'minutes') : moment(date).subtract(timezoneOffset, 'minutes')

		this.setState({
			displayDate: new Date(fixedForDateObj)
		})
	}

	handleDateChange = (date) => {
		const systemDate = moment(date).utcOffset()
		const gigDate = moment.tz.zone(this.props.timeZone).utcOffset(date) * -1
		const timezoneOffset = gigDate - systemDate
		const fixedForDateObj = timezoneOffset >= 0 ? moment(date).subtract(timezoneOffset, 'minutes') : moment(date).add(timezoneOffset, 'minutes')

		this.setState({
			actualDate: new Date(fixedForDateObj),
			displayDate: date
		})
	}

	getActualDate = () => {
		const { displayDate } = this.state
		const { timeZoneHuman } = this.props

		return `${moment(displayDate).format('MMM D, YYYY - h:mm a')} (${timeZoneHuman})`
	}

	handleSaveDate = async () => {
		const { actualDate } = this.state

		if(!actualDate) {
			this.setState({
				editMode: false
			})

			return
		}

		try {
			this.setState({ processing: true })

			const response = await axios.post(`${config.coreAPI}/activity/${this.props.activityId}`, {
				due_date_millis: actualDate.valueOf(),
				request_time_zone: moment.tz.guess(true)
			})

			if(response.status === 200 || response.status === 204) {
				this.props.onRefreshGig()

				this.setState({
					processing: false,
					editMode: false,
					done: true,
				})

				setTimeout(() => this.setState({
					done: false
				}), 2000)
			}

		} catch(e) {
			this.setState({
				processing: false,
				actualDate: null,
				errors: this.state.errors.includes(e.response.data.message) ? [...this.state.errors] : this.state.errors.concat([`${e.response.data.message}`])
			})
		}
	}
}

class Address extends Component {
	state = {
		editMode: false,
		processing: false,
		done: false,
		errors: [],
		address: this.props.gig.address,
		city: this.props.gig.city,
		state: this.props.gig.state,
		postal_code: this.props.gig.postal_code,
	}

	render() {
		const { gig } = this.props
		const { editMode, processing, done, errors, address, city, state, postal_code } = this.state

		return (
			<div>
				{editMode
					? <div>
							<InputGroup>
								<Form.Control
									placeholder='Street Address'
									name='address'
									value={address}
									onChange={this.handleInputChange}
								/>
								<Form.Control
									placeholder='City'
									name='city'
									value={city}
									onChange={this.handleInputChange}
								/>
								<Form.Control
									placeholder='State'
									name='state'
									value={state}
									onChange={this.handleInputChange}
								/>
								<Form.Control
									placeholder='Zip'
									name='postal_code'
									value={postal_code}
									onChange={this.handleInputChange}
								/>
							</InputGroup>
						</div>
					: <div>{`${gig.address}, ${gig.city} ${gig.state} ${gig.postal_code}`}</div>
				}
				&nbsp;<i className="fas fa-edit pointer" title='Edit' onClick={() => this.setState({ editMode: !editMode })}></i>&nbsp;
				{editMode && <i className="far fa-save pointer" title='Save' onClick={this.handleSaveAddress}></i>}&nbsp;
				{processing && <Spinner animation='border' variant='info' size='sm' />}&nbsp;
				{done && <i className="fas fa-check" style={{color: '#28a745'}}></i>}&nbsp;
				{errors.map((err, i) => <span key={i} style={{color: 'red'}}>{err}</span>)}
			</div>
		)
	}

	handleInputChange = (event) => {
		const { name, value } = event.target

		this.setState({
			[name]: value
		})
	}

	handleSaveAddress = async () => {
		const { address, city, state, postal_code } = this.state

		try {
			this.setState({ processing: true })

			const response = await axios.post(`${config.coreAPI}/activity/${this.props.gig.id}`, {
				address,
				city,
				state,
				postal_code
			})

			if(response.status === 200 || response.status === 204) {
				this.props.onRefreshGig()

				this.setState({
					processing: false,
					editMode: false,
					done: true,
					// [name]: this.state[name]
				})

				setTimeout(() => this.setState({
					done: false
				}), 2000)
			}
		} catch(e) {
			this.setState({
				processing: false,
				actualDate: null,
				errors: this.state.errors.includes(e.response.data.message) ? [...this.state.errors] : this.state.errors.concat([`${e.response.data.message}`])
			})
		}
	}
}

class HourPay extends Component {
	state = {
		editMode: false,
		processing: false,
		done: false,
		errors: [],
		rate: this.props.value,
		hours: this.props.value,
		payment: this.props.value
	}

	render() {
		const { editMode, processing, done, errors } = this.state
		const { name } = this.props

		return (
			<div className='d-flex align-items-center'>
				{editMode
					? <div>
							<Form.Control
								type='text'
								value={this.state[name]}
								name={name}
								onChange={this.handleInputChange}
							/>
						</div>
					: <div>{`${name !== 'hours' ? '$' : ''}${this.state[name] ? this.state[name] : 'n/a'}`}</div>
				}
				&nbsp;<i className="fas fa-edit pointer" title='Edit' onClick={() => this.setState({ editMode: !editMode })}></i>&nbsp;
				{editMode && <i className="far fa-save pointer" title='Save' onClick={this.handleSaveHourPay}></i>}&nbsp;
				{processing && <Spinner animation='border' variant='info' size='sm' />}&nbsp;
				{done && <i className="fas fa-check" style={{color: '#28a745'}}></i>}&nbsp;
				{errors.map((err, i) => <span key={i} style={{color: 'red'}}>{err}</span>)}
			</div>
		)
	}

	handleInputChange = (event) => {
		const { name, value } = event.target

		this.setState({
			[name]: value
		})
	}

	handleSaveHourPay = async () => {
		const { name, activityId } = this.props

		try {
			this.setState({ processing: true })

			const response = await axios({
				url: `${config.coreAPI}/fieldDayAdmin/activity/${activityId}`,
				method: 'patch',
				params: { [name]: this.state[name] },
			})

			if(response.status === 200 || response.status === 204) {
				this.props.onRefreshGig()

				this.setState({
					processing: false,
					editMode: false,
					done: true,
					[name]: this.state[name]
				})

				setTimeout(() => this.setState({
					done: false
				}), 2000)
			}
		} catch(e) {
			this.setState({
				errors: this.state.errors.includes(e.response.data.message) ? [...this.state.errors] : this.state.errors.concat([`${e.response.data.message}`]),
				processing: false,
				[name]: this.props.value
			})
		}
	}
}

class AssignedAmbassador extends Component {
	state = {
		errors: [],
		showResult: false,
		processing: false,
		done: false,
		assignedAmbassador: null,
		dirty: false,
		showUnassignReasons: false,
		unassignReason: null,
		unassignReasonDescription: ''
	}

	componentDidMount() {
		const { gig, ambassadors } = this.props

		// if(ambassadors && !Object.keys(ambassadors).length) return

		if(gig.field_day_operation_details.assigned_ambassador_id) {
			this.setState({
				assignedAmbassador: _.find(ambassadors, amb => amb.id === gig.field_day_management.field_day_workflow.accepted_ambassador_id)
			})
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { gig, ambassadors } = this.props

		if(ambassadors && !Object.keys(ambassadors).length) return

		if(gig.field_day_operation_details.assigned_ambassador_id && !this.state.assignedAmbassador) {
			this.setState({
				assignedAmbassador: _.find(ambassadors, amb => amb.id === gig.field_day_management.field_day_workflow.accepted_ambassador_id),
				dirty: false
			})
		}

		if(!gig.field_day_operation_details.assigned_ambassador_id && !this.state.dirty) {
			this.setState({
				assignedAmbassador: null,
				dirty: true
			})
		}
	}

	// different alternatives for getting input value
	// ambassadorInput = React.createRef()
	// ambassadorInput = target => this.setState({ target })

	render() {
		const { searchResult, showResult, processing, done, errors, assignedAmbassador, unassignReason, showUnassignReasons, unassignReasonDescription } = this.state

		// if(!assignedAmbassador) return null

		return (
			<div className='d-flex align-items-center'>
				<div>
					{!assignedAmbassador &&
						<div className='search-box d-flex align-items-center'>
							<Form.Control
								type='text'
								placeholder='search and assign ambassador'
								onChange={this.handleInputChange}
								// ref={this.ambassadorInput}
								// ref={(input) => this.input = input}
							/>&nbsp;
							{showResult &&
								<div className='search-result'>
									<div className='text-right'>
										<span className='pointer' onClick={() => this.setState({ showResult: false, searchResult: null })}>[x]</span>
									</div>
									{searchResult.map(ambassador => (
										<div key={ambassador.id} className='search-result-item pointer' onClick={() => this.handleAssignAmbassador(ambassador)}>
											{ambassador.ambassador_name}&nbsp;&nbsp;{'<'}{ambassador.email_address}{'>'}
										</div>
									))}
								</div>
							}
						</div>
					}
					{assignedAmbassador &&
						<div className='d-flex align-items-center'>
							{assignedAmbassador.ambassador_name}&nbsp;&nbsp;{'<'}{assignedAmbassador.email_address}{'>'}&nbsp;
							{showUnassignReasons &&
								<DropdownButton size='sm' variant='outline-dark' title={unassignReason || 'Select Reason'}>
									<Dropdown.Item onClick={() => this.setState({ unassignReason: 'Reschedule', unassignReasonDescription: '' })}>Reschedule</Dropdown.Item>
									<Dropdown.Item onClick={() => this.setState({ unassignReason: 'Churn', unassignReasonDescription: '' })}>Churn</Dropdown.Item>
									<Dropdown.Item onClick={() => this.setState({ unassignReason: 'Other', unassignReasonDescription: '' })}>Other</Dropdown.Item>
								</DropdownButton>
							}&nbsp;&nbsp;
							<i
								className='fas fa-times pointer'
								title='Unassign Ambassador'
								onClick={() => this.setState(({ showUnassignReasons }) => ({ showUnassignReasons: !showUnassignReasons }))}
							/>
							&nbsp;
							{showUnassignReasons &&
								<i
									className='fas fa-save pointer'
									title='Save'
									onClick={this.handleUnassignAmbassador}
								/>
							}
						</div>
					}
					{unassignReason === 'Other' &&
						<Form.Group>
					    <Form.Control
								as='textarea'
								rows='2'
								value={unassignReasonDescription}
								onChange={e => this.setState({ unassignReasonDescription: e.target.value })}
					    />
					  </Form.Group>
					}
				</div>
				<Col>
					{processing && <Spinner animation='border' variant='info' size='sm' />}
					{done && <i className="fas fa-check" style={{color: '#28a745'}}></i>}
					{errors.map((err, i) => <span key={i} style={{color: 'red'}}>{err}</span>)}
				</Col>
			</div>
		)
	}

	handleInputChange = async (event) => {
		const { value } = event.target

		if(!value) {
			this.setState({
				searchResult: null,
				showResult: false
			})

			return
		}

		try {
			const searchResult = await axios.get(`${config.coreAPI}/fieldDayAdmin/ambassadors?pageFrom=0&pageSize=15&searchString=${value}&approved=true&autoComplete=true`)

			this.setState({
				searchResult: searchResult.data.responses[0].results,
				showResult: true
			})
		} catch(e) {
			this.setState({
				errors: this.state.errors.concat([`${e.response.data.message}`])
			})
		}
	}

	handleAssignAmbassador = async (ambassador) => {
		const activityId = this.props.gig.id

		try {
			this.setState({
				processing: true,
				errors: []
			})

			const response = await axios.post(`${config.coreAPI}/fieldDayAdmin/activity/${activityId}/assign/${ambassador.id}`)

			if(response.status === 200 || response.status === 204) {
				this.props.onRefreshGig()

				this.setState({
					processing: false,
					done: true,
					searchResult: null,
					showResult: false,
					assignedAmbassador: ambassador
				})

				setTimeout(() => this.setState({
					done: false
				}), 2000)
			}
		} catch(e) {
			this.setState({
				errors: this.state.errors.concat([`${e.response.data.message}`]),
				processing: false,
				showResult: false,
			})
		}
	}

	handleUnassignAmbassador = async () => {
		const { unassignReason, unassignReasonDescription } = this.state

		if(!unassignReason) {
			alert('You must select a reason for unassigning')
			return
		}

		const activityId = this.props.gig.id
		const unassignType = unassignReason.toUpperCase()
		const description = unassignReasonDescription ? `&description=${unassignReasonDescription}` : ''

		try {
			this.setState({ processing: true })

			const response = await axios.post(`${config.coreAPI}/fieldDayAdmin/activity/${activityId}/unassign?unassignType=${unassignType}${description}`)

			if(response.status === 200 || response.status === 204) {
				this.props.onRefreshGig()

				this.setState({
					processing: false,
					done: true,
					assignedAmbassador: null,
					errors: []
				})

				setTimeout(() => this.setState({
					done: false
				}), 2000)
			}
		} catch(e) {
			this.setState({
				errors: this.state.errors.includes(e.response.data.message) ? [...this.state.errors] : this.state.errors.concat([`${e.response.data.message}`]),
				processing: false
			})
		}
	}
}

class Applicants extends Component {
	state = {
		errors: []
	}

	render() {
		const { applicants, gig } = this.props
		const { errors } = this.state

		if(!applicants) {
			return <h4 style={{margin: 'auto'}}><i>No one has applied to this gig yet!</i></h4>
		}

		return (
			<div>
				{errors.map((err, i) => <span key={i} style={{color: 'red'}}>{err}</span>)}
				<div className='d-flex flex-wrap' style={{ maxHeight: '400px', overflow: 'auto' }}>
					{_.map(applicants, (applicant) => (
						<AmbassadorCard
							key={applicant.id}
							applicant={applicant}
							accepted={this.getAcceptedCase(applicant)}
							onAccept={this.handleAcceptApplicant}
							onReject={this.handleRejectApplicant}
							applicationDetails={_.filter(gig.field_day_management.field_day_applications, g => g.applied_by_ambassador_id === applicant.id)[0]}
						/>
					))}
				</div>
			</div>
		)
	}

	getAcceptedCase = (applicant) => {
		const { applicants, gig } = this.props
		const assignedAmbassador = _.find(applicants, amb => amb.id === gig.field_day_management.field_day_workflow.accepted_ambassador_id)

		if(assignedAmbassador) {
			return applicant.id === assignedAmbassador.id
		}

		return false
	}

	handleAcceptApplicant = async (ambassadorId) => {
		try {
			await axios.post(`${config.coreAPI}/fieldDayAdmin/activity/${this.props.gig.id}/accept/${ambassadorId}`)

			this.props.onRefreshGig()
		} catch(e) {
			this.setState({
				errors: this.state.errors.includes(e.response.data.message) ? [...this.state.errors] : this.state.errors.concat([`${e.response.data.message}`])
			})

			setTimeout(() => this.setState({
					errors: []
				}), 3000)
		}
	}

	handleRejectApplicant = async () => {
		// to be added if needed
	}
}

export default GigActions
