import React from 'react'
import { Icon, Image, Statistic, Loader } from 'semantic-ui-react'
import Spinner from 'react-bootstrap/Spinner'

function BrandStats({ brands, brandsExpenses, brandsActivityStats }) {
	return (
		<Statistic.Group widths='six' size='mini'>
			<Statistic>
				{brands && <Statistic.Value>{brands.total}</Statistic.Value>}
				<Statistic.Label>Brand{brands && brands.total > 1 && 's'}</Statistic.Label>
			</Statistic>

			<Statistic>
				{brandsActivityStats && <Statistic.Value>{brandsActivityStats.totalGigs.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Statistic.Value>}
				<Statistic.Label>Gigs</Statistic.Label>
			</Statistic>

			<Statistic>
				{brandsExpenses && <Statistic.Value>${(brandsExpenses.totalAmountInCents / 100).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Statistic.Value>}
				<Statistic.Label>Total Spend</Statistic.Label>
			</Statistic>

			<Statistic>
				{brandsActivityStats && <Statistic.Value>{brandsActivityStats.businessesVisitedTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Statistic.Value>}
				<Statistic.Label>Businesses Visited</Statistic.Label>
			</Statistic>

			<Statistic>
				{brandsActivityStats && <Statistic.Value>{brandsActivityStats.materialsHandedOutTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Statistic.Value>}
				<Statistic.Label>Materials Distributed</Statistic.Label>
			</Statistic>

			<Statistic>
				{brandsActivityStats && <Statistic.Value>{brandsActivityStats.leadsTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Statistic.Value>}
				<Statistic.Label>Leads</Statistic.Label>
			</Statistic>
		</Statistic.Group>
	)
}

export default BrandStats
