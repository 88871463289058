import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Pagination from 'react-bootstrap/Pagination'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'

import logo from '../../logo.png'
import { AmbassadorsActions } from './AmbassadorsTable'
import { handlePageChange, handlePageSizeChange, _getAmbassadors } from '../../actions/ambassadors'

class AmbassadorsGrid extends Component {
	state = {
		showActions: false
	}

	componentDidUpdate(prevProps, prevState) {
		const { dispatch, pageIndex, pageSize } = this.props

		if(prevProps.pageIndex !== pageIndex && prevProps.pageSize === pageSize) {
			dispatch(_getAmbassadors(this.getParams()))
		}

		if(prevProps.pageSize !== pageSize) {
			dispatch(_getAmbassadors(this.getParams()))
		}
	}

	render() {
		const { ambassadors, total, pageIndex, pageSize, dispatch } = this.props
		const numOfPages = total <= pageSize ? 1 : Math.ceil(total / pageSize)
		const pageSizes = [20, 40, 60, 80, 100]
		const paginationItems = []

		for(let i = 0; i < numOfPages; i++) {
			paginationItems.push(
				<Pagination.Item onClick={() => dispatch(handlePageChange(i))} key={i} active={i === pageIndex}>
					{i + 1}
				</Pagination.Item>
			)
		}

		return (
			<div>
				<Col>
					<AmbassadorsActions
						showActions={this.state.showActions}
						onShowActions={() => this.setState({ showActions: !this.state.showActions })}
						// onEmailClick={this.handleShowModal}
					/>
				</Col>
				<div className='d-flex justify-content-between align-items-center'>
					<Pagination>
						<Pagination.Prev onClick={this.handlePrev} />
						{paginationItems}
						<Pagination.Next onClick={this.handleNext} />
					</Pagination>
					<DropdownButton variant='secondary' title={`Page Size: ${pageSize}`}>
						{pageSizes.map((size, i) => <Dropdown.Item key={i} onClick={() => dispatch(handlePageSizeChange(pageIndex, size, total))}>{size}</Dropdown.Item>)}
					</DropdownButton>
				</div>
				<div className='d-flex flex-wrap justify-content-center'>
					{ambassadors.map(ambassador => <Grid key={ambassador.id} ambassador={ambassador} />)}
				</div>
				<div className='d-flex justify-content-between align-items-center'>
					<Pagination>
						<Pagination.Prev onClick={this.handlePrev} />
						{paginationItems}
						<Pagination.Next onClick={this.handleNext} />
					</Pagination>
					<DropdownButton variant='secondary' title={`Page Size: ${pageSize}`}>
						{pageSizes.map((size, i) => <Dropdown.Item key={i} onClick={() => dispatch(handlePageSizeChange(pageIndex, size, total))}>{size}</Dropdown.Item>)}
					</DropdownButton>
				</div>
			</div>
		)
	}

	getParams = () => {
		const { filters, pageIndex, pageSize } = this.props
		const filterParams = [`approved=${filters.approved}`]

		if(filters.searchString) {
			filterParams.push(`searchString=${filters.searchString}`)
		}

		if(filters.registrationVideoUploaded) {
			filterParams.push('registrationVideoUploaded=true')
		}

		if(filters.stripeSetup) {
			filterParams.push('stripeSetup=true')
		}

		if(filters.zipCodes) {
			filterParams.push(`zipCodes=${filters.zipCodeInput}`)
		}

		if(filters.radius) {
			filterParams.push(`radiusInMiles=${filters.radiusInMiles}`)
		}

		const params = filterParams.concat([`pageFrom=${pageIndex * pageSize}`], [`pageSize=${pageSize}`]).join('&')

		return params
	}

	handlePrev = () => {
		const { dispatch, pageIndex } = this.props

		if(pageIndex <= 0) return

		dispatch(handlePageChange(pageIndex - 1))
	}

	handleNext = () => {
		const { dispatch, pageIndex, pageSize, total } = this.props
		const numOfPages = total <= pageSize ? 1 : Math.ceil(total / pageSize)

		if(pageIndex >= numOfPages - 1) return

		dispatch(handlePageChange(pageIndex + 1))
	}
}

function mapStateToProps({ ambassadors }) {
	return {
		pageIndex: ambassadors.pageIndex,
		pageSize: ambassadors.pageSize,
		ambassadors: ambassadors.results,
		total: ambassadors.total,
		filters: ambassadors.filters
	}
}

class Grid extends Component {
	render() {
		const { ambassador } = this.props

		return (
			<Card className='mr-2 ml-2 mb-3' border='light' style={{ width: '15rem', height: '15rem' }}>
				<Card.Img variant="top" src={ambassador.profileImage || logo} style={{height: '5vw'}} />
				<Card.Body>
					<Card.Title style={{fontSize: '1rem'}}>
						<Link to={`/ambassador/${ambassador.id}`}>{ambassador.ambassador_name}</Link>&nbsp;
						<span>{ambassador.approved && <i className='fas fa-check-circle fa-xs' style={{color: '#357BB8'}} title='Approved'></i>}</span>
					</Card.Title>
					<Card.Text>
						{ambassador.city}, {ambassador.state}
					</Card.Text>
				</Card.Body>
			</Card>
		)
	}
}

export default connect(mapStateToProps)(AmbassadorsGrid)
