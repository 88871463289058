import React, { Fragment } from 'react'

function BusinessCards() {
	return (
		<Fragment>
			BusinessCardsz
		</Fragment>
	)
}

export default BusinessCards
