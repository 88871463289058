import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Dropdown } from 'semantic-ui-react'

export default function Details({ objectives, tactics, businessCardQuestions, onInputChange, gigState }) {
	const objectivesDropdown = objectives.map((objective, i) => ({
		key: objective.category_name + i,
		value: objective.category_name,
		text: objective.category_name,
	}))

	const tacticsDropdown = tactics.map((tactic, i) => ({
		key: tactic.category_name + i,
		value: tactic.category_name,
		text: tactic.category_name,
	}))

	const businessCardQuestionsDropdown = businessCardQuestions.map((questionGroup, i) => ({
		key: questionGroup.tag_name + i,
		value: questionGroup,
		text: questionGroup.tag_name,
	}))

	return (
		<Row>
			<Col>
				<div className='mb-4'>
					<h6>Objective</h6>
					<Dropdown
						clearable
						search
						selection
						placeholder='Select Objective'
						className='w-100'
						style={{ backgroundColor: '#0797a6' , fontWeight: '700', color: 'white' }}
						name='purpose'
						options={objectivesDropdown}
						value={gigState.purpose}
						onChange={(e, { name, value }) => onInputChange(name, value)}
					/>
				</div>
				<div className='mb-4'>
					<h6>Tactics</h6>
					<Dropdown
						clearable
						search
						selection
						multiple
						placeholder='Select Tactics'
						className='w-100'
						style={{ backgroundColor: '#0797a6' , fontWeight: '700', color: 'white' }}
						name='tactics'
						options={tacticsDropdown}
						value={gigState.tactics || []}
						onChange={(e, { name, value }) => onInputChange(name, value)}
					/>
				</div>
				<div className='mb-4'>
					<h6>Business Card Questions</h6>
					<Dropdown
						clearable
						search
						selection
						placeholder='Select Question Group'
						className='w-100'
						style={{ backgroundColor: '#0797a6' , fontWeight: '700', color: 'white' }}
						name='business_card_questions'
						options={businessCardQuestionsDropdown}
						value={gigState.business_card_questions}
						onChange={(e, { name, value }) => onInputChange(name, value)}
					/>
				</div>
			</Col>
			<Col>
				<h6>Additional Questions</h6>
			</Col>
		</Row>
	)
}
