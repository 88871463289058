import React from 'react'
import { map } from 'underscore'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import FDlogo from '../../assets/img/FD_running.png'
import internalImg from '../../assets/img/internal_staff_icon.png'
import phoneImg from '../../assets/img/phone_dial_icon.png'
import trainingImg from '../../assets/img/training_icon.png'

export default function ExecutionType({ onSelectExecution, selectedExecution }) {
	const options = {
		// 'Internal Staff': internalImg,
		'PHONE': phoneImg,
		'STREET': FDlogo,
		// 'TRAINING': trainingImg
	}

	return (
		<Row className='ex-container'>
			{map(options, (imgSrc, title) =>
				<Tile
					key={title}
					title={title}
					img={imgSrc}
					onImgClick={onSelectExecution}
					selectedExecution={selectedExecution}
				/>
			)}
		</Row>
	)
}

function Tile({ title, img, onImgClick, selectedExecution }) {
	return (
		<Col className='ex-tile'>
			<h3 className={`fd-green mb-5 mt-3 ${title === selectedExecution ? 'border-bottom border-danger' : ''}`}>{title}</h3>
			<img
				alt=''
				src={img}
				className='pointer'
				onClick={() => onImgClick(title)}
			/>
		</Col>
	)
}
