import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { Icon } from 'semantic-ui-react'

function QuestionGroupPreview({ group, onQuestionGroupClick, onDeleteQuestionGroup }) {
	console.log('group: ', group)

	return (
		<Card style={{ maxWidth: '40rem' }}>
			<Card.Header as='h4' className='d-flex justify-content-between'>
				{group.tag_name}
				<div>
					<Icon className='pointer' name='setting' onClick={() => onQuestionGroupClick(group)} />
					<Icon className='pointer' color='red' name='trash' onClick={() => console.log('group')} />
				</div>
			</Card.Header>
			<Card.Body>
				{group.business_card_questions.map((q, i, arr) => (
					<div key={i} className={`mb-3 ${i < arr.length - 1 ? 'border-bottom' : ''}`}>
						<div className='mb-3'>
							<div>
								<b className='font-weight-bold'>Question</b>&nbsp;
								(<em>{`Short answer ${q.short_answer_enabled ? 'enabled' : 'disabled'}`}, {`${q.character_min} Characters minimum`}</em>)
							</div>
							<div>{q.question}</div>
						</div>
						<div className='mb-3'>
							<div className='font-weight-bold'>Answers</div>
							{q.answers.map((answer, i) => <div key={i}>{i + 1}- {answer}</div>)}
						</div>
					</div>
				))}
			</Card.Body>
		</Card>
	)
}

export default QuestionGroupPreview
