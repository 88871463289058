import React from 'react'
import { Step, Icon } from 'semantic-ui-react'
import { map } from 'underscore'

function Steps({ currentStep, onStepClick }) {
	// const steps = {
	// 	Brand: 'Select brand',
	// 	Execution: 'Select execution type',
	// 	Outreach: 'Select outreach and campaign type',
	// 	'Pay & Date': 'Select pay rate and activity date',
	// 	Description: 'Add campaign notes',
	// 	Details: 'Add objective, tactics, and questions',
	// 	'User, Store, Route': 'Assign to user and store, and set route'
	// }

	const steps = [
		{ name: 'Brand', icon: 'briefcase' },
		{ name: 'Execution', icon: 'lightbulb' },
		{ name: 'Pay & Date', icon: 'calendar alternate outline' },
		{ name: 'Description', icon: 'edit' },
		{ name: 'Email', icon: 'mail' },
		{ name: 'Details', icon: 'clipboard outline' },
		{ name: 'User, Store, Targets', icon: 'building' }
		// { name: 'Targets', icon: 'point' }
	]

	// const steps = {
	// 	Brand: 'briefcase',
	// 	Execution: 'lightbulb',
	// 	// Outreach: '',
	// 	'Pay & Date': 'calendar alternate outline',
	// 	Description: 'edit',
	// 	// Details: 'columns',
	// 	// Details: 'table',
	// 	Details: 'clipboard outline',
	// 	'User, Store, Targets': 'building'
	// }

	return (
		<Step.Group size='mini' className='text-left'>
			{map(steps, (step, i) => {
				return (
				  <Step key={step.name} active={step.name === currentStep.name} onClick={() => onStepClick(i)}>
				  	<Icon name={step.icon} />
				    <Step.Content>
				      <Step.Title>{step.name}</Step.Title>
				      <Step.Description></Step.Description>
				    </Step.Content>
				  </Step>
				)
			})}
		</Step.Group>
	)
}

export default Steps

	// return (
	// 	<Step.Group ordered size='mini'>
	// 	  <Step completed>
	// 	    <Step.Content>
	// 	      <Step.Title>Shipping</Step.Title>
	// 	      <Step.Description>Choose your shipping options</Step.Description>
	// 	    </Step.Content>
	// 	  </Step>

	// 	  <Step completed>
	// 	    <Step.Content>
	// 	      <Step.Title>Billing</Step.Title>
	// 	      <Step.Description>Enter billing information</Step.Description>
	// 	    </Step.Content>
	// 	  </Step>

	// 	  <Step active>
	// 	    <Step.Content>
	// 	      <Step.Title>Confirm Order</Step.Title>
	// 	    </Step.Content>
	// 	  </Step>
	// 	</Step.Group>
	// )
