import React, { Component } from 'react'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

class PushNotification extends Component {
	state = {
		title: '',
		message: ''
	}

	handleInputChange = (event) => {
		const { name, value } = event.target

		this.setState({
			[name]: value
		})
	}

	render() {
		return (
			<Form onSubmit={e => e.preventDefault()}>
				<Col className='email-form-recipients' style={{marginBottom: '20px'}}>
					{this.props.selected.map(user => <span key={user.id}>{user.ambassador_name} {`<${user.email_address}>`}</span>)}
				</Col>
				<Form.Group>
					<Form.Label>Title (only applies to Andriod)</Form.Label>
					<Form.Control
						type='text'
						name='title'
						onChange={this.handleInputChange}
						value={this.state.title}
					/>
				</Form.Group>
				<Form.Group>
					<Form.Label>Message</Form.Label>
					<Form.Control
						as='textarea'
						rows='10'
						name='message'
						onChange={this.handleInputChange}
						value={this.state.message}
					/>
					<Form.Text className="text-muted">
						Double check your message. Clicking "Send" will send a mobile notification to all the ambassadors listed above!
					</Form.Text>
				</Form.Group>
				<Button
					variant='dark'
					onClick={() => this.props.onSendPushNotification(this.state)}
					disabled={this.isDisabled()}
				>
					Send
				</Button>
			</Form>
		)
	}

	isDisabled = () => {
		const { title, message } = this.state

		return title === '' || message === '' || this.props.selected.length === 0
	}
}

export default PushNotification
