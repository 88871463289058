import React, { Fragment, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import { Dropdown } from 'semantic-ui-react'
import { map, filter } from 'underscore'

import SIC_GROUPS from './PredefinedSicGroups'

function SicCodeSettings({ targetSettings, onInputChange }) {
	const [tempAddedCode, setTempAddedCode] = useState('')
	const [tempExcludedCode, setTempExcludedCode] = useState('')

	const targetGroupsDropdown = [
		// 'All',
		'Phone – SIC Cluster 1 Hospitality',
		'Street – SIC Cluster 1 Hospitality',
		'Canvassing (all SIC codes except some)',
		'All except 58, 70-81, 83-99',
	].map(name => ({
		key: name,
		value: name,
		text: name,
	}))

	return (
		<div>
			<Row className='mb-2'>
				<Col>
					SIC Code Type:
				</Col>
				<Col>
					<Form.Check
						type='radio'
						label='SIC Codes'
						checked={targetSettings.sicCodeType === 'sic_code_ids'}
						onChange={() => onInputChange('sicCodeType', 'sic_code_ids')}
					/>
				</Col>
				<Col>
					<Form.Check
						type='radio'
						label='Primary SIC Codes'
						checked={targetSettings.sicCodeType === 'primary_sic_code_id'}
						onChange={() => onInputChange('sicCodeType', 'primary_sic_code_id')}
					/>
				</Col>
			</Row>
			<Row className='mb-2'>
				<Col>
					<Dropdown
						clearable
						search
						selection
						placeholder='Select Predefined Target Group'
						className='w-100'
						options={targetGroupsDropdown}
						onChange={(e, { name, value }) => {
							if(!value) {
								onInputChange('codes', {
									// ...targetSettings.codes,
									addedCodes: [],
									excludedCodes: []
								})
							} else if(value.includes('except')) {
								onInputChange('codes', {
									excludedCodes: SIC_GROUPS[value],
									addedCodes: []
								})
							} else {
								onInputChange('codes', {
									addedCodes: SIC_GROUPS[value],
									excludedCodes: []
								})
							}
						}}
					/>
				</Col>
			</Row>

			<Row>
				<Col>
					<div className='input-box'>
						<div className='pl-2 pr-2 pt-1 d-flex justify-content-between'>
							<span>Add Code</span>
							<i
								className='fas fa-plus pointer'
								onClick={() => {
									if(!tempAddedCode) return

									onInputChange('codes', {
										...targetSettings.codes,
										addedCodes: targetSettings.codes && targetSettings.codes.addedCodes ? targetSettings.codes.addedCodes.concat(tempAddedCode.split(',')) : [].concat(tempAddedCode.split(','))
									})
									setTempAddedCode('')
								}}
							>
							</i>
						</div>
						<Form.Control
							value={tempAddedCode}
							onChange={e => setTempAddedCode(e.target.value)}
						/>
					</div>
				</Col>
				<Col>
					<div className='input-box'>
						<div className='pl-2 pr-2 pt-1 d-flex justify-content-between'>
							<span>Exclude Code</span>
							<i
								className='fas fa-plus pointer'
								onClick={() => {
									if(!tempExcludedCode) return

									onInputChange('codes', {
										...targetSettings.codes,
										excludedCodes: targetSettings.codes && targetSettings.codes.excludedCodes ? targetSettings.codes.excludedCodes.concat(tempExcludedCode.split(',')) : [].concat(tempExcludedCode.split(','))
									})
									setTempExcludedCode('')
								}}
							>
							</i>
						</div>
						<Form.Control
							value={tempExcludedCode}
							onChange={e => setTempExcludedCode(e.target.value)}
						/>
					</div>
				</Col>
			</Row>

			<Row>
				<Col>
					{targetSettings.codes && targetSettings.codes.addedCodes &&
						<Fragment>
							<div><b>Added Codes</b></div>
							<div style={{ height: '150px', overflow: 'auto' }}>
								{targetSettings.codes.addedCodes.map((code, i) =>
									<div key={i}>
										{code}&nbsp;-&nbsp;
										<span
											className='pointer text-danger'
											onClick={() => onInputChange('codes', {
												...targetSettings.codes,
												addedCodes: targetSettings.codes.addedCodes.filter(c => c !== code)
											})}
										>
											[X]
										</span>
									</div>
								)}
							</div>
						</Fragment>
					}
				</Col>
				<Col>
					{targetSettings.codes && targetSettings.codes.excludedCodes &&
						<Fragment>
							<div><b>Excluded Codes</b></div>
							<div style={{ height: '150px', overflow: 'auto' }}>
								{targetSettings.codes.excludedCodes.map((code, i) =>
									<div key={i}>
										{code}&nbsp;-&nbsp;
										<span
											className='pointer text-danger'
											onClick={() => onInputChange('codes', {
												...targetSettings.codes,
												excludedCodes: targetSettings.codes.excludedCodes.filter(c => c !== code)
											})}
										>
											[X]
										</span>
									</div>
								)}
							</div>
						</Fragment>
					}
				</Col>
			</Row>
		</div>
	)
}

export default SicCodeSettings
