import React, { useState } from 'react'
import { useAuth0 } from '../react-auth0-wrapper'

function TokenGenerator() {
	const [token, setToken] = useState('')
	const { getTokenSilently } = useAuth0()

	getTokenSilently({
	  audience: 'core',
	  scope: 'admin'
	})
	.then(res => setToken(res))

	return (
		<div style={{
			width: '50%',
			margin: 'auto',
			wordWrap: 'break-word'
		}}>
			{token}
		</div>
	)
}

export default TokenGenerator
