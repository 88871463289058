import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
// import LoadingBar from 'react-redux-loading'

// import SideBar from '../components/SideBar'
import { getAmbassadors } from '../actions/ambassadors'
import Loading from '../components/Loading'
import AmbassadorFilters from '../components/ambassadors/AmbassadorFilters'
import AmbassadorsTable from '../components/ambassadors/AmbassadorsTable'
import AmbassadorsMap from '../components/ambassadors/AmbassadorsMap'
import AmbassadorsGrid from '../components/ambassadors/AmbassadorsGrid'

class Ambassadors extends Component {
	componentDidMount() {
		this.getAmbassadorsData()
	}

	render() {
		const { view } = this.props

		return (
			<Fragment>
				{/*<SideBar />*/}
				{this.props.loading
					? <Loading />
					: <Col>
						<Card>
							<Card.Body>
								<AmbassadorFilters />
								{view === 'table' && <AmbassadorsTable />}
								{view === 'map' && <AmbassadorsMap />}
								{view === 'grid' && <AmbassadorsGrid />}
							</Card.Body>
							</Card>
						</Col>
				}
			</Fragment>
		)
	}

	getAmbassadorsData = () => {
		const { filters, pageIndex, pageSize, dispatch } = this.props
		const paramFilters = []

		if(filters.rejected) {
			paramFilters.push(`rejected=${filters.rejected}`)
		}

		if(!filters.rejected) {
			paramFilters.push(`approved=${filters.approved}`)
		}

		if(filters.searchString) {
			paramFilters.push(`searchString=${filters.searchString}`)
		}

		if(filters.registrationVideoUploaded) {
			paramFilters.push('registrationVideoUploaded=true')
		}

		if(filters.stripeSetup) {
			paramFilters.push('stripeSetup=true')
		}

		if(filters.zipCodes) {
			paramFilters.push(`zipCodes=${filters.zipCodeInput}`)
		}

		if(filters.radius) {
			paramFilters.push(`radiusInMiles=${filters.radiusInMiles}`)
		}

		if(filters.dmaNames) {
			paramFilters.push(`dmaNames=${filters.dmaNames}`)
		}

		const params = paramFilters.concat([`pageFrom=${pageIndex * pageSize}`], [`pageSize=${pageSize}`]).join('&')

		dispatch(getAmbassadors(params))
	}
}

function mapStateToProps(state) {
	return {
		loading: state.ambassadors.results ? false : true,
		view: state.ambassadors.view,
		filters: state.ambassadors.filters,
		pageIndex: state.ambassadors.pageIndex,
		pageSize: state.ambassadors.pageSize
	}
}

export default connect(mapStateToProps)(Ambassadors)
