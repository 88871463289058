import React, { Component, useState, useEffect, Fragment } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import ReactTable from 'react-table'
import moment from 'moment'
import _ from 'underscore'
import Badge from 'react-bootstrap/Badge'

import config from '../config'

function GigsTable(props) {
	const [ambassadorSearchStr, setAmbassadorSearchStr] = useState('')
	const [ambassadorSearchResults, setAmbassadorSearchResults] = useState([])

	useEffect(() => {
		if(!ambassadorSearchStr) {
			props.onAmbassadorFilter(null)
			setAmbassadorSearchResults([])
		}

		if(ambassadorSearchStr) {
			handleAmbssadorInput(ambassadorSearchStr)
		}
	}, [ambassadorSearchStr])

	const getAmbassadorName = (gig) => {
		const result = _.find(gig.applied_ambassadors, amb => amb.id === gig.brand_user_activity.field_day_management.field_day_workflow.accepted_ambassador_id)

		return result ? result : ''
	}

	const {
		loading,
		data,
		onGigClick,
		page,
		pageSize,
		onPageChange,
		onPageSizeChange,
		onFilterColumns,
		onSortColumns,
		brandNameSearchString,
		titleSearchString,
		addressSearchString,
		campaignIdSearchString,
		onAmbassadorFilter
	} = props

	if(!data) return null

	// const loading = data.results ? false : true

	const numOfPages = data.total <= pageSize ? 1 : Math.ceil(data.total / pageSize)

	if(page >= numOfPages) {
		onPageChange(numOfPages - 1)
	}

	// const tableStyle = {
	// 	fontSize: '13px',
	// 	fontWeight: '100',
	// 	border: '1px solid #C1C1C1'
	// }

	// const headerStyle = {
	// 	borderRight: 'none',
	// 	fontWeight: '600',
	// 	backgroundColor: '#f1f1f1',
	// 	padding: '8px',
	// 	textAlign: 'center',
	// 	borderBottom: '1px solid #C1C1C1'
	// }

	// const columnStyle = {
	// 	padding: '8px',
	// 	textAlign: 'center',
	// 	borderRight: '1px solid #C1C1C1'
	// }

	return (
		<ReactTable
			data={data.results}
			filterable
			manual
			className='-striped -highlight table-style'
			getTrProps={(state, rowInfo, instance) => {
			  if (rowInfo && rowInfo.original && rowInfo.original.brand_user_activity && rowInfo.original.brand_user_activity.field_day_operation_details.finished_gig) {
			    return {
			      style: {
			        background: '#96e883',
			      }
			    }
			  }
			  return {};
			}}
			// style={tableStyle}
			noDataText={'No gigs found'}
			loading={loading}
			pages={numOfPages}
			page={page}
			pageSize={pageSize}
			onPageChange={onPageChange}
			onPageSizeChange={onPageSizeChange}
			columns={[
				{
					Header: 'Brand',
					id: 'brandNameSearchString',
					filterName: 'brandNameSearchString',
					// accessor: d => <Link to={`/gig/${d.brand_user_activity.id}`}>{d.associated_brand.brand_name}</Link>,
					accessor: d => <span className='pointer' onClick={() => onGigClick(d)}>{d.associated_brand.brand_name}</span>,
					headerClassName: 'table-header',
					className: 'table-column',
					// filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase()),
					Filter: ({ filter, onChange }) => (
						<input
							type='text'
							placeholder='Filter'
							style={{ width:  '100%' }}
							// name='brandNameSearchString'
							value={brandNameSearchString}
							onChange={e => onFilterColumns('brandNameSearchString', e.target.value)}
						/>
					)
				},
				{
					Header: 'Title',
					id: 'titleSearchString',
					filterName: 'titleSearchString',
					// accessor: d => <Link to={`/gig/${d.brand_user_activity.id}`}>{d.brand_user_activity.title}</Link>,
					accessor: d => (
						<span className='pointer' onClick={() => onGigClick(d)}>
							{d.brand_user_activity.field_day_management.field_day_review && d.brand_user_activity.field_day_management.field_day_review.needs_review &&
								<Badge variant='warning' className='float-left mt-1'>R</Badge>
							}
							{d.brand_user_activity.title}
						</span>
					),
					headerClassName: 'table-header',
					className: 'table-column',
					// filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase()),
					Filter: ({ filter, onChange }) => (
						<input
							type='text'
							placeholder='Filter'
							style={{ width:  '100%' }}
							// name='brandNameSearchString'
							value={titleSearchString}
							onChange={e => onFilterColumns('titleSearchString', e.target.value)}
						/>
					)
				},
				{
					Header: 'Location',
					id: 'addressSearchString',
					filterName: 'addressSearchString',
					// accessor: d => <Link to={`/gig/${d.brand_user_activity.id}`}>{`${d.brand_user_activity.address}, ${d.brand_user_activity.city} ${d.brand_user_activity.state}`}</Link>,
					accessor: d => <span className='pointer' onClick={() => onGigClick(d)}>{`${d.brand_user_activity.address}, ${d.brand_user_activity.city} ${d.brand_user_activity.state}`}</span>,
					headerClassName: 'table-header',
					className: 'table-column',
					sortable: false,
					// filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase())
					Filter: ({ filter, onChange }) => (
						<input
							type='text'
							placeholder='Filter'
							style={{ width:  '100%' }}
							// name='brandNameSearchString'
							value={addressSearchString}
							onChange={e => onFilterColumns('addressSearchString', e.target.value)}
						/>
					)
				},
				{
					Header: 'Zip Code',
					id: 'zipCode',
					// accessor: d => <Link to={`/gig/${d.brand_user_activity.id}`}>{d.brand_user_activity.postal_code}</Link>,
					accessor: d => <span className='pointer' onClick={() => onGigClick(d)}>{d.brand_user_activity.postal_code}</span>,
					headerClassName: 'table-header',
					className: 'table-column',
					filterable: false,
					sortable: false,
				},
				{
					Header: 'Date',
					id: 'date',
					// accessor: d => <Link to={`/gig/${d.brand_user_activity.id}`}>{moment(d.brand_user_activity.due_date_millis).format('MMM Do, YYYY')}</Link>,
					accessor: d => (
						<span
							className='pointer'
							onClick={() => onGigClick(d)}
						>
							{d.brand_user_activity.time_zone
								? moment(d.brand_user_activity.due_date_millis).tz(d.brand_user_activity.time_zone).format('MM/DD/YY - h:mm A')
								: moment(d.brand_user_activity.due_date_millis).format('MM/DD/YY - h:mm A')
							}&nbsp;
							{d.brand_user_activity.time_zone_human}
						</span>
					),
					headerClassName: 'table-header',
					className: 'table-column',
					filterable: false,
					sortable: false,
				},
				{
					Header: 'Hours',
					id: 'hours',
					// accessor: d => <Link to={`/gig/${d.brand_user_activity.id}`}>{d.brand_user_activity.field_day_management.field_day_remuneration.max_hours}</Link>,
					accessor: d => <span className='pointer' onClick={() => onGigClick(d)}>{d.brand_user_activity.field_day_management.field_day_remuneration.max_hours}</span>,
					headerClassName: 'table-header',
					className: 'table-column',
					filterable: false,
					sortable: false,
					// filterMethod: (filter, row) => row[filter.id].props.children === filter.value
				},
				{
					Header: 'Rate/Hour',
					id: 'rateHour',
					// accessor: d => <Link to={`/gig/${d.brand_user_activity.id}`}>{`$${d.brand_user_activity.field_day_management.field_day_remuneration.ambassador_remuneration_rate_per_hour}`}</Link>,
					accessor: d => <span className='pointer' onClick={() => onGigClick(d)}>{`$${d.brand_user_activity.field_day_management.field_day_remuneration.ambassador_remuneration_rate_per_hour}`}</span>,
					headerClassName: 'table-header',
					className: 'table-column',
					filterable: false,
					sortable: false,
				},
				{
					Header: 'Ambassador Name',
					id: 'ambassadorName',
					accessor: d => <Link to={`/ambassador/${getAmbassadorName(d).id}`}>{getAmbassadorName(d).ambassador_name}</Link>,
					headerClassName: 'table-header',
					className: 'table-column',
					sortable: false,
					Filter: ({ filter, onChange }) => (
						<Fragment>
							<input
								type='text'
								placeholder='Search names'
								style={{ width:  '100%' }}
								// name='brandNameSearchString'
								value={ambassadorSearchStr}
								onChange={e => setAmbassadorSearchStr(e.target.value)}
							/>
							{ambassadorSearchResults.length > 0 &&
								<div
									style={{
										top: '0 !important',
										position: 'relative !important',
										textAlign: 'left',
										maxHeight: '150px',
										backgroundColor: 'white',
										padding: '10px',
										overflow: 'auto',
										border: '1px solid rgba(0,0,0,.125)',
										borderRadius: '7px',
										color: '#252525',
										fontWeight: '100',
									}}
								>
									{ambassadorSearchResults.map((res, i) => (
										<div key={i} className='search-result-item' onClick={() => onAmbassadorFilter(res.id)}>
											{res.ambassador_name}
										</div>
									))}
								</div>
							}
						</Fragment>
					)
				},
				{
					Header: 'Ambassador Phone',
					id: 'ambassadorPhone',
					accessor: d => <Link to={`/ambassador/${getAmbassadorName(d).id}`}>{getAmbassadorName(d).phone}</Link>,
					headerClassName: 'table-header',
					className: 'table-column',
					filterable: false,
					sortable: false,
				},
				{
					Header: 'Campaign ID',
					id: 'campaignIdSearchString',
					accessor: d => <span className='pointer' onClick={() => onGigClick(d)}>{d.brand_user_activity.campaign && d.brand_user_activity.campaign.campaign_id}</span>,
					headerClassName: 'table-header',
					className: 'table-column',
					sortable: false,
					Filter: ({ filter, onChange }) => (
						<input
							type='text'
							placeholder='Filter'
							style={{ width:  '100%' }}
							// name='campaignIdSearchString'
							value={campaignIdSearchString}
							onChange={e => onFilterColumns('campaignIdSearchString', e.target.value)}
						/>
					)
				},
			]}
			// onFilteredChange={onFilterColumns}
			onSortedChange={onSortColumns}
			// defaultSortMethod={(a, b, desc) => {
			// 	console.log(a, b, desc)
			// 	// force null and undefined to the bottom
			// 	a = a.props.children === null || a.props.children === undefined ? '' : a.props.children
			// 	b = b.props.children === null || b.props.children === undefined ? '' : b.props.children
			// 	// force any string values to lowercase
			// 	a = typeof a === 'string' ? a.toLowerCase() : a
			// 	b = typeof b === 'string' ? b.toLowerCase() : b
			// 	// Return either 1 or -1 to indicate a sort priority
			// 	if (a > b) {
			// 	  return 1
			// 	}
			// 	if (a < b) {
			// 	  return -1
			// 	}
			// 	// returning 0, undefined or any falsey value will use subsequent sorts or
			// 	// the index as a tiebreaker
			// 	return 0
			// }}
		/>
	)

	function handleAmbssadorInput() {
		axios.get(`${config.coreAPI}/fieldDayAdmin/ambassadors`, {
			params: {
				searchString: ambassadorSearchStr,
				approved: true,
				autoComplete: true,
				pageFrom: 0,
				pageSize: 20
			}
		})
		.then(res => setAmbassadorSearchResults(res.data.responses[0].results))
		.catch(err => console.error(err))
	}
}

export default GigsTable
