import axios from 'axios'
import { API } from "aws-amplify"
import { showLoading, hideLoading } from 'react-redux-loading'
// import { showLoading, hideLoading } from './loading'

import config from '../config'

export const RECEIVE_AMBASSADORS = 'RECEIVE_AMBASSADORS'
export const CHANGE_PAGESIZE = 'CHANGE_PAGESIZE'
export const CHANGE_PAGE = 'CHANGE_PAGE'
export const SORT_COLUMN = 'SORT_COLUMN'
export const TOGGLE_FILTERS = 'TOGGLE_FILTERS'
export const UPDATE_STATUS = 'UPDATE_STATUS'
export const UPDATE_TAGS = 'UPDATE_TAGS'
export const UPDATE_FILTER_INPUT = 'UPDATE_FILTER_INPUT'
export const RECEIVE_SIGNED_URL = 'RECEIVE_SIGNED_URL'
export const SET_AMBASSADORS_VIEW = 'SET_AMBASSADORS_VIEW'
export const RESET_FILTERS = 'RESET_FILTERS'

export function setView(view) {
	return {
		type: SET_AMBASSADORS_VIEW,
		view
	}
}

async function getAWSUrl(key) {
	if(!key) return

	try {
		const result = await API.post('getSignedUrl', '/getSignedUrl', {
			body: { key }
		})

		return result
	}
	catch(e) {
		console.error(e.response.data.status)
	}
}

export function _getAmbassadors(params, pageIndex, pageSize) {
	return (dispatch) => {
		dispatch(showLoading())

		return axios.get(`${config.coreAPI}/fieldDayAdmin/ambassadors?${params}`)
			.then(res => {
				dispatch(receiveAmbassadors(res.data.responses[0]))

				pageIndex >= 0 && dispatch(handlePageChange(pageIndex))
				pageIndex >= 0 && pageSize && dispatch(handlePageSizeChange(pageIndex, pageSize))

				res.data.responses[0].results.forEach((user, i) => user.profile_photos && dispatch(getSignedUrl(user, i)))
			})
			.then(() => dispatch(hideLoading()))
			.catch(err => alert(err.response.data.message))
	}
}

// to be refactored and replaced with above action
export function getAmbassadors(params, pageIndex, pageSize) {
	return (dispatch) => {
		dispatch(showLoading())

		return axios.get(`${config.coreAPI}/fieldDayAdmin/ambassadors?${params}`)
			.then(res => {
				dispatch(receiveAmbassadors(res.data.responses[0]))
				res.data.responses[0].results.forEach((user, i) => user.profile_photos && dispatch(getSignedUrl(user, i)))
			})
			.then(() => dispatch(hideLoading()))
			.catch(err => alert(err.response.data.message))
	}
}

export function receiveAmbassadors(ambassadors) {
	return {
		type: RECEIVE_AMBASSADORS,
		ambassadors
	}
}

export function toggleFilters(open) {
	return {
		type: TOGGLE_FILTERS,
		open
	}
}

export function handlePageSizeChange(pageIndex, pageSize, total) {
	const numOfPages = total <= pageSize ? 1 : Math.ceil(total / pageSize)
	const newPageIndex = numOfPages <= pageIndex ? numOfPages - 1 : pageIndex

	return {
		type: CHANGE_PAGESIZE,
		pageIndex: newPageIndex,
		pageSize
	}
}

export function handlePageChange(pageIndex) {
	return {
		type: CHANGE_PAGE,
		pageIndex
	}
}

export function handleSortColumns(newSorted, column, shiftKey) {
	const sortField = newSorted[0].id
	const sortDirection = newSorted[0].desc ? 'desc' : 'asc'

	return {
		type: SORT_COLUMN,
		sortField,
		sortDirection
	}
}

export function handleStatusChange(status, rejected) {
	return (dispatch, getState) => {
		dispatch(resetFilters())
		dispatch(updateStatus(status, rejected))
	}
}

function updateStatus(status, rejected) {
	const approved = status === 'approved' ? true : false

	return {
		type: UPDATE_STATUS,
		approved,
		rejected: rejected ? true : false
	}
}

export function updateTags(tag) {
	return {
		type: UPDATE_TAGS,
		tag
	}
}

export function updateFilterInput(event) {
	const target = event.target
	const name = target.name
	let value = target.type === 'checkbox' ? target.checked : target.value

	return {
		type: UPDATE_FILTER_INPUT,
		name,
		value
	}
}

export function getSignedUrl(user, index) {
	return (dispatch) => {
		const promises = [
			getAWSUrl(user.profile_photos[0].photo_url),
			getAWSUrl(user.registration_video_url)
		]

		return Promise.all(promises)
		 .then(res => {
		 	dispatch(receiveSignedUrl({
		 		profileImage: res[0],
		 		profileVideo: res[1]
		 	}, index))
		 })
	}
}

function receiveSignedUrl(urls, index) {
	return {
		type: RECEIVE_SIGNED_URL,
		index,
		urls,
	}
}

export function resetFilters() {
	return {
		type: RESET_FILTERS
	}
}
