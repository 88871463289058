import React, { Fragment } from 'react'
import Col from 'react-bootstrap/Col'
import ReactTable from 'react-table'
import { find } from 'underscore'

function PlacesTable({ places, count, page, pageSize, onPageChange, onPageSizeChange }) {
	const numOfPages = count <= pageSize ? 1 : Math.ceil(count / pageSize)

	if(page >= numOfPages) {
		onPageChange(numOfPages - 1)
	}

	return (
		<Fragment>
			<ReactTable
				data={places}
				manual
				className='-striped -highlight table-style'
				noDataText={'No places found'}
				defaultPageSize={20}
				sortable={false}
				// loading={loading}
				// filterable
				pages={numOfPages}
				page={page}
				pageSize={pageSize}
				onPageChange={onPageChange}
				onPageSizeChange={onPageSizeChange}
				columns={[
					{
						Header: <span>Map ID</span>,
						id: 'index',
						width: 70,
						headerClassName: 'table-header',
						className: 'table-column',
						// filterMethod: (filter, row) => row[filter.id].props.children === Number(filter.value),
						accessor: d => {
							let index

							find(places, (place, i, col) => {
								if(place.infogroup_id === d.infogroup_id) {
									index = i
								}
							})

							return index + 1
						}
					},
					{
						Header: 'InfoGroup ID',
						id: 'infoGroupId',
						headerClassName: 'table-header',
						className: 'table-column',
						// filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase()),
						accessor: d => <span className='text-left'>{d.infogroup_id}</span>
					},
					{
						Header: 'Place',
						id: 'name',
						headerClassName: 'table-header',
						className: 'table-column',
						// filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase()),
						accessor: d => <span className='text-left'>{d.name}</span>
					},
					{
						Header: 'Place Type',
						id: 'placeType',
						headerClassName: 'table-header',
						className: 'table-column',
						// filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase()),
						accessor: d => <span className='text-left'>{d.place_type}</span>
					},
					{
						Header: 'Employee Size',
						id: 'employeeSize',
						headerClassName: 'table-header',
						className: 'table-column',
						// filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase()),
						accessor: d => <span className='text-left'>{d.estimated_location_employee_count}</span>
					},
				]}
				defaultSortMethod={(a, b, desc) => {
					// force null and undefined to the bottom
					a = a.props.children === null || a.props.children === undefined ? '' : a.props.children
					b = b.props.children === null || b.props.children === undefined ? '' : b.props.children
					// force any string values to lowercase

					a = typeof a === 'string' ? a.toLowerCase().replace(/,/g, '').replace(/\$/g, '') : a
					b = typeof b === 'string' ? b.toLowerCase().replace(/,/g, '').replace(/\$/g, '') : b

					if(Number(a) || Number(b)) {
						a = Number(a)
						b = Number(b)
					}

					// Return either 1 or -1 to indicate a sort priority
					if (a > b) {
					  return 1
					}
					if (a < b) {
					  return -1
					}
					// returning 0, undefined or any falsey value will use subsequent sorts or
					// the index as a tiebreaker
					return 0
				}}
			/>
		</Fragment>
	)
}

export default PlacesTable
