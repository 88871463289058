import React, { useState, useEffect, useRef } from 'react'
import { Col, Row, Form } from 'react-bootstrap'

import FilterRelations from './FilterRelations'

function EmployeeCountFilter({ filterName, onSetRelation, relation, onSetAttribute, onSetValue, value }) {
	const [input1, setInput1] = useState('')
	const [input2, setInput2] = useState('')
	const relationOptions = ['between', 'equals', 'greater_than', 'less_than', 'in', 'present', 'missing']

	useEffect(() => {
		onSetAttribute(filterName)

		if(relation === 'between') {
			onSetValue([input1, input2])
		} else {
			onSetValue(input1)
		}
	}, [input1, input2])

	const handleInputChange = (e) => {
		const { name, value } = e.target

		if(name === 'input1') {
			setInput1(value)
		} else {
			setInput2(value)
		}
	}

	return (
		<Row>
			<Col>
				<FilterRelations relationOptions={relationOptions} onSelectRelation={onSetRelation} />
			</Col>
			<Col>
				{relation === 'between' &&
					<div className='d-flex align-items-center'>
						<Form.Control
							name='input1'
							value={input1}
							onChange={handleInputChange}
						/>
						&nbsp;and&nbsp;
						<Form.Control
							name='input2'
							value={input2}
							onChange={handleInputChange}
						/>
					</div>
				}
				{(relation === 'equals' || relation === 'greater_than' || relation === 'less_than') &&
					<Form.Control
						name='input1'
						value={input1}
						onChange={handleInputChange}
					/>
				}
			</Col>
		</Row>
	)
}

export default EmployeeCountFilter
