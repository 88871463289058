import React, { useEffect, useState } from 'react'
import axios from 'axios'
import moment from 'moment-timezone'
import { map, each, find, findIndex, reduce } from 'underscore'
import { Icon, Modal, Button as SemanticButton } from 'semantic-ui-react'
import Button from 'react-bootstrap/Button'

import '../../styles/createCampaign.css'
import config from '../../config'
import Steps from './Steps'
import Brands from './Brands'
import Execution from './Execution'
import PayDate from './PayDate'
import Description from './Description'
import Email from './Email'
import WYSIWYG from '../WYSIWYG'
import Details from './Details'
import UsersStores from './UsersStores'
import StoreTargetList from './StoreTargetList'

function CampaignModal({ showModal, onClose }) {
	const [currentStep, setCurrentStep] = useState(0)
	const [brandName, setBrandName] = useState('')
	const [brandId, setBrandId] = useState('')
	const [selectedBrand, setSelectedBrand] = useState(null)
	const [campaigns, setCampaigns] = useState([])
	const [trainings, setTrainings] = useState([])
	const [users, setUsers] = useState([])
	const [stores, setStores] = useState([])
	const [showSecondModal, setShowSecondModal] = useState(false)
	const [secondModalTitle, setSecondModalTitle] = useState('')
	const [secondModalName, setSecondModalName] = useState('')
	const [selectedStoreIndexForSecondModal, setSelectedStoreIndexForSecondModal] = useState(null)
	const [gettingTargetCount, setGettingTargetCount] = useState(false)
	const [creatingCampaign, setCreatingCampaign] = useState(false)

	const [steps, setSteps] = useState([
		{ name: 'Brand', completed: false },
		{ name: 'Execution', completed: false },
		{ name: 'Pay & Date', completed: false },
		{ name: 'Description', completed: false },
		{ name: 'Email', completed: false },
		{ name: 'Details', completed: false },
		{ name: 'User, Store, Targets', completed: false },
	])

	const [gigState, setGigState] = useState({
		single_activity: false,
		one_activity_per_recipient_store: true,
		selectedStores: [],
		attachments: [],
		sicGroup: [],
		targetsEmployeeSize: 10,
		addedSicCodes: [],
		excludedSicCodes: [],
		dynamicEmailTemplateData: [],
		withPhone: true,
		newCampaign: true,
	})

	// useEffect(() => {
	// 	if(brandId) {
	// 		setCurrentStep('Execution')
	// 	}
	// }, [brandId])

	// const handleCompleteStep = (step, completed) => {
	// 	setSteps({ ...steps, [step]: completed })
	// }

	useEffect(() => {
		getBrand()
		getCampaigns()
		getTrainings()
		getUsers()
		getStores()
	}, [brandId])

	useEffect(() => {
		getTargetsCount()
	}, [gigState.getTargets])

	const getBrand = async () => {
		if(!brandId) return

		try {
			const brand = await axios.get(`${config.coreAPI}/brand/${brandId}`)

			setSelectedBrand(brand.data.responses[0].results[0])
		} catch(e) {
			console.error(e)
		}
	}

	const getCampaigns = async () => {
		if(!brandId) return

		try {
			const campaigns = await axios.get(`${config.coreAPI}/activity/campaigns?brandId=${brandId}`)

			setCampaigns(campaigns.data)
		} catch(e) {
			console.error(e)
		}
	}

	const getTrainings = async () => {
		if(!brandId) return

		try {
			const trainings = await axios.get(`${config.coreAPI}/fieldDayAdmin/training/plans?brandId=${brandId}&pageFrom=0&pageSize=1000`)

			setTrainings(trainings.data)
		} catch(e) {
			console.error(e)
		}
	}

	const getUsers = async () => {
		if(!brandId) return

		try {
			const users = await axios.get(`${config.coreAPI}/users?brandId=${brandId}&pageSize=10000`)

			setUsers(users.data.users)
		} catch(e) {
			console.error(e)
		}
	}

	const getStores = async () => {
		if(!brandId) return

		try {
			const stores = await axios.get(`${config.coreAPI}/stores?brandId=${brandId}&pageSize=10000`)

			setStores(stores.data.stores)
		} catch(e) {
			console.error(e)
		}
	}

	const getTargetsCount = async () => {
		if(!gigState.selectedStores.length) return
		// if(!gigState.sicGroup.length) return
		if(!gigState.targetsRadius) return
		if(!gigState.targetsEmployeeSize) return

		try {
			setGettingTargetCount(true)

			const results = gigState.selectedStores.map(async (store, i) => {
				let filters

				if(gigState.sicGroup === 'all' || gigState.excludedSicCodes.length) {
					if(gigState.excludedSicCodes.length) {
						filters = [
							{
								relation: 'geo_distance',
								value: {
									distance: gigState.targetsRadius + 'mi',
									lat: store.loc_point[1],
									lon: store.loc_point[0]
								}
							},
							{
								attribute: 'estimated_location_employee_count',
								relation: 'greater_than',
								value: gigState.targetsEmployeeSize
							},
							{
								attribute: 'sic_code_ids',
								relation: 'in',
								value: gigState.excludedSicCodes,
								negated: true
							},
							{
								attribute: 'phone',
								relation: gigState.withPhone ? 'present' : 'in',
							},
						]
					} else {
						filters = [
							{
								relation: 'geo_distance',
								value: {
									distance: gigState.targetsRadius + 'mi',
									lat: store.loc_point[1],
									lon: store.loc_point[0]
								}
							},
							{
								attribute: 'estimated_location_employee_count',
								relation: 'greater_than',
								value: gigState.targetsEmployeeSize
							},
							{
								attribute: 'phone',
								relation: gigState.withPhone ? 'present' : 'in',
							},
						]
					}
				} else {
					filters = [
						{
							relation: 'geo_distance',
							value: {
								distance: gigState.targetsRadius + 'mi',
								lat: store.loc_point[1],
								lon: store.loc_point[0]
							}
						},
						{
							attribute: 'sic_code_ids',
							relation: 'in',
							value: gigState.sicGroup.concat(gigState.addedSicCodes)
						},
						{
							attribute: 'estimated_location_employee_count',
							relation: 'greater_than',
							value: gigState.targetsEmployeeSize
						},
						{
							attribute: 'phone',
							relation: gigState.withPhone ? 'present' : 'in',
						},
					]
				}

				// const response = await axios.post(`http://localhost:3064/fieldday/infoGroup?limit=400&offset=0`, {
				const response = await axios.post(`https://dev.fieldday.app/fieldday/infoGroup?limit=400&offset=0`, {
					filters,
					filters2: [],
				})

				const newState = [...gigState.selectedStores]

				newState[i].targetsCount = response.data.count
				newState[i].places = response.data.documents
				newState[i].placesCount = response.data.count

				updateGigState('selectedStores', newState)
			})

			Promise.all(results).then(values => {
				setGettingTargetCount(false)
			})

		} catch(e) {
			setGettingTargetCount(false)
			console.error(e)
		}
	}

	const updateGigState = (field, value) => {
		let newState = {}
		// const newState = Object.assign({}, gigState, { [field]: value })

		if(typeof field === 'object') {
			const tempState = reduce(field, (accum, val, key) => {
				accum[key] = val
				return accum
			}, {})

			newState = { ...gigState, ...tempState }
		} else {
			newState = { ...gigState, [field]: value }
		}

		if(field === 'assigned_to_field_day') {
			newState.phoneGig = false
		}

		if(field === 'phoneGig') {
			newState.assigned_to_field_day = true
		}

		setGigState(newState)
	}

	const handleStepClick = (i) => {
		setCurrentStep(i)
	}

	const handleBrandSelect = (name, value) => {
		if(name === 'brandId') {
			setBrandId(value)
		} else {
			setBrandName(value)
		}
	}

	const handleOpenSecondModal = (title, name, index) => {
		console.log(title, name)

		if(name === 'targets') {
			setSelectedStoreIndexForSecondModal(index)
		}

		setSecondModalTitle(title)
		setSecondModalName(name)
		setShowSecondModal(true)
	}

	const handleCreateCampaign = async () => {
		// TODO; move validation to its own function
		if(!brandId) {
			alert('Select brand')
			return
		}

		if(!gigState.dueDates || !gigState.dueDates.length) {
			alert('Select due date')
			return
		}

		if(!gigState.title) {
			alert('Title is required')
			return
		}

		if(typeof gigState.assigned_to_field_day === 'undefined') {
			alert('Select execution')
			return
		}

		if(!gigState.title) {
			alert('Title is required')
			return
		}

		if(!gigState.selectedStores.length) {
			alert('Select stores')
			return
		}

		if(!gigState.assignedUser) {
			alert('Assign user')
			return
		}

		if(gigState.useInfoGroupTargets && !gigState.phoneGig && (!selectedBrand.activity_route_settings || !selectedBrand.activity_route_settings[0].max_destination_count)) {
			alert('Activity route settings are not set for this brand.')
			return
		}

		if(gigState.useInfoGroupTargets && gigState.assigned_to_field_day && !gigState.phoneGig) {
			const filteredStores = gigState.selectedStores.filter(store => store.targetsCount < gigState.dueDates.length * selectedBrand.activity_route_settings[0].max_destination_count)

			if(filteredStores.length) {
				alert(`Based on route settings and number of gigs, each store must have at least ${gigState.dueDates.length * selectedBrand.activity_route_settings[0].max_destination_count} targets or more for routing to work. Either remove those stores from the list or change settings to pass the threshold.`)
				return
			}
		}

		if(gigState.assigned_to_field_day) {
			if(!gigState.useInfoGroupTargets) {
				const confirm = window.confirm('You are creating a campaign without using targets. If you want to use Targets, make sure you select "Use these targets" option. Proceed without using targets?')

				if(!confirm) return
			}

			if(gigState.useInfoGroupTargets && !gigState.maxTargets) {
				alert('You must select maximum number of targets')
				return
			}
		}

		if(gigState.send_follow_up_email) {
			if(!selectedBrand.sendgrid_api_key) {
				alert("The brand you have selected does not have a SendGrid API key. Please head over to brand's profile page and generate an API key for this brand")
				return
			}

			if(!gigState.emailTemplateId) {
				alert('Template ID required')
				return
			}

			if(!gigState.sender_email_address || !validateEmail(gigState.sender_email_address)) {
				alert('Sender email address not valid')
				return
			}

			if(!gigState.email_subject) {
				alert('Email subject required')
				return
			}
		}

		const body = {
			one_activity_per_recipient_store: gigState.one_activity_per_recipient_store,
			single_activity: gigState.single_activity,
			recipient_to_stores_map: { [gigState.assignedUser]: gigState.selectedStores.map(store => store.store_id) },
			activity_data: {
				assigned_to_field_day: gigState.assigned_to_field_day,
				attachments: gigState.attachments,
				title: gigState.title,
				description: gigState.description,
				best_practices: gigState.best_practices,
				brand_details: gigState.brand_details,
				script: gigState.script,
				brand_id: brandId,
				created_by_username: 'fieldday',
				// due_date: moment(gigState.dueDates[0]).tz('America/Los_Angeles').format('YYYY-MM-DD HH:mm:ss'),
				due_date_millis: gigState.dueDates[0],
				jira_issue_id: gigState.jira_issue_id,
				purpose: gigState.purpose,
				tactics: gigState.tactics,
				// questions: gigState.questions,
				request_time_zone: moment.tz.guess(),
				training_plan_id: gigState.training_plan_id,
				type: 'ACTIVITY'
			}
		}

		if(gigState.send_follow_up_email) {
			body.activity_data.send_follow_up_email = gigState.send_follow_up_email

			body.activity_data.sendgrid_data = {
				template_id: gigState.emailTemplateId,
				sender_name: gigState.sender_name,
				sender_email_address: gigState.sender_email_address,
				email_subject: gigState.email_subject,
				reply_to: gigState.reply_to,
				template_data: gigState.dynamicEmailTemplateData.length
					? gigState.dynamicEmailTemplateData.reduce((accum, el) => {
							accum[el[0]] = el[1]
							return accum
						}, {})
					: null,
			}
		}

		if(gigState.dueDates && gigState.dueDates.length > 1) {
			body.activity_data.batch_settings = {
				assign_as_group: gigState.isMasterGig,
        activity_timestamps_seconds: gigState.dueDates.map(time => time / 1000)
			}
		}

		if(gigState.newCampaign === true) {
			body.activity_data.campaign = {
				campaign_name: gigState.campaign_name,
				// objective: gigState.purpose,
				// tactics: gigState.tactics,
				// field_strategy: gigState.field_strategy
			}
		}

		if(gigState.newCampaign === false) {
			body.activity_data.campaign = {
				campaign_id: gigState.campaign_id
			}
		}

		if(gigState.assigned_to_field_day) {
			if(gigState.phoneGig) {
				body.activity_data.execution_type = 'PHONE'
			}

			if(!gigState.phoneGig) {
				body.activity_data.execution_type = 'STREET'
			}

			body.activity_data.field_day_activity_settings = {
				business_card_questions: (gigState.business_card_questions && gigState.business_card_questions.business_card_questions) || null
			}

			body.activity_data.remuneration_rate_per_hour = gigState.remuneration_rate_per_hour
      body.activity_data.max_hours = gigState.max_hours
		}

		try {
			setCreatingCampaign(true)

			// TODO; create S3 upload flow internally for central
			const attachmentPromises = gigState.attachments.map(async (attachment) => {
				// TODO; FIX THIS! SHOULD NOT BE DOING THIS!
				const token = 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJlYzY3OTc4MzIyZTVmYmQ3NjA0ZThkYjJhZDBlODlhYjFmYzRiN2RlZTBhOWE5NjU1ZmI2NjJiOGFlNjQ3ZTcxIn0.gAarGBEnQf11oc8h2bfyaiLoBYGOiaj_Lrjb-KV_SL5GZECE7j50wegLkI7ea1RfNAZBhFFa6LV4IPJQ7I3rjA'

				const attachmentsUploadUrl = process.env.REACT_APP_STAGE === 'dev' ? `https://dev.fieldday.app/attachments?attachmentToken=${token}` : `https://platform.fieldday.app/attachments?attachmentToken=${token}`
				// const attachmentsUploadUrl = process.env.REACT_APP_STAGE === 'dev' ? `http://localhost:3064/attachments?attachmentToken=${token}` : `https://platform.fieldday.app/attachments?attachmentToken=${token}`

				const formData = new FormData()
				formData.append('attachment', attachment)
				formData.append('public', true)

				const attachmentResult = await axios.post(attachmentsUploadUrl, formData, {
					headers: {
						'content-type': 'multipart/form-data',
					}
				})

				return attachmentResult
			})

			// TODO; make this whole flow better
			Promise.all(attachmentPromises)
				.then(async (attachmentValues) => {
					body.activity_data.attachments = attachmentValues.map(val => val.data)

					const response = await axios.post(`${config.coreAPI}/activities/create`, body)

					alert(`Campaign was successfully created. ${gigState.useInfoGroupTargets ? 'Adding targets now for amassador gigs' : ''}`)

					if(gigState.useInfoGroupTargets) {
						handleTargetsUpload(response.data)
					} else {
						onClose()
					}
				})
				.catch(err => {
					alert('Error in creating campaign: ' + err)
					console.error(err)
					setCreatingCampaign(false)
				})
		} catch(e) {
			alert('Error in creating campaign: ' + e)
			setCreatingCampaign(false)
			console.error(e)
		}
	}

	const validateEmail = (email) => {
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    return regex.test(email)
	}

	const handleTargetsUpload = async (storeActivities) => {
		if(gigState.assigned_to_field_day) {
			const promises = map(storeActivities, (store, storeId) => {
				const activityIds = store.filter(act => !act.master).map(act => act.id).join('|')

				return getTargetsForStore(storeId, activityIds)
			})

			Promise.all(promises)
			.then(res => {
				setCreatingCampaign(false)
				onClose()
			})
			.catch(e => console.error(e))
		}
	}

	const getTargetsForStore = async (storeId, activityIds) => {
		if(!gigState.selectedStores.length) return alert('Select stores')
		if(!gigState.sicGroup) return alert('Select target group')

		try {
			const store = find(gigState.selectedStores, s => s.store_id === Number(storeId))
			const storeIndex = findIndex(gigState.selectedStores, s => s.store_id === Number(storeId))

			let filters

			if(gigState.sicGroup === 'all' || gigState.excludedSicCodes.length) {
				if(gigState.excludedSicCodes.length) {
					filters = [
						{
							relation: 'geo_distance',
							value: {
								distance: gigState.targetsRadius + 'mi',
								lat: store.loc_point[1],
								lon: store.loc_point[0]
							}
						},
						{
							attribute: 'estimated_location_employee_count',
							relation: 'greater_than',
							value: gigState.targetsEmployeeSize
						},
						{
							attribute: 'sic_code_ids',
							relation: 'in',
							value: gigState.excludedSicCodes,
							negated: true
						},
						{
							attribute: 'phone',
							relation: gigState.withPhone ? 'present' : 'in',
						},
					]
				} else {
					filters = [
						{
							relation: 'geo_distance',
							value: {
								distance: gigState.targetsRadius + 'mi',
								lat: store.loc_point[1],
								lon: store.loc_point[0]
							}
						},
						{
							attribute: 'estimated_location_employee_count',
							relation: 'greater_than',
							value: gigState.targetsEmployeeSize
						},
						{
							attribute: 'phone',
							relation: gigState.withPhone ? 'present' : 'in',
						},
					]
				}
			} else {
				filters = [
					{
						relation: 'geo_distance',
						value: {
							distance: gigState.targetsRadius + 'mi',
							lat: store.loc_point[1],
							lon: store.loc_point[0]
						}
					},
					{
						attribute: 'sic_code_ids',
						relation: 'in',
						value: gigState.sicGroup.concat(gigState.addedSicCodes)
					},
					{
						attribute: 'estimated_location_employee_count',
						relation: 'greater_than',
						value: gigState.targetsEmployeeSize
					},
					{
							attribute: 'phone',
							relation: gigState.withPhone ? 'present' : 'in',
						},
				]
			}
			console.log('GET TARGETS', store)
			if(gigState.maxTargets > 400 && store.placesCount > 400) {

				// const scanRes = await axios.post(`http://localhost:3064/fieldday/infoGroup?scan=true`, {
				const scanRes = await axios.post(`https://dev.fieldday.app/fieldday/infoGroup?scan=true`, {
					filters,
					filters2: []
				})

				const scrollId = scanRes.data.scroll_id
				// const scanCount = scanRes.data.count
				const limit = 50
				// const numOfPages = Math.ceil(scanCount / limit)
				const numOfPages = Math.ceil(gigState.maxTargets / limit)
				let finalResults = []

				for(let i = 0; i < numOfPages; i++) {
					// const places = await axios.post(`http://localhost:3064/fieldday/infoGroup?scan=true&scrollId=${scrollId}&fullRecord=true`, {
					const places = await axios.post(`https://dev.fieldday.app/fieldday/infoGroup?scan=true&scrollId=${scrollId}&fullRecord=true`, {
						filters,
						filters2: []
					})

					finalResults = finalResults.concat(places.data)

					if(i === numOfPages - 1) {
						console.log('last scan call: ', finalResults)

						const newState = [...gigState.selectedStores]

						newState[storeIndex].targets = finalResults

						updateGigState('selectedStores', newState)

						if(gigState.phoneGig) {
							uploadPhoneTargets(activityIds, finalResults)
						}

						if(!gigState.phoneGig) {
							uploadStreetTargets(activityIds, finalResults, store)
						}
					}
				}

			} else {
				const response = await axios.post(`https://dev.fieldday.app/fieldday/infoGroup?limit=${gigState.maxTargets}&offset=0&exportList=true`, {
					filters,
					filters2: []
				})

				const newState = [...gigState.selectedStores]

				newState[storeIndex].targets = response.data.documents

				updateGigState('selectedStores', newState)

				if(gigState.phoneGig) {
					uploadPhoneTargets(activityIds, response.data.documents)
				}

				if(!gigState.phoneGig) {
					uploadStreetTargets(activityIds, response.data.documents, store)
				}
			}

		} catch(e) {
			alert(`Error in fetching places from InfoGroup for store ${storeId}: ` + e)
			console.error(e)
		}
	}

	const uploadStreetTargets = async (activityIds, streetTargets, store) => {
		if(!selectedBrand.activity_route_settings) {
			alert('Route settings are not set on brand. Set them on playbook')
			return
		}

		const formData = new FormData()
		const csvFile = getCsvFile(streetTargets,  store)

		formData.append('custom_places_csv', csvFile)
		formData.append('settings', JSON.stringify(selectedBrand.activity_route_settings[0]))

		try {
			await axios.post(`${config.coreAPI}/route/customJob/submit?activityIds=${activityIds}`, formData, {
				headers: {
					'content-type': 'multipart/form-data'
				}
			})
		} catch(e) {
			alert(`Error in uploading street targets for ${activityIds}: ` + e)
		}
	}

	const getCsvFile = (data, store) => {
		const header = ['Business', 'State', 'City', 'Address', 'Zip', 'Lat', 'Lon']
		const rows = data.map(d => (
			[
				d.name ? d.name.replace(/,/g, '') : '',
				d.state ? d.state.replace(/,/g, '') : '',
				d.city ? d.city.replace(/,/g, '') : '',
				d.street ? d.street.replace(/,/g, '') : '',
				d.zip ? d.zip.replace(/,/g, '') : '',
				d.latitude ? d.latitude + '' : '',
				d.longitude ? d.longitude + '' : '',
			]
		))

		rows.unshift(header)

		// let csvFileDownload = 'data:text/csv;charset=utf-8,'
		let csvContent = ''

		rows.forEach(r => {
			let row = r.join(',')
			csvContent += row + '\n'
		})

		// console.log(csvContent)
		// window.open(encodeURI('data:text/csv;charset=utf-8,' + csvContent))
		const uri = 'data:text/csv;charset=utf-8,' + csvContent

		const downloadLink = document.createElement('a')
		downloadLink.href = uri
		downloadLink.download = `${selectedBrand.brand_name}_${store.store_name}_${store.store_id}.csv`

		document.body.appendChild(downloadLink)
		downloadLink.click();
		document.body.removeChild(downloadLink)

		const csvFile = new Blob([csvContent], { type: 'text/csv' })

		return csvFile
	}

	const uploadPhoneTargets = async (activityIds, d) => {
		const temp = d.map(r => Object.assign({}, r))
		const phoneTargets = temp.map(record => {
			if(record.labels) {
				record.labels.sic_code_categories = record.labels.sic_code_ids
				record.labels.primary_sic_code_category = record.labels.primary_sic_code_id
			}

			return record
		})

		const formData = new FormData()
		const json = JSON.stringify(phoneTargets)
		const jsonFile = new Blob([json], { type: 'application/json' })

		formData.append('places', jsonFile)

		try {
			await axios.post(`${config.coreAPI}/phone/activities/targets?activityIds=${activityIds}`, formData)
		} catch(e) {
			alert(`Error in uploading phone targets for ${activityIds}: ` + e)
			console.log(e)
		}
	}

	console.log('ACTIVITY DATA: ', gigState)
	console.log('selectedBrand: ', selectedBrand)

	return (
		<Modal
		  className='semantic-modal'
		  open={showModal}
		  size='large'
		  dimmer={'blurring'}
		  onClose={onClose}
		  closeOnEscape={false}
		  closeOnDimmerClick={false}
		>
			<Modal.Header style={{ borderBottom: '1px solid #1a98a6' }}>
				<div className='d-flex justify-content-between text-info'>
					<span>Create New Campaign</span>
					<span className='pointer' onClick={onClose}>X</span>
				</div>
			</Modal.Header>
			<Modal.Content style={{ height: '300px' }}>
        <Modal.Description>
        	{steps[currentStep].name === 'Brand' &&
        		<Brands
        			onBrandSelect={handleBrandSelect}
        			selectedBrandName={brandName}
        			selectedBrandId={brandId}
      			/>
        	}
        	{steps[currentStep].name === 'Execution' &&
        		<Execution
        			onClick={updateGigState}
        			selectedExecution={(() => {
        				if(gigState.phoneGig) return 'Phone Ambassadors'
        				if(!gigState.phoneGig && gigState.assigned_to_field_day) return 'Field Day Ambassadors'
        				if(gigState.phoneGig === false && gigState.assigned_to_field_day === false) return 'Internal Staff'
        			})()}
        			// onCompleteStep={handleCompleteStep}
      			/>
      		}
      		{steps[currentStep].name === 'Pay & Date' &&
        		<PayDate
        			onInputChange={updateGigState}
        			gigState={gigState}
        		/>
      		}
      		{steps[currentStep].name === 'Description' &&
        		<Description
        			gigState={gigState}
        			onInputChange={updateGigState}
        			campaigns={campaigns}
        			trainings={trainings}
        			onOpenSecondModal={handleOpenSecondModal}
        		/>
      		}
      		{steps[currentStep].name === 'Email' &&
        		<Email
        			gigState={gigState}
        			onInputChange={updateGigState}
        			campaigns={campaigns}
        			trainings={trainings}
        			onOpenSecondModal={handleOpenSecondModal}
        			selectedBrand={selectedBrand}
        		/>
      		}
      		{steps[currentStep].name === 'Details' &&
        		<Details
        			gigState={gigState}
        			onInputChange={updateGigState}
        			objectives={selectedBrand ? selectedBrand.purpose_categories : []}
        			tactics={selectedBrand ? selectedBrand.tactical_categories : []}
        			businessCardQuestions= {
        				selectedBrand &&
        				selectedBrand.field_day_settings &&
        				selectedBrand.field_day_settings.business_card_question_settings
        				? selectedBrand.field_day_settings.business_card_question_settings
        				: []
        			}
        		/>
      		}
      		{steps[currentStep].name === 'User, Store, Targets' &&
        		<UsersStores
        			gigState={gigState}
        			onInputChange={updateGigState}
        			users={users}
        			stores={stores}
        			gettingTargetCount={gettingTargetCount}
        			onOpenSecondModal={handleOpenSecondModal}
        		/>
      		}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions className='d-flex justify-content-between align-items-center text-info pb-4 pt-4' style={{ borderTop: '1px solid #1a98a6' }}>
      	<div>
      		{/*currentStep > 0 &&
		      	<Button
		      		className='mt-3'
		      		onClick={() => setCurrentStep(currentStep - 1)}
		      		size='md'
		      		variant='outline-info'
		      	>
		      	  Prev
		      	</Button>
      		*/}
      	</div>
      	<Steps
      		currentStep={steps[currentStep]}
      		onStepClick={handleStepClick}
      	/>
    		<div>
      	{/*currentStep < steps.length - 1 &&
	        <Button
	        	className='mt-3'
	        	onClick={() => setCurrentStep(currentStep + 1)}
	        	size='md'
	        	variant='info'
	        >
	          Next
	        </Button>
      	*/}
      	{currentStep === steps.length - 1 &&
	        <SemanticButton
	        	className='w-100 mt-3'
	        	size='small'
	        	color='black'
	        	loading={creatingCampaign}
	        	onClick={handleCreateCampaign}
	        >
	          Create
	        </SemanticButton>
      	}
    		</div>
      </Modal.Actions>
      <Modal
      	className='semantic-modal'
        onClose={() => setShowSecondModal(false)}
        open={showSecondModal}
        size='small'
        // closeOnEscape={false}
			  // closeOnDimmerClick={false}
      >
      	<Modal.Header>{secondModalTitle}</Modal.Header>
      	<Modal.Content>
      		{secondModalName === 'targets'
      			? <StoreTargetList targets={gigState.selectedStores[selectedStoreIndexForSecondModal].places} />
	      		: <WYSIWYG
		      		  id={secondModalName}
		      		  onUpdateField={updateGigState}
		      		  fieldName={secondModalName}
		      		  defaultValue={gigState[secondModalName] || ''}
		      		  height={'300px'}
		      		/>
      		}
      	</Modal.Content>
      	<Modal.Actions>
      		<Button onClick={() => setShowSecondModal(false)}>Done</Button>
      	</Modal.Actions>
			</Modal>
		</Modal>
	)
}
// currentStep !== 'Brand' && currentStep !== 'Execution'
export default CampaignModal
