import React from 'react'
import { compose, withProps, withStateHandlers, lifecycle } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps'
import { DrawingManager } from 'react-google-maps/lib/components/drawing/DrawingManager'

const googleApiKey = 'AIzaSyBzt4U31ue3BGvUsNj0v4MSKjm87sQLSzc'

const PlacesMap = compose(
	withProps({
		googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&v=3.exp&libraries=geometry,drawing,places`,
		loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
	}),
	withScriptjs,
	withGoogleMap
)((props) => {
	const markers = props.geoPoints.map((point, i) => {
			return (
				<Marker key={i} position={point.coordinates} label={String(i + 1)} />
			)
		})

	// const storeIcon = {
	// 	path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
 //    scale: 1,
 //    fillColor: "#0699a8",
 //    fillOpacity: 1,
 //    strokeWeight: 1
	// }

	// markers.push(<Marker key={1231243234} position={{ lat: props.activityLocation.latitude, lng: props.activityLocation.longitude }} icon={storeIcon} />)

	const bounds = new window.google.maps.LatLngBounds()

	// props.geoPoints.push({ coordinates: { lat: props.activityLocation.latitude, lng: props.activityLocation.longitude } })
  // props.geoPoints.map((point, i) => bounds.extend({lat: point.coordinates.lat, lng: point.coordinates.lng}))
  props.geoPoints.forEach((point, i) => {
  	if(point.coordinates.lat && point.coordinates.lng) {
  		bounds.extend({lat: point.coordinates.lat, lng: point.coordinates.lng})
  	}
  })

  const handleDrawingComplete = (drawing) => {
  	const pointsArray = drawing.getPath().getArray()
  	const geoCoordintes = pointsArray.map(point => ({ lat: point.lat(), lon: point.lng() }))
  	console.log(pointsArray)
  	console.log(geoCoordintes)

  	props.onFilterChange({
  		relation: 'geo_polygon',
  		value: geoCoordintes
  	})
  }

	return (
		<GoogleMap
			ref={map => map && map.fitBounds(bounds)}
			defaultZoom={15}
			defaultCenter={props.geoPoints[0]}
		>
			{props.isMarkerShown && markers}
			{props.drawOnMap &&
				<DrawingManager
		      defaultDrawingMode={window.google.maps.drawing.OverlayType.POLYGON}
		      onPolygonComplete={handleDrawingComplete}
		      // onCircleComplete={handleDrawingComplete}
		      // onRectangleComplete={handleDrawingComplete}
		      defaultOptions={{
		        drawingControl: true,
		        drawingControlOptions: {
		          position: window.google.maps.ControlPosition.TOP_CENTER,
		          drawingModes: [
		            window.google.maps.drawing.OverlayType.POLYGON,
		            // window.google.maps.drawing.OverlayType.CIRCLE,
		            // window.google.maps.drawing.OverlayType.RECTANGLE,
		            // window.google.maps.drawing.OverlayType.POLYLINE,
		          ],
		        },
		        circleOptions: {
		          fillColor: '#ffff00',
		          fillOpacity: 1,
		          strokeWeight: 5,
		          clickable: false,
		          editable: true,
		          zIndex: 1,
		        },
		      }}
		    />
			}
		</GoogleMap>
	)
})

export default PlacesMap
