import React from 'react'
import ReactTable from 'react-table'

function BrandsTable({ brands, brandsExpenses, brandsActivityStats, onBrandClick }) {

	if(!brands) return <em>Loading..</em>

	return (
		<ReactTable
			data={brands.results}
			className='-striped -highlight table-style'
			defaultPageSize={50}
			filterable
			columns={[
				{
					Header: 'ID',
					id: 'brandId',
					headerClassName: 'table-header',
					className: 'table-column',
					filterMethod: (filter, row) => row[filter.id].props.children === Number(filter.value),
					accessor: d => <span>{d.brand_id}</span>
				},
				{
					Header: 'Brand',
					id: 'brandName',
					headerClassName: 'table-header',
					className: 'table-column',
					filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase()),
					accessor: d => <span className='pointer' onClick={() => onBrandClick(d)}>{d.brand_name}</span>
				},
				{
					Header: 'Total Gigs',
					id: 'totalGigs',
					headerClassName: 'table-header',
					className: 'table-column',
					filterable: false,
					accessor: d => <span>{brandsActivityStats && brandsActivityStats[d.brand_id] ? (brandsActivityStats[d.brand_id].total_gigs).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}</span>
					// filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase()),
					// accessor: d => <span>{brandsExpenses && brandsExpenses[d.brand_id] ? (brandsExpenses[d.brand_id].total_in_cents / 100) : 0}</span>
				},
				{
					Header: 'Total Spend',
					id: 'totalSpend',
					headerClassName: 'table-header',
					className: 'table-column',
					filterable: false,
					filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase()),
					accessor: d => <span>{brandsExpenses && brandsExpenses[d.brand_id] ? `$${(brandsExpenses[d.brand_id].total_in_cents / 100).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` : 0}</span>
					// accessor: d => <span>{brandsExpenses && brandsExpenses[d.brand_id] ? (brandsExpenses[d.brand_id].total_in_cents / 100) : 0}</span>
				},
				{
					Header: 'Finished Gigs',
					id: 'finishedGigs',
					headerClassName: 'table-header',
					className: 'table-column',
					filterable: false,
					accessor: d => <span>{brandsActivityStats && brandsActivityStats[d.brand_id] ? (brandsActivityStats[d.brand_id].total_gigs_finished).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}</span>
					// filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase()),
					// accessor: d => <span>{brandsExpenses && brandsExpenses[d.brand_id] ? (brandsExpenses[d.brand_id].total_in_cents / 100) : 0}</span>
				},
				{
					Header: 'In-Progress Gigs',
					id: 'inProgressGigs',
					headerClassName: 'table-header',
					className: 'table-column',
					filterable: false,
					accessor: d => <span>{brandsActivityStats && brandsActivityStats[d.brand_id] ? (brandsActivityStats[d.brand_id].total_gigs_in_progress).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}</span>
					// filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase()),
					// accessor: d => <span>{brandsExpenses && brandsExpenses[d.brand_id] ? (brandsExpenses[d.brand_id].total_in_cents / 100) : 0}</span>
				},
				{
					Header: 'Remaining Gigs',
					id: 'remainingGigs',
					headerClassName: 'table-header',
					className: 'table-column',
					filterable: false,
					accessor: d => <span>{brandsActivityStats && brandsActivityStats[d.brand_id] ? (brandsActivityStats[d.brand_id].total_gigs_not_started).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}</span>
					// filterMethod: (filter, row) => row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase()),
					// accessor: d => <span>{brandsExpenses && brandsExpenses[d.brand_id] ? (brandsExpenses[d.brand_id].total_in_cents / 100) : 0}</span>
				},
				{
					Header: 'Businesses Visited',
					id: 'businessesVisited',
					headerClassName: 'table-header',
					className: 'table-column',
					filterable: false,
					filterMethod: (filter, row) => row[filter.id].props.children === Number(filter.value),
					accessor: d => <span>{brandsActivityStats && brandsActivityStats[d.brand_id] ? (brandsActivityStats[d.brand_id].businesses_visited).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}</span>
				},
				{
					Header: 'Materials Distributed',
					id: 'materialsDistributed',
					headerClassName: 'table-header',
					className: 'table-column',
					filterable: false,
					filterMethod: (filter, row) => row[filter.id].props.children === Number(filter.value),
					accessor: d => <span>{brandsActivityStats && brandsActivityStats[d.brand_id] ? (brandsActivityStats[d.brand_id].materials_handed_out).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}</span>
				},
				{
					Header: 'Leads',
					id: 'leads',
					headerClassName: 'table-header',
					className: 'table-column',
					filterable: false,
					filterMethod: (filter, row) => row[filter.id].props.children === Number(filter.value),
					accessor: d => <span>{brandsActivityStats && brandsActivityStats[d.brand_id] ? (brandsActivityStats[d.brand_id].business_card_count).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0}</span>
				},
			]}
			defaultSortMethod={(a, b, desc) => {
				// force null and undefined to the bottom
				a = a.props.children === null || a.props.children === undefined ? '' : a.props.children
				b = b.props.children === null || b.props.children === undefined ? '' : b.props.children
				// force any string values to lowercase

				a = typeof a === 'string' ? a.toLowerCase().replace(/,/g, '').replace(/\$/g, '') : a
				b = typeof b === 'string' ? b.toLowerCase().replace(/,/g, '').replace(/\$/g, '') : b

				if(Number(a) || Number(b)) {
					a = Number(a)
					b = Number(b)
				}

				// Return either 1 or -1 to indicate a sort priority
				if (a > b) {
				  return 1
				}
				if (a < b) {
				  return -1
				}
				// returning 0, undefined or any falsey value will use subsequent sorts or
				// the index as a tiebreaker
				return 0
			}}
		/>
	)
}

export default BrandsTable
