import React, { Fragment, useEffect, useState, useRef } from 'react'
import axios from 'axios'
import { Col, Row, Form } from 'react-bootstrap'

import config from '../../config'
import FilterRelations from './FilterRelations'

function SicCodesFilter({ filterName, onSetRelation, relation, onSetAttribute, onSetValue }) {
	const [selectedCategory, setSelectedCategory] = useState('')
	const [searchResult, setSearchResult] = useState([])
	const searchInput = useRef('')

	// const relationOptions = ['equals', 'in', 'present', 'missing', 'exclude']
	const relationOptions = ['in', 'present', 'missing', 'exclude']

	useEffect(() => {
		onSetAttribute(filterName)
	}, [])

	// TODO; refactor this if disbaling category search lookup
	const handleCategorySearch = () => {
		const { value } = searchInput.current

		onSetValue(value.split(','))

		// if(inputValue) {
		// 	inputValue.concat([value])
		// } else {
		// 	onSetValue([value])
		// }

		// try {
		// 	if(Number(value) >= 0) {
		// 		onSetValue(value)

		// 		const response = await axios.get(`${config.coreAPI}/LIDCategories/categoryCodes?codeId=${value}&pageFrom=0&pageSize=1000`)

		// 		setSearchResult(response.data)
		// 	} else {
		// 		const response = await axios.get(`${config.coreAPI}/LIDCategories/categoryCodes?codeDescription=${value}&pageFrom=0&pageSize=1000`)

		// 		setSearchResult(response.data)
		// 	}
		// } catch(e) {
		// 	console.error(e)
		// }
	}

	const handleCategorySelect = (value, sicCode) => {
		searchInput.current.value = value
		onSetValue(sicCode)
		setSearchResult([])
	}

	return (
		<Row>
			<Col>
				<FilterRelations relationOptions={relationOptions} onSelectRelation={onSetRelation} />
			</Col>
			<Col>
				{(relation === 'equals' || relation === 'exclude' || relation === 'in') &&
					<Fragment>
						<Form.Control
							placeholder='Enter SIC codes'
							ref={searchInput}
							onKeyUp={handleCategorySearch}
						/>
						{searchResult.length
							? <div
									style={{
										position: 'absolute',
										maxWidth: '100%',
										maxHeight: '400px',
										// zIndex: '100000',
										backgroundColor: 'white',
										padding: '15px',
										overflow: 'auto'
									}}
								>
									<div className='text-danger text-right pointer' onClick={() => setSearchResult([])}>[X]</div>
									{searchResult.map(result => (
										<div
											key={result.id}
											className='border-bottom mb-3 pointer'
											onClick={() => handleCategorySelect(`${result.category_code_description} (${result.category_code_id})`, result.category_code_id)}
										>
											{result.category_code_description} ({result.category_code_id})
										</div>
									))}
								</div>
							: null
						}
					</Fragment>
				}
			</Col>
		</Row>
	)
}

export default SicCodesFilter
