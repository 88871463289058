import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { API } from 'aws-amplify'
import moment from 'moment'
import Col from 'react-bootstrap/Col'
import Figure from 'react-bootstrap/Figure'

import config from '../../config'
import { getActiveBrandsStats } from '../../actions/dashboard'
import { getAmbassadorAssetsUrl, getGigAssetsUrl } from '../../actions/shared'

class ActiveBrands extends Component {
	state = {
		brandLogos: []
	}

	componentDidMount() {
		this.props.dispatch(getActiveBrandsStats())
		// this.getBrandLogo(brand.brand_id, i)
	}

	componentDidUpdate(prevProps, prevState) {
		if(moment(prevProps.endDate).valueOf() !==  moment(this.props.endDate).valueOf()) {
			this.props.dispatch(getActiveBrandsStats())
		}
	}

	render() {
		const { activeBrands } = this.props
		const { brandLogos } = this.state
		// console.log('ACTIVE BRANDS PROPS: ', this.props)

		if(!activeBrands.length) {
			return <h4 className='text-center'><i>No Active Brands at this time</i></h4>
		}

		return (
			<Col lg={12} className='d-flex justify-content-lg-around flex-lg-wrap' style={{marginTop: '20px', maxHeight: '500px', overflow: 'auto'}}>
				{activeBrands.map((brand, i) => {
					if(!brandLogos[i]) {
						// this.getBrandLogo(brand.brand_id, i)
					}

					return (
						<ActiveBrandTile
							key={brand.brand_id}
							brandName={brand.brand_name}
							brandId={brand.brand_id}
							// brandLogo={brandLogos[i]}
							assignedCount={brand.assigned_count}
							unassignedCount={brand.unassigned_count}
							{...this.props}
						/>
					)
				})}
			</Col>
		)
	}

	// getBrandLogo = async (brandId, index) => {
	// 	try {
	// 		const key = await axios.get(`${config.coreAPI}/brand/${brandId}/logoUrl`)
	// 		const brandLogo = await API.post('getSignedUrl', '/getSignedUrl', {
	// 			body: { key: key.data }
	// 		})

	// 		const brandLogos = [...this.state.brandLogos]
	// 		brandLogos[index] = brandLogo

	// 		this.setState(brandLogos)
	// 	} catch(e) {
	// 		console.error(e)
	// 	}
	// }
}

function mapStateToProps({ dashboard }) {
	return {
		startDate: dashboard.startDate,
		endDate: dashboard.endDate,
		activeBrands: dashboard.activeBrands
	}
}

class ActiveBrandTile extends Component {
	state = {
		brandLogo: null
	}

	componentDidMount() {
		// console.log('+++++ActiveBrandTile DID MOUNT:', this.props.brandId)
		this.getBrandLogo()
	}

	render() {
		const { brandName, assignedCount, unassignedCount, onBrandClick, brandLogo } = this.props
		// const { brandName, assignedCount, unassignedCount, onBrandClick, onBrandStatsCountClick } = this.props
		const imgStyles = {
			backgroundImage: `url(${this.state.brandLogo})`,
			height: '80px',
			width: '80px',
			backgroundPosition: 'center',
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'contain',
			marginRight: '15px',
			border: '1px solid #C7C7C7'
		}

		return (
			<div style={{width: '250px', height: '140px', margin: '10px'}}>
				<h5
					className='pointer'
					style={{borderBottom: '1px solid #C7C7C7', marginBottom: '7px'}}
					onClick={() => onBrandClick(brandName, 'allGigsForBrand')}
				>
					{brandName}
				</h5>
				<div className='d-flex justify-content-between'>
					<div>
						<div style={imgStyles} onClick={() => onBrandClick(brandName, 'allGigsForBrand')} className='pointer'>
						{/*<img src={brandLogo} />*/}
						</div>
						<div style={{fontSize: '11px'}}>LOCATIONS: n/a</div>
					</div>
					<div className='d-flex'>
						<div className='d-flex flex-column' style={{marginRight: '12px'}}>
							<div style={{fontSize: '11px', textAlign: 'center', marginBottom: '7px'}}>ASSIGNED</div>
							<NumberBubble
								number={assignedCount}
								bgColor={'#F1F1F1'}
								fontColor={'#33CC02'}
								fontSize={22}
								width={60}
								height={40}
								onClick={() => onBrandClick(brandName, 'assignedGigs')}
							/>
						</div>
						<div className='d-flex flex-column'>
							<div style={{fontSize: '11px', textAlign: 'center', marginBottom: '7px'}}>UNASSIGNED</div>
							<NumberBubble
								number={unassignedCount}
								bgColor={'#F1F1F1'}
								fontColor={'#EC1C2C'}
								fontSize={22}
								width={60}
								height={40}
								onClick={() => onBrandClick(brandName, 'unassignedGigs')}
							/>
						</div>
					</div>
				</div>
			</div>
		)
	}

	getBrandLogo = async () => {
		try {
			const key = await axios.get(`${config.coreAPI}/brand/${this.props.brandId}/logoUrl`)
			// const brandLogo = await getGigAssetsUrl(key.data)
			const brandLogo = await API.post('getSignedUrl', '/getSignedUrl', {
				body: { key: key.data }
			})

			this.setState({ brandLogo })
		} catch(e) {
			console.error(e)
		}
	}

	// getSignedUrl = (key) => {
	// 	return API.post('getSignedUrl', '/getSignedUrl', {
	// 		body: { key }
	// 	})

	// 	// try {
	// 	// 	const result = await API.post('getSignedUrl', '/getSignedUrl', {
	// 	// 		body: { key }
	// 	// 	})

	// 	// 	this.setState({ logoUrl: result })
	// 	// }
	// 	// catch(e) {
	// 	// 	console.error('Error in getting signed url: ' + e)
	// 	// }
	// }
}

function NumberBubble({ number, bgColor, fontSize, fontColor, width, height, onClick }) {
	return (
		<div
			className='number-bubble'
			style={{
				backgroundColor: bgColor,
				color: fontColor,
				fontSize,
				width,
				height
			}}
		>
			<span className='pointer' onClick={onClick}>{number}</span>
		</div>
	)
}

export default connect(mapStateToProps)(ActiveBrands)
