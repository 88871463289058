import React from 'react'
import DatePicker from 'react-datepicker'
import { Form } from 'react-bootstrap'

function DateFilter({ startDate, endDate, onStartDateChange, onEndDateChange }) {
	return (
		<div>
			<DatePicker
				selectsStart
				placeholderText='Select Start Date'
				selected={startDate}
				onChange={date => onStartDateChange(date)}
				startDate={startDate}
				endDate={endDate}
				customInput={<Form.Control />}
			/>
			&nbsp;TO&nbsp;
			<DatePicker
				selectsEnd
				placeholderText='Select End Date'
				selected={endDate}
				onChange={date => onEndDateChange(date)}
				endDate={endDate}
				minDate={startDate}
				customInput={<Form.Control />}
			/>
		</div>
	)
}

export default DateFilter
