import React, { Component } from 'react'
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button'

import { getEmailLists } from '../../actions/brands'

class EmailList extends Component {
	componentDidMount() {
		// const { dispatch, brandId } = this.props

		// dispatch(getEmailLists(brandId))
	}

	render() {
		console.log('EmailList PROPS: ', this.props)
		return (
			<div>
				<div className='d-flex justify-content-between'>
					<h2>Email List</h2>
					<Button variant='outline-dark' onClick={() => this.handleCreateNewList}>+</Button>
				</div>
				<hr />
			</div>
		)
	}

	handleCreateNewList = () => {
	}
}

function mapStateToProps({ brands }) {
	return {
		emailLists: brands.emailLists
	}
}

export default connect(mapStateToProps)(EmailList)
