import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { API } from 'aws-amplify'
import { Search, Label, Segment } from 'semantic-ui-react'

import config from '../../config'

export default function Brands({ onBrandSelect, selectedBrandId, selectedBrandName }) {
	const [brands, setBrands] = useState([])
	const [searchResults, setSearchResults] = useState([])

	useEffect(() => {
		getBrands()
	}, [])

	const getBrands = async () => {
		try {
			const brands = await axios.get(`${config.coreAPI}/brand/all/names?pageFrom=0&pageSize=10000`)

			setBrands(brands.data.responses[0].results)
		} catch(e) {
			console.error(e)
		}
	}

	const handleSearchChange = (e, data) => {
		const value = e.target.value.toLowerCase()

		if(!value) {
			setSearchResults([])
		} else {
			setSearchResults(brands.filter(b => b.brand_name.toLowerCase().includes(e.target.value)))
		}
	}

	return (
		<Segment raised>
			<div className='d-flex align-items-center'>
				<Label size='big' color='teal' ribbon>
					Select Brand
				</Label>
				<Search
					placeholder='Search brands..'
					onSearchChange={handleSearchChange}
					showNoResults={false}
				/>
				<b className='ml-5 border-bottom border-danger'>
					{selectedBrandName}
					{selectedBrandName &&
						<span className='text-danger pointer' onClick={() => {
							onBrandSelect('brandId', null)
							onBrandSelect('brandName', null)
						}}>
							&nbsp;[x]
						</span>
					}
				</b>
			</div>
			<div className='cf-brands-container'>
				{searchResults.length && !selectedBrandId
					? searchResults.map((brand, i) => <BrandLogo key={i + Math.random() + Math.random()} brandId={brand.brand_id} brandName={brand.brand_name} onBrandClick={onBrandSelect} />)
					: null
				}
				{!searchResults.length && !selectedBrandId
					? brands.map((brand, i) => <BrandLogo key={i + Math.random() + Math.random()} brandId={brand.brand_id} brandName={brand.brand_name} onBrandClick={onBrandSelect} />)
					: null
				}
				{selectedBrandId
					? <BrandLogo brandId={selectedBrandId} brandName={selectedBrandName} onBrandClick={() => console.log(selectedBrandName)} />
					: null
				}
			</div>
		</Segment>
	)
}

function BrandLogo({ brandId, brandName, onBrandClick }) {
	const [logoUrl, setLogoUrl] = useState('')

	useEffect(() => {
		getLogoUrl()
	}, [])

	const getLogoUrl = async () => {
		try {
			const key = await axios.get(`${config.coreAPI}/brand/${brandId}/logoUrl`)

			const brandLogo = await API.post('getSignedUrl', '/getSignedUrl', {
				body: { key: key.data }
			})

			setLogoUrl(brandLogo)
		} catch(e) {
			console.error(e)
		}
	}

	const handleClick = () => {
		onBrandClick('brandId', brandId)
		onBrandClick('brandName', brandName)
	}

	return (
		<div className='cf-brand-logo-wrapper pointer' onClick={handleClick}>
			<img src={logoUrl} alt='' title={brandName} />
			<div>{brandName}</div>
		</div>
	)
}
