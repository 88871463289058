import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import axios from 'axios'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import ReactTable from 'react-table'
import { Map } from 'immutable'
import _ from 'underscore'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import config from '../config'
import SideBar from '../components/SideBar'
import GigsSideBar from '../components/gigs/GigsSideBar'
import TimeFilters from '../components/TimeFilters'
import 'react-datepicker/dist/react-datepicker.css'
import GigsTable from '../components/GigsTable'
import {
	getUnpaidGigs,
	getInProgressGigs,
	getInReviewGigs,
	getCompletedGigs,
	getCompletionApprovedGigs,
	handleStartDateChange,
	handleEndDateChange,
	handlePageChange,
	handlePageSizeChange,
	handleTableFilterChange,
	handleTableSortChange,
	handleAmbassadorFilter
} from '../actions/gigs'

class Gigs extends Component {
	state = {}

	componentDidMount() {
		this.mapStatusToFunction()
		this.getGigTotals()
	}

	componentDidUpdate(prevProps, prevState) {
		if(prevProps.endDate.valueOf() !== this.props.endDate.valueOf()) {
			this.mapStatusToFunction()
			this.getGigTotals()
		}

		if(prevProps.selectedStatus !== this.props.selectedStatus) {
			this.mapStatusToFunction()
		}

		if(prevProps.pageFrom !== this.props.pageFrom && prevProps.pageSize === this.props.pageSize) {
			// on page index change
			this.mapStatusToFunction()
		}

		if(prevProps.pageSize !== this.props.pageSize) {
			// on page size change
			this.mapStatusToFunction()
		}

		if(prevProps.brandNameSearchString !== this.props.brandNameSearchString ||
			prevProps.titleSearchString !== this.props.titleSearchString ||
			prevProps.addressSearchString !== this.props.addressSearchString ||
			prevProps.ambassadorFilter !== this.props.ambassadorFilter
		) {

			this.mapStatusToFunction()
		}

		if(prevProps.sortFieldName !== this.props.sortFieldName || prevProps.sortDirection !== this.props.sortDirection) {
			// on page size change
			this.mapStatusToFunction()
		}
	}

	mapStatusToFunction = () => {
		const { dispatch, selectedStatus } = this.props
		const actionFunctions = {
			unpaidGigs: getUnpaidGigs,
			inProgressGigs: getInProgressGigs,
			inReviewGigs: getInReviewGigs,
			completedGigs: getCompletedGigs,
			completionApprovedGigs: getCompletionApprovedGigs,
			// appliedGigs: getAppliedGigs,
			// unassignedGigs: getUnassignedGigs,
			// unfulfilledGigs: getUnfulfilledGigs,
			// canceledGigs: getCanceledGigs,
			// unconfirmedGigs: getUnconfirmedGigs,
			// confirmedGigs: getConfirmedGigs,
			// assignedGigs: getAssignedGigs,
		}

		dispatch(actionFunctions[selectedStatus]())
	}

	render() {
		const {
			dispatch,
			loading,
			selectedStatus,
			startDate,
			endDate,
			pageFrom,
			pageSize,
			brandNameSearchString,
			titleSearchString,
			addressSearchString
		} = this.props

		const viewTitles = {
			unpaidGigs: 'Unpaid Gigs',
			inProgressGigs: 'In-Progress Gigs',
			inReviewGigs: 'In-Review Gigs',
			completedGigs: 'Completed Gigs',
		}

		return (
			<Row>
				<SideBar>
					<GigsSideBar
						unpaidGigsTotal={this.state.unpaidGigsTotal}
						inProgressGigsTotal={this.state.inProgressGigsTotal}
						inReviewGigsTotal={this.state.inReviewGigsTotal}
						completedGigsTotal={this.state.completedGigsTotal}
						completionApprovedGigsTotal={this.state.completionApprovedGigsTotal}
					/>
				</SideBar>
				<Col className='p-4'>
					<Col className='d-flex justify-content-between align-items-center'>
						<h2>{viewTitles[selectedStatus]}</h2>
						<div className='mr-5'>
							<DatePicker
								selected={startDate}
								selectsStart
								startDate={startDate}
								endDate={endDate}
								onChange={this.onStartDateChange}
								showMonthDropdown
								showYearDropdown
								dropdownMode='select'
							/>
							&nbsp;TO&nbsp;
							<DatePicker
								selected={endDate}
								selectsEnd
								startDate={startDate}
								endDate={endDate}
								onChange={this.onEndDateChange}
								minDate={startDate}
								showMonthDropdown
								showYearDropdown
								dropdownMode='select'
							/>
							<TimeFilters
								onStartChange={this.onStartDateChange}
								onEndChange={this.onEndDateChange}
								size='sm'
							/>
						</div>
					</Col>
					<hr />
					<Col className='pl-0 pr-0'>
						{this.props[selectedStatus] &&
								<GigsTable
									// total={this.props[selectedStatus].total}
									loading={loading}
									data={this.props[selectedStatus]}
									onGigClick={this.handleGigClick}
									page={pageFrom}
									pageSize={pageSize}
									onPageChange={pageFrom => dispatch(handlePageChange(pageFrom))}
									onPageSizeChange={(pageSize, pageFrom) => dispatch(handlePageSizeChange(pageFrom, pageSize, this.props[selectedStatus].total))}
									onFilterColumns={this.handleFilterColumns}
									onSortColumns={this.handleSortColumns}
									brandNameSearchString={brandNameSearchString}
									titleSearchString={titleSearchString}
									addressSearchString={addressSearchString}
									onAmbassadorFilter={ambassadorId => dispatch(handleAmbassadorFilter(ambassadorId))}
									// onExport={this.handleExport}
								/>
						}
					</Col>
				</Col>
			</Row>
		)
	}

	getGigTotals = () => {
		const { startDate, endDate } = this.props
		const unfulfilledParams = `dueDateFromMillis=${moment(startDate).valueOf()}&dueDateToMillis=${Date.now()}&pageFrom=0&pageSize=0&noMasters=true`
		const filters = {
			dueDateFromMillis: moment(startDate).valueOf(),
			dueDateToMillis: moment(endDate).valueOf(),
			// dueDateToMillis: unfulfilledGigs ? Date.now() : moment(endDate).valueOf(),
			pageFrom: 0,
			pageSize: 0,
			noMasters: true
		}

		const params = _.map(filters, (val, key) => `${key}=${val}`).join('&')

		// axios.get(`${config.coreAPI}/fieldDayAdmin/activities?applied=true&accepted=false&${params}`)
		// 	.then(appliedGigs => this.setState({ appliedGigsTotal: appliedGigs.data.responses[0].total }))

		// axios.get(`${config.coreAPI}/fieldDayAdmin/activities?accepted=false&${params}`)
		// 	.then(unassignedGigs => this.setState({ unassignedGigsTotal: unassignedGigs.data.responses[0].total }))

		// axios.get(`${config.coreAPI}/fieldDayAdmin/activities?accepted=true&started=false&${unfulfilledParams}`)
		// 	.then(unfulfilledGigs => this.setState({ unfulfilledGigsTotal: unfulfilledGigs.data.responses[0].total }))

		// axios.get(`${config.coreAPI}/fieldDayAdmin/activities/cancelled?ignoreAssigned=true&${params}`)
		// 	.then(canceledGigs => this.setState({ canceledGigsTotal: canceledGigs.data.responses[0].total }))

		// axios.get(`${config.coreAPI}/fieldDayAdmin/activities?smsConfirmed=false&${params}`)
		// 	.then(unconfirmedGigs => this.setState({ unconfirmedGigsTotal: unconfirmedGigs.data.responses[0].total }))

		// axios.get(`${config.coreAPI}/fieldDayAdmin/activities?smsConfirmed=true&${params}`)
		// 	.then(confirmedGigs => this.setState({ confirmedGigsTotal: confirmedGigs.data.responses[0].total }))

		// axios.get(`${config.coreAPI}/fieldDayAdmin/activities?accepted=true&completed=false&${params}`)
		// 	.then(assignedGigs => this.setState({ assignedGigsTotal: assignedGigs.data.responses[0].total }))

		axios.get(`${config.coreAPI}/fieldDayAdmin/activities?finished=true&paid=false&${params}`)
			.then(unpaidGigs => this.setState({ unpaidGigsTotal: unpaidGigs.data.responses[0].total }))

		axios.get(`${config.coreAPI}/fieldDayAdmin/activities?started=true&finished=true&completed=true&paid=true&${params}`)
			.then(completedGigs => this.setState({ completedGigsTotal: completedGigs.data.responses[0].total }))

		axios.get(`${config.coreAPI}/fieldDayAdmin/activities?hasReview=true&paid=false&completed=false&${params}`)
			.then(inReviewGigs => this.setState({ inReviewGigsTotal: inReviewGigs.data.responses[0].total }))

		axios.get(`${config.coreAPI}/fieldDayAdmin/activities?accepted=true&started=true&finished=false&${params}`)
			.then(inProgressGigs => this.setState({ inProgressGigsTotal: inProgressGigs.data.responses[0].total }))

		axios.get(`${config.coreAPI}/fieldDayAdmin/activities?started=true&finished=true&completed=true&paid=false&${params}`)
			.then(completionApprovedGigs => this.setState({ completionApprovedGigsTotal: completionApprovedGigs.data.responses[0].total }))
	}

	onStartDateChange = (date) => {
		this.props.dispatch(handleStartDateChange(date))
	}

	onEndDateChange = (date) => {
		this.props.dispatch(handleEndDateChange(date))
	}

	handleGigClick = (gig) => {
		this.props.history.push(`/gig/${gig.brand_user_activity.id}`)
	}

	handleFilterColumns = (name, value) => {
		this.props.dispatch(handleTableFilterChange(name, value))
	}

	handleSortColumns = (newSorted, column, shiftKey) => {
		let sortFieldName
		const descSort = newSorted[0].desc

		if(newSorted[0].id === 'brandNameSearchString') sortFieldName = 'assigned_to_user.brand_roles.brand_name'
		if(newSorted[0].id === 'titleSearchString') sortFieldName = 'title'
		// if(newSorted === 'location') sortFieldName = 'location'

		this.props.dispatch(handleTableSortChange(sortFieldName, descSort))
	}
}

function mapStateToProps({ gigs }) {
	return {
		loading: gigs.loading,
		pageFrom: gigs.pageFrom,
		pageSize: gigs.pageSize,
		startDate: gigs.startDate,
		endDate: gigs.endDate,
		selectedStatus: gigs.selectedStatus,
		brandNameSearchString: gigs.brandNameSearchString,
		titleSearchString: gigs.titleSearchString,
		addressSearchString: gigs.addressSearchString,
		ambassadorSearchString: gigs.ambassadorSearchString,
		ambassadorFilter: gigs.ambassadorFilter,
		// zipCodeSearchString: gigs.zipCodeSearchString,
		sortFieldName: gigs.sortFieldName,
		sortDirection: gigs.sortDirection,
		unpaidGigs: gigs.unpaidGigs,
		inProgressGigs: gigs.inProgressGigs,
		inReviewGigs: gigs.inReviewGigs,
		completedGigs: gigs.completedGigs,
		completionApprovedGigs: gigs.completionApprovedGigs,
	}
}

export default connect(mapStateToProps)(withRouter(Gigs))
