import React, { Fragment } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { map } from 'underscore'

import { EmployeeCountInput } from './TargetSettings'

export default function StoreOverride({ selectedStores, targetSettings, onUpdateStore, selectedExecution, onGetTargetCount }) {
	if(!selectedStores) return <h3 className='text-center'>No stores selected</h3>

	const updateStore = (settings, storeIndex) => {
		const newStoresState = [ ...selectedStores ]

		newStoresState[storeIndex].targetSettings = settings

		onUpdateStore(newStoresState)
	}
// console.log('targetSettings: ', targetSettings)
// console.log('selectedStores: ', selectedStores)
	return (
		<div>
			<div className='d-flex justify-content-end'>
				<Button onClick={onGetTargetCount}>
					Get target count
				</Button>
			</div>
			{selectedStores.map((store, i) => {
				let minTargetCount = 0

				if(store.targetSettings) {
					minTargetCount = Number(store.targetSettings.reachGoal) + (Number(store.targetSettings.reachGoal) * (Number(store.targetSettings.targetCushion) / 100))
				} else {
					minTargetCount = Number(targetSettings.reachGoal) + (Number(targetSettings.reachGoal) * (Number(targetSettings.targetCushion) / 100))
				}

				return (
					<div key={store.store_id} className='mb-3 pb-3' style={{ borderBottom: '2px solid' }}>
						<Row className='mb-2'>
							<Col>
								<b>{store.store_name} ({store.store_id})</b>&nbsp;has&nbsp;
								<span
									style={{
										fontSize: '20px',
										fontWeight: '600',
										color: `${store.targetCount < minTargetCount && store.targetCount > 0 ? 'red' : 'green'}`
									}}
								>
									{store.targetCount || 0}
								</span>
								&nbsp;targets - (Reach goal + cushion is <span style={{ fontSize: '20px', fontWeight: '600', }}>{minTargetCount}</span>)
							</Col>
						</Row>
						<Row>
							{store.targetSettings
								? <StoreTargetSettings settings={store.targetSettings} selectedExecution={selectedExecution} storeIndex={i} updateStore={updateStore} />
								: <StoreTargetSettings settings={targetSettings} selectedExecution={selectedExecution} storeIndex={i} updateStore={updateStore} />
							}
						</Row>
					</div>
				)
			})}
		</div>
	)
}

function StoreTargetSettings({ settings, selectedExecution, storeIndex, updateStore }) {
	const handleInputChange = (e, val) => {
		let name = ''
		let value = ''

		if(val) {
			name = e
			value = val
		} else {
			name = e.target.name
			value = e.target.value
		}

		const newSettings = { ...settings }

		newSettings[name] = value

		console.log(storeIndex, newSettings)
		updateStore(newSettings, storeIndex)
	}

	return (
		<Fragment>
			<InputField
				title={'Reach Goal'}
				type='number'
				name={'reachGoal'}
				value={settings.reachGoal}
				onInputChange={handleInputChange}
			/>
			<InputField
				title={'Target Cushion %'}
				type='number'
				name={'targetCushion'}
				value={settings.targetCushion}
				onInputChange={handleInputChange}
			/>
			<InputField
				title={'Radius'}
				type='number'
				name={'radius'}
				value={settings.radius}
				onInputChange={handleInputChange}
			/>
			{selectedExecution === 'STREET' &&
				<Fragment>
					<InputField
						title={'Max Targets per Gig'}
						type='number'
						name={'max_destination_count'}
						value={settings.max_destination_count}
						onInputChange={handleInputChange}
					/>
					<InputField
						title={'Minutes per destination'}
						type='number'
						name={'minutes_per_destination'}
						value={settings.minutes_per_destination}
						onInputChange={handleInputChange}
					/>
				</Fragment>
			}
			{selectedExecution === 'PHONE' &&
				<Fragment>
					<InputField
						title={'Calls per Hour'}
						type='number'
						name={'calls_per_hour'}
						value={settings.calls_per_hour}
						onInputChange={handleInputChange}
					/>
				</Fragment>
			}
			<br />
			<Col md={4}>
				<EmployeeCountInput
					targetSettings={settings}
					onInputChange={handleInputChange}
				/>
			</Col>
		</Fragment>
	)
}

function InputField({ title, type, name, value, onInputChange }) {
	return (
		<Col className='input-box ml-1 mr-1'>
			<span className='pl-2'>{title}</span>
			<Form.Control
				type={type}
				name={name}
				value={value || ''}
				onChange={onInputChange}
			/>
		</Col>
	)
}
