import { combineReducers } from 'redux'
import { loadingBarReducer } from 'react-redux-loading'

// import loading from './loading'
import dashboard from './dashboard'
import ambassadors from './ambassadors'
import gigs from './gigs'
import brands from './brands'
// import sidebar from './sidebar'

export default combineReducers({
	// loading,
	dashboard,
	ambassadors,
	gigs,
	brands,
	// sidebar,
	loadingBar: loadingBarReducer
})