import React from 'react'
import moment from 'moment'
import { map, find } from 'underscore'

import excelExporter from '../../utils/excelExporter'

function ExportTable({ columns, data, filename, lateNoShowEvents }) {
	const handleExportExcel = () => {
		const headerRow = map(columns, cell => ({ value: cell, type: 'string'}))
		let contentRows

		if(filename === 'payments') {
			contentRows = map(data, gig => {
				return [
					{
						value: (() => {
							const lateNoShowEvent = lateNoShowEvents && find(lateNoShowEvents, event => event.activity_id === gig.activity_id)

							return (
								`${gig.title} ${lateNoShowEvent ? `(${lateNoShowEvent.tags.join(' & ')})` : ''}`
							)
						})(),
						type: 'string'
					},
					{
						value: gig.category,
						type: 'string'
					},
					{
						value: gig.activity_title,
						type: 'string'
					},
					{
						value: gig.brand_name,
						type: 'string'
					},
					{
						value: (gig.amount_in_cents / 100).toFixed(2),
						type: 'number'
					},
					{
						value: gig.status,
						type: 'string'
					},
					{
						value: gig.payout_arrival_timestamp_millis ? moment(gig.payout_arrival_timestamp_millis).format('MM/DD/YYYY') : '',
						type: 'string'
					},
				]
			})
		} else {
			contentRows = map(data, gig => {
				return [
					{
						value: gig.brand_user_activity.title,
						type: 'string'
					},
					{
						value: gig.associated_brand.brand_name,
						type: 'string'
					},
					{
						value: moment(gig.brand_user_activity.due_date_millis).format('MM/DD/YYYY'),
						type: 'string'
					},
					{
						value: gig.brand_user_activity.field_day_operation_details.finished_gig_timestamp ? moment(gig.brand_user_activity.field_day_operation_details.finished_gig_timestamp * 1000).format('MM/DD/YYYY') : '',
						type: 'string'
					},
					{
						value: gig.brand_user_activity.field_day_management.field_day_remuneration.max_hours,
						type: 'number'
					},
					{
						value: `${gig.brand_user_activity.field_day_operation_details.ambassador_remuneration_to_pay ? Number(gig.brand_user_activity.field_day_operation_details.ambassador_remuneration_to_pay).toFixed(2) : 0}`,
						type: 'number'
					},
					{
						value: gig.brand_user_activity.field_day_operation_details.business_card_photos ? gig.brand_user_activity.field_day_operation_details.business_card_photos.length : 0,
						type: 'number'
					},
					{
						value: gig.brand_user_activity.field_day_management.field_day_remuneration.bonus_eligible ? 'Yes' : 'No',
						type: 'string'
					},
				]
			})
		}


		contentRows.unshift(headerRow)

		const config = {
		  filename,
		  sheet: {
		    data: contentRows
		  }
		}

		excelExporter(config)
	}


	return (
		<i
			title='Export Table'
			className='fas fa-file-export fa-lg float-right pointer'
			onClick={handleExportExcel}
		>
		</i>
	)
}

export default ExportTable
