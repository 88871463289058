import React, { useState } from 'react'
import { Dropdown, DropdownButton } from 'react-bootstrap'

function FilterRelations({ relationOptions, onSelectRelation }) {
	const [selectedRelation, setSelectedRelation] = useState(null)

	const handleSelectRelation = (relation) => {
		setSelectedRelation(relation)
		onSelectRelation(relation)
	}

	return (
		<DropdownButton variant='outline-dark' title={selectedRelation ? selectedRelation : 'Select relation'}>
			{relationOptions.map((relation, i) =>
				<Dropdown.Item key={i} onClick={() => handleSelectRelation(relation)}>
					{relation}
				</Dropdown.Item>
			)}
		</DropdownButton>
	)
}

export default FilterRelations
