import React, { Fragment, useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'

import SicCodeSettings from './SicCodeSettings'

export default function TargetSettings({ targetSettings, onTargetSettingsUpdate, selectedExecution }) {
	return (
		<Row>
			<Col>
				<InputField
					title={'Reach Goal'}
					type='number'
					name={'reachGoal'}
					value={targetSettings.reachGoal}
					onInputChange={onTargetSettingsUpdate}
				/>
				<hr />
				<InputField
					title={'Target Cushion %'}
					type='number'
					name={'targetCushion'}
					value={targetSettings.targetCushion}
					onInputChange={onTargetSettingsUpdate}
				/>
				<hr />
				<InputField
					title={'Radius'}
					type='number'
					name={'radius'}
					value={targetSettings.radius}
					onInputChange={onTargetSettingsUpdate}
				/>
				<hr />
				<InputField
					title={'Reach per Hour'}
					type='number'
					name={'reach_per_hour'}
					value={targetSettings.reach_per_hour}
					onInputChange={onTargetSettingsUpdate}
				/>
				<hr />
				{selectedExecution === 'STREET' &&
					<InputField
						title={'Minutes per destination (needed for routing purposes)'}
						type='number'
						name={'minutes_per_destination'}
						value={targetSettings.minutes_per_destination}
						onInputChange={onTargetSettingsUpdate}
					/>
				}
			</Col>
			<Col>
				{/*<Dropdown
					clearable
					search
					selection
					multiple
					placeholder='Place Type'
					className='w-100'
					name='placeType'
					options={tacticsDropdown}
					value={selectedTactics}
					onChange={(e, { name, value }) => onTargetSettingsUpdate(value)}
				/>*/}
				<EmployeeCountInput
					targetSettings={targetSettings}
					onInputChange={onTargetSettingsUpdate}
				/>
				<hr />
				<SicCodeSettings
					targetSettings={targetSettings}
					onInputChange={onTargetSettingsUpdate}
				/>
			</Col>
		</Row>
	)
}

export function InputField({ title, type, name, value, onInputChange }) {
	return (
		<div className='input-box'>
			<span className='pl-2'>{title}</span>
			<Form.Control
				type={type}
				name={name}
				value={value || ''}
				onChange={e => onInputChange(name, e.target.value)}
			/>
		</div>
	)
}

export function EmployeeCountInput({ targetSettings, onInputChange }) {
	const relationOptions = ['between', 'equals', 'greater_than', 'less_than']
	const relation = targetSettings.employeeCountRelation

	const handleInputChange = (e) => {
		const { name, value } = e.target

		onInputChange(name, value)
	}

	return (
		<Row className='align-items-center'>
			<Col md={4}>
				<span>Employee Count</span>
				<DropdownButton
					variant='outline-dark'
					title={relation ? relation : 'Select relation'}
				>
					{relationOptions.map((relationOption, i) =>
						<Dropdown.Item key={i} onClick={() => onInputChange('employeeCountRelation', relationOption)}>
							{relationOption}
						</Dropdown.Item>
					)}
				</DropdownButton>
			</Col>
			<Col>
				{relation === 'between' &&
					<div className='d-flex align-items-center'>
						<Form.Control
							name='employeeCountValueFrom'
							value={targetSettings.employeeCountValueFrom}
							onChange={handleInputChange}
						/>
						&nbsp;and&nbsp;
						<Form.Control
							name='employeeCountValueTo'
							value={targetSettings.employeeCountValueTo}
							onChange={handleInputChange}
						/>
					</div>
				}
				{(relation === 'equals' || relation === 'greater_than' || relation === 'less_than') &&
					<Form.Control
						name='employeeCountValue'
						value={targetSettings.employeeCountValue}
						onChange={handleInputChange}
					/>
				}
			</Col>
		</Row>
	)
}
