import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { API } from 'aws-amplify'
import _ from 'underscore'
import moment from 'moment-timezone'
// import loadImage from 'blueimp-load-image'
import Alert from 'react-bootstrap/Alert'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
// import Image from 'react-bootstrap/Image'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'

import '../styles/gig.css'
import config from '../config'
import GigDetail from '../components/gigTile/GigDetail'
import PhoneTargetsMap from '../components/gigTile/PhoneTargetsMap'
import GeoTrackingMap from '../components/gigTile/GeoTrackingMap'
import PhoneGigEmailTemplate from '../components/gigTile/PhoneGigEmailTemplate'
import GigDescription from '../components/gigTile/GigDescription'
import PhoneTargetList from '../components/gigTile/PhoneTargetList'
import BusinessCards from '../components/gigTile/BusinessCards'
import PayReport from '../components/gigTile/PayReport'
import Slideshow from '../components/Slideshow'
// import PhoneGigConfig from '../components/gigTile/PhoneGigConfig'
import LeadsList from '../components/gigTile/LeadsList'
import GigReview from '../components/gigTile/GigReview'
import ReviewNotes from '../components/gigTile/ReviewNotes'
import CustomRoutingUpload from '../components/gigTile/CustomRoutingUpload'
import PhoneTargetsUpload from '../components/gigTile/PhoneTargetsUpload'
import ContactFormsTable from '../components/gigTile/ContactFormsTable'
import Loading from '../components/Loading'
import { getAmbassadorAssetsUrl, getGigAssetsUrl } from '../actions/shared'

class GigTile extends Component {
	state = {
		errors: [],
		showModal: false,
		showActionsBar: true,
		modalName: null,
		meetingSpotPhotos: [],
		collateralPhotos: [],
		executionPhotos: [],
		bizCardPhotos: [],
		ambassadorGeoTracks: null
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClick)
		this.getGig()
		this.getTargetList()
		this.getLeads()
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClick)
	}

	render() {
		const {
			errors,
			working,
			done,
			gig,
			showActions,
			showModal,
			modalName,
			assignedAmbassador,
			ambassadorPicture,
			meetingSpotPhotos,
			collateralPhotos,
			executionPhotos,
			bizCardPhotos,
			showActionsBar,
			slideshowItems,
			slideshowIndex,
			slideshowName,
			ambassadorGeoTracks,
			googleRouteLink
		} = this.state

		console.log('GIG STATE: ', this.state)
		// console.log('GIG PROPS: ', this.props)

		return (
			<Fragment>
				{working && <Loading />}
				<Col lg={9} className='m-auto'>
				{done && <Alert variant={'success'}>Successful</Alert>}
				{errors.map((err, i) => <Alert key={i} variant={'danger'}>{err}</Alert>)}
				{googleRouteLink && <Alert className='text-break' variant={'success'}>{googleRouteLink}</Alert>}
				{showModal &&
					<Modal
						show={showModal}
						onHide={this.handleCloseModal}
						size={'lg'}
						// dialogClassName={modalName === 'gigActions' ? 'modal-60w' : ''}
						// aria-labelledby="contained-modal-title-vcenter"
						centered
					>
						{modalName === 'payReport' &&
							<Fragment>
								<Modal.Header closeButton>
									<Modal.Title>
										Pay & Report
									</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<PayReport
										gig={gig.brand_user_activity}
										brand={gig.associated_brand}
										onRefreshGigInfo={this.handleRefreshGigInfo}
										assignedAmbassador={assignedAmbassador}
										ambassadorPhoto={ambassadorPicture}
										meetingSpotPhoto={meetingSpotPhotos.length && meetingSpotPhotos[0].url}
										materialsPhoto={collateralPhotos.length && collateralPhotos[0].url}
										businessCardPhoto={bizCardPhotos.length && bizCardPhotos[0].url}
										businessesVisited={gig.brand_user_activity.field_day_operation_details.feedback
											? `(${gig.brand_user_activity.field_day_operation_details.feedback.businesses_visited})`
											: ''
										}
										materialsHandedout={gig.brand_user_activity.field_day_operation_details.feedback
											? `(${gig.brand_user_activity.field_day_operation_details.feedback.materials_handed_out})`
											: ''
										}
										// onSendEmail={this.handleSendEmail}
									/>
								</Modal.Body>
							</Fragment>
						}
						{modalName === 'slideshow' &&
							<Fragment>
								<Modal.Header closeButton>
									<Modal.Title id="contained-modal-title-vcenter">
										{slideshowName}
									</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<Slideshow
										items={slideshowItems}
										slideNumber={slideshowIndex}
										// onClose={this.handleCloseModal}
									/>
								</Modal.Body>
							</Fragment>
						}
						{modalName === 'gigReview' &&
							<Fragment>
								<Modal.Header closeButton>
									<Modal.Title id="contained-modal-title-vcenter">
										Submit Gig For Review
									</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<GigReview
										gigId={this.state.gig.brand_user_activity.id}
										onSubmitSuccess={this.handleCloseModal}
									/>
								</Modal.Body>
							</Fragment>
						}
						{modalName === 'customRouting' &&
							<Fragment>
								<Modal.Header closeButton>
									<Modal.Title id="contained-modal-title-vcenter">
										Custom Routing
									</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<CustomRoutingUpload
										brandId={gig.brand_user_activity.brand_id}
										gig={gig.brand_user_activity}
									/>
								</Modal.Body>
							</Fragment>
						}
						{modalName === 'phoneTargets' &&
							<Fragment>
								<Modal.Header closeButton>
									<Modal.Title id="contained-modal-title-vcenter">
										Phone Targets
									</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<PhoneTargetsUpload
										brandId={gig.brand_user_activity.brand_id}
										gig={gig.brand_user_activity}
									/>
								</Modal.Body>
							</Fragment>
						}
					</Modal>
				}
				{gig &&
					<Card>
						<Card.Body>
							<Col className='pl-0 d-flex justify-content-between align-items-center'>
								<Card.Title>{gig.brand_user_activity.title}</Card.Title>
								{showActionsBar && <i className='fas fa-bars fa-lg pointer' onClick={() => this.setState({ showActions: !showActions })}></i>}
								{showActions &&
									<Card className='actions-popover' ref={node => this.gigActions = node}>
										<Card.Body>
											<Card.Text className='pointer' onClick={() => this.handleOpenModal('gigReview')}>Submit for Review</Card.Text>
											<Card.Text className='pointer' onClick={() => this.handleOpenModal('payReport')}>Pay & Report</Card.Text>
											{gig.brand_user_activity.execution_type === 'STREET' &&
												<Card.Text className='pointer' onClick={() => this.handleOpenModal('customRouting')}>
													Custom Routing
												</Card.Text>
											}
											{gig.brand_user_activity.execution_type === 'PHONE' &&
												<Card.Text className='pointer' onClick={() => this.handleOpenModal('phoneTargets')}>
													Phone Targets
												</Card.Text>
											}
											{/*<Card.Text className='pointer' onClick={this.generateGoogleRoute}>Generate Google Route</Card.Text>*/}
										</Card.Body>
									</Card>
								}
							</Col>
							<hr />
							<Col>
								<ReviewNotes
									review={this.state.gig.brand_user_activity.field_day_management.field_day_review}
									gigId={this.state.gig.brand_user_activity.id}
									onSuccess={this.handleSuccess}
									onError={this.handleErrors}
									onRefreshGigInfo={this.getTextualInfo}
								/>
							</Col>
							<Row>
								<Col className='border-right'>
									{assignedAmbassador &&
										<AmbassadorPreview
											assignedAmbassador={assignedAmbassador}
											ambassadorPicture={ambassadorPicture}
										/>
									}
								</Col>
								<Col>
									{this.state.leadActions &&
										<GigDetail
											gigId={this.props.match.params.id}
											brandId={gig.brand_user_activity.brand_id}
											isPhoneGig={gig.brand_user_activity.execution_type === 'PHONE'}
											isFeetGig={gig.brand_user_activity.execution_type === 'STREET'}
											leadActions={this.state.leadActions}
										/>
									}
								</Col>
								{/*<Col>
									<div><b>Location: </b>{gig.brand_user_activity.address}, {gig.brand_user_activity.city} {gig.brand_user_activity.state}</div>
									<div><b>Started: </b>
										{gig.brand_user_activity.field_day_operation_details.started_gig_timestamp &&
											moment(gig.brand_user_activity.field_day_operation_details.started_gig_timestamp * 1000)
											.tz(gig.brand_user_activity.time_zone)
											.format('MM/DD/YY - h:mm A')
										}&nbsp;
										{gig.brand_user_activity.field_day_operation_details.started_gig_timestamp && gig.brand_user_activity.time_zone_human}
									</div>
									<div><b>Ended: </b>
										{gig.brand_user_activity.field_day_operation_details.finished_gig_timestamp &&
											moment(gig.brand_user_activity.field_day_operation_details.finished_gig_timestamp * 1000)
											.tz(gig.brand_user_activity.time_zone)
											.format('MM/DD/YY - h:mm A')
										}&nbsp;
										{gig.brand_user_activity.field_day_operation_details.finished_gig_timestamp && gig.brand_user_activity.time_zone_human}
									</div>
									<div>
										<b>Rate: </b>
										${gig.brand_user_activity.field_day_management.field_day_remuneration.ambassador_remuneration_rate_per_hour}
									</div>
									<div>
										<b>Businesses Visited: </b>
										{gig.brand_user_activity.field_day_operation_details.feedback &&
											gig.brand_user_activity.field_day_operation_details.feedback.businesses_visited
										}
									</div>
									<div>
										<b>Materials Handed Out: </b>
										{gig.brand_user_activity.field_day_operation_details.feedback &&
											gig.brand_user_activity.field_day_operation_details.feedback.materials_handed_out
										}
									</div>
									<div>
										<b>Business Cards Collected: </b>
										{gig.brand_user_activity.field_day_operation_details.business_card_photos &&
											gig.brand_user_activity.field_day_operation_details.business_card_photos.length
										}
									</div>
									<div>
										<b>Assigned by brand user: </b>
										{gig.brand_user_activity.created_by_user.first_name} {gig.brand_user_activity.created_by_user.last_name}
									</div>
									<div>
										<b>Assigned to brand user: </b>
										{gig.brand_user_activity.assigned_to_user.first_name} {gig.brand_user_activity.assigned_to_user.last_name}
									</div>
								</Col>*/}
							</Row>
							<hr />
							<Row>
								<Col>
									<GigDescription
										gigId={this.props.match.params.id}
										isPhoneGig={gig.brand_user_activity.execution_type === 'PHONE'}
									/>
								</Col>
							</Row>
							<hr />
							<Row className='pb-4'>
								<Col className='pt-3'>
									<div style={{ width: '550px', height: '550px', margin: 'auto' }} ref={node => this.trackingMap = node}>
										{gig.brand_user_activity.execution_type === 'PHONE' && this.state.phoneTargetList &&
											<PhoneTargetsMap
												store={gig.brand_user_activity.stores[0]}
												targetList={this.state.phoneTargetList}
											/>
										}
										{gig.brand_user_activity.execution_type === 'STREET' && ambassadorGeoTracks &&
											<Fragment>
												<em>(F = first, L = last)</em>
												<GeoTrackingMap
													isMarkerShown
													targets={this.state.feetTargetList}
													geoPoints={
														_.chain(ambassadorGeoTracks)
														.sortBy(point => point.timestamp_millis)
														.map((point, i) => ({
															lat: point.coordinates[1],
															lng: point.coordinates[0],
															id: i,
															timestamp: point.timestamp_millis
														}))
														.value()
													}
												/>
											</Fragment>
										}
									</div>
								</Col>
								<Col className='overflow-auto pt-3'>
									{gig.brand_user_activity.execution_type === 'STREET' &&
										<Notes
											gig={gig.brand_user_activity}
											onRefreshGigInfo={this.handleRefreshGigInfo}
											onError={this.handleErrors}
										/>
									}
									{gig.brand_user_activity.execution_type === 'PHONE' &&
										<PhoneGigEmailTemplate gigId={this.props.match.params.id} />
									}
								</Col>
							</Row>
							<hr />
							{gig.brand_user_activity.execution_type === 'PHONE' &&
								<Fragment>
									<PhoneTargetList targetList={this.state.phoneTargetList} gigTitle={gig.brand_user_activity.title} />
									<hr />
									{this.state.leadActions && <LeadsList gig={gig.brand_user_activity} lead_actions={this.state.leadActions} />}
								</Fragment>
							}
							<hr />
							{gig.brand_user_activity.execution_type === 'STREET' &&
								<Fragment>
									<Card.Subtitle className='d-flex align-items-center'>
										Meeting Spot & Collateral Photos
										<ButtonToolbar className='ml-3'>
											<ButtonGroup>
												<Button size='sm' variant='outline-primary'>
													Upload Meeting Spot
													<input
														type='file'
														className='pointer'
														name='meetingSpotPhoto'
														multiple='multiple'
														onChange={this.handleUploadPhotos}
													/>
												</Button>
												<Button size='sm' variant='outline-primary'>
													Upload Collateral
													<input
														type='file'
														className='pointer'
														name='materialsPhoto'
														multiple='multiple'
														onChange={this.handleUploadPhotos}
													/>
												</Button>
											</ButtonGroup>
										</ButtonToolbar>
									</Card.Subtitle>
									<div className='photos-container mt-3 d-flex flex-wrap'>
										{/*meetingSpotPhotos && !meetingSpotPhotos.length && collateralPhotos && !collateralPhotos && <em className='m-auto'>No Photos Taken</em>*/}
										{meetingSpotPhotos && meetingSpotPhotos.map((photo, i) =>
											<PhotoPreview
												onDeletePhoto={this.handleDeletePhoto}
												apiEndpoint='meetingSpotPhoto'
												key={photo.timestamp * Math.random()}
												src={photo.url}
												timestamp={photo.timestamp}
												timeZone={gig.brand_user_activity.time_zone}
												timeZoneHuman={gig.brand_user_activity.time_zone_human}
												onClick={this.handleOpenModal}
												name='meetingSpotPhotos'
												index={i}
												path={photo.path}
											/>
										)}
										{collateralPhotos && collateralPhotos.map((photo, i) =>
											<PhotoPreview
												onDeletePhoto={this.handleDeletePhoto}
												apiEndpoint='materialsPhoto'
												key={photo.timestamp * Math.random()}
												src={photo.url}
												timestamp={photo.timestamp}
												timeZone={gig.brand_user_activity.time_zone}
												timeZoneHuman={gig.brand_user_activity.time_zone_human}
												onClick={this.handleOpenModal}
												name='collateralPhotos'
												index={i + meetingSpotPhotos.length}
												path={photo.path}
											/>
										)}
									</div>
									<hr />
									<Card.Subtitle className='d-flex align-items-center'>
										Execution Photos
										<ButtonToolbar className='ml-3'>
											<ButtonGroup>
												<Button size='sm' variant='outline-primary' className='ml-3'>
													Upload Execution
													<input
														type='file'
														className='pointer'
														name='handoutPhoto'
														multiple='multiple'
														onChange={this.handleUploadPhotos}
													/>
												</Button>
											</ButtonGroup>
										</ButtonToolbar>
									</Card.Subtitle>
									<div className='photos-container mt-3 d-flex flex-wrap'>
										{/*executionPhotos && !executionPhotos.length && <em className='m-auto'>No Photos Taken</em>*/}
										{executionPhotos && executionPhotos.map((photo, i) =>
											<PhotoPreview
												onDeletePhoto={this.handleDeletePhoto}
												apiEndpoint='handoutPhoto'
												key={photo.timestamp * Math.random()}
												src={photo.url}
												timestamp={photo.timestamp}
												timeZone={gig.brand_user_activity.time_zone}
												timeZoneHuman={gig.brand_user_activity.time_zone_human}
												onClick={this.handleOpenModal}
												name='executionPhotos'
												index={i}
												path={photo.path}
											/>
										)}
									</div>
									<hr />
									<Card.Subtitle className='d-flex align-items-center'>
										Business Card Photos
										<ButtonToolbar className='ml-3'>
											<ButtonGroup>
												<Button size='sm' variant='outline-primary' className='ml-3'>
													Upload Business Card
													<input
														type='file'
														className='pointer'
														name='businessCard'
														multiple='multiple'
														onChange={this.handleUploadPhotos}
													/>
												</Button>
											</ButtonGroup>
										</ButtonToolbar>
									</Card.Subtitle>
									{/*!gig.brand_user_activity.field_day_operation_details || !gig.brand_user_activity.field_day_operation_details.business_card_photos
										? <em>There are no business cards</em>
										: <BusinessCards />
									*/}
									{<div className='photos-container mt-3 d-flex flex-wrap'>
										{/*bizCardPhotos && !bizCardPhotos.length && <em className='m-auto'>No Photos Taken</em>*/}
										{bizCardPhotos && bizCardPhotos.map((photo, i) =>
											<PhotoPreview
												onDeletePhoto={this.handleDeletePhoto}
												apiEndpoint='businessCard'
												key={photo.timestamp * Math.random()}
												src={photo.url}
												timestamp={photo.timestamp}
												timeZone={gig.brand_user_activity.time_zone}
												timeZoneHuman={gig.brand_user_activity.time_zone_human}
												notes={photo.notes}
												onClick={this.handleOpenModal}
												name='bizCardPhotos'
												index={i}
												path={photo.path}
												onRefreshBizCards={this.getGig}
												onHandleErrors={this.handleErrors}
												gigId={this.props.match.params.id}
												hotLead={photo.hotLead}
												questions={photo.questions}
											/>
										)}
									</div>}
									<hr />
									<Card.Subtitle>Contact Forms</Card.Subtitle>
									<br />
									{gig && gig.brand_user_activity.field_day_operation_details.contact_forms
										? <ContactFormsTable
												gigId={gig.brand_user_activity.id}
												onError={this.handleErrors}
											/>
										: <em>No Contact Forms</em>
									}
								</Fragment>
							}
						</Card.Body>
					</Card>
				}
			</Col>
			</Fragment>
		)
	}

	getLeads = async () => {
		try {
			const res = await axios.get(`${config.coreAPI}/lead-actions?activity_id=${this.props.match.params.id}&page_size=1000`)

			this.setState({ leadActions: res.data.responses[0].results })
		} catch(e) {
			console.error(e)
		}
	}

	handleClick = (e) => {
		if(this.gigActions && !this.gigActions.contains(e.target)) {
			// click is inside
			this.setState({
				showActions: false
			})
			// return
		}

		// if(this.trackingMap && this.trackingMap.contains(e.target)) {
		// 	return
		// }

	}

	handleSuccess = () => {
		window.scrollTo(0, 0)

		this.setState({
			done: true
		})

		setTimeout(() => this.setState({
				done: false
			}), 3000)
	}

	handleErrors = (e) => {
		window.scrollTo(0, 0)

		e.response && this.setState({
			errors: this.state.errors.includes(e.response.data.message) ? [...this.state.errors] : this.state.errors.concat([`${e.response.data.message}`])
		})

		setTimeout(() => this.setState({
			errors: []
		}), 10000)
	}

	handleRefreshGigInfo = async () => {
		const gigId = this.props.match.params.id

		try {
			const response = await axios.get(`${config.coreAPI}/fieldDayAdmin/activity/${gigId}`)
			const gig = response.data.responses[0].results[0]

			this.setState({
				gig,
				// assignedAmbassador: _.filter(gig.applied_ambassadors, ambassador => ambassador.id === gig.brand_user_activity.field_day_operation_details.assigned_ambassador_id)[0]
			})

		} catch(e) {
			this.handleErrors(e)
		}
	}

	getGig = async () => {
		const gigId = this.props.match.params.id

		try {
			const response = await axios.get(`${config.coreAPI}/fieldDayAdmin/activity/${gigId}`)
			const gig = response.data.responses[0].results[0]

			this.setState({
				gig,
				assignedAmbassador: _.filter(gig.applied_ambassadors, ambassador => ambassador.id === gig.brand_user_activity.field_day_operation_details.assigned_ambassador_id)[0]
			}, () => {
				this.getAmbassadorGeoTracks()
				this.getAssets()
			})

		} catch(e) {
			this.handleErrors(e)
		}
	}

	getTargetList = async () => {
		try {
			const gig = await axios.get(`${config.coreAPI}/fieldDayAdmin/activity/${this.props.match.params.id}`)
			const gigData = gig.data.responses[0].results[0].brand_user_activity

			if(gigData.execution_type === 'PHONE') {
				const contacts = await axios({
					method: 'get',
					url: `${config.coreAPI}/contact`,
					params: {
						activityId: gigData.id,
						pageFrom: 0,
						pageSize: 2000
					}
				})

				this.setState({
					phoneTargetList: contacts.data.responses[0].results
				})

			}

			if(gigData.execution_type === 'STREET' && gigData.activity_route_id) {
				const route = await axios({
					method: 'get',
					url: `${config.coreAPI}/route/${gigData.activity_route_id}`
				})

				this.setState({
					feetTargetList: route.data.activity_destinations
				})
			}

		} catch(e) {
			console.error(e)
		}
	}

	getAmbassadorGeoTracks = async () => {
		const gigId = this.props.match.params.id

		try {
			const ambassadorGeoTracks = await axios.get(`${config.coreAPI}/geotracks?activityId=${gigId}&pageSize=100000`)

			if(ambassadorGeoTracks.data.total > 500) {
				const skip = Math.ceil(ambassadorGeoTracks.data.total / 500)
				const geoTracks = ambassadorGeoTracks.data.results.filter((point, i, arr) => i === 0 || i % skip === 0 || i === arr.length - 1)

				this.setState({
					ambassadorGeoTracks: geoTracks
				})
			} else {
				this.setState({
					ambassadorGeoTracks: ambassadorGeoTracks.data.results
				})
			}

		} catch(e) {
			this.handleErrors(e)
		}
	}

	getTextualInfo = async () => {
		const gigId = this.props.match.params.id

		try {
			const response = await axios.get(`${config.coreAPI}/fieldDayAdmin/activity/${gigId}`)
			const gig = response.data.responses[0].results[0]

			this.setState({ gig })

		} catch(e) {
			this.handleErrors(e)
		}
	}

	getAssets = () => {
		const { gig, assignedAmbassador} = this.state
		// const gigId = gig.brand_user_activity.id
		const gigExecDetail = gig.brand_user_activity.field_day_operation_details

		this.getAmbassadorPhoto()
		this.getMeetingSpotPhotos()
		this.getCollateralPhotos()
		this.getExecutionPhotos()
		this.getBizCardPhotos()

		// try {
			// get amabassador picture
			// const ambassadorPicture = await this.getSignedUrl(assignedAmbassador.profile_photos[0].photo_url)

			// this.setState({ ambassadorPicture })

			// get meeting spot photos
			// const meetingSpotPhotos = []
			// const meetingSpotPhotosPromises = gigExecDetail.meeting_spot_photos && gigExecDetail.meeting_spot_photos.map(async (photo) => await this.getSignedUrl(photo.photo_url))

			// for(const [i, result] of meetingSpotPhotos ? meetingSpotPhotosPromises.entries() : [].entries()) {
				// const url = await result

				// console.log('url: ', url)
				// // const byteCharacters = atob(url.toString('base64'))
				// const byteCharacters = atob(url)
				// const byteNumbers = new Array(byteCharacters.length)

				// for (let i = 0; i < byteCharacters.length; i++) {
			 //    byteNumbers[i] = byteCharacters.charCodeAt(i)
				// }

				// const byteArray = new Uint8Array(byteNumbers)

				// const blob = new Blob([byteArray], {type: "image/png"})

				// loadImage(url, img => {
				// 	console.log('canvas: ', img)
				// 	meetingSpotPhotos.push({
				// 		url: img,
				// 		timestamp: gigExecDetail.meeting_spot_photos[i].photo_timestamp
				// 	})

				// 	this.setState({ meetingSpotPhotos })
				// }, {
				// 	orientation: true,
				// 	// canvas: true
				// 	// crossOrigin: true
				// })

			// 	meetingSpotPhotos.push({
			// 		url: await result,
			// 		timestamp: gigExecDetail.meeting_spot_photos[i].photo_timestamp,
			// 		path: gigExecDetail.meeting_spot_photos[i].photo_url
			// 	})
			// }

			// this.setState({ meetingSpotPhotos })

			// get collateral photos
			// const collateralPhotos = []
			// const collateralPhotosPromises = gigExecDetail.materials_photos && gigExecDetail.materials_photos.map(async (photo) => await this.getSignedUrl(photo.photo_url))

			// for(const [i, result] of collateralPhotosPromises ? collateralPhotosPromises.entries() : [].entries()) {
			// 	collateralPhotos.push({
			// 		url: await result,
			// 		timestamp: gigExecDetail.materials_photos[i].photo_timestamp,
			// 		path: gigExecDetail.materials_photos[i].photo_url
			// 	})
			// }

			// this.setState({ collateralPhotos })

			// get execution photos
			// const executionPhotos = []
			// const executionPhotosPromises = gigExecDetail.handout_photos && gigExecDetail.handout_photos.map(async (photo) => await this.getSignedUrl(photo.photo_url))

			// for(const [i, result] of executionPhotosPromises ? executionPhotosPromises.entries() : [].entries()) {

			// 	executionPhotos.push({
			// 		url: await result,
			// 		timestamp: gigExecDetail.handout_photos[i].photo_timestamp,
			// 		path: gigExecDetail.handout_photos[i].photo_url
			// 	})
			// }

			// this.setState({ executionPhotos })

			// get business card photos
			// const bizCardPhotos = []
			// const bizCardPhotosPromises = gigExecDetail.business_card_photos && gigExecDetail.business_card_photos.map(async (photo) => await this.getSignedUrl(photo.photo_url))

			// for(const [i, result] of bizCardPhotosPromises ? bizCardPhotosPromises.entries() : [].entries()) {
			// 	bizCardPhotos.push({
			// 		url: await result,
			// 		timestamp: gigExecDetail.business_card_photos[i].photo_timestamp,
			// 		notes: gigExecDetail.business_card_photos[i].notes,
			// 		path: gigExecDetail.business_card_photos[i].photo_url,
			// 		hotLead: gigExecDetail.business_card_photos[i].top_lead,
			// 	})
			// }

			// this.setState({
			// 	bizCardPhotos,
			// 	showActionsBar: true
			// })

		// } catch(e) {
		// 	console.error(e)
		// 	// this.handleErrors(e)
		// }
	}

	generateGoogleRoute = async () => {
		const { gig } = this.state
		console.log(gig)

		try {
			const route = await axios.get(`${config.coreAPI}/route/${gig.brand_user_activity.activity_route_id}`)
			const targets = route.data.activity_destinations.map(d => `${d.place.loc.latitude},${d.place.loc.longitude}`)

			const routeLink = `https://www.google.com/maps/dir/${targets.join('/')}`
			// const routeLink = `https://www.google.com/maps/place/${targets.join('/')}`

			this.setState({ googleRouteLink: routeLink })

			console.log(route.data)
		} catch(e) {
			console.error(e)
			this.handleErrors(e)
		}
	}

	getAmbassadorPhoto = async () => {
		const { assignedAmbassador} = this.state

		if(!assignedAmbassador) return

		try {
			// const signedUrl = await getAmbassadorAssetsUrl(assignedAmbassador.profile_photos[0].photo_url)
			const signedUrl = await API.post('getSignedUrl', '/getSignedUrl', {
				body: { key: assignedAmbassador.profile_photos[0].photo_url }
			})

			this.setState({ ambassadorPicture: signedUrl })
		} catch(e) {
			console.log(e)
		}
	}

	getMeetingSpotPhotos = async () => {
		const { gig } = this.state
		const gigExecDetail = gig.brand_user_activity.field_day_operation_details

		if(!gigExecDetail.meeting_spot_photos) return

		const meetingSpotPhotos = []

		try {
			// const meetingSpotPhotosPromises = gigExecDetail.meeting_spot_photos.map(async (photo) => await getGigAssetsUrl(photo.photo_url))
			const meetingSpotPhotosPromises = gigExecDetail.meeting_spot_photos.map(async (photo, i, arr) => {
				const url = await API.post('getSignedUrl', '/getSignedUrl', {
					body: { key: photo.photo_url }
				})

				meetingSpotPhotos.push({
					url: url,
					timestamp: gigExecDetail.meeting_spot_photos[i].photo_timestamp,
					path: gigExecDetail.meeting_spot_photos[i].photo_url
				})

				if(i === arr.length - 1) {
					this.setState({ meetingSpotPhotos })
				}
			})

		} catch(e) {
			console.error(e)
		}
	}

	getCollateralPhotos = async () => {
		const { gig } = this.state
		const gigExecDetail = gig.brand_user_activity.field_day_operation_details

		if(!gigExecDetail.materials_photos) return

		const collateralPhotos = []

		try {
			const collateralPhotosPromises = gigExecDetail.materials_photos.map(async (photo, i, arr) => {
				const url = await API.post('getSignedUrl', '/getSignedUrl', {
					body: { key: photo.photo_url }
				})

				collateralPhotos.push({
					url: url,
					timestamp: gigExecDetail.materials_photos[i].photo_timestamp,
					path: gigExecDetail.materials_photos[i].photo_url
				})

				if(i === arr.length - 1) {
					this.setState({ collateralPhotos })
				}
			})

		} catch(e) {
			console.error(e)
		}
	}

	getExecutionPhotos = async () => {
		const { gig } = this.state
		const gigExecDetail = gig.brand_user_activity.field_day_operation_details

		if(!gigExecDetail.handout_photos) return

		const executionPhotos = []

		try {
			const executionPhotosPromises = gigExecDetail.handout_photos.map(async (photo, i, arr) => {
				const url = await API.post('getSignedUrl', '/getSignedUrl', {
					body: { key: photo.photo_url }
				})

				executionPhotos.push({
					url: url,
					timestamp: gigExecDetail.handout_photos[i].photo_timestamp,
					path: gigExecDetail.handout_photos[i].photo_url
				})

				if(i === arr.length - 1) {
					this.setState({ executionPhotos })
				}
			})

		} catch(e) {
			console.error(e)
		}
	}

	getBizCardPhotos = async () => {
		const { gig } = this.state
		const gigExecDetail = gig.brand_user_activity.field_day_operation_details

		if(!gigExecDetail.business_card_photos) return

		const bizCardPhotos = []

		try {
			const bizCardPhotosPromises = gigExecDetail.business_card_photos.map(async (photo, i, arr) => {
				const url = await API.post('getSignedUrl', '/getSignedUrl', {
					body: { key: photo.photo_url }
				})

				bizCardPhotos.push({
					url: url,
					timestamp: gigExecDetail.business_card_photos[i].photo_timestamp,
					notes: gigExecDetail.business_card_photos[i].notes,
					path: gigExecDetail.business_card_photos[i].photo_url,
					hotLead: gigExecDetail.business_card_photos[i].top_lead,
					questions: gigExecDetail.business_card_photos[i].questions,
				})

				if(i === arr.length - 1) {
					this.setState({
						bizCardPhotos,
						// showActionsBar: true
					})
				}
			})

		} catch(e) {
			console.error(e)
		}
	}

	handleOpenModal = (modalName, slideshowName, index) => {
		const { meetingSpotPhotos, collateralPhotos, executionPhotos, bizCardPhotos } = this.state

		if(slideshowName === 'meetingSpotPhotos' || slideshowName === 'collateralPhotos') {
			this.setState({
				modalName,
				showModal: true,
				slideshowItems: meetingSpotPhotos.concat(collateralPhotos),
				slideshowIndex: index,
				slideshowName
			})
		} else if(slideshowName === 'executionPhotos') {
			this.setState({
				modalName,
				showModal: true,
				slideshowItems: executionPhotos,
				slideshowIndex: index,
				slideshowName
			})
		} else if(slideshowName === 'bizCardPhotos') {
			this.setState({
				modalName,
				showModal: true,
				slideshowItems: bizCardPhotos,
				slideshowIndex: index,
				slideshowName
			})
		} else {
			this.setState({
				showModal: true,
				showActions: false,
				modalName
			})
		}
	}

	handleCloseModal = () => {
		this.setState({
			showModal: false
		}, this.getTextualInfo)
	}

	handleUploadPhotos = async (event) => {
		const { name, files } = event.target
		const gigId = this.state.gig.brand_user_activity.id

		try {
			this.setState({ working: true })

			for(let i = 0; i < files.length; i++) {
				await this.uploadSinglePhoto(files[i], name)
			}

			this.setState({ working: false }, () => {
				this.getGig()
				// if(name === 'meetingSpotPhoto') this.getMeetingSpotPhotos()
				// if(name === 'materialsPhoto') this.getCollateralPhotos()
				// if(name === 'handoutPhoto') this.getExecutionPhotos()
				// if(name === 'businessCard') this.getBizCardPhotos()
			})

		} catch(e) {
			this.handleErrors(e)
			this.setState({ working: false })
		}
	}

	uploadSinglePhoto = async (file, name) => {
		const gigId = this.state.gig.brand_user_activity.id
		const formData = new FormData()

		formData.append('file', file, file.name)

		try {
			await axios({
	      method: 'post',
	      url: `${config.coreAPI}/fieldDayAdmin/activity/${gigId}/${name}`,
	      data: formData,
	      config: {
	        headers: { 'Content-Type': 'multipart/form-data' }
	      }
	    })
		} catch(e) {
			this.handleErrors(e)
		}
	}

	handleDeletePhoto = async (photoUrl, apiEndpoint) => {
		const gigId = this.state.gig.brand_user_activity.id

		try {
			await axios.delete(`${config.coreAPI}/fieldDayAdmin/activity/${gigId}/${apiEndpoint}?photoUrl=${photoUrl}`)

			this.getGig()
		} catch(e) {
			console.error(e)
			this.handleErrors(e)
		}
	}
}

function AmbassadorPreview({ assignedAmbassador, ambassadorPicture }) {
	return (
		<Fragment>
			<h4 className='border-bottom border-info text-center'>Assigned Ambassador</h4>
			<div className='d-flex'>
				<div>
					<img
						src={ambassadorPicture}
						alt='ambassadorImg'
						style={{maxWidth: '100%', maxHeight: '100%', borderRadius: '10px'}}
						className='m-auto'
					/>
				</div>
				<Col lg={10}>
					<Link to={`/ambassador/${assignedAmbassador.id}`}>
						<div className='font-weight-bold'>{assignedAmbassador.ambassador_name}</div>
					</Link>
					<div className='font-weight-light text-muted'>{assignedAmbassador.city}, {assignedAmbassador.state}</div>
					<div className='font-weight-light text-muted'>Gigs Completed: {assignedAmbassador.total_gigs_completed}</div>
				</Col>
			</div>
		</Fragment>
	)
}

class PhotoPreview extends Component {
	state = {
		isHotLead: this.props.hotLead
	}

	componentDidMount() {}

	render() {
		const { src, timestamp, timeZone, timeZoneHuman, notes, onClick, name, index, onDeletePhoto, path, apiEndpoint, hotLead, questions } = this.props
		const { editMode, isHotLead } = this.state

		return (
			<div>
				<div className='text-danger font-weight-bold pointer' title='Delete Photo' onClick={() => onDeletePhoto(path, apiEndpoint)}>X</div>
				<Card className='mr-4 mb-3' style={{ width: '20rem' }}>
					<Card.Img
						className='pointer'
						variant='top' src={src}
						style={{ height: '10vw' }}
						onClick={() => onClick('slideshow', name, index)}
					/>
					<Card.Body className='p-1'>
						<Card.Text className='text-center m-0' style={{ fontSize: '11px' }}>
							{moment(timestamp * 1000).tz(timeZone).format('MM/DD/YY - h:mm A')} {timeZoneHuman}
						</Card.Text>
						{name === 'bizCardPhotos' &&
							<div className='d-flex justify-content-between align-items-center'>
								<ButtonGroup>
									<Button
										size='sm'
										variant='dark'
										onClick={() => this.setState(({ editMode }) => ({ editMode: !editMode }))}
									>
										Edit Notes
									</Button>
									<Button
										size='sm'
										variant='warning'
										title={isHotLead ? 'Remove from hot leads' : 'Add to hot leads'}
										onClick={this.handleAddHotLead}
									>
										{`${isHotLead ? 'Not' : 'Hot!'}`}
									</Button>
								</ButtonGroup>
								{isHotLead && <i className='fas fa-fire-alt fa-lg mr-2' title='Hot Lead!' style={{ color: '#e25822' }}></i>}
							</div>
						}
						{name === 'bizCardPhotos' && editMode &&
							<Button
								size='sm'
								variant='info'
								onClick={this.handleSaveNotes}
							>
								save
							</Button>
						}
						{name === 'bizCardPhotos' &&
							<Card.Text>
								<textarea
									rows={5}
									defaultValue={notes}
									disabled={!editMode}
									ref={input => this.notes = input}
									style={{
										border: `${editMode ? '1px solid' : 'none'}`,
										resize: 'none',
										width: '100%',
									}}
								/>
							</Card.Text>
						}
						{name === 'bizCardPhotos' &&
							<Card.Text>
								<textarea
									rows={5}
									defaultValue={questions && this.getCardQuestions()}
									// disabled={!editMode}
									disabled={true}
									style={{
										border: `${editMode ? '1px solid' : 'none'}`,
										resize: 'none',
										width: '100%',
									}}
								/>
							</Card.Text>
						}
					</Card.Body>
				</Card>
			</div>
		)
	}

	getCardQuestions() {
		return this.props.questions.map(q => `${q.question} - ${q.answer}\n`)
	}

	handleSaveNotes = async () => {
		const { gigId, onRefreshBizCards, onHandleErrors, path } = this.props
		const notes = this.notes.value
		const formData = new FormData()

		formData.set('notes', notes)

		try {
			await axios.put(`${config.coreAPI}/fieldDayAdmin/activity/${gigId}/businessCard?photoUrl=${path}`, formData)

			onRefreshBizCards()
		} catch(e) {
			console.error(e)
			onHandleErrors(e)
		}
	}

	handleAddHotLead = async () => {
		const { gigId, path, hotLead, onHandleErrors } = this.props
		const { isHotLead } = this.state
		const isTopLead = isHotLead ? false : true
		const params = new URLSearchParams()

		params.append('photoUrls', path)
		params.append('isTopLead', isTopLead)

		try {
			await axios({
				method: 'post',
				url: `${config.coreAPI}/fieldDayAdmin/activity/${gigId}/topLeads`,
				data: params,
				headers: { 'content-type': 'application/x-www-form-urlencoded' }
			})

			// this.props.onRefreshBizCards()

			this.setState({
				isHotLead: !isHotLead
			})
		} catch(e) {
			console.error(e)
			onHandleErrors(e)
		}
	}
}

class Notes extends Component {
	state = {
		editMode: false,
		working: false,
		questions: this.props.gig.questions,
		businessesVisited: null,
		materialsHandedOut: null,
		logistics: null,
		overallExperience: null,
		additionalComments: null
	}

	render() {
		const { gig } = this.props
		const { editMode, working, questions } = this.state

		return (
			<Fragment>
				<Card.Subtitle className='mb-3'>
					Notes & Questions&nbsp;
					<i className='fas fa-edit pointer' onClick={() => this.setState(({ editMode }) => ({ editMode: !editMode }))}></i>&nbsp;
					{editMode && <Button variant='dark' size='sm' onClick={this.handleSave}>Save</Button>}
					{working && <Spinner animation='border' size='sm' variant='info' />}
				</Card.Subtitle> 
				<div className='mb-4'>
					{questions &&
						questions.map((q, i) => (
							<div key={i} className='mb-3'>
							{editMode
								? <Fragment>
										<Form.Control
											name='name'
											placeholder={`Question ${i + 1}`}
											onChange={e => this.handleQuestionInputChange(e, i)}
											defaultValue={q.name}
										/>
										<Form.Control
											as='textarea'
											rows='3'
											name='value'
											placeholder={`Answer ${i + 1}`}
											onChange={e => this.handleQuestionInputChange(e, i)}
											defaultValue={q.value}
										/>
									</Fragment>
								: <Fragment>
										<Card.Subtitle className='text-muted'>{q.name}</Card.Subtitle>
										<Card.Text className='text-muted'>{q.value}</Card.Text>
									</Fragment>
							}
							</div>
						))
					}
				</div>
				<div className='mb-4'>
					<Card.Subtitle className='text-muted'>Businesses Visited</Card.Subtitle>
					{editMode
						? <Form.Control
								name='businessesVisited'
								onChange={this.handleInputChange}
								defaultValue={gig.field_day_operation_details.feedback && gig.field_day_operation_details.feedback.businesses_visited}
							/>
						: <Card.Text>
								{gig.field_day_operation_details.feedback && gig.field_day_operation_details.feedback.businesses_visited}
							</Card.Text>
					}
				</div>
				<div className='mb-4'>
					<Card.Subtitle className='text-muted'>Materials Handed Out</Card.Subtitle>
					{editMode
						? <Form.Control
								name='materialsHandedOut'
								onChange={this.handleInputChange}
								defaultValue={gig.field_day_operation_details.feedback && gig.field_day_operation_details.feedback.materials_handed_out}
							/>
						: <Card.Text>
								{gig.field_day_operation_details.feedback && gig.field_day_operation_details.feedback.materials_handed_out}
							</Card.Text>
					}
				</div>
				<div className='mb-4'>
					<Card.Subtitle className='text-muted'>BRAND PERCEPTION</Card.Subtitle>
					{editMode
						? <Form.Control
								as='textarea'
								rows='3'
								name='logistics'
								onChange={this.handleInputChange}
								defaultValue={gig.field_day_operation_details.feedback && gig.field_day_operation_details.feedback.logistics}
							/>
						: <Card.Text>
								{gig.field_day_operation_details.feedback && gig.field_day_operation_details.feedback.logistics}
							</Card.Text>
					}
				</div>
				<div className='mb-4'>
					<Card.Subtitle className='text-muted'>EXPERIENCE</Card.Subtitle>
					{editMode
						? <Form.Control
								as='textarea'
								rows='3'
								name='overallExperience'
								onChange={this.handleInputChange}
								defaultValue={gig.field_day_operation_details.feedback && gig.field_day_operation_details.feedback.overall_experience}
							/>
						: <Card.Text>
								{gig.field_day_operation_details.feedback && gig.field_day_operation_details.feedback.overall_experience}
							</Card.Text>
					}
				</div>
				<div className='mb-4'>
					<Card.Subtitle className='text-muted'>ADDITIONAL NOTES</Card.Subtitle>
					{editMode
						? <Form.Control
								as='textarea'
								rows='4'
								name='additionalComments'
								onChange={this.handleInputChange}
								defaultValue={(gig.field_day_operation_details.feedback && gig.field_day_operation_details.feedback.additional_comments) || ''}
							/>
						: <Form.Control
								disabled
								as='textarea'
								style={{ backgroundColor: 'white', border: 'none' }}
								rows='4'
								name='additionalComments'
								onChange={this.handleInputChange}
								value={(gig.field_day_operation_details.feedback && gig.field_day_operation_details.feedback.additional_comments) || ''}
							/>
					}
				</div>
			</Fragment>
		)
	}

	handleInputChange = (event) => {
		const { name, value } = event.target

		this.setState({ [name]: value })
	}

	handleQuestionInputChange = (event, index) => {
		const { name, value } = event.target
		const questions = this.state.questions.map(el => ({...el}))

		questions[index] = {
			...questions[index],
			[name]: value
		}

		this.setState({ questions })
	}

	handleSave = async () => {
		const { gig, onRefreshGigInfo, onError } = this.props
		const feedback = Object.assign({}, this.props.gig.field_day_operation_details.feedback)
		const { logistics, overallExperience, additionalComments, businessesVisited, materialsHandedOut, questions } = this.state

		if(businessesVisited) feedback.businesses_visited = businessesVisited
		if(materialsHandedOut) feedback.materials_handed_out = materialsHandedOut
		if(logistics) feedback.logistics = logistics
		if(overallExperience) feedback.overall_experience = overallExperience
		if(additionalComments) feedback.additional_comments = additionalComments

		this.setState({ working: true })

		try {
			await axios.put(`${config.coreAPI}/fieldDayAdmin/activity/${gig.id}/feedback`, feedback)

			questions && questions.length && await axios.post(`${config.coreAPI}/activity/${gig.id}`, { questions })

			this.setState({
				working: false,
				editMode: false
			}, () => onRefreshGigInfo())
		} catch(e) {
			this.setState({
				working: false
			}, () => onError(e))
		}
	}
}

export default GigTile
