import React, { Component } from 'react'
import axios from 'axios'
import { Modal, Form, Col } from 'react-bootstrap'
import { Button } from 'semantic-ui-react'
import { times } from 'underscore'

import config from '../../../../config'
import Question from './Question'

class QuestionGroup extends Component {
	state = {
		tag_name: this.props.group ? this.props.group.tag_name : '',
		business_card_questions: this.props.group ? this.props.group.business_card_questions : [],
		numOfQuestions: this.props.group ? this.props.group.business_card_questions.length : 0,
	}

	render() {
		const { showModal, onClose } = this.props
		const { tag_name, numOfQuestions, business_card_questions } = this.state
		const questionComponents = []

		for(let i = 0; i < numOfQuestions; i++) {
			questionComponents.push(
				<Question
					key={'key' + i}
					index={i}
					onDeleteQuestion={this.handleDeleteQuestion}
					characterMin={business_card_questions[i].character_min}
					onCharMinChange={this.handleCharMinChange}
					shortAnswerEnabled={business_card_questions[i].short_answer_enabled}
					onShortAnswerEnableChange={this.handleShortAnswerEnableChange}
					question={business_card_questions[i].question}
					onQuestionInputChange={this.handleQuestionInputChange}
					answers={business_card_questions[i].answers}
					onAddAnswer={this.handleAddAnswer}
					onAnswerInputChange={this.handleAnswerInputChange}
					onDeleteAnswer={this.handleDeleteAnswer}
				/>
			)
		}

		return (
			<Modal
				size='lg'
				centered
				dialogClassName='modal-50w'
				show={showModal}
				onHide={onClose}
			>
				<Modal.Header closeButton>
					<Modal.Title>Question Group</Modal.Title>
				</Modal.Header>
				<Form onSubmit={this.handleSubmit}>
					<Modal.Body>
							<Form.Row>
								<Col lg={5}>
									<Form.Group>
										<Form.Label>Name</Form.Label>
										<Form.Control
										  type='text'
										  name='tag_name'
										  value={tag_name}
										  onChange={e => this.setState({ tag_name: e.target.value })}
										/>
									</Form.Group>
								</Col>
							</Form.Row>
							<div className='d-flex border-bottom border-dark justify-content-between align-items-center mt-3'>
								<h3>Questions</h3>
								<Button
									className='ml-2'
									color='blue'
									icon='add'
									size='tiny'
									onClick={this.handleAddQuestion}
									// onClick={e => {
									// 	e.preventDefault()
									// 	setQuestions(questions.concat([
									// 		<Questions key={'key' + questions.length} index={questions.length} onDeleteQuestion={handleDeleteQuestion} />
									// 	]))
									// }}
								/>
							</div>
							{/*times(numOfQuestions, (i) => <Questions key={'key' + i} index={i} onDeleteQuestion={handleDeleteQuestion} />)*/}
							{/*questions.map((group, i) => <Questions key={'key' + i} index={i} onDeleteQuestion={handleDeleteQuestion} />)*/}
							{questionComponents}
					</Modal.Body>
					<Modal.Footer>
						<Button color='blue' type='submit'>Save</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		)

	}

	handleAddQuestion = (e) => {
		e.preventDefault()

		this.setState(({ numOfQuestions, business_card_questions }) => ({
			numOfQuestions: numOfQuestions + 1,
			business_card_questions: [...business_card_questions].concat([{
				character_min: 0,
				short_answer_enabled: false,
				question: '',
				answers: []
			}])
		}))
	}

	handleDeleteQuestion = (e, index) => {
		e.preventDefault()

		this.setState(({ numOfQuestions, business_card_questions }) => ({
			numOfQuestions: numOfQuestions - 1,
			business_card_questions: business_card_questions.filter((q, i) => i !== index)
		}))
	}

	handleCharMinChange = (value, index) => {
		const businessCardQuestions = [...this.state.business_card_questions]
		businessCardQuestions[index].character_min = value

		this.setState({ business_card_questions: businessCardQuestions })
	}

	handleShortAnswerEnableChange = (value, index) => {
		const businessCardQuestions = [...this.state.business_card_questions]
		businessCardQuestions[index].short_answer_enabled = value

		this.setState({ business_card_questions: businessCardQuestions })
	}

	handleQuestionInputChange = (value, index) => {
		const businessCardQuestions = [...this.state.business_card_questions]
		businessCardQuestions[index].question = value

		this.setState({ business_card_questions: businessCardQuestions })
	}

	handleAddAnswer = (e, index) => {
		e.preventDefault()

		const businessCardQuestions = [...this.state.business_card_questions]
		businessCardQuestions[index].answers = this.state.business_card_questions[index].answers.concat([''])

		this.setState({ business_card_questions: businessCardQuestions })
	}

	handleAnswerInputChange = (value, questionIndex, answerIndex) => {
		const businessCardQuestions = [...this.state.business_card_questions]
		businessCardQuestions[questionIndex].answers[answerIndex] = value

		this.setState({ business_card_questions: businessCardQuestions })
	}

	handleDeleteAnswer = (e, questionIndex, answerIndex) => {
		e.preventDefault()

		const businessCardQuestions = [...this.state.business_card_questions]
		businessCardQuestions[questionIndex].answers = this.state.business_card_questions[questionIndex].answers.filter((a, i) => i !== answerIndex)

		this.setState({ business_card_questions: businessCardQuestions })
	}

	handleSubmit = async (e) => {
		e.preventDefault()

		const { tag_name, business_card_questions } = this.state
		const { brandId, onClose,allGroups } = this.props

		if(!this.state.tag_name) {
			alert('Name is required')
			return
		}

		try {
			await axios.patch(`${config.coreAPI}/brand/${brandId}/fieldDaySettings`, {
				business_card_question_settings: allGroups.concat([{
					tag_name,
					business_card_questions
				}])
			})

			onClose()
		} catch(e) {
			console.error(e)
			this.setState({ errors: e })
		}
	}
}

export default QuestionGroup
