import React, { Fragment, useEffect, useState } from 'react'
import ReactTable from 'react-table'
import axios from 'axios'
import moment from 'moment-timezone'
import { find, reduce, map } from 'underscore'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import config from '../../config'
import LeadModal from './LeadModal'
import TranscriptionModal from './TranscriptionModal'
import excelExporter from '../../utils/excelExporter'

export default function LeadsList({ gig, lead_actions }) {
	const [contacts, setContacts] = useState(null)
	const [leadActions, setLeadActions] = useState(lead_actions)
	const [leadsData, setLeadsData] = useState([])
	const [showLeadModal, setShowLeadModal] = useState(false)
	const [selectedLead, setSelectedLead] = useState(null)
	const [selectedTranscription, setSelectedTranscription] = useState(null)
	// const [selectedLead2, setSelectedLead2] = useState(null)

	useEffect(() => {
		getContacts()
		// getLeads()
	}, [])

	useEffect(() => {
		getLeadsData()
	}, [contacts, leadActions])

	const getContacts = async () => {
		try {
			const res = await axios.get(`${config.coreAPI}/contact?activityId=${gig.id}&pageSize=1000`)

			setContacts(res.data.responses[0].results)
		} catch(e) {
			console.error(e)
		}
	}

	const getLeads = async () => {
		try {
			const res = await axios.get(`${config.coreAPI}/lead-actions?activity_id=${gig.id}&page_size=1000`)

			setLeadActions(res.data.responses[0].results)
		} catch(e) {
			console.error(e)
		}
	}

	const getLeadsData = () => {
		if(!contacts && !leadActions) return null

		const leadList = reduce(leadActions, (accum, el) => {
			const contact = find(contacts, con => con.id === el.associated_entity_id)

			accum.push({
				leadActionId: el.id,
				...el,
				...contact
			})

			return accum
		}, [])

		setLeadsData(leadList)
	}

	const editLeadAction = async (value, leadActionId) => {
		try {
			await axios.patch(`${config.coreAPI}/lead-actions/${leadActionId}`, {
				notes: value
			})

			setTimeout(() => {
				getLeads()
			}, 1000)

			setSelectedLead(null)
		} catch(e) {
			console.error(e)
		}
	}

	const editContact = async (value, contactId) => {
		try {
			await axios.patch(`${config.coreAPI}/contact/${contactId}`, {
				frequency_of_need: value
			})

			setTimeout(() => {
				getContacts()
			}, 1000)

			setSelectedLead(null)
		} catch(e) {
			console.error(e)
		}
	}

	const handleCloseModal = () => {
		setSelectedLead(null)
		// getLeads()
	}

	const handleExport = () => {
		const filename = `Call List - ${gig.title}`
		const columns = [
  		'Business',
  		'Contact Name',
  		'Contact Title',
  		'Person Reached',
  		'Email',
  		'Employee Size',
  		'Address',
  		'City',
  		'State',
  		'Zip',
  		'Phone',
  		'Fax',
  		'Date',
  		'Time',
  		'Call Duration (seconds)',
  		'Lead Score',
			'Frequency of Need',
			'Sampling',
			'Catering',
  		'Notes',
  		'SIC Codes',
  		'SIC Categories',
  		'Call Recording',
  	]

		const headerRow = map(columns, cell => ({ value: cell, type: 'string'}))

		const contentRows = map(leadsData, place => ([
  		{
  			value: place.company_name,
  			type: 'string'
  		},
  		{
  			value: place.contact_name,
  			type: 'string'
  		},
  		{
  			value: place.contact_title,
  			type: 'string'
  		},
  		{
  			value: place.person_reached_name,
  			type: 'string'
  		},
  		{
  			value: place.contact_email,
  			type: 'string'
  		},
  		{
  			value: place.employee_size >= 0 ? Number(place.employee_size) : '',
  			type: 'number'
  		},
  		{
  			value: place.company_address,
  			type: 'string'
  		},
  		{
  			value: place.city,
  			type: 'string'
  		},
  		{
  			value: place.state,
  			type: 'string'
  		},
  		{
  			value: place.postal_code,
  			type: 'string'
  		},
  		{
  			value: place.phone_1,
  			type: 'string'
  		},
  		{
  			value: place.phone_3, // phone 3 is being used for fax number for now
  			type: 'string'
  		},
  		{
  			value: moment(place.action_date).tz(gig.time_zone).format('MM/DD/YYYY'),
  			type: 'string'
  		},
  		{
  			value: moment(place.action_date).tz(gig.time_zone).format('hh:mm a'),
  			type: 'string'
  		},
  		{
  			value: place.duration || '',
  			type: 'number'
  		},
  		{
  			value: place.lead_score || '',
  			type: 'number'
  		},
			{
				value: place.frequency_of_need || '',
				type: 'string'
			},
			{
				value: place.questions && place.questions.filter(q => q.question === 'SAMPLING' && q.answer === 'true').length ? 'Yes' : '',
				type: 'string'
			},
			{
				value: place.questions && place.questions.filter(q => q.question === 'CATERING' && q.answer === 'true').length ? 'Yes' : '',
				type: 'string'
			},
  		{
  			value: place.notes,
  			type: 'string'
  		},
  		{
  			value: place.category_codes ? place.category_codes.map(category => category.category_code_id).join(' - ') : '',
  			type: 'string'
  		},
  		{
  			value: place.category_codes ? place.category_codes.map(category => category.category_code_description).join('; ') : '',
  			type: 'string'
  		},
  		{
  			value: place.recording_url,
  			type: 'string'
  		},
  	]))

  	contentRows.unshift(headerRow)

		const config = {
		  filename,
		  sheet: {
		    data: contentRows
		  }
		}

		excelExporter(config)
  }

	// console.log('contacts: ', contacts)
	// console.log('leadActions: ', leadActions)
	// console.log('leadsData: ', leadsData)
	// console.log('LeadsList gig: ', gig)

	return (
		<Fragment>
			{selectedLead ? <LeadModal lead={selectedLead} onEditLeadAction={editLeadAction} onClose={handleCloseModal} /> : null}
			{selectedTranscription
				? <TranscriptionModal
						lead={selectedLead}
						selectedTranscription={selectedTranscription}
						onClose={() => setSelectedTranscription(null)}
					/>
				: null}
			<div className='d-flex align-items-center justify-content-between mb-3'>
				<h3>Calls</h3>
				<Button onClick={handleExport}>
					Export
				</Button>
			</div>
			<ReactTable
				data={leadsData}
				className='-striped -highlight table-style'
				noDataText={'No places found'}
				defaultPageSize={10}
				// showPageSizeOptions={false}
				filterable
				columns={[
					{
						Header: 'Business',
						id: 'business',
						headerClassName: 'table-header',
						className: 'table-column',
						filterMethod: (filter, row) => row[filter.id].props.children && row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase()),
						accessor: d => <span className='text-left'>{d.company_name}</span>
					},
					{
						Header: 'Address',
						id: 'address',
						headerClassName: 'table-header',
						className: 'table-column',
						filterMethod: (filter, row) => row[filter.id].props.children && row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase()),
						accessor: d => <span className='text-left'>{`${d.company_address}, ${d.city}, ${d.state} ${d.postal_code}`}</span>
					},
					{
						Header: 'Contact Name',
						id: 'contactName',
						headerClassName: 'table-header',
						className: 'table-column',
						filterMethod: (filter, row) => row[filter.id].props.children && row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase()),
						accessor: d => <span className='text-left'>{d.contact_name}</span>
					},
					{
						Header: 'Person Reached',
						id: 'personReached',
						headerClassName: 'table-header',
						className: 'table-column',
						filterMethod: (filter, row) => row[filter.id].props.children && row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase()),
						accessor: d => <span className='text-left'>{d.person_reached_name}</span>
					},
					{
						Header: 'Duration (seconds)',
						id: 'duration',
						headerClassName: 'table-header',
						className: 'table-column',
						filterMethod: (filter, row) => row[filter.id].props.children && row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase()),
						accessor: d => <span className='text-left'>{d.duration ? Number(d.duration) : 0}</span>
						// accessor: d => <span className='text-left'>{d.duration ? getDuration(d.duration) : `0s`}</span>
					},
					{
						Header: 'Recording',
						id: 'recording',
						headerClassName: 'table-header',
						className: 'table-column',
						filterable: false,
						sortable: false,
						filterMethod: (filter, row) => row[filter.id].props.children && row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase()),
						accessor: d => (
							<audio controls style={{ height: '10px' }} key={d.recording_url}>
								<source src={d.recording_url} type='audio/ogg' />
								<source src={d.recording_url} type='audio/mpeg' />
							</audio>
						)
						// accessor: d => <span className='text-left' onClick={() => window.open(d.recording_url)}><i className='fas fa-volume-up pointer'></i></span>
					},
					{
						Header: 'Call Transcription',
						id: 'callTranscription',
						headerClassName: 'table-header',
						className: 'table-column',
						filterMethod: (filter, row) => row[filter.id].props.children && row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase()),
						accessor: d => d.symbl_conversation_id && <Button variant='outline-dark' onClick={() => setSelectedTranscription(d.symbl_conversation_id)}>open</Button>
					},
					{
						Header: 'Phone',
						id: 'phone',
						headerClassName: 'table-header',
						className: 'table-column',
						filterMethod: (filter, row) => row[filter.id].props.children && row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase()),
						accessor: d => <span className='text-left'>{d.phone_1}</span>
					},
					{
						Header: 'Email',
						id: 'email',
						headerClassName: 'table-header',
						className: 'table-column',
						filterMethod: (filter, row) => row[filter.id].props.children && row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase()),
						accessor: d => <span className='text-left'>{d.contact_email}</span>
					},
					{
						Header: 'Employee Size',
						id: 'employeeSize',
						headerClassName: 'table-header',
						className: 'table-column',
						filterMethod: (filter, row) => row[filter.id].props.children && row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase()),
						accessor: d => <span className='text-left'>{d.employee_size}</span>
					},
					{
						Header: 'Frequency of Need',
						id: 'frequencyOfNeed',
						headerClassName: 'table-header',
						className: 'table-column',
						filterMethod: (filter, row) => row[filter.id].props.children && row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase()),
						// accessor: d => <span onClick={() => setSelectedLead2(d)} className='text-left pointer'>{d.frequency_of_need}</span>
						accessor: d => <span>{d.frequency_of_need}</span>
					},
					{
						Header: 'Notes',
						id: 'notes',
						headerClassName: 'table-header',
						className: 'table-column',
						filterMethod: (filter, row) => row[filter.id].props.children && row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase()),
						accessor: d => <span onClick={() => setSelectedLead(d)} className='text-left pointer'>{d.notes}</span>
					},
					{
						Header: 'Date',
						id: 'date',
						headerClassName: 'table-header',
						className: 'table-column',
						filterMethod: (filter, row) => row[filter.id].props.children && row[filter.id].props.children.toLowerCase().includes(filter.value.toLowerCase()),
						accessor: d => <span className='text-left date-col'>{moment(d.action_date).tz(gig.time_zone).format('MM/DD/YYYY - h:mm a')} ({d.action_date})</span>
					},
				]}
				defaultSortMethod={(a, b, desc) => {
					const aIsDateCol = a.props.className.includes('date-col')
					const bIsDateCol = b.props.className.includes('date-col')

					// force null and undefined to the bottom
					a = a.props.children === null || a.props.children === undefined ? '' : a.props.children
					b = b.props.children === null || b.props.children === undefined ? '' : b.props.children

					// hacking sort for date column to work timestamp
					a = aIsDateCol ? a[2] : a
					b = bIsDateCol ? b[2] : b

					// force any string values to lowercase
					a = typeof a === 'string' ? a.toLowerCase().replace(/,/g, '').replace(/\$/g, '') : a
					b = typeof b === 'string' ? b.toLowerCase().replace(/,/g, '').replace(/\$/g, '') : b

					if(Number(a) || Number(b)) {
						a = Number(a)
						b = Number(b)
					}

					// Return either 1 or -1 to indicate a sort priority
					if (a > b) {
					  return 1
					}

					if (a < b) {
					  return -1
					}
					// returning 0, undefined or any falsey value will use subsequent sorts or
					// the index as a tiebreaker
					return 0
				}}
			/>
		</Fragment>
	)
}
