import React, { useState } from 'react'
import axios from 'axios'
import { Button, Checkbox } from 'semantic-ui-react'
import Form from 'react-bootstrap/Form'

import config from '../../config'

function PhoneTargetsUpload({ brandId, gig }) {
	const [uploadingFile, setUploadingFile] = useState(false)
	const [fileUploaded, setFileUploaded] = useState(false)
	const [deletingRoute, setDeletingRoute] = useState(false)
	const [routeDeleted, setRouteDeleted] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [selectedAll, setSelectedAll] = useState(true)
	const [selectedGigs, setSelectedGigs] = useState(gig.activity_group && gig.activity_group.child_activities && gig.activity_group.child_activities.map(childGig => childGig.activity_id))
	const [appendTargets, setAppendTargets] = useState(false)
console.log("GIG: ", gig)
	return (
		<div>
			{gig.activity_group && gig.activity_group.group_master
				? <div className='mb-4'>
						<b>Upload targets to these gigs only:</b>
						<Checkbox
							key='all'
							className='d-block mt-3'
							label='All'
							checked={selectedAll}
							onChange={(e, { name, checked }) => {
								setSelectedAll(checked)
								checked ? setSelectedGigs(gig.activity_group.child_activities.map(childGig => childGig.activity_id)) : setSelectedGigs([])
							}}
						/>
						{gig.activity_group.child_activities.map(childGig => (
							<Checkbox
								key={childGig.activity_id}
								className='d-block'
								label={`${childGig.title} (${childGig.activity_id})`}
								checked={selectedGigs.includes(childGig.activity_id)}
								disabled={selectedAll}
								onChange={ (e, { name, checked }) => checked
									? setSelectedGigs([...new Set(selectedGigs.concat([childGig.activity_id]))])
									: setSelectedGigs(selectedGigs.filter(id => id !== childGig.activity_id))
								}
							/>
						))}
					</div>
				: <Form.Check
						type='checkbox'
						label='Add more targets to the existing list'
						className='mb-4'
						checked={appendTargets}
						onChange={e => setAppendTargets(!appendTargets)}
					/>
			}
			<div className='d-flex justify-content-around'>
				<div className='position-relative'>
				  <input
				  	type='file'
				  	accept='.xls,.xlsx'
				  	className='custom-file-input'
				  	id='customFile'
				  	onChange={uploadFile}
			  	/>
				  <Button primary loading={uploadingFile}>Upload Phone Targets</Button>
				  {fileUploaded && <i className='fas fa-check text-success'></i>}
				</div>
				<div>
				  <Button negative onClick={deletePhoneTargets} loading={deletingRoute}>Delete All Targets</Button>
				  {routeDeleted && <i className='fas fa-check text-success'></i>}
				</div>
			</div>
			<div className='text-danger'>
				{errorMessage}
			</div>
		</div>
	)

	function uploadFile(e) {
		const file = e.target.files[0]

		if(!file) return

		const fileReader = new FileReader()

		fileReader.readAsBinaryString(file)

		fileReader.onload = async (event) => {
			const data = event.target.result
			const workbook = window.XLSX.read(data, { type: 'binary' })
			let targetList

			workbook.SheetNames.forEach(sheet => {
				targetList = window.XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheet])
				targetList = targetList.map(target => {
					target.labels = {}

					if(target.sic_code_ids) {
						target.sic_code_ids = String(target.sic_code_ids).split(' - ')
					}

					if(target.sic_code_categories) {
						target.labels.sic_code_categories = target.sic_code_categories.split('; ')
					}

					if(target.primary_sic_code_id) {
						target.primary_sic_code_id = String(target.primary_sic_code_id)
					}

					if(target.primary_sic_code_category) {
						target.labels.primary_sic_code_category = target.primary_sic_code_category
					}

					return target
				})
				console.log('targetList: ', targetList)
			})

			const formName = 'places'
			const formData = new FormData()

			formData.append('places', JSON.stringify(targetList))

			let gigIds
			const appendToList = appendTargets ? '&append=true' : ''

			if(!gig.activity_group || !gig.activity_group.group_master) {
				gigIds = gig.id
			} else if(gig.activity_group.group_master && selectedAll) {
				gigIds = gig.activity_group.group_child_ids.join('|')
			} else if(gig.activity_group.group_master && !selectedAll && selectedGigs.length) {
				gigIds = selectedGigs.join('|')
			} else if(gig.activity_group.group_master && !selectedAll && !selectedGigs.length) {
				setErrorMessage('You must select a gig')
				return
			} else {
				setErrorMessage('No activity ID provided')
				return
			}

			try {
				setErrorMessage('')
				setUploadingFile(true)

				await axios.post(`${config.coreAPI}/phone/activities/targets?activityIds=${gigIds}${appendToList}`, formData, {
					headers: {
						'content-type': 'multipart/form-data'
					}
				})

				setUploadingFile(false)
				setFileUploaded(true)
			} catch(e) {
				console.error(e)
				setUploadingFile(false)

				if(e && e.response && e.response.data) {
					setErrorMessage(e.response.data.message)
				} else {
					setErrorMessage('Error submitting the list')
				}
			}
		}
	}

	async function deletePhoneTargets() {
		const confirm = window.confirm('This will delete all contacts associated witht this gig. Are you sure?')

		if(!confirm) return

		let isMaster

		if(!gig.activity_group || !gig.activity_group.group_master) {
			isMaster = false
		} else if(gig.activity_group.group_master) {
			isMaster = true
		} else {
			setErrorMessage('No master status provided')
			return
		}

		if(isMaster) {
			setErrorMessage('You can only delete targets on child gigs.')
			return
		}

		try {
			setErrorMessage('')
			setDeletingRoute(true)

			// const targetIds = gig.field_day_operation_details.phone_targets.map(t => t.target_id).join('|')

			await axios.delete(`${config.coreAPI}/phone/activity/${gig.id}/targets?deleteAllTargets=true`)

			setDeletingRoute(false)
			setRouteDeleted(true)

		} catch(e) {
			console.error(e)
			setDeletingRoute(false)

			if(e && e.response && e.response.data) {
				setErrorMessage(e.response.data.message)
			} else {
				setErrorMessage('Error deleting the history')
			}
		}
	}
}

export default PhoneTargetsUpload
