import React, { useState } from 'react'
import { Dropdown, Segment } from 'semantic-ui-react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import expander_icon from '../../assets/img/expander_icon.png'
import attachments_icon from '../../assets/img/attachments_icon.png'

export default function Description({ gigState, onInputChange, campaigns, trainings, onOpenSecondModal }) {
	// const [isNotCampaign, setIsNotCampaign] = useState(typeof gigState.newCampaign === 'undefined' ? true : gigState.newCampaign === null)
	const [isNewCampaign, setIsNewCampaign] = useState(typeof gigState.newCampaign === 'undefined' ? false : gigState.newCampaign === true)
	const [isExistingCampaign, setIsExistingCampaign] = useState(typeof gigState.newCampaign === 'undefined' ? false : gigState.newCampaign === false)

	const toggleNewCampaign = (e) => {
		const { name, checked } = e.target

		if(name === 'notCampaign') {
			// setIsNotCampaign(true)
			setIsNewCampaign(false)
			setIsExistingCampaign(false)
			onInputChange('newCampaign', null)
		}

		if(name === 'newCampaign') {
			// setIsNotCampaign(false)
			setIsNewCampaign(true)
			setIsExistingCampaign(false)
			onInputChange('newCampaign', true)
		}

		if(name === 'existingCampaign') {
			// setIsNotCampaign(false)
			setIsNewCampaign(false)
			setIsExistingCampaign(true)
			onInputChange('newCampaign', false)
		}
	}

	const campaignsDropdown = campaigns.map(campaign => ({
		key: campaign.campaign_id,
		value: campaign.campaign_id,
		text: `${campaign.campaign_name} (${campaign.campaign_id})`,
	}))

	const trainingsDropdown = trainings.map(training => ({
		key: training.id,
		value: training.id,
		text: training.name,
	}))

	const handleAddAttachment = (e) => {
		e.preventDefault()

		const file = e.target.files[0]

		onInputChange('attachments', gigState.attachments.concat([file]))
	}

	return (
		<Row>
			<Col className='d-flex flex-column'>
				<div className='d-flex justify-content-around mb-3' style={{ fontSize: '13px' }}>
					{/*<Form.Check
						type='radio'
						name='notCampaign'
						label='Single Gig'
						checked={isNotCampaign}
						onChange={toggleNewCampaign}
					/>*/}
					<Form.Check
						type='radio'
						name='newCampaign'
						label='New Campaign'
						checked={isNewCampaign}
						onChange={toggleNewCampaign}
					/>
					<Form.Check
						type='radio'
						name='existingCampaign'
						label='Existing Campaign'
						checked={isExistingCampaign}
						onChange={toggleNewCampaign}
					/>
				</div>
				{isNewCampaign &&
					<InputBox
						title={'Campaign Name'}
						name={'campaign_name'}
						value={gigState.campaign_name}
						onInputChange={onInputChange}
					/>
				}
				{isExistingCampaign &&
					<div className='mb-2'>
						<Dropdown
							clearable
							search
							selection
							placeholder='Select campaign'
							className='w-100 dropdown-bg-green'
							style={{ backgroundColor: '#0797a6' , fontWeight: '700', color: 'white' }}
							name='campaign_id'
							options={campaignsDropdown}
							value={gigState.campaign_id}
							onChange={(e, { name, value }) => onInputChange(name, value)}
						/>
					</div>
				}
				<InputBox
					title={'Title'}
					name={'title'}
					value={gigState.title}
					onInputChange={onInputChange}
				/>
				{/*<InputBox
					title={'Jira Issue ID'}
					name={'jira_issue_id'}
					value={gigState.jira_issue_id}
					onInputChange={onInputChange}
				/>*/}
				<div className='mb-2'>
					<Dropdown
						clearable
						search
						selection
						placeholder='Select training'
						className='w-100 dropdown-bg-green'
						style={{ backgroundColor: '#0797a6' , fontWeight: '700', color: 'white' }}
						name='training_plan_id'
						options={trainingsDropdown}
						value={gigState.training_plan_id}
						onChange={(e, { name, value }) => onInputChange(name, value)}
					/>
				</div>
			</Col>
			<Col className='d-flex flex-column'>
				<TextBox
					title='Description'
					name='description'
					value={gigState.description}
					onInputChange={onInputChange}
					onOpenSecondModal={onOpenSecondModal}
				/>
				<TextBox
					title='Best Practices'
					name='best_practices'
					value={gigState.best_practices}
					onInputChange={onInputChange}
					onOpenSecondModal={onOpenSecondModal}
				/>
				<TextBox
					title='Brand Details'
					name='brand_details'
					value={gigState.brand_details}
					onInputChange={onInputChange}
					onOpenSecondModal={onOpenSecondModal}
				/>
			</Col>
			<Col className='d-flex flex-column'>
				<TextBox
					title='Script'
					name='script'
					value={gigState.script}
					onInputChange={onInputChange}
					onOpenSecondModal={onOpenSecondModal}
				/>
				<AttachmentsUpload onAddAttachment={handleAddAttachment} />
				{gigState.attachments.map(att =>
					<div key={att.lastModified}>
						{att.name}&nbsp;
						<span
							className='text-danger pointer'
							onClick={() => onInputChange('attachments', gigState.attachments.filter(att2 => att2.name !== att.name))}
						>
							[X]
						</span>
					</div>
				)}
			</Col>
		</Row>
	)
}

function InputBox({ title, name, value, onInputChange }) {
	return (
		<div className='input-box mb-2'>
			<span className='pl-2'>{title}</span>
			<Form.Control
				name={name}
				value={value || ''}
				onChange={e => onInputChange(name, e.target.value)}
			/>
		</div>
	)
}

function TextBox({ title, name, value, onInputChange, onOpenSecondModal }) {
	return (
		<Segment raised className='p-0 mb-1 mt-0'>
			<div className='text-center text-white font-weight-bold' style={{ backgroundColor: '#f49e23' }}>{title}</div>
			<textarea
				className='w-100'
				rows={3}
				name={name}
				value={value || ''}
				onChange={e => onInputChange(name, e.target.value)}
				style={{
					border: 'none',
					resize: 'none'
				}}
			/>
			<div className='des-text-box-expander pointer' onClick={() => onOpenSecondModal(title, name)}>
				<img src={expander_icon} alt='' />
			</div>
		</Segment>
	)
}

function AttachmentsUpload({ onAddAttachment }) {
	return (
		<div className='d-flex mt-3'>
			<div style={{ width: '60px', height: '60px' }}>
				<img className='img-fit' src={attachments_icon} alt='' />
			</div>
			<div className='ml-2'>
				<h4 className='fd-green'>Attachments</h4>
				<div className='position-relative'>
				  <input type='file' className='custom-file-input' id='customFile' onChange={onAddAttachment} />
				  <Button size='sm' variant='light'>Add Attachment</Button>
				</div>
			</div>
		</div>
	)
}
