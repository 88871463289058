import { applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

// Since application state or actions payloads are too large making Redux DevTools serialization slow and consuming a lot of memory, this is to ommit large
// data. https://git.io/fpcP5
const actionSanitizer = (action) => (
  action.ambassadors || action.gigs ?
  { ...action, data: '<<LONG_BLOB>>' } : action
)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
	? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
	  actionSanitizer,
	  stateSanitizer: (state) => state.data ? { ...state, data: '<<LONG_BLOB>>' } : state
	})
	: compose

export default composeEnhancers(applyMiddleware(thunk))
