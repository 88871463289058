import React, { Component, Fragment } from 'react'
import Button from 'react-bootstrap/Button'

class Slideshow extends Component {
	state = {
		currentSlide: this.props.slideNumber
	}

	componentDidMount() {
		document.addEventListener('keydown', this.handleKeyDown)
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.handleKeyDown)
	}

	render() {
		const { currentSlide } = this.state
		const { items, onDeletePhoto, profilePhotos, onSetPhotoAsDefault } = this.props

		return (
			<div className='slideshow-container'>
				<div className='d-flex justify-content-between mb-3'>
					<div>{currentSlide + 1}/{items.length}</div>
					{profilePhotos && profilePhotos.length &&
						<Fragment>
							{profilePhotos[currentSlide].default_photo
								? <b>Default Photo</b>
								: <Button size='sm' onClick={() => onSetPhotoAsDefault(profilePhotos[currentSlide].photo_id)}>Set as default</Button>
							}
						</Fragment>
					}
					{onDeletePhoto && profilePhotos && profilePhotos[currentSlide] &&
						<div>
							<i className="fas fa-trash pointer text-danger" onClick={() => onDeletePhoto(profilePhotos[currentSlide].photo_id)} />
						</div>
					}
				</div>
				<div className='slideshow-img-container'>
					<i className="fas fa-chevron-left fa-2x pointer" onClick={this.handlePrev} />
					<div className='slideshow-img-wrapper'>
						<img src={typeof items[currentSlide] === 'string' ? items[currentSlide] : items[currentSlide].url} alt='' />
					</div>
					<i className="fas fa-chevron-right fa-2x pointer" onClick={this.handleNext} />
				</div>
			</div>
		)
	}

	handlePrev = () => {
		const { currentSlide } = this.state
		const { items } = this.props

		this.setState({
			currentSlide: currentSlide > 0 ? currentSlide - 1 : items.length - 1
		})
	}

	handleNext = () => {
		const { currentSlide } = this.state
		const { items } = this.props

		this.setState({
			currentSlide: currentSlide < items.length - 1 ? currentSlide + 1 : 0
		})
	}

	handleKeyDown = (e) => {
		if(e.keyCode === 37) {
			this.handlePrev()
		}

		if(e.keyCode === 39) {
			this.handleNext()
		}
	}
}

export default Slideshow
