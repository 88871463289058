import React, { Fragment, useEffect, useState } from 'react'
import axios from 'axios'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { each, map, filter } from 'underscore'
import { remove } from 'lodash'

import config from '../config'
import CoreInformation from '../components/places/CoreInformation'
import BrandStores from '../components/places/BrandStores'
import FiltersBar from '../components/places/FiltersBar'
import PlacesMap from '../components/places/PlacesMap'
import PlacesTable from '../components/places/PlacesTable'
import excelExporter from '../utils/excelExporter'

function Places() {
	const [places, setPlaces] = useState([])
	const [scanResults, setScanResults] = useState([])
	const [gettingScanResults, setGettingScanResults] = useState(false)
	const [count, setCount] = useState([])
	const [filters, setFilters] = useState([])
	const [page, setPage] = useState(0)
	const [pageSize, setPageSize] = useState(20)
	const [stores, setStores] = useState(null)
	const [matchAny, setMatchAny] = useState(false)
	const [extraFilter, setExtraFilter] = useState(false)
	const [filters2, setFilters2] = useState([])
	const [drawOnMap, setDrawOnMap] = useState(false)
	const [deduping, setDeduping] = useState(false)
	// const [pulledInfoGroupIds, setPulledInfoGroupIds] = useState([])

	let pulledInfoGroupIds = []
	let duplicateTargets = []
	let doNotPullList = []

	useEffect(() => {
		getPlaces()
	}, [filters, filters2, page, pageSize, matchAny])

	const getPlaces = async (exportList) => {
		try {
			if(exportList) {
				if(count > 400) {
					setGettingScanResults(true)

					// const scanRes = await axios.post(`http://localhost:3064/fieldday/infoGroup?scan=true&matchAny=${matchAny}`, {
					const scanRes = await axios.post(`https://dev.fieldday.app/fieldday/infoGroup?scan=true&matchAny=${matchAny}`, {
						filters,
						filters2
					})

					const scrollId = scanRes.data.scroll_id
					const scanCount = scanRes.data.count
					const limit = 50
					const numOfPages = Math.ceil(scanCount / limit)
					let finalResults = []

					console.log('numOfPages: ', numOfPages)

					for(let i = 0; i < numOfPages; i++) {
						// const places = await axios.post(`http://localhost:3064/fieldday/infoGroup?scan=true&scrollId=${scrollId}&matchAny=${matchAny}&fullRecord=true`, {
						const places = await axios.post(`https://dev.fieldday.app/fieldday/infoGroup?scan=true&scrollId=${scrollId}&matchAny=${matchAny}&fullRecord=true`, {
							filters,
							filters2
						})

						finalResults = finalResults.concat(places.data)

						if(i === numOfPages - 1) {
							console.log('last call: ', finalResults)
							setScanResults(finalResults)
							storePlacesInDb(finalResults)
							exportAsExcel(finalResults)
							setGettingScanResults(false)
						}
					}


				} else {
					const places = await axios.post(`https://dev.fieldday.app/fieldday/infoGroup?limit=400&offset=0&exportList=${exportList}&matchAny=${matchAny}`, {
						filters,
						filters2
					})

					// const places = await axios.post(`http://localhost:3064/fieldday/infoGroup?limit=400&offset=0&exportList=${exportList}&matchAny=${matchAny}`, {
					// 	filters,
					// 	filters2
					// })

					storePlacesInDb(places.data.documents)
					exportPlaces(places.data.documents)
				}
			} else {
				const places = await axios.post(`https://dev.fieldday.app/fieldday/infoGroup?limit=${pageSize}&offset=${page * pageSize}&matchAny=${matchAny}`, {
					filters,
					filters2
				})

				// const places = await axios.post(`http://localhost:3064/fieldday/infoGroup?limit=${pageSize}&offset=${page * pageSize}&matchAny=${matchAny}`, {
				// 	filters,
				// 	filters2
				// })

				setPlaces(places.data.documents)
				setCount(places.data.count)
			}
		} catch(e) {
			setGettingScanResults(false)
			console.error(e)
		}
	}

	const getPlacesForStore = async (key, filters) => {
		try {
				const storesState = { ...stores }

				// const places = await axios.post(`https://dev.fieldday.app/fieldday/infoGroup?limit=${pageSize}&offset=${page * pageSize}&matchAny=${matchAny}`, {
				const places = await axios.post(`http://localhost:3064/fieldday/infoGroup?limit=0&offset=${page * pageSize}&matchAny=${matchAny}`, {
				// const places = await axios.post(`https://dev.fieldday.app/fieldday/infoGroup?limit=400&offset=${page * pageSize}&matchAny=${matchAny}`, {
					// filters: filters.concat([{
					// 	relation: 'in',
					// 	attribute: 'infogroup_id',
					// 	value: pulledInfoGroupIds,
					// 	negated: true
					// }]),
					filters,
					filters2
				})

				// const places = await axios.post(`http://localhost:3064/fieldday/infoGroup?limit=${pageSize}&offset=${page * pageSize}&matchAny=${matchAny}`, {
				// 	filters,
				// 	filters2
				// })

				if(places.data.count > 400) {
					// const scanRes = await axios.post(`http://localhost:3064/fieldday/infoGroup?scan=true&matchAny=${matchAny}`, {
					const scanRes = await axios.post(`https://dev.fieldday.app/fieldday/infoGroup?scan=true&matchAny=${matchAny}`, {
						// filters: filters.concat([{
						// 	relation: 'in',
						// 	attribute: 'infogroup_id',
						// 	value: pulledInfoGroupIds,
						// 	negated: true
						// }]),
						filters,
						filters2
					})
// WE WANT FULLRECORD TO BE TRUE ON CALLS HERE WHEWN EXPOERTING AND WHEN CREATING GIGS
					const scrollId = scanRes.data.scroll_id
					const scanCount = scanRes.data.count
					const limit = 50
					const numOfPages = Math.ceil(scanCount / limit)
					let finalResults = []

					for(let i = 0; i < numOfPages; i++) {
						// const places = await axios.post(`http://localhost:3064/fieldday/infoGroup?scan=true&scrollId=${scrollId}&matchAny=${matchAny}&fullRecord=false`)
						const places = await axios.post(`https://dev.fieldday.app/fieldday/infoGroup?scan=true&scrollId=${scrollId}&matchAny=${matchAny}&fullRecord=false`)
						// const places = await axios.post(`https://dev.fieldday.app/fieldday/infoGroup?scan=true&scrollId=${scrollId}&matchAny=${matchAny}&fullRecord=false`, {
						// 	filters,
						// 	filters2
						// })

						await sleep(3000)

						finalResults = finalResults.concat(places.data)

						// places.data.forEach(place => setPulledInfoGroupIds(pulledInfoGroupIds.concat([place.infogroup_id])))
						places.data.forEach(place => pulledInfoGroupIds.push(place.infogroup_id))
						console.log('ALL TARGETS: ', pulledInfoGroupIds)

						if(i === numOfPages - 1) {
							console.log('last call: ', finalResults)
							// setScanResults(finalResults)
							// storePlacesInDb(finalResults)
							// exportAsExcel(finalResults)
							// setGettingScanResults(false)
							storesState[key].places = finalResults
							setStores(storesState)
						}
					}
				} else {
					// const places = await axios.post(`http://localhost:3064/fieldday/infoGroup?limit=400&offset=${page * pageSize}&matchAny=${matchAny}`, {
					const places = await axios.post(`https://dev.fieldday.app/fieldday/infoGroup?limit=400&offset=${page * pageSize}&matchAny=${matchAny}`, {
						// filters: filters.concat([{
						// 	relation: 'in',
						// 	attribute: 'infogroup_id',
						// 	value: pulledInfoGroupIds,
						// 	negated: true
						// }]),
						filters,
						filters2
					})

					// console.log('ELSE getPlacesForStore: ', places.data)
					// console.log("THISSS: ", places.data.documents.map(place => place.infogroup_id))
					// setPulledInfoGroupIds(pulledInfoGroupIds.concat(places.data.documents.map(place => place.infogroup_id)))
					places.data.documents.forEach(place => pulledInfoGroupIds.push(place.infogroup_id))
					console.log('ALL TARGETS: ', pulledInfoGroupIds)

					storesState[key].places = places.data
					setStores(storesState)
				}
		} catch(e) {
			console.error(e)
		}
	}

	const sleep = (ms) => {console.log("++++++SLEEEEPPP: ", ms)
		return new Promise(resolve => setTimeout(resolve, ms))
	}

	const handleStoreTargetsDedupe = () => {
		const temp = []

		setDeduping(true)

		each(stores, store => {
			if(store.places && store.places.documents) {
				remove(store.places.documents, place => {
					if(!temp.includes(place.infogroup_id)) {
						temp.push(place.infogroup_id)
					} else {
						return true
					}
				})
			}

			if(store.places && !store.places.documents) {
				remove(store.places, place => {
					if(!temp.includes(place.infogroup_id)) {
						temp.push(place.infogroup_id)
					} else {
						return true
					}
				})
			}
		})

		console.log('++++AFTER STORES: ', stores)
		console.log('++++TEMP: ', temp)

		const allInfogroupIds = map(stores, store => {
			let results = []
			if(store.places && store.places.documents) {
				results = store.places.documents.map(place => place.infogroup_id)
			}

			if(store.places && !store.places.documents) {
				results = store.places.map(place => place.infogroup_id)
			}

			return results
		}).flat()

		console.log('allInfogroupIds', allInfogroupIds)

		const uniqueIds = new Set(allInfogroupIds)

		console.log('uniqueIds: ', uniqueIds)


		const duplicates = allInfogroupIds.filter(item => {
			if(uniqueIds.has(item)) {
				uniqueIds.delete(item)
			} else {
				return item
			}
		})

		console.log('DUPLICATES: ', [...new Set(duplicates)])
		// return [...new Set(duplicates)]

		setStores(stores)
		setDeduping(false)
	}

	const handleFilterChange = async (filter) => {
		console.log('handleFilterChange: ', filter, stores)
		if(extraFilter) { // adding a secondary nested filter
			if(filter.relation === 'geo_distance') {
				if(filter.value.postal_codes) {
					setFilters2(filters2.concat([{
						relation: filter.relation,
						value: filter.value,
					}]))
				}

				if(stores) {
					const storesArray = map(stores, store => store)

					// TODO; do this in a much better way
					storesArray.forEach((store, i) => {
						if(i > 100 && i <= 200) {
							setTimeout(() => {
								getPlacesForStore(i, filters.concat([{
									relation: filter.relation,
									value: {
										distance: filter.value.distance,
										lat: store.loc_point[1],
										lon: store.loc_point[0],
									}
								}]))
							}, 30000)
						} else if(i > 200 && i <= 300) {
							setTimeout(() => {
								getPlacesForStore(i, filters.concat([{
									relation: filter.relation,
									value: {
										distance: filter.value.distance,
										lat: store.loc_point[1],
										lon: store.loc_point[0],
									}
								}]))
							}, 60000)
						} else if(i > 300 && i <= 400) {
							setTimeout(() => {
								getPlacesForStore(i, filters.concat([{
									relation: filter.relation,
									value: {
										distance: filter.value.distance,
										lat: store.loc_point[1],
										lon: store.loc_point[0],
									}
								}]))
							}, 90000)
						} else if(i > 400 && i <= 500) {
							setTimeout(() => {
								getPlacesForStore(i, filters.concat([{
									relation: filter.relation,
									value: {
										distance: filter.value.distance,
										lat: store.loc_point[1],
										lon: store.loc_point[0],
									}
								}]))
							}, 120000)
						} else if(i > 500 && i <= 600) {
							setTimeout(() => {
								getPlacesForStore(i, filters.concat([{
									relation: filter.relation,
									value: {
										distance: filter.value.distance,
										lat: store.loc_point[1],
										lon: store.loc_point[0],
									}
								}]))
							}, 150000)
						} else if(i > 600 && i <= 700) {
							setTimeout(() => {
								getPlacesForStore(i, filters.concat([{
									relation: filter.relation,
									value: {
										distance: filter.value.distance,
										lat: store.loc_point[1],
										lon: store.loc_point[0],
									}
								}]))
							}, 180000)
						} else if(i > 700 && i <= 800) {
							setTimeout(() => {
								getPlacesForStore(i, filters.concat([{
									relation: filter.relation,
									value: {
										distance: filter.value.distance,
										lat: store.loc_point[1],
										lon: store.loc_point[0],
									}
								}]))
							}, 210000)
						} else if(i > 800 && i <= 900) {
							setTimeout(() => {
								getPlacesForStore(i, filters.concat([{
									relation: filter.relation,
									value: {
										distance: filter.value.distance,
										lat: store.loc_point[1],
										lon: store.loc_point[0],
									}
								}]))
							}, 240000)
						} else if(i > 900 && i <= 1000) {
							setTimeout(() => {
								getPlacesForStore(i, filters.concat([{
									relation: filter.relation,
									value: {
										distance: filter.value.distance,
										lat: store.loc_point[1],
										lon: store.loc_point[0],
									}
								}]))
							}, 270000)
						} else if(i > 1000 && i <= 1100) {
							setTimeout(() => {
								getPlacesForStore(i, filters.concat([{
									relation: filter.relation,
									value: {
										distance: filter.value.distance,
										lat: store.loc_point[1],
										lon: store.loc_point[0],
									}
								}]))
							}, 300000)
						} else {
							getPlacesForStore(i, filters.concat([{
								relation: filter.relation,
								value: {
									distance: filter.value.distance,
									lat: store.loc_point[1],
									lon: store.loc_point[0],
								}
							}]))
						}
					})

					// each(stores, (store, key) => {
					// 	getPlacesForStore(key, filters2.concat([{
					// 		relation: filter.relation,
					// 		value: {
					// 			distance: filter.value.distance,
					// 			lat: store.loc_point[1],
					// 			lon: store.loc_point[0],
					// 		}
					// 	}]))
					// })
				}
			} else {
				setFilters2(filters2.concat([{
					relation: filter.relation === 'exclude' ? 'in' : filter.relation,
					attribute: filter.attribute,
					value: filter.value,
					negated: filter.relation === 'exclude'
				}]))
			}
		} else { // adding the original set of filters
			if(filter.relation === 'geo_distance') {
				if(filter.value.postal_codes) {
					setFilters(filters.concat([{
						relation: filter.relation,
						value: filter.value,
					}]))
				}

				if(stores) {
					const storesArray = map(stores, store => store)

					// TODO; do this in a much better way
					storesArray.forEach((store, i) => {
					// for(let i = 0; i < storesArray.length; i++) {
						if(i > 100 && i <= 200) {
							setTimeout(() => {
								getPlacesForStore(i, filters.concat([{
									relation: filter.relation,
									value: {
										distance: filter.value.distance,
										lat: storesArray[i].loc_point[1],
										lon: storesArray[i].loc_point[0],
									}
								}]))
							}, 30000)
						} else if(i > 200 && i <= 300) {
							setTimeout(() => {
								getPlacesForStore(i, filters.concat([{
									relation: filter.relation,
									value: {
										distance: filter.value.distance,
										lat: storesArray[i].loc_point[1],
										lon: storesArray[i].loc_point[0],
									}
								}]))
							}, 60000)
						} else if(i > 300 && i <= 400) {
							setTimeout(() => {
								getPlacesForStore(i, filters.concat([{
									relation: filter.relation,
									value: {
										distance: filter.value.distance,
										lat: storesArray[i].loc_point[1],
										lon: storesArray[i].loc_point[0],
									}
								}]))
							}, 90000)
						} else if(i > 400 && i <= 500) {
							setTimeout(() => {
								getPlacesForStore(i, filters.concat([{
									relation: filter.relation,
									value: {
										distance: filter.value.distance,
										lat: storesArray[i].loc_point[1],
										lon: storesArray[i].loc_point[0],
									}
								}]))
							}, 120000)
						} else if(i > 500 && i <= 600) {
							setTimeout(() => {
								getPlacesForStore(i, filters.concat([{
									relation: filter.relation,
									value: {
										distance: filter.value.distance,
										lat: storesArray[i].loc_point[1],
										lon: storesArray[i].loc_point[0],
									}
								}]))
							}, 150000)
						} else if(i > 600 && i <= 700) {
							setTimeout(() => {
								getPlacesForStore(i, filters.concat([{
									relation: filter.relation,
									value: {
										distance: filter.value.distance,
										lat: storesArray[i].loc_point[1],
										lon: storesArray[i].loc_point[0],
									}
								}]))
							}, 180000)
						} else if(i > 700 && i <= 800) {
							setTimeout(() => {
								getPlacesForStore(i, filters.concat([{
									relation: filter.relation,
									value: {
										distance: filter.value.distance,
										lat: storesArray[i].loc_point[1],
										lon: storesArray[i].loc_point[0],
									}
								}]))
							}, 210000)
						} else if(i > 800 && i <= 900) {
							setTimeout(() => {
								getPlacesForStore(i, filters.concat([{
									relation: filter.relation,
									value: {
										distance: filter.value.distance,
										lat: storesArray[i].loc_point[1],
										lon: storesArray[i].loc_point[0],
									}
								}]))
							}, 240000)
						} else if(i > 900 && i <= 1000) {
							setTimeout(() => {
								getPlacesForStore(i, filters.concat([{
									relation: filter.relation,
									value: {
										distance: filter.value.distance,
										lat: storesArray[i].loc_point[1],
										lon: storesArray[i].loc_point[0],
									}
								}]))
							}, 270000)
						} else if(i > 1000 && i <= 1100) {
							setTimeout(() => {
								getPlacesForStore(i, filters.concat([{
									relation: filter.relation,
									value: {
										distance: filter.value.distance,
										lat: storesArray[i].loc_point[1],
										lon: storesArray[i].loc_point[0],
									}
								}]))
							}, 300000)
						} else {
							getPlacesForStore(i, filters.concat([{
								relation: filter.relation,
								value: {
									distance: filter.value.distance,
									lat: storesArray[i].loc_point[1],
									lon: storesArray[i].loc_point[0],
								}
							}]))
						}
					})

					// each(stores, (store, key) => {
					// 	getPlacesForStore(key, filters.concat([{
					// 		relation: filter.relation,
					// 		value: {
					// 			distance: filter.value.distance,
					// 			lat: store.loc_point[1],
					// 			lon: store.loc_point[0],
					// 		}
					// 	}]))
					// })
				}
			} else {
				setFilters(filters.concat([{
					relation: filter.relation === 'exclude' ? 'in' : filter.relation,
					attribute: filter.attribute,
					value: filter.value,
					negated: filter.relation === 'exclude'
				}]))
			}
		}
	}

	const handleRemoveFilter = (index) => {
		setFilters(filters.filter((f, i) => i !== index))
	}

	const handleRemoveFilter2 = (index) => {
		setFilters2(filters2.filter((f, i) => i !== index))
	}

	const onPageChange = (page) => {
		setPage(page)
	}

	const onPageSizeChange = (pageSize, page) => {
		const numOfPages = count <= pageSize ? 1 : Math.ceil(count / pageSize)
		const newPageIndex = numOfPages <= page ? numOfPages - 1 : page

		setPageSize(pageSize)
		setPage(newPageIndex)
	}

	const handleExportList = () => {
		// TODO; when exporting file as json, make sure you only export data.document as json, and not "count"

		// if(count >= 400) {
		// 	alert('You can only export 400 records at a time!')
		// 	return
		// }

		const confirm = window.confirm(`This export is going to cost $${(count * 0.04).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}! Are you sure you want to do it?`)

		if(confirm) {
			const exportList = true

			getPlaces(exportList)
		}
	}

	const exportPlaces = (data) => {
		exportAsJson(data)
		exportAsExcel(data)
	}

	const exportAsJson = (data) => {
		const a = document.createElement('a')
		const json = JSON.stringify(data)
		const file = new Blob([json], { type: 'application/json' })

		a.href = URL.createObjectURL(file)
		a.download = 'places.json'

		a.click()
		a.remove()

		URL.revokeObjectURL(file)
	}

	const exportAsExcel = (data) => {
		const headerRow = [
			'name',
			'street',
			'suite',
			'city',
			'state',
			'postal_code',
			'phone',
			'place_type',
			'estimated_location_employee_count',
			'sic_code_ids',
			'sic_code_categories',
			'primary_sic_code_id',
			'primary_sic_code_category',
			'latitude',
			'longitude'
		].map(cell => ({ value: cell, type: 'string'}))

		const contentRows = data.map(doc => {
			return [
				{
					value: doc.name && doc.name.replace(/,/g, ' '),
					type: 'string'
				},
				{
					value: doc.street,
					type: 'string'
				},
				{
					value: doc.suite,
					type: 'string'
				},
				{
					value: doc.city,
					type: 'string'
				},
				{
					value: doc.state,
					type: 'string'
				},
				{
					value: doc.postal_code,
					type: 'string'
				},
				{
					value: doc.phone,
					type: 'string'
				},
				{
					value: doc.place_type,
					type: 'string'
				},
				{
					value: doc.estimated_location_employee_count || '',
					type: 'number'
				},
				{
					value: doc.sic_code_ids ? doc.sic_code_ids.join(' - ') : '',
					type: 'string'
				},
				{
					value: doc.labels && doc.labels.sic_code_ids ? doc.labels.sic_code_ids.join('; ') : '',
					type: 'string'
				},
				{
					value: doc.primary_sic_code_id,
					type: 'string'
				},
				{
					value: doc.labels && doc.labels.primary_sic_code_id,
					type: 'string'
				},
				{
					value: doc.latitude,
					type: 'string'
				},
				{
					value: doc.longitude,
					type: 'string'
				}
			]
		})

		contentRows.unshift(headerRow)

		const config = {
		  filename: 'places',
		  sheet: {
		    data: contentRows
		  }
		}

		excelExporter(config)
	}

	const storePlacesInDb = async (d) => {
		// if needed, primary_contact also needs to be added as new obj to fix referencing
		const temp = d.map(r => Object.assign({}, r))
		const data = temp.map(record => {
			if(record.labels) delete record.labels
			if(record.primary_contact && record.primary_contact.labels) delete record.primary_contact.labels

			return record
		})

		const formData = new FormData()
		formData.append('places', JSON.stringify(data))

		try {
			await axios.post(`${config.coreAPI}/infogroup/places`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
		} catch(e) {
			alert('Error in storing data in the database. Check the logs.')
			console.error(e)
		}
	}

	// const exportSymbl = () => {
	// 	const data = symblData()
	// 	const headerRow = [
	// 		'From',
	// 		'Text',
	// 		'Sentiment Polarity Score',
	// 		'Sentiment Suggested',
	// 		'Phrases Type',
	// 		'Phrases Text',
	// 		'Start Time',
	// 		'End Time',
	// 	].map(cell => ({ value: cell, type: 'string'}))

	// 	const contentRows = data.messages.map(doc => {
	// 		return [
	// 			{
	// 				value: doc.from && doc.from.name,
	// 				type: 'string'
	// 			},
	// 			{
	// 				value: doc.text,
	// 				type: 'string'
	// 			},
	// 			{
	// 				value: doc.sentiment && doc.sentiment.polarity ? doc.sentiment.polarity.score : '',
	// 				type: 'number'
	// 			},
	// 			{
	// 				value: doc.sentiment && doc.sentiment.suggested ? doc.sentiment.suggested : '',
	// 				type: 'string'
	// 			},
	// 			{
	// 				value: doc.phrases && doc.phrases.length ? doc.phrases.map(t => t.type).join('---') : '',
	// 				type: 'string'
	// 			},
	// 			{
	// 				value: doc.phrases && doc.phrases.length ? doc.phrases.map(t => t.text).join('---') : '',
	// 				type: 'string'
	// 			},
	// 			{
	// 				value: doc.startTime,
	// 				type: 'string'
	// 			},
	// 			{
	// 				value: doc.endTime,
	// 				type: 'string'
	// 			},
	// 		]
	// 	})

	// 	contentRows.unshift(headerRow)

	// 	const config = {
	// 	  filename: 'SymblSampleData',
	// 	  sheet: {
	// 	    data: contentRows
	// 	  }
	// 	}

	// 	excelExporter(config)
	// }
// console.log('stores: ', stores)
console.log('deduping: ', deduping)
	return (
		<Fragment>
			<Row>
			{/*<Button onClick={exportSymbl}>Symbl</Button>*/}
				<Col md={2} className='border-right h-100' style={{ minHeight: '100vh' }}>
					<h3 className='pt-3'>Search InfoGroup</h3>
					<hr />
					<CoreInformation onFilterChange={handleFilterChange} />
					<Form.Check
						type='checkbox'
						label={'Draw on Map'}
						className='mb-3'
						checked={drawOnMap}
						onChange={e => setDrawOnMap(e.target.checked)}
					/>
					<hr />
					<Button onClick={handleStoreTargetsDedupe}>Dedupe</Button>
					{deduping && <em>deduping..</em>}
					<BrandStores
						stores={stores}
						onSetStores={setStores}
					/>
				</Col>
				<Col className='pl-0 pr-0'>
					<FiltersBar
						filters={filters}
						filters2={filters2}
						onRemoveFilter={handleRemoveFilter}
						onRemoveFilter2={handleRemoveFilter2}
						count={count}
						onExportList={handleExportList}
					/>
					<Col>
						{gettingScanResults && <h4 className='text-center'>Pulling data. This may take a bit. Do not refresh or leave the page.</h4>}
						<Form.Check
							type='checkbox'
							label={'Match any initial filter'}
							className='mb-3'
							checked={matchAny}
							onChange={e => setMatchAny(e.target.checked)}
						/>
					</Col>
					<Col>
						<Form.Check
							type='checkbox'
							label={'Secondary Filters'}
							className='mb-3'
							checked={extraFilter}
							onChange={e => setExtraFilter(e.target.checked)}
						/>
					</Col>
					<div style={{ height: '600px' }}>
						<PlacesMap
							isMarkerShown
							geoPoints={places.map(place => ({ coordinates: { lat: place.latitude, lng: place.longitude } }))}
							drawOnMap={drawOnMap}
							onFilterChange={handleFilterChange}
						/>
					</div>
					<PlacesTable
						places={places}
						count={count}
						page={page}
						pageSize={pageSize}
						onPageChange={onPageChange}
						onPageSizeChange={onPageSizeChange}
					/>
				</Col>
			</Row>
		</Fragment>
	)
}

export default Places
