import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { API } from 'aws-amplify'
// import Container from 'react-bootstrap/Container'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Navbar from 'react-bootstrap/Navbar'
import Badge from 'react-bootstrap/Badge'
import Spinner from 'react-bootstrap/Spinner'
import Figure from 'react-bootstrap/Figure'
import ReactTable from 'react-table'
import _ from 'underscore'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { Radio, Checkbox } from 'semantic-ui-react'

import config from '../config'
import SideBar from '../components/SideBar'
import DashboardSideBar from '../components/dashboard/DashboardSideBar'
import ActiveBrands from '../components/dashboard/ActiveBrands'
import LeadsExporter from '../components/dashboard/LeadsExporter'
import GigsTable from '../components/GigsTable'
import GigsCalendar from '../components/shared/GigsCalendar'
import GigActions from '../components/GigActions'
import TimeFilters from '../components/TimeFilters'
import excelExporter from '../utils/excelExporter'
import {
	getAppliedGigs,
	getUnassignedGigs,
	getUnfulfilledGigs,
	getCanceledGigs,
	getUnconfirmedGigs,
	getConfirmedGigs,
	getAssignedGigs,
	getUnpaidGigs,
	getCompletedGigs,
	getInReviewGigs,
	getUpcomingGigs,
	getAllGigsForBrand,
	getActiveBrandsStats,
	handleStartDateChange,
	handleEndDateChange,
	handleGigStatusClick,
	handleMasterToggleChange,
	handlePageChange,
	handlePageSizeChange,
	handleTableFilterChange,
	handleTableSortChange,
	handleSelectActiveBrand,
	handleAmbassadorFilter,
	// getSelectedBrandGigs,
	handleGigsViewChange
} from '../actions/dashboard'

class Dashboard extends Component {
	state = {
		showModal: false,
		modalName: null,
		clickedGig: null,
	}

	tableRef = React.createRef()

	componentDidMount() {
		this.getGigs()
		this.getSnapshotTotals()
		this.getTotalGigs()
	}

	componentDidUpdate(prevProps, prevState) {
		if(moment(prevProps.endDate).valueOf() !==  moment(this.props.endDate).valueOf()) {
			this.getGigs()
			this.getSnapshotTotals()
			this.getTotalGigs()
		}

		if(prevProps.masterToggle !== this.props.masterToggle) {
			// this.mapStatusToFunction()
			this.getGigs()
			this.getSnapshotTotals()
			this.getTotalGigs()
			this.props.dispatch(getActiveBrandsStats())
		}

		if(prevProps.pageFrom !== this.props.pageFrom && prevProps.pageSize === this.props.pageSize) {
			this.mapStatusToFunction()
		}

		if(prevProps.pageSize !== this.props.pageSize) {
			this.mapStatusToFunction()
		}

		if(prevProps.brandNameSearchString !== this.props.brandNameSearchString ||
			prevProps.titleSearchString !== this.props.titleSearchString ||
			prevProps.campaignIdSearchString !== this.props.campaignIdSearchString ||
			prevProps.addressSearchString !== this.props.addressSearchString ||
			prevProps.ambassadorFilter !== this.props.ambassadorFilter
		) {

			this.mapStatusToFunction()
		}

		if(prevProps.sortFieldName !== this.props.sortFieldName || prevProps.sortDirection !== this.props.sortDirection) {
			this.mapStatusToFunction()
		}

		if(prevProps.selectedGigStatus !== this.props.selectedGigStatus) {
			this.mapStatusToFunction()
		}

		if(prevProps.selectedBrand !== this.props.selectedBrand) {
			this.mapStatusToFunction()
		}
	}

	mapStatusToFunction = () => {
		const { dispatch, selectedGigStatus } = this.props
		const actionFunctions = {
			appliedGigs: getAppliedGigs,
			unassignedGigs: getUnassignedGigs,
			unfulfilledGigs: getUnfulfilledGigs,
			canceledGigs: getCanceledGigs,
			unconfirmedGigs: getUnconfirmedGigs,
			confirmedGigs: getConfirmedGigs,
			assignedGigs: getAssignedGigs,
			unpaidGigs: getUnpaidGigs,
			completedGigs: getCompletedGigs,
			upcomingGigs: getUpcomingGigs,
			inReviewGigs: getInReviewGigs,
			allGigsForBrand: getAllGigsForBrand
		}

		dispatch(actionFunctions[selectedGigStatus]())
	}

	render() {
		const { showModal, modalName, clickedGig } = this.state
		const {
			dispatch,
			startDate,
			endDate,
			pageFrom,
			pageSize,
			appliedGigs,
			unassignedGigs,
			unfulfilledGigs,
			canceledGigs,
			unconfirmedGigs,
			confirmedGigs,
			assignedGigs,
			unpaidGigs,
			completedGigs,
			inReviewGigs,
			allGigsForBrand,
			selectedGigStatus,
			brandNameSearchString,
			titleSearchString,
			campaignIdSearchString,
			addressSearchString,
			selectedBrand,
			masterToggle,
			gigsView
		} = this.props

		// TODO: add error msgs
		return (
			<Row>
				<SideBar>
					<DashboardSideBar
						startDate={moment(startDate).valueOf()}
						endDate={moment(endDate).valueOf()}
						totalGigs={this.state.totalGigs}
					/>
				</SideBar>
				<Col>
					<Card>
						{this.state.showModal &&
							<Modal
								show={showModal}
								onHide={this.handleCloseModal}
								dialogClassName='modal-60w'
								aria-labelledby='example-custom-modal-styling-title'
								centered
							>
								{modalName === 'gigActions' &&
									<Fragment>
										<Modal.Header closeButton>
											<Modal.Title id='contained-modal-title-vcenter'>
												Gig Actions
											</Modal.Title>
										</Modal.Header>
										<Modal.Body>
											<GigActions gig={clickedGig} />
										</Modal.Body>
									</Fragment>
								}
							</Modal>
						}
						<Card.Body>
							<Row className="justify-content-lg-between">
								<h3>Dashboard</h3>
								{/*<i className="fas fa-cog"></i>*/}
								<div className='d-flex mr-3'>
									{masterToggle ? '' : <span className='mr-3'>Master Gigs Only</span>}
									<Radio toggle onChange={(e, data) => dispatch(handleMasterToggleChange(e, data))} />
								</div>
							</Row>
							<Navbar className='bg-light justify-content-lg-between'>
								<div>GIGS SNAPSHOT</div>
								<div className='d-flex flex-column align-items-center mr-5'>
									<div className='dashboard-datepicker mb-1'>
										<DatePicker
											selected={startDate}
											selectsStart
											startDate={startDate}
											endDate={endDate}
											onChange={this.onStartDateChange}
											showMonthDropdown
											showYearDropdown
											dropdownMode='select'
										/>
										&nbsp;TO&nbsp;
										<DatePicker
											selected={endDate}
											selectsEnd
											startDate={startDate}
											endDate={endDate}
											onChange={this.onEndDateChange}
											minDate={startDate}
											showMonthDropdown
											showYearDropdown
											dropdownMode='select'
										/>
									</div>
									<TimeFilters
										// size={'sm'}
										onStartChange={this.onStartDateChange}
										onEndChange={this.onEndDateChange}
									/>
								</div>
							</Navbar>
							<Row className='justify-content-lg-around' style={{marginTop: '20px'}}>
								<SnapshotTile total={this.state.appliedGigsTotal} onSnapshotDetailClick={this.onGigStatusClick} label={'Applied Gigs'} status={'appliedGigs'} color={'#EB1627'} />
								<SnapshotTile total={this.state.unassignedGigsTotal} onSnapshotDetailClick={this.onGigStatusClick} label={'Unassigned Gigs'} status={'unassignedGigs'} color={'#EB1627'} />
								<SnapshotTile total={this.state.unfulfilledGigsTotal} onSnapshotDetailClick={this.onGigStatusClick} label={'Unfulfilled Gigs'} status={'unfulfilledGigs'} color={'#EB1627'} />
								<SnapshotTile total={this.state.canceledGigsTotal} onSnapshotDetailClick={this.onGigStatusClick} label={'Canceled Gigs'} status={'canceledGigs'} color={'#EB1627'} />
								<SnapshotTile total={this.state.unconfirmedGigsTotal} onSnapshotDetailClick={this.onGigStatusClick} label={'Unconfirmed Gigs'} status={'unconfirmedGigs'} color={'#EB1627'} />
								<SnapshotTile total={this.state.confirmedGigsTotal} onSnapshotDetailClick={this.onGigStatusClick} label={'Confirmed Gigs'} status={'confirmedGigs'} color={'#37CD15'} />
								<SnapshotTile total={this.state.assignedGigsTotal} onSnapshotDetailClick={this.onGigStatusClick} label={'Assigned Gigs'} status={'assignedGigs'} color={'#37CD15'} />
								<SnapshotTile total={this.state.unpaidGigsTotal} onSnapshotDetailClick={this.onGigStatusClick} label={'Unpaid Gigs'} status={'unpaidGigs'} color={'#37CD15'} />
								<SnapshotTile total={this.state.completedGigsTotal} onSnapshotDetailClick={this.onGigStatusClick} label={'Completed Gigs'} status={'completedGigs'} color={'#37CD15'} />
								<SnapshotTile total={this.state.inReviewGigsTotal} onSnapshotDetailClick={this.onGigStatusClick} label={'In-Review Gigs'} status={'inReviewGigs'} color={'#ffc107'} />
								<SnapshotTile total={this.state.upcomingGigsTotal} onSnapshotDetailClick={this.onGigStatusClick} label={'Upcoming Gigs'} status={'upcomingGigs'} color={'#37CD15'} />
							</Row>

							<Navbar className='bg-light' style={{marginTop: '30px', marginBottom: '30px'}}>
								<div>ACTIVE BRANDS</div>
							</Navbar>
							<ActiveBrands
								onBrandClick={this.handleBrandClick}
								// onBrandStatsCountClick={this.handleBrandStatsCountClick}
							/>
							{/*<Col lg={12} className='d-flex justify-content-lg-around flex-lg-wrap' style={{marginTop: '20px', maxHeight: '500px', overflow: 'auto'}}>
								{Object.keys(this.state.activeBrands).length
									? _.map(this.state.activeBrands, brand => 
											<ActiveBrandTile
												key={brand.brand.id}
												item={brand}
												onItemClick={this.handleBrandItemClick}
												onBrandClick={this.handleBrandClick}
											/>
										)
									: <h3><i>No Active Brands at this time</i></h3>
								}
							</Col>*/}

							<Navbar className='bg-light d-flex justify-content-between'>
								<div className='d-flex'>
									<Button onClick={this.handleExportGigs}>Export Gigs</Button>
									{this.state.exportingExcel && <Spinner animation='border' variant='info' size='sm' />}&nbsp;&nbsp;
									<Checkbox
										label='include SendGrid stats'
										className='border-right border-dark pr-3 ml-1 mr-3'
										checked={this.state.includeSendGridStats}
										onChange={(e, { name, checked }) => this.setState({ includeSendGridStats: checked })}
									/>
									<LeadsExporter props={{ ...this.props }} />
								</div>
								<div style={{ fontSize: '25px' }}>
									<Badge variant='dark'>
										{`${selectedBrand ? `${selectedBrand} - ${selectedGigStatus}` : selectedGigStatus}`}
									</Badge>
								</div>
								<div className='d-flex'>
									<i className='fas fa-table fa-lg float-right pointer mr-2' title='Table View' onClick={() => dispatch(handleGigsViewChange('tableView'))}></i>
									<i className='far fa-calendar-alt fa-lg float-right pointer mr-2' title='Calendar View' onClick={() => dispatch(handleGigsViewChange('calendarView'))}></i>
								</div>
							</Navbar>
							<div className='mt-3' ref={this.tableRef}>
								{/*selectedGigStatus === 'gigs applied' && <TableData status={selectedGigStatus} onGigClick={this.handleGigClick} data={appliedGigs} />*/}
								{gigsView === 'tableView'
									? <GigsTable
											data={this.props[selectedGigStatus]}
											onGigClick={this.handleGigClick}
											page={pageFrom}
											pageSize={pageSize}
											// total={this.props[selectedGigStatus].total}
											onPageChange={pageFrom => dispatch(handlePageChange(pageFrom))}
											onPageSizeChange={(pageSize, pageFrom) => dispatch(handlePageSizeChange(pageFrom, pageSize, this.props[selectedGigStatus].total))}
											onFilterColumns={this.handleFilterColumns}
											onSortColumns={this.handleSortColumns}
											brandNameSearchString={brandNameSearchString}
											titleSearchString={titleSearchString}
											campaignIdSearchString={campaignIdSearchString}
											addressSearchString={addressSearchString}
											onAmbassadorFilter={ambassadorId => dispatch(handleAmbassadorFilter(ambassadorId))}
											// onExport={this.handleExport}
										/>
									: <GigsCalendar
											start={startDate}
											end={endDate}
											gigs={this.props[selectedGigStatus]}
											onGigClick={this.handleGigClick}
										/>
								}
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		)
	}

	getTotalGigs = async () => {
		const { startDate, endDate, masterToggle } = this.props
		const params = `noMasters=${masterToggle}&noChildren=${!masterToggle}&pageSize=0&dueDateFromMillis=${moment(startDate).valueOf()}&dueDateToMillis=${moment(endDate).valueOf()}`

		try {
			const response = await axios.get(`${config.coreAPI}/fieldDayAdmin/activities?${params}`)

			this.setState({
				totalGigs: response.data.responses[0].total
			})
		} catch(e) {
			console.error(e)
		}
	}

	getSnapshotTotals = () => {
		const { startDate, endDate, masterToggle } = this.props
		const unfulfilledParams = `dueDateFromMillis=${moment(startDate).valueOf()}&dueDateToMillis=${Date.now()}&pageFrom=0&pageSize=0&noMasters=${masterToggle}&noChildren=${!masterToggle}`
		const filters = {
			dueDateFromMillis: moment(startDate).valueOf(),
			dueDateToMillis: moment(endDate).valueOf(),
			pageFrom: 0,
			pageSize: 0,
			noMasters: masterToggle,
			noChildren: !masterToggle
		}

		const params = _.map(filters, (val, key) => `${key}=${val}`).join('&')

		axios.get(`${config.coreAPI}/fieldDayAdmin/activities?applied=true&accepted=false&${params}`)
			.then(appliedGigs => this.setState({ appliedGigsTotal: appliedGigs.data.responses[0].total }))

		axios.get(`${config.coreAPI}/fieldDayAdmin/activities?accepted=false&${params}`)
			.then(unassignedGigs => this.setState({ unassignedGigsTotal: unassignedGigs.data.responses[0].total }))

		axios.get(`${config.coreAPI}/fieldDayAdmin/activities?accepted=true&started=false&${unfulfilledParams}`)
			.then(unfulfilledGigs => this.setState({ unfulfilledGigsTotal: unfulfilledGigs.data.responses[0].total }))

		axios.get(`${config.coreAPI}/fieldDayAdmin/activities/cancelled?ignoreAssigned=true&${params}`)
			.then(canceledGigs => this.setState({ canceledGigsTotal: canceledGigs.data.responses[0].total }))

		axios.get(`${config.coreAPI}/fieldDayAdmin/activities?smsConfirmed=false&${params}`)
			.then(unconfirmedGigs => this.setState({ unconfirmedGigsTotal: unconfirmedGigs.data.responses[0].total }))

		axios.get(`${config.coreAPI}/fieldDayAdmin/activities?smsConfirmed=true&${params}`)
			.then(confirmedGigs => this.setState({ confirmedGigsTotal: confirmedGigs.data.responses[0].total }))

		axios.get(`${config.coreAPI}/fieldDayAdmin/activities?accepted=true&completed=false&${params}`)
			.then(assignedGigs => this.setState({ assignedGigsTotal: assignedGigs.data.responses[0].total }))

		axios.get(`${config.coreAPI}/fieldDayAdmin/activities?finished=true&paid=false&${params}`)
			.then(unpaidGigs => this.setState({ unpaidGigsTotal: unpaidGigs.data.responses[0].total }))

		axios.get(`${config.coreAPI}/fieldDayAdmin/activities?started=true&finished=true&${params}`)
			.then(completedGigs => this.setState({ completedGigsTotal: completedGigs.data.responses[0].total }))

		axios.get(`${config.coreAPI}/fieldDayAdmin/activities?hasReview=true&paid=false&completed=false&${params}`)
			.then(inReviewGigs => this.setState({ inReviewGigsTotal: inReviewGigs.data.responses[0].total }))

		axios.get(`${config.coreAPI}/fieldDayAdmin/activities?finished=false&completed=false&${params}`)
			.then(upcomingGigs => this.setState({ upcomingGigsTotal: upcomingGigs.data.responses[0].total }))
	}

	getGigs = () => {
		const { dispatch } = this.props

		dispatch(getAppliedGigs())
		dispatch(getUnassignedGigs())
		dispatch(getUnfulfilledGigs())
		dispatch(getCanceledGigs())
		dispatch(getUnconfirmedGigs())
		dispatch(getConfirmedGigs())
		dispatch(getAssignedGigs())
		dispatch(getUnpaidGigs())
		dispatch(getCompletedGigs())
		dispatch(getInReviewGigs())
		dispatch(getAllGigsForBrand())
		dispatch(getUpcomingGigs())
	}

	onStartDateChange = (date) => {
		// const currentState = this.state.snapshotDateRange

		// return new Promise((resolve, reject) => {
		// 	this.setState({
		// 		snapshotDateRange: Object.assign({}, currentState, { start: date })
		// 	}, resolve())
		// })

		this.props.dispatch(handleStartDateChange(date))
	}

	onEndDateChange = (date) => {
		this.props.dispatch(handleEndDateChange(date))
	}

	onGigStatusClick = (status) => {
		window.scrollTo(0, this.tableRef.current.offsetTop)

		this.props.dispatch(handleSelectActiveBrand(null))
		this.props.dispatch(handleGigStatusClick(status))
		// this.setState({
		// 	tableView: tileName,
		// 	activeBrand: null
		// }, () => window.scrollTo(0, this.tableRef.current.offsetTop))
	}

	handleBrandClick = (brandName, status) => {
		window.scrollTo(0, this.tableRef.current.offsetTop)
		this.props.dispatch(handleSelectActiveBrand(brandName, status))

		// this.setState({
		// 	tableView: null,
		// 	activeBrand: {
		// 		name: brandName,
		// 		status: 'All'
		// 	}
		// }, () => window.scrollTo(0, this.tableRef.current.offsetTop))
	}

	// handleBrandStatsCountClick = (brandName, status) => {
	// 	window.scrollTo(0, this.tableRef.current.offsetTop)
	// 	this.props.dispatch(handleSelectActiveBrand(brandName))

	// 	// this.setState({
	// 	// 	tableView: null,
	// 	// 	activeBrand: {
	// 	// 		name: brandName,
	// 	// 		status
	// 	// 	}
	// 	// }, () => window.scrollTo(0, this.tableRef.current.offsetTop))
	// }

	handleGigClick = (gig) => {
		if(this.props.selectedGigStatus === 'inReviewGigs') {
			this.props.history.push(`/gig/${gig.brand_user_activity.id}`)
			return
		}

		this.setState({
			showModal: !this.state.gigModalOpen,
			modalName: 'gigActions',
			clickedGig: gig
		})
	}

	handleCloseModal = () => {
		this.setState({
			showModal: false,
			clickedGig: null
		}, () => {
			this.getGigs()
			this.getSnapshotTotals()
		})
	}

	handleFilterColumns = (name, value) => {
		this.props.dispatch(handleTableFilterChange(name, value))
	}

	handleSortColumns = (newSorted, column, shiftKey) => {
		let sortFieldName
		const descSort = newSorted[0].desc

		if(newSorted[0].id === 'brandNameSearchString') sortFieldName = 'assigned_to_user.brand_roles.brand_name'
		if(newSorted[0].id === 'titleSearchString') sortFieldName = 'title'
		// if(newSorted === 'location') sortFieldName = 'location'

		this.props.dispatch(handleTableSortChange(sortFieldName, descSort))
	}

	handleExportGigs = () => {
		const {
			startDate,
			endDate,
			brandNameSearchString,
			titleSearchString,
			campaignIdSearchString,
			addressSearchString,
			sortFieldName,
			sortDirection,
			selectedGigStatus,
			selectedBrand,
			masterToggle
		} = this.props

		const unfulfilledParams = `dueDateFromMillis=${moment(startDate).valueOf()}&dueDateToMillis=${Date.now()}&pageFrom=0&pageSize=10000&noMasters=${masterToggle}&noChildren=${!masterToggle}`
		const filters = {
			dueDateFromMillis: moment(startDate).valueOf(),
			dueDateToMillis: moment(endDate).valueOf(),
			pageFrom: 0,
			pageSize: 10000,
			noMasters: masterToggle,
			noChildren: !masterToggle
		}

		if(brandNameSearchString) filters.brandNameSearchString = brandNameSearchString
		if(titleSearchString) filters.titleSearchString = titleSearchString
		if(campaignIdSearchString) filters.campaignId = campaignIdSearchString
		if(addressSearchString) filters.addressSearchString = addressSearchString
		if(sortFieldName) filters.sortFieldName = sortFieldName
		if(sortDirection) filters.sortDirection = sortDirection
		if(selectedBrand) filters.brandNameSearchString = selectedBrand

		const params = _.map(filters, (val, key) => `${key}=${val}`).join('&')

		this.setState({ exportingExcel: true })

		if(selectedGigStatus === 'appliedGigs') {
			axios.get(`${config.coreAPI}/fieldDayAdmin/activities?applied=true&accepted=false&${params}`)
				.then(appliedGigs => this.getAdditionalPhoneStats(appliedGigs.data.responses[0].results))
		}

		if(selectedGigStatus === 'unassignedGigs') {
			axios.get(`${config.coreAPI}/fieldDayAdmin/activities?accepted=false&${params}`)
				.then(unassignedGigs => this.getAdditionalPhoneStats(unassignedGigs.data.responses[0].results))
		}

		if(selectedGigStatus === 'unfulfilledGigs') {
			axios.get(`${config.coreAPI}/fieldDayAdmin/activities?accepted=true&started=false&${unfulfilledParams}`)
				.then(unfulfilledGigs => this.getAdditionalPhoneStats(unfulfilledGigs.data.responses[0].results))
		}

		if(selectedGigStatus === 'canceledGigs') {
			axios.get(`${config.coreAPI}/fieldDayAdmin/activities/cancelled?ignoreAssigned=true&${params}`)
				.then(canceledGigs => this.getAdditionalPhoneStats(canceledGigs.data.responses[0].results))
		}

		if(selectedGigStatus === 'unconfirmedGigs') {
			axios.get(`${config.coreAPI}/fieldDayAdmin/activities?smsConfirmed=false&${params}`)
				.then(unconfirmedGigs => this.getAdditionalPhoneStats(unconfirmedGigs.data.responses[0].results))
		}

		if(selectedGigStatus === 'confirmedGigs') {
			axios.get(`${config.coreAPI}/fieldDayAdmin/activities?smsConfirmed=true&${params}`)
				.then(confirmedGigs => this.getAdditionalPhoneStats(confirmedGigs.data.responses[0].results))
		}

		if(selectedGigStatus === 'assignedGigs') {
			axios.get(`${config.coreAPI}/fieldDayAdmin/activities?accepted=true&completed=false&${params}`)
				.then(assignedGigs => this.getAdditionalPhoneStats(assignedGigs.data.responses[0].results))
		}

		if(selectedGigStatus === 'unpaidGigs') {
			axios.get(`${config.coreAPI}/fieldDayAdmin/activities?finished=true&paid=false&${params}`)
				.then(unpaidGigs => this.getAdditionalPhoneStats(unpaidGigs.data.responses[0].results))
		}

		if(selectedGigStatus === 'completedGigs') {
			axios.get(`${config.coreAPI}/fieldDayAdmin/activities?started=true&finished=true&${params}`)
				.then(completedGigs => this.getAdditionalPhoneStats(completedGigs.data.responses[0].results))
		}

		if(selectedGigStatus === 'inReviewGigs') {
			axios.get(`${config.coreAPI}/fieldDayAdmin/activities?hasReview=true&paid=false&completed=false&${params}`)
				.then(inReviewGigs => this.getAdditionalPhoneStats(inReviewGigs.data.responses[0].results))
		}

		if(selectedGigStatus === 'upcomingGigs') {
			axios.get(`${config.coreAPI}/fieldDayAdmin/activities?finished=false&completed=false&${params}`)
				.then(upcomingGigs => this.getAdditionalPhoneStats(upcomingGigs.data.responses[0].results))
		}

		if(selectedGigStatus === 'allGigsForBrand') {
			axios.get(`${config.coreAPI}/fieldDayAdmin/activities?${params}`)
				.then(allGigsForBrand => this.getAdditionalPhoneStats(allGigsForBrand.data.responses[0].results))
		}
	}

	getAdditionalPhoneStats = async (gigs) => {
		// let sendGridReqCounter = 1
		const gigsData = [ ...gigs ]
		// const phoneGigs = gigs.filter(gig => gig.brand_user_activity.execution_type === 'PHONE')
		// const callStatsPromises = gigsData.map(gig => axios.get(`${config.coreAPI}/lead-actions/stats?activityId=${gig.brand_user_activity.id}`))
		// const emailStatsPromises = gigsData.map(gig => axios.get(`${config.coreAPI}/contact/stats?activityId=${gig.brand_user_activity.id}`))

		const callStatsPromises = gigsData.map(gig => {
			if(gig.brand_user_activity.execution_type === 'PHONE') {
				return axios.get(`${config.coreAPI}/lead-actions/stats?activityId=${gig.brand_user_activity.id}`)
			}

			return null
		})

		const emailStatsPromises = gigsData.map(gig => {
			if(gig.brand_user_activity.execution_type === 'PHONE') {
				return axios.get(`${config.coreAPI}/contact/stats?activityId=${gig.brand_user_activity.id}`)
			}

			return null
		})

		const sendGridStats = []

		if(this.state.includeSendGridStats) {
			for(let i = 0; i < gigsData.length; i++) {
				if(
					gigsData[i].brand_user_activity.execution_type === 'PHONE' &&
					gigsData[i].associated_brand.sendgrid_api_key &&
					gigsData[i].brand_user_activity.field_day_operation_details.started_gig &&
					gigsData[i].brand_user_activity.field_day_operation_details.phone_targets &&
					gigsData[i].brand_user_activity.field_day_operation_details.phone_targets.length
				) {
					console.log('SG STAT: ', i, gigsData[i], sendGridStats)
					// sendGridReqCounter++

					// sendGridReqCounter % 20 === 0 && await this.sleep(30000)

					try {
						const sgStat = await axios.get(`https://ddnofdks8d.execute-api.us-west-2.amazonaws.com/dev/getSendGridStats`, {
						// const sgStat = await axios.get(`https://api.sendgrid.com/v3/categories/stats`, {
							// headers: { 'Authorization': `Bearer ${gigsData[i].associated_brand.sendgrid_api_key}`, },
							params: {
								start_date: moment(this.props.startDate).format('YYYY-MM-DD'),
								categories: `Gig ID: ${gigsData[i].brand_user_activity.id}`,
								limit: 1000,
								aggregated_by: 'day',
								sendgrid_api_key: gigsData[i].associated_brand.sendgrid_api_key
							}
						})

						 console.log('PUSH STAT: ', i, sgStat.data, sendGridStats)
						 sendGridStats.push(sgStat)

						 if(sgStat.data[0].headers['x-ratelimit-remaining'] === '1') {
						 	 const millisToPause = (Number(sgStat.data[0].headers['x-ratelimit-reset']) * 1000) - Date.now() + 3000
						 	 console.log("+++YESSSS: ", millisToPause)
						 	 await this.sleep(millisToPause)
						 }
						 // console.log("TIMEEEE: ", Math.floor(Date.now() / 1000) - Number(sgStat.data[0].headers['x-ratelimit-reset']), moment(Number(sgStat.data[0].headers['x-ratelimit-reset']) * 1000).format)
						 console.log("TIMEEEE: ", moment().format('dddd, MMMM Do YYYY, h:mm:ss a'), " - ", moment(Number(sgStat.data[0].headers['x-ratelimit-reset']) * 1000).format('dddd, MMMM Do YYYY, h:mm:ss a'))
					} catch(e) {
						sendGridStats.push(null)
						console.log("ERRR 3: ", e.response)
						console.error(e)
					}


					// sendGridStatsPromises.push(axios.get(`https://ddnofdks8d.execute-api.us-west-2.amazonaws.com/dev/getSendGridStats`, {
					// 	params: {
					// 		start_date: moment(this.props.startDate).format('YYYY-MM-DD'),
					// 		categories: `Gig ID: ${gigsData[i].brand_user_activity.id}`,
					// 		limit: 1000,
					// 		aggregated_by: 'day',
					// 		sendgrid_api_key: gigsData[i].associated_brand.sendgrid_api_key
					// 	}
					// }))

				} else {
					console.log('ELSE STAT: ', i, sendGridStats)
					sendGridStats.push(null)
				}
			}
		}


		// const sendGridStatsPromises = gigsData.map(async (gig) => {
		// 	if(gig.brand_user_activity.execution_type === 'PHONE' && gig.associated_brand.sendgrid_api_key) {
		// 		console.log('SG GIGS: ', gig, sendGridReqCounter)
		// 		sendGridReqCounter++

		// 		await this.sleep(5000)

		// 		return axios.get(`https://ddnofdks8d.execute-api.us-west-2.amazonaws.com/dev/getSendGridStats`, {
		// 			params: {
		// 				start_date: moment(this.props.startDate).format('YYYY-MM-DD'),
		// 				// categories: `Gig ID: ${gig.brand_user_activity.id}`.replaceAll(' ', '%20'),
		// 				categories: `Gig ID: ${gig.brand_user_activity.id}`,
		// 				limit: 1000,
		// 				aggregated_by: 'day',
		// 				sendgrid_api_key: gig.associated_brand.sendgrid_api_key
		// 			}
		// 		})
		// 	}

		// 	return null
		// })

		Promise.all(callStatsPromises).then(callsStats => {console.log('callsStats: ', callsStats)
			callsStats.forEach((callStat, i) => {
				if(callStat) {
					gigsData[i].callsStats = callStat.data
				}
			})

			Promise.all(emailStatsPromises).then(emailStats => {console.log('emailStats: ', emailStats)
				emailStats.forEach((emailStat, i) => {
					if(emailStat) {
						gigsData[i].emailStats = emailStat.data
					}
				})

				// for(let i = 0; i < sendGridStatsPromises.length; i++) {
				// 	const res = await sendGridStatsPromises[i]

				// 	console.log('PPPP: ', res)
				// }

				// sendGridStatsPromises.forEach(async (p) => {
				// 	const res = await p

				// 	if(res && res.status === 200) {
				// 		console.log('SG RES headers rate limit: ', res.data[0].headers['x-ratelimit-remaining'])
				// 		console.log('SG RES body: ', res.data[1])

				// 		if(res.data[0].headers['x-ratelimit-remaining'] === '0') {
				// 			console.log("RATE LIMITTTTT: ", res.data)
				// 		}
				// 	}

				// })
console.log("sendGridStats FINALLLL: ", sendGridStats)
				// Promise.all(sendGridStatsPromises).then((sendGridStats, i) => {
				// 	console.log("sendGridStats: ", sendGridStats)
					sendGridStats.forEach((sendGridStat, i) => {
						if(sendGridStat) {
							gigsData[i].sendGridStats = sendGridStat.data[1]
						}
					})

					this.exportAsExcel(gigsData)
				// })

			})
		})
		.catch(e => {
			console.error(e)
		})
	}

	sleep = (ms) => {
		console.log("+++SLEEP: ", ms)
	  return new Promise(resolve => setTimeout(resolve, ms));
	}

	exportAsExcel = async (data) => {
		// try {
		// } catch(e) {
		// 	alert('Error in export' + e)
		// 	console.error(e)
		// }

		console.log('GIGS EXPORT: ', data)

		const headerRow = [
			'Gig ID',
			'Brand',
			'Title',
			'Description',
			'Best Practices',
			'Brand Details',
			'Script',
			'Execution Type',
			'Status',
			'Location',
			'Zip Code',
			'Date',
			'Time',
			'Hours',
			'Rate/Hour',
			'Ambassador Name',
			'Ambassador Email',
			'Ambassador Phone',
			'Campaign ID',
			'Campaign Name',
			'Phone Targets',
			'# of Phone Targets',
			'Has Routing',
			'# of Street Targets',
			'Businesses Visited',
			'Materials Handed Out',
			'Business Cards Collected',
			"Total Photos",
			'Store Name',
			'Store ID',
			'Email Capture Rate',
			'Emails Collected',
			'Emails Sent',
			'Emails Delivered',
			'Email Opens',
			'Email Clicks',
			'Total Calls',
			'Total Calls over 10s',
			'Total Calls over 30s',
			'Connection Rate',
			'Brand Perception Notes',
			'Experience Notes',
			'Additional Notes',
		].map(cell => ({ value: cell, type: 'string'}))

		const contentRows = _.map(data, gig => {
			console.log("EXPORT ROW: ", gig)
			return [
				{
					value: gig.brand_user_activity.id,
					type: 'string'
				},
				{
					value: gig.associated_brand.brand_name,
					type: 'string'
				},
				{
					value: gig.brand_user_activity.title,
					type: 'string'
				},
				{
					value: gig.brand_user_activity.description ? 'Yes' : 'No',
					type: 'string'
				},
				{
					value: gig.brand_user_activity.best_practices ? 'Yes' : 'No',
					type: 'string'
				},
				{
					value: gig.brand_user_activity.brand_details ? 'Yes' : 'No',
					type: 'string'
				},
				{
					value: gig.brand_user_activity.script ? 'Yes' : 'No',
					type: 'string'
				},
				{
					value: gig.brand_user_activity.execution_type,
					type: 'string'
				},
				{
					value: this.getGigStatus(gig.brand_user_activity),
					type: 'string'
				},
				{
					value: `${gig.brand_user_activity.address}, ${gig.brand_user_activity.city} ${gig.brand_user_activity.state}`,
					type: 'string'
				},
				{
					value: gig.brand_user_activity.postal_code,
					type: 'string'
				},
				{
					value: moment(gig.brand_user_activity.due_date_millis).format('MM/DD/YYYY'),
					type: 'string'
				},
				{
					// value: moment(gig.brand_user_activity.due_date_millis).format('h:mm A'),
					value: `${moment(gig.brand_user_activity.due_date_millis).tz(gig.brand_user_activity.time_zone).format('h:mm A')} ${gig.brand_user_activity.time_zone_human}`,
					type: 'string'
				},
				{
					value: gig.brand_user_activity.field_day_management.field_day_remuneration.max_hours,
					type: 'string'
				},
				{
					value: gig.brand_user_activity.field_day_management.field_day_remuneration.ambassador_remuneration_rate_per_hour,
					type: 'string'
				},
				{
					value: this.getAmbassadorName(gig).ambassador_name,
					type: 'string'
				},
				{
					value: this.getAmbassadorName(gig).email_address,
					type: 'string'
				},
				{
					value: this.getAmbassadorName(gig).phone,
					type: 'string'
				},
				{
					value: gig.brand_user_activity.campaign && gig.brand_user_activity.campaign.campaign_id ? gig.brand_user_activity.campaign.campaign_id : '',
					type: 'number'
				},
				{
					value: gig.brand_user_activity.campaign && gig.brand_user_activity.campaign.campaign_name ? gig.brand_user_activity.campaign.campaign_name : '',
					type: 'string'
				},
				{
					value: gig.brand_user_activity.field_day_operation_details.phone_targets ? 'Yes' : 'No',
					type: 'string'
				},
				{
					value: gig.brand_user_activity.field_day_operation_details.phone_targets ? gig.brand_user_activity.field_day_operation_details.phone_targets.length : '',
					type: 'number'
				},
				{
					value: gig.brand_user_activity.activity_route_id ? 'Yes' : 'No',
					type: 'string'
				},
				{
					value: gig.route_summary ? gig.route_summary.total_destinations : '',
					type: 'number'
				},
				{
					value: gig.brand_user_activity.field_day_operation_details && gig.brand_user_activity.field_day_operation_details.feedback && gig.brand_user_activity.field_day_operation_details.feedback.businesses_visited ? gig.brand_user_activity.field_day_operation_details.feedback.businesses_visited : 0,
					type: 'number'
				},
				{
					value: gig.brand_user_activity.field_day_operation_details && gig.brand_user_activity.field_day_operation_details.feedback && gig.brand_user_activity.field_day_operation_details.feedback.materials_handed_out ? gig.brand_user_activity.field_day_operation_details.feedback.materials_handed_out : 0,
					type: 'number'
				},
				{
				  value: gig.brand_user_activity.field_day_operation_details && gig.brand_user_activity.field_day_operation_details.business_card_photos && gig.brand_user_activity.field_day_operation_details.business_card_photos.length ? gig.brand_user_activity.field_day_operation_details.business_card_photos.length : 0,
				  type: 'number'
				},
				{
					value: this.getNumberOfPhotos(gig.brand_user_activity),
					type: 'number'
				},
				{
					value: gig.brand_user_activity.stores ? gig.brand_user_activity.stores[0].store_name : '',
					type: 'string'
				},
				{
					value: gig.brand_user_activity.stores ? gig.brand_user_activity.stores[0].store_id : '',
					type: 'number'
				},
				{
					value: gig.emailStats ? (gig.emailStats.phone_gig_contacts_with_emails / Number(gig.brand_user_activity.field_day_management.field_day_remuneration.max_hours)).toFixed(2) : '',
					type: 'number'
				},
				{
					value: (gig.emailStats && gig.emailStats.phone_gig_contacts_with_emails) || '',
					type: 'number'
				},
				{
					value: gig.sendGridStats ? gig.sendGridStats.reduce((accum, el) => accum += el.stats[0].metrics.requests , 0) : '',
					type: 'number'
				},
				{
					value: gig.sendGridStats ? gig.sendGridStats.reduce((accum, el) => accum += el.stats[0].metrics.delivered , 0) : '',
					type: 'number'
				},
				{
					value: gig.sendGridStats ? gig.sendGridStats.reduce((accum, el) => accum += el.stats[0].metrics.opens , 0) : '',
					type: 'number'
				},
				{
					value: gig.sendGridStats ? gig.sendGridStats.reduce((accum, el) => accum += el.stats[0].metrics.clicks , 0) : '',
					type: 'number'
				},
				{
					// value: gig.callsStats && gig.callsStats.lead_action_call_statuses && gig.callsStats.lead_action_call_statuses.filter(status => status.call_status === 'completed')[0] ? gig.callsStats.lead_action_call_statuses.filter(status => status.call_status === 'completed')[0].count : '',
					value: gig.callsStats && gig.callsStats.lead_action_call_statuses ? gig.callsStats.lead_action_call_statuses.reduce((accum, el) => accum += el.count, 0) : '',
					type: 'number'
				},
				{
					value: (gig.callsStats && gig.callsStats.lead_action_calls_over_10_seconds) || '',
					type: 'number'
				},
				{
					value: (gig.callsStats && gig.callsStats.lead_action_calls_over_30_seconds) || '',
					type: 'number'
				},
				{
				  value: gig.callsStats && gig.callsStats.lead_action_calls_over_30_seconds && gig.callsStats.lead_action_call_statuses
				  	? (gig.callsStats.lead_action_calls_over_30_seconds / gig.callsStats.lead_action_call_statuses.reduce((accum, el) => accum += el.count, 0)).toFixed(1)
				  	: 0,
				  type: 'number'
				},
				{
				  value: gig.brand_user_activity.field_day_operation_details && gig.brand_user_activity.field_day_operation_details.feedback && gig.brand_user_activity.field_day_operation_details.feedback.logistics,
				  type: 'string'
				},
				{
				  value: gig.brand_user_activity.field_day_operation_details && gig.brand_user_activity.field_day_operation_details.feedback && gig.brand_user_activity.field_day_operation_details.feedback.overall_experience,
				  type: 'string'
				},
				{
				  value: gig.brand_user_activity.field_day_operation_details && gig.brand_user_activity.field_day_operation_details.feedback && gig.brand_user_activity.field_day_operation_details.feedback.additional_comments,
				  type: 'string'
				},
			]
		})

		contentRows.unshift(headerRow)

		const config = {
		  // filename: this.props.selectedGigStatus,
		  filename: `${this.props.selectedGigStatus} - (${moment(this.props.startDate).format('MM_DD_YYYY')} - ${moment(this.props.endDate).format('MM_DD_YYYY')})`,
		  sheet: {
		    data: contentRows
		  }
		}

		excelExporter(config)

		this.setState({ exportingExcel: false })
	}

	getNumberOfPhotos = (activity) => {
	  let total = 0

	  if(!activity.field_day_operation_details) return total

	  if(activity.field_day_operation_details.handout_photos) {
	    total += activity.field_day_operation_details.handout_photos.length
	  }

	  if(activity.field_day_operation_details.materials_photos) {
	    total += activity.field_day_operation_details.materials_photos.length
	  }

	  if(activity.field_day_operation_details.meeting_spot_photos) {
	    total += activity.field_day_operation_details.meeting_spot_photos.length
	  }

	  return total
	}

	getGigStatus = (gig) => {
		if(!gig) return ''

		if(gig.field_day_operation_details.started_gig && !gig.field_day_operation_details.finished_gig) return 'started'
		if(gig.field_day_operation_details.started_gig && gig.field_day_operation_details.finished_gig && !gig.field_day_management.field_day_workflow.approved_field_day_completion) return 'finished'
		if(gig.field_day_operation_details.started_gig && gig.field_day_operation_details.finished_gig && gig.field_day_management.field_day_workflow.approved_field_day_completion && !gig.field_day_management.field_day_workflow.paid_ambassador) return 'approved completion'
		if(gig.field_day_operation_details.started_gig && gig.field_day_operation_details.finished_gig && gig.field_day_management.field_day_workflow.paid_ambassador) return 'paid'
	}

	getAmbassadorName = (gig) => {
		const result = _.find(gig.applied_ambassadors, amb => amb.id === gig.brand_user_activity.field_day_management.field_day_workflow.accepted_ambassador_id)

		return result ? result : ''
	}
}

function mapStateToProps({ dashboard }) {
	return {
		startDate: dashboard.startDate,
		endDate: dashboard.endDate,
		masterToggle: dashboard.masterToggle,
		pageFrom: dashboard.pageFrom,
		pageSize: dashboard.pageSize,
		selectedGigStatus: dashboard.selectedGigStatus,
		selectedBrand: dashboard.selectedBrand,
		brandNameSearchString: dashboard.brandNameSearchString,
		titleSearchString: dashboard.titleSearchString,
		campaignIdSearchString: dashboard.campaignIdSearchString,
		addressSearchString: dashboard.addressSearchString,
		// zipCodeSearchString: dashboard.zipCodeSearchString,
		ambassadorSearchString: dashboard.ambassadorSearchString,
		ambassadorFilter: dashboard.ambassadorFilter,
		sortFieldName: dashboard.sortFieldName,
		sortDirection: dashboard.sortDirection,
		appliedGigs: dashboard.appliedGigs,
		unassignedGigs: dashboard.unassignedGigs,
		unfulfilledGigs: dashboard.unfulfilledGigs,
		canceledGigs: dashboard.canceledGigs,
		unconfirmedGigs: dashboard.unconfirmedGigs,
		confirmedGigs: dashboard.confirmedGigs,
		assignedGigs: dashboard.assignedGigs,
		unpaidGigs: dashboard.unpaidGigs,
		completedGigs: dashboard.completedGigs,
		inReviewGigs: dashboard.inReviewGigs,
		upcomingGigs: dashboard.upcomingGigs,
		allGigsForBrand: dashboard.allGigsForBrand,
		// selectedBrandGigs: dashboard.selectedBrandGigs,
		// selectedBrandStatus: dashboard.selectedBrandStatus,
		gigsView: dashboard.gigsView,
	}
}

function SnapshotTile({ label, total, status, onSnapshotDetailClick, color }) {
	return (
		<Card style={{backgroundColor: '#f8f9fa', paddingTop: '10px', textAlign: 'center'}}>
			<div className='snapshot-tile-pill' style={{color}}>
				{total >= 0
					? total
					: <Spinner animation="grow" variant="info" />
				}
			</div>
			<Card.Body>
				<div className='snapshot-tile-label' style={{width: `${status === 'appliedGigs' || status === 'unpaidGigs' ? '85px' : ''}`}}>{label}</div>
				<div className='snapshot-tile-link pointer' onClick={() => onSnapshotDetailClick(status)}>View All</div>
			</Card.Body>
		</Card>
	)
}

function NumberBubble({ number, bgColor, fontSize, fontColor, width, height, onClick }) {
	return (
		<div
			className='number-bubble'
			style={{
				backgroundColor: bgColor,
				color: fontColor,
				fontSize,
				width,
				height
			}}
		>
			<span className='pointer' onClick={onClick}>{number}</span>
		</div>
	)
}

export default connect(mapStateToProps)(withRouter(Dashboard))
