import React, { useState } from 'react'
import axios from 'axios'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import config from '../../config'

function RejectAmbassador({ ambassadorId }) {
	const [rejectionReason, setRejectionReason] = useState('')
	const [sendRejectionEmail, setSendRejectionEmail] = useState(false)

	const handleRejectAmbassador = async () => {
		if(!rejectionReason) {
			alert('You need to specify a reason.')
			return
		}

		try {
			await axios.post(`${config.coreAPI}/fieldDayAdmin/ambassador/${ambassadorId}/reject`, {
				'rejection_reason': rejectionReason,
				'send_rejection_email': sendRejectionEmail
			})

			alert('Ambassador rejected successfully')
		} catch(e) {
			console.error(e)
			e.response.data && e.response.data.message ? alert(e.response.data.message) : alert('Error')
		}
	}

	return (
		<div>
			<Form.Check
				className='mb-4'
				type='checkbox'
				label='Send Rejection Email'
				checked={sendRejectionEmail}
				onChange={e => setSendRejectionEmail(e.target.checked)}
			/>
			<Form.Control
				as='textarea'
				rows='7'
				placeholder='Rejection Reason'
				name='rejectionReason'
				value={rejectionReason}
				onChange={e => setRejectionReason(e.target.value)}
			/>
			<Button className='mt-4' onClick={handleRejectAmbassador}>
				Reject
			</Button>
		</div>
	)
}

export default RejectAmbassador
