import React, { Component, Fragment } from 'react'
import axios from 'axios'
// import Row from 'react-bootstrap/Row'
// import Col from 'react-bootstrap/Col'

import config from '../../config'

class DashboardSideBar extends Component {
	state = {}

	componentDidMount() {
	}

	render() {
		const { totalGigs } = this.props

		return (
			<Fragment>
				<h3 className='text-center'>Total Gigs: {totalGigs}</h3>
			</Fragment>
		)
	}
}

export default DashboardSideBar
