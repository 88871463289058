import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Segment, Button } from 'semantic-ui-react'
import { map } from 'underscore'
import moment from 'moment'

import BrandStats from './BrandStats'
import BrandsTable from './BrandsTable'
import DateFilter from '../shared/DateFilter'
import excelExporter from '../../utils/excelExporter'
import { handleBrandSelect, getBrandsExpenses, getBrandsActivityStats } from '../../actions/brands'

class BrandsOverview extends Component {
	state = {
		startDate: null,
		endDate: null
	}

	componentDidMount() {
		this.props.dispatch(getBrandsExpenses())
		this.props.dispatch(getBrandsActivityStats())
	}

	render() {
		const { brands, brandsExpenses, brandsActivityStats, dispatch } = this.props
		const { startDate, endDate } = this.state

		return (
			<div>
				<BrandStats
					brands={brands}
					brandsExpenses={brandsExpenses}
					brandsActivityStats={brandsActivityStats}
				/>
				<Segment color='teal'>
					<div className='d-flex justify-content-between'>
						<Button color='blue' size='small' icon='upload' title='Export as Excel' onClick={this.exportAsExcel} />
						<DateFilter
							startDate={startDate}
							endDate={endDate}
							onStartDateChange={startDate => this.setState({ startDate }, () => {
								dispatch(getBrandsExpenses(startDate, endDate))
								dispatch(getBrandsActivityStats(startDate, endDate))
							})}
							onEndDateChange={endDate => this.setState({ endDate }, () => {
								dispatch(getBrandsExpenses(startDate, endDate))
								dispatch(getBrandsActivityStats(startDate, endDate))
							})}
						/>
					</div>
				</Segment>
				<BrandsTable
					brands={brands}
					brandsExpenses={brandsExpenses}
					brandsActivityStats={brandsActivityStats}
					onBrandClick={(brand) => dispatch(handleBrandSelect(brand))}
				/>
			</div>
		)
	}

	exportAsExcel = (data) => {
		const { brands, brandsExpenses, brandsActivityStats } = this.props
		const { startDate, endDate } = this.state

		const headerRow = [
			'ID',
			'Brand',
			'Total Gigs',
			'Total Spend',
			'Finished Gigs',
			'In-Progress Gigs',
			'Remaining Gigs',
			'Businesses Visited',
			'Materials Distributed',
			'Leads',
			'Date'
		].map(cell => ({ value: cell, type: 'string'}))

		const contentRows = map(brands.results, brand => {

			return [
				{
					value: brand.brand_id,
					type: 'number'
				},
				{
					value: brand.brand_name,
					type: 'string'
				},
				{
					value: brandsActivityStats[brand.brand_id] ? brandsActivityStats[brand.brand_id].total_gigs : 0,
					type: 'number'
				},
				{
					value: `$${brandsExpenses[brand.brand_id] ? brandsExpenses[brand.brand_id].total_in_cents / 100 : 0}`,
					type: 'string'
				},
				{
					value: brandsActivityStats[brand.brand_id] ? brandsActivityStats[brand.brand_id].total_gigs_finished : 0,
					type: 'number'
				},
				{
					value: brandsActivityStats[brand.brand_id] ? brandsActivityStats[brand.brand_id].total_gigs_in_progress : 0,
					type: 'number'
				},
				{
					value: brandsActivityStats[brand.brand_id] ? brandsActivityStats[brand.brand_id].total_gigs_not_started : 0,
					type: 'number'
				},
				{
					value: brandsActivityStats[brand.brand_id] ? brandsActivityStats[brand.brand_id].businesses_visited : 0,
					type: 'number'
				},
				{
					value: brandsActivityStats[brand.brand_id] ? brandsActivityStats[brand.brand_id].materials_handed_out : 0,
					type: 'number'
				},
				{
					value: brandsActivityStats[brand.brand_id] ? brandsActivityStats[brand.brand_id].business_card_count : 0,
					type: 'number'
				},
				{
					value: `${startDate ? moment(startDate).format('MM/DD/YY') : ''} - ${endDate ? moment(endDate).format('MM/DD/YY') : ''}`,
					type: 'string'
				},
			]
		})

		contentRows.unshift(headerRow)

		const config = {
		  filename: `Brands Overview ${startDate ? moment(startDate).format('MM/DD/YY') : ''} - ${endDate ? moment(endDate).format('MM/DD/YY') : ''}`,
		  sheet: {
		    data: contentRows
		  }
		}

		excelExporter(config)
	}
}

function mapStateToProps({ brands }) {
	return {
		brands: brands.brands,
		brandsExpenses: brands.brandsExpenses,
		brandsActivityStats: brands.brandsActivityStats,
	}
}

export default connect(mapStateToProps)(BrandsOverview)
