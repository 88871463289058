import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Breadcrumb } from 'semantic-ui-react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { reduce } from 'underscore'
import moment from 'moment-timezone'

import config from '../config'
import ExecutionType from '../components/newCreateCampaign/ExecutionType'
import Brands from '../components/newCreateCampaign/Brands'
import TacticObjective from '../components/newCreateCampaign/TacticObjective'
import Stores from '../components/newCreateCampaign/Stores'
import TargetSettings from '../components/newCreateCampaign/TargetSettings'
import StoreOverride from '../components/newCreateCampaign/StoreOverride'
import GigSettings from '../components/newCreateCampaign/GigSettings'
import GigsOverview from '../components/newCreateCampaign/GigsOverview'
import ProgramDetails from '../components/newCreateCampaign/ProgramDetails'

function CreateCampaign() {
	const [currentStep, setCurrentStep] = useState(0)
	const [selectedExecution, setExecutionType] = useState(null)
	const [brands, setBrands] = useState([])
	const [selectedBrand, setSelectedBrand] = useState(null)
	const [selectedTactics, setSelectedTactics] = useState([])
	const [selectedPurpose, setSelectedPurpose] = useState(null)
	const [stores, setStores] = useState([])
	const [selectedStores, setSelectedStores] = useState([])
	const [targetSettings, setTargetSettings] = useState({})
	const [gigSettings, setGigSettings] = useState({
		daysOfWeek: [],
		excludedDates: []
	})
	const [programDetails, setProgramDetails] = useState({})
	const [emailCampaignDetails, setEmailCampaignDetails] = useState({
		dynamicEmailTemplateData: []
	})

	// const [campaignState, setCampaignState] = useState({
	// 	// selectedBrand: null,
	// })

	useEffect(() => {
		getBrands()
	}, [])

	useEffect(() => {
		resetState()

		getStores()
		// getCampaigns()
		// getTrainings()
		// getUsers()

	}, [selectedBrand])

	useEffect(() => {
		resetState()
	}, [selectedExecution])

	const resetState = () => {
		setSelectedTactics([])
		setSelectedPurpose(null)
		setSelectedStores([])
		setStores([])
		setTargetSettings({})
		setGigSettings({
			daysOfWeek: [],
			excludedDates: []
		})
	}

	const handleTargetSettingsUpdate = (field, value) => {
		let newState = {}

		newState = { ...targetSettings, [field]: value }

		setTargetSettings(newState)
	}

	// const updateCampaignState = (field, value) => {
	// 	console.log(field, value)
	// 	let newState = {}
	// 	// const newState = Object.assign({}, campaignState, { [field]: value })

	// 	if(typeof field === 'object') {
	// 		const tempState = reduce(field, (accum, val, key) => {
	// 			accum[key] = val
	// 			return accum
	// 		}, {})

	// 		newState = { ...campaignState, ...tempState }
	// 	} else {
	// 		newState = { ...campaignState, [field]: value }
	// 	}

	// 	// if(field === 'assigned_to_field_day') {
	// 	// 	newState.phoneGig = false
	// 	// }

	// 	// if(field === 'phoneGig') {
	// 	// 	newState.assigned_to_field_day = true
	// 	// }

	// 	setCampaignState(newState)
	// }

	const getBrands = async () => {
		try {
			const brands = await axios.get(`${config.coreAPI}/brand/all/names?pageFrom=0&pageSize=10000`)

			setBrands(brands.data.responses[0].results)
		} catch(e) {
			console.error(e)
		}
	}

	const getStores = async () => {
		if(!selectedBrand) return

		setSelectedStores([])

		try {
			const stores = await axios.get(`${config.coreAPI}/stores?brandId=${selectedBrand.brand_id}&pageSize=10000`)

			setStores(stores.data.stores)
		} catch(e) {
			console.error(e)
		}
	}

	// const getCampaigns = async () => {
	// 	if(!brandId) return

	// 	try {
	// 		const campaigns = await axios.get(`${config.coreAPI}/activity/campaigns?brandId=${brandId}`)

	// 		setCampaigns(campaigns.data)
	// 	} catch(e) {
	// 		console.error(e)
	// 	}
	// }

	// const getTrainings = async () => {
	// 	if(!brandId) return

	// 	try {
	// 		const trainings = await axios.get(`${config.coreAPI}/fieldDayAdmin/training/plans?brandId=${brandId}&pageFrom=0&pageSize=1000`)

	// 		setTrainings(trainings.data)
	// 	} catch(e) {
	// 		console.error(e)
	// 	}
	// }

	// const getUsers = async () => {
	// 	if(!brandId) return

	// 	try {
	// 		const users = await axios.get(`${config.coreAPI}/users?brandId=${brandId}&pageSize=10000`)

	// 		setUsers(users.data.users)
	// 	} catch(e) {
	// 		console.error(e)
	// 	}
	// }

	const handleGetTargetCount = () => {
		if(!selectedStores) {
			alert('There are no stores selected')
			return
		}

		selectedStores.forEach((store, i) => {
			if(store.targetSettings) {
				getTargetCount(store, store.targetSettings, i)
			} else {
				getTargetCount(store, targetSettings, i)
			}
		})
	}

	const getTargetCount = async (store, settings, storeIndex) => {
		let filters = [
			{
				relation: 'geo_distance',
				value: {
					distance: settings.radius + 'mi',
					lat: store.loc_point[1],
					lon: store.loc_point[0]
				}
			},
			{
				attribute: 'estimated_location_employee_count',
				relation: settings.employeeCountRelation,
				value: settings.employeeCountRelation === 'between' ? [settings.employeeCountValueFrom, settings.employeeCountValueTo] : settings.employeeCountValue
			},
			{
				attribute: 'phone',
				relation: 'present',
			},
		]

		if(settings.codes && settings.codes.excludedCodes && settings.codes.excludedCodes.length) {
			filters.push({
				attribute: settings.sicCodeType,
				relation: 'in',
				value: settings.codes.excludedCodes,
				negated: true
			})
		}

		if(settings.codes && settings.codes.addedCodes && settings.codes.addedCodes.length) {
			filters.push({
				attribute: settings.sicCodeType,
				relation: 'in',
				value: settings.codes.addedCodes,
			})
		}

		try {
			// gets target count only
			// const response = await axios.post(`http://localhost:3064/fieldday/infoGroup?limit=0&offset=0`, {
			const response = await axios.post(`https://dev.fieldday.app/fieldday/infoGroup?limit=0&offset=0`, {
				filters,
				filters2: [],
			})

			console.log('get count: ', response.data)

			const newStoresState = [ ...selectedStores ]

			newStoresState[storeIndex].targetCount = response.data.count
			newStoresState[storeIndex].targets = response.data.documents

			setSelectedStores(newStoresState)

		} catch(e) {
			console.error(e)
			// handleErrors(e)
		}
	}

	const handleGetSchedule = () => {
		if(!selectedStores) {
			alert('There are no stores selected')
			return
		}

		selectedStores.forEach((store, i) => {
			getStoreSchedule(store, i)
		})
	}

	const getStoreSchedule = async (store, storeIndex) => {
		const daysOfWeek = {
			'Mo': 'MONDAY',
			'Tu': 'TUESDAY',
			'We': 'WEDNESDAY',
			'Th': 'THURSDAY',
			'Fr': 'FRIDAY',
			'Sa': 'SATURDAY',
			'Su': 'SUNDAY',
		}

		let estimatedNumOfGigs = 0

		// if(true || selectedExecution === 'PHONE') {
		// 	let minTargetCount = 0

		// 	if(store.targetSettings) {
		// 		minTargetCount = Number(store.targetSettings.reachGoal) + (Number(store.targetSettings.reachGoal) * (Number(store.targetSettings.targetCushion) / 100))
		// 	}

		// 	if(!store.targetSettings) {
		// 		minTargetCount = Number(targetSettings.reachGoal) + (Number(targetSettings.reachGoal) * (Number(targetSettings.targetCushion) / 100))
		// 	}

		// 	estimatedNumOfGigs = minTargetCount / Number(gigSettings.duration)
		// }

		if(store.targetSettings) {
			estimatedNumOfGigs = Number(store.targetSettings.reachGoal) / Number(store.targetSettings.reach_per_hour) / Number(gigSettings.duration)
		}

		if(!store.targetSettings) {
			estimatedNumOfGigs = Number(targetSettings.reachGoal) / Number(targetSettings.reach_per_hour) / Number(gigSettings.duration)
		}

		try {
			console.log("EST # OF GIGS: ", estimatedNumOfGigs)

			const response = await axios.post(`${config.coreAPI}/activity/generateSchedule`, {
				'start_time': gigSettings.startDate,
				'weekly_days': gigSettings.daysOfWeek.map(day => daysOfWeek[day]),
				'max_simultaneous_count': gigSettings.simultaneousGigs ? gigSettings.maxGigsPerDay : 0,
				'excluded_days': gigSettings.excludedDates,
				'events_needed_count': estimatedNumOfGigs,
				'time_zone': moment.tz.guess(),
			})

			console.log('SCHEDULE: ', response.data)

			const newStoresState = [ ...selectedStores ]

			newStoresState[storeIndex].schedule = response.data.event_times || []

			setSelectedStores(newStoresState)

		} catch(e) {
			console.error(e)
			// handleErrors(e)
		}
	}

	// console.log('campaignState: ', campaignState)
	// console.log('selectedExecution: ', selectedExecution)
	// console.log('selectedBrand: ', selectedBrand)
	// console.log('selectedTactics: ', selectedTactics)
	// console.log('selectedPurpose: ', selectedPurpose)
	// console.log('stores: ', stores)
	// console.log('selectedStores: ', selectedStores)
	// console.log('targetSettings: ', targetSettings)
	// console.log('gigSettings: ', gigSettings)

	return (
		<Container>
			<Row className='mb-3 mt-3'>
				<Col>
					<Breadcrumb className='bg-whitess'>

					  <Breadcrumb.Section active={currentStep === 0} onClick={() => setCurrentStep(0)}>Execution Type</Breadcrumb.Section>
					  <Breadcrumb.Divider icon='right chevron' />

					  <Breadcrumb.Section active={currentStep === 1} onClick={() => setCurrentStep(1)}>Brand</Breadcrumb.Section>
					  <Breadcrumb.Divider icon='right chevron' />

					  <Breadcrumb.Section active={currentStep === 2} onClick={() => setCurrentStep(2)}>Tactic & Objective</Breadcrumb.Section>
					  <Breadcrumb.Divider icon='right chevron' />

					  <Breadcrumb.Section active={currentStep === 3} onClick={() => setCurrentStep(3)}>Stores</Breadcrumb.Section>
					  <Breadcrumb.Divider icon='right chevron' />

					  <Breadcrumb.Section active={currentStep === 4} onClick={() => setCurrentStep(4)}>Target Settings (per store)</Breadcrumb.Section>
					  <Breadcrumb.Divider icon='right chevron' />

					  <Breadcrumb.Section active={currentStep === 5} onClick={() => setCurrentStep(5)}>Store Override</Breadcrumb.Section>
					  <Breadcrumb.Divider icon='right chevron' />

					  <Breadcrumb.Section active={currentStep === 6} onClick={() => setCurrentStep(6)}>Gig Settings (per gig)</Breadcrumb.Section>
					  <Breadcrumb.Divider icon='right chevron' />

					  <Breadcrumb.Section active={currentStep === 7} onClick={() => setCurrentStep(7)}>Gigs Overview</Breadcrumb.Section>
					  <Breadcrumb.Divider icon='right chevron' />

					  <Breadcrumb.Section active={currentStep === 8} onClick={() => setCurrentStep(8)}>Program Details</Breadcrumb.Section>

					</Breadcrumb>
				</Col>
			</Row>
			{currentStep === 0 &&
				<ExecutionType
					onSelectExecution={setExecutionType}
					selectedExecution={selectedExecution}
				/>
			}

			{currentStep === 1 &&
				<Brands
					brands={brands}
					onSelectBrand={setSelectedBrand}
					selectedBrand={selectedBrand}
				/>
			}

			{currentStep === 2 &&
				<TacticObjective
					selectedBrand={selectedBrand}
					onSelectTactics={setSelectedTactics}
					selectedTactics={selectedTactics}
					onSelectPurpose={setSelectedPurpose}
					selectedPurpose={selectedPurpose}
				/>
			}

			{currentStep === 3 &&
				<Stores
					stores={stores}
					onSelectStores={setSelectedStores}
					selectedStores={selectedStores}
					selectedBrand={selectedBrand}
				/>
			}

			{currentStep === 4 &&
				<TargetSettings
					targetSettings={targetSettings}
					onTargetSettingsUpdate={handleTargetSettingsUpdate}
					selectedExecution={selectedExecution}
				/>
			}

			{currentStep === 5 &&
				<StoreOverride
					targetSettings={targetSettings}
					selectedStores={selectedStores}
					onUpdateStore={setSelectedStores}
					selectedExecution={selectedExecution}
					onGetTargetCount={handleGetTargetCount}
				/>
			}
			{currentStep === 6 &&
				<GigSettings
					gigSettings={gigSettings}
					onGigSettingsChange={setGigSettings}
				/>
			}
			{currentStep === 7 &&
				<GigsOverview
					selectedStores={selectedStores}
					gigSettings={gigSettings}
					selectedExecution={selectedExecution}
					targetSettings={targetSettings}
					onGetSchedule={handleGetSchedule}
				/>
			}
			{currentStep === 8 &&
				<ProgramDetails
					programDetails={programDetails}
					onProgramDetailsChange={setProgramDetails}
					emailCampaignDetails={emailCampaignDetails}
					onEmailCampaignDetailsChange={setEmailCampaignDetails}
					selectedExecution={selectedExecution}
					selectedBrand={selectedBrand}
				/>
			}
		</Container>
	)
}

export default CreateCampaign