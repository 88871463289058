import React from 'react'
import { map } from 'underscore'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import internalImg from '../../assets/img/internal_staff_icon.png'
import FDlogo from '../../assets/img/FD_running.png'
import phoneImg from '../../assets/img/phone_dial_icon.png'

export default function Execution({ onClick, selectedExecution }) {
	const options = {
		'Internal Staff': internalImg,
		'Field Day Ambassadors': FDlogo,
		'Phone Ambassadors': phoneImg
	}

	return (
		<Row className='ex-container'>
			{map(options, (imgSrc, title) =>
				<Tile
					key={title}
					title={title}
					img={imgSrc}
					onImgClick={onClick}
					selectedExecution={selectedExecution}
				/>
			)}
		</Row>
	)
}

function Tile({ title, img, onImgClick, selectedExecution }) {
	return (
		<Col className='ex-tile'>
			<h3 className={`fd-green mb-5 mt-3 ${title === selectedExecution ? 'border-bottom border-danger' : ''}`}>{title}</h3>
			<img
				alt=''
				src={img}
				className='pointer'
				onClick={() => {
					if(title === 'Internal Staff') onImgClick('assigned_to_field_day', false)
					if(title === 'Field Day Ambassadors') onImgClick('assigned_to_field_day', true)
					if(title === 'Phone Ambassadors') onImgClick('phoneGig', true)
				}}
			/>
		</Col>
	)
}
