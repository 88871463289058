import React, { useState } from 'react'
import axios from 'axios'
import { Modal, Form, Col, Button } from 'react-bootstrap'

import config from '../../../config'
import StoreForm from './StoreForm'
import { getBrandStores } from '../../../actions/brands'

function AddStore({ brandId, showModal, onClose }) {
	const [validated, setValidated] = useState(false)
	const [numOfStores, setNumOfStores] = useState(1)
	const [autoGenerateId, setAutoGenerateId] = useState(false)
	const [adding, setAdding] = useState(false)
	const [error, setError] = useState('')
	const [stores, setStores] = useState([
		{
		  generate_store_id: false,
		  store_id: '',
		  store_name: '',
		  street_address: '',
		  street_address2: '',
		  city: '',
		  state: '',
		  postal_code: '',
		  country: '',
		  region_name: ''
		}
	])

	const storeFormComponents = []

	for(let i = 0; i < numOfStores; i++) {
		storeFormComponents.push(
			<StoreForm
				key={i}
				index={i}
				values={stores[i]}
				onChange={handleInputChange}
				autoGenerateId={autoGenerateId}
				onRemoveStore={handleRemoveStore}
			/>
		)
	}

	return (
		<Modal
			size='lg'
			centered
			dialogClassName='modal-70w'
			show={showModal}
			onHide={onClose}
		>
			<Modal.Header closeButton>
				<Modal.Title>Add New Stores</Modal.Title>
			</Modal.Header>
			<Form noValidate validated={validated} onSubmit={handleSubmit}>
				<Modal.Body>
					<Form.Check
						className='ml-1 mb-4'
						type='checkbox'
						label='Auto Generate Store IDs'
						value={autoGenerateId}
						onChange={e => setAutoGenerateId(e.target.checked)}
					/>
					{storeFormComponents}
					<Button className='mt-2 ml-1' onClick={handleAddNewStore}>+</Button>
				</Modal.Body>
				<Modal.Footer>
					<span className='text-danger'>{error}</span>
					<Button type='submit' disabled={adding}>{adding ? 'Adding..' : 'Add'}</Button>
				</Modal.Footer>
			</Form>
		</Modal>
	)

	function handleAddNewStore() {
		const newState = [...stores].concat([
			{
			  generate_store_id: false,
			  store_id: '',
			  store_name: '',
			  street_address: '',
			  street_address2: '',
			  city: '',
			  state: '',
			  postal_code: '',
			  country: '',
			  region_name: ''
			}
		])

		setNumOfStores(numOfStores + 1)
		setStores(newState)
	}

	function handleRemoveStore(index) {
		const newState = [...stores]

		newState.splice(index, 1)

		setNumOfStores(numOfStores - 1)
		setStores(newState)
	}

	function handleInputChange(index, event) {
		const { name, value } = event.target
		const newState = [...stores]

		newState[index][name] = value

		setStores(newState)
	}

	async function handleSubmit(event) {
		const form = event.currentTarget
		const storeParams = [...stores]

		setError('')
		setAdding(true)

		if(form.checkValidity() === false) {
			event.preventDefault()
			event.stopPropagation()
			setValidated(true)
			setAdding(false)
		} else {
			event.preventDefault()

			try {
				if(autoGenerateId) {
					storeParams.forEach(store => store.generate_store_id = true)

					await axios.post(`${config.coreAPI}/stores`, {
						brand_id: brandId,
						stores: storeParams
					})

				} else {
					await axios.post(`${config.coreAPI}/stores`, {
						brand_id: brandId,
						stores: storeParams
					})
				}

				setAdding(false)
				onClose()

			} catch(e) {
				console.error(e)
				setAdding(false)

				if(e.response && e.response.data && e.response.data.message) {
					setError(e.response.data.message)
				} else if(e.response && e.response.data && e.response.data.errors) {
					setError(e.response.data.errors.map(err => (
						<div>
							<div>{err}</div>
						</div>
					)))
				} else {
					setError('Error! Check the logs.')
				}
			}
		}
	}
}

export default AddStore
