import React, { Component, Fragment } from 'react'
import axios from 'axios'
import _ from 'underscore'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner'

import config from '../config'
import WYSIWYG from './WYSIWYG'

class TrainingForm extends Component {
	state = {
		errors: [],
		brandsSearchResults: [],
		working: false,
		created: 1,
		brandName: this.props.selectedTraining && this.props.selectedTraining.brand_name ? this.props.selectedTraining.brand_name : '',
		brandId: this.props.selectedTraining && this.props.selectedTraining.brand_id ? this.props.selectedTraining.brand_id : null,
		emailSubject: this.props.selectedTraining && this.props.selectedTraining.email_subject ? this.props.selectedTraining.email_subject : '',
		trainingTitle: this.props.selectedTraining && this.props.selectedTraining.name ? this.props.selectedTraining.name : '',
		trainingDescription: this.props.selectedTraining && this.props.selectedTraining.description ? this.props.selectedTraining.description : '',
		numOfVideos: this.props.selectedTraining && this.props.selectedTraining.training_videos ? this.props.selectedTraining.training_videos.length : 0,
		trainingVideos: this.props.selectedTraining && this.props.selectedTraining.training_videos ? this.props.selectedTraining.training_videos : [],
		numOfUrls: this.props.selectedTraining && this.props.selectedTraining.training_urls ? this.props.selectedTraining.training_urls.length : 0,
		trainingUrls: this.props.selectedTraining && this.props.selectedTraining.training_urls ? this.props.selectedTraining.training_urls : [],
		attachments: this.props.selectedTraining && this.props.selectedTraining.training_materials ? this.props.selectedTraining.training_materials : [],
		quiz: this.props.selectedTraining && this.props.selectedTraining.quiz
			? _.map(this.props.selectedTraining.quiz.question_key, (q, i) => ({
				question: q.question,
				answers: q.answers,
				correctAnswers: this.props.selectedTraining.quiz.answer_key[i].answers
			}))
			: [],
		numOfQuestions: this.props.selectedTraining && this.props.selectedTraining.quiz ? this.props.selectedTraining.quiz.question_key.length : 0,
		minScore: this.props.selectedTraining && this.props.selectedTraining.quiz ? this.props.selectedTraining.quiz.min_score : ''
		// selectedBrand: {},
		// name: '',
		// trainingVideoUrl: [],
		// trainingVideoTitle: [],
		// trainingVideoDescription: [],
		// attachmentsTitles: [],
		// attachmentsDescriptions:[],
		// quiz: [{
		// 	question: '',
		// 	answers: [],
		// 	correctAnswers: []
		// }],
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClick)
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClick)
	}

	render() {
		let videoInputComponents = []
		let urlInputComponents = []
		let questionCardComponents = []
		const { selectedTraining } = this.props
		const {
			errors,
			working,
			brandsSearchResults,
			brandName,
			emailSubject,
			trainingTitle,
			trainingDescription,
			numOfVideos,
			trainingVideos,
			numOfUrls,
			trainingUrls,
			attachments,
			quiz,
			numOfQuestions
		} = this.state

		for(let i = 0; i < numOfVideos; i++) {
			videoInputComponents.push(
				<VideoInput
					key={i}
					index={i}
					url={trainingVideos[i] ? trainingVideos[i].url : ''}
					title={trainingVideos[i] ? trainingVideos[i].title : ''}
					description={trainingVideos[i] ? trainingVideos[i].description : ''}
					onVideoInputChange={this.handleVideoInputChange}
					onRemoveVideo={this.handleRemoveVideo}
				/>
			)
		}

		for(let i = 0; i < numOfUrls; i++) {
			urlInputComponents.push(
				<UrlInput
					key={i}
					index={i}
					url={trainingUrls[i] ? trainingUrls[i].url : ''}
					title={trainingUrls[i] ? trainingUrls[i].title : ''}
					description={trainingUrls[i] ? trainingUrls[i].description : ''}
					onUrlInputChange={this.handleUrlInputChange}
					onRemoveUrl={this.handleRemoveUrl}
				/>
			)
		}

		for(let i = 0; i < numOfQuestions; i++) {
			questionCardComponents.push(
				<QuestionCard
					key={i}
					index={i}
					question={quiz[i].question}
					answers={quiz[i].answers}
					correctAnswers={quiz[i].correctAnswers}
					onQuizInputChange={this.handleQuizInputChange}
					onAnswerClick={this.handleAnswerClick}
					onRemoveQuestion={this.handleRemoveQuestion}
				/>
			)
		}

		console.log('Training Form State: ', this.state)
		console.log('Training Form Props: ', this.props)

		return (
			<div ref={node => this.form = node}>
			<Form>
					{errors.map((err, i) => <Alert key={i} variant={'danger'}>{err}</Alert>)}
					<Col className='pl-0' lg={4}>
						<Form.Group>
							<Form.Label>Select a Brand</Form.Label>
							<Form.Control
								type='text'
								// name='brandId'
								value={brandName}
								onChange={this.handleBrandAutoComplete}
							/>
							<Form.Text className="text-muted">*required</Form.Text>
						</Form.Group>
						{brandsSearchResults.length
							? <div className='search-result' style={{top: '70px'}} ref={node => this.brandsSearchBox = node}>
									{brandsSearchResults.map((brand, i) => (
										<div
											key={i}
											className='search-result-item'
											onClick={() => this.setState({
												brandName: brand.brand_name,
												brandId: brand.brand_id,
												brandsSearchResults: [],
											})}>
											{brand.brand_name}
										</div>
										))}
								</div>
							: null
						}
					</Col>

					<Form.Group>
						<Form.Label>Training Email Subject</Form.Label>
						<Form.Control
							type='text'
							name='emailSubject'
							value={emailSubject}
							onChange={this.handleInputChange}
						/>
					</Form.Group>

					<Form.Group>
						<Form.Label>Training Title</Form.Label>
						<Form.Control
							type='text'
							name='trainingTitle'
							value={trainingTitle}
							onChange={this.handleInputChange}
						/>
						<Form.Text className="text-muted">*required</Form.Text>
					</Form.Group>

					<Form.Group>
						<Form.Label>Training Description</Form.Label>
						<WYSIWYG
							id={'trainingDescription'}
							fieldName={'trainingDescription'}
							defaultValue={trainingDescription}
							onUpdateField={this.handleInputChange}
						/>
					</Form.Group>

					<Form.Group>
						<Form.Label>Training Video</Form.Label>
						<Button className='ml-2' size='sm' onClick={this.handleAddNewVideo}>+</Button>
						{videoInputComponents}
					</Form.Group>

					<hr />

					<Form.Group>
						<Form.Label>Training Links</Form.Label>
						<Button className='ml-2' size='sm' onClick={this.handleAddNewUrl}>+</Button>
						<Form.Text className="text-muted">Link URLs must start with http:// or https://</Form.Text>
						{urlInputComponents}
					</Form.Group>

					<hr />

					<Form.Group>
						<Form.Label>Upload Assets</Form.Label>
						<br />
						<Col lg={3}  className='upload-btn-wrapper'>
							<Form.Control type='button' value='Choose File' className='pointer'/>
							<input
								type='file'
								multiple
								onChange={this.handleAddAttachment}
								className='pointer'
							/>
						</Col>
						<br />
						{attachments.map((file, i) => (
							<Attachments
								key={i}
								index={i}
								file={file}
								title={attachments[i] && attachments[i].title !== 'undefined' ? attachments[i].title : ''}
								description={attachments[i] && attachments[i].description !== 'undefined' ? attachments[i].description : ''}
								onRemoveAttachment={this.handleRemoveAttachment}
								onAttachmentInputChange={this.handleAttachmentInputChange}
							/>
						))}
					</Form.Group>

					<hr />

					<Form.Group>
						<Form.Label>Quiz</Form.Label>
						{questionCardComponents}
						<Button className='mt-2 ml-1' onClick={this.handleAddNewQuestion}>+</Button>
						{quiz.question_key && quiz.question_key.length > 9 && <i><small className="text-muted">maximum of 10 questions can be added</small></i>}
					</Form.Group>

					<hr />

					<Row>
						<Col lg={3}>
							<Form.Group>
								Passing Score
								<Form.Control
									type='text'
									name='minScore'
									value={this.state.minScore}
									onChange={this.handleInputChange}
								/>
							</Form.Group>
						</Col>
					</Row>

				{this.state.created === 2 &&<Alert variant={'success'}>Training plan successfully created</Alert>}
				{this.state.created === 3 &&<Alert variant={'danger'}>Training plan failed to create!</Alert>}

				{working
					? <Spinner animation='border' variant='info' />
					: <Button
							variant='dark'
							size='lg'
							// block
							onClick={this.handleSubmit}
						>
							{this.props.selectedTraining ? 'SAVE' : 'CREATE'}
						</Button>
				}
			</Form>
			</div>
		)
	}

	handleErrors = (e) => {
		this.form.scrollTop = 0

		this.setState({
			errors: this.state.errors.includes(e.response.data.message) ? [...this.state.errors] : this.state.errors.concat([`${e.response.data.message}`])
		})

		setTimeout(() => this.setState({
			errors: []
		}), 10000)
	}

	handleClick = (e) => {
		if(this.brandsSearchBox && this.brandsSearchBox.contains(e.target)) {
			// click is inside
			return
		}

		this.setState({
			brandsSearchResults: []
		})
	}

	handleBrandAutoComplete = async ({ target }) => {
		this.setState({ brandName: target.value })

		try {
			const response = await axios.get(`${config.coreAPI}/brand/autocomplete?searchString=${target.value}&pageFrom=0&pageSize=20`)

			this.setState({
				brandsSearchResults: response.data,
			})
		} catch(e) {
			this.handleErrors(e)
		}
	}

	handleInputChange = (event, val) => {
		let name
		let value

		if(val) {
			name = event
			value = val
		} else {
			name = event.target.name
			value = event.target.value
		}

		this.setState({
			[name]: value
		})
	}

	handleVideoInputChange = (event, index) => {
		const { name, value } = event.target
		const trainingVideos = [...this.state.trainingVideos]

		trainingVideos[index] = {
			...trainingVideos[index],
			[name]: value
		}

		this.setState({ trainingVideos })
	}

	handleAddNewVideo = () => {
		this.setState(({ numOfVideos }) => ({ numOfVideos: numOfVideos + 1 }))
	}

	handleRemoveVideo = (url) => {
		this.setState(({ trainingVideos, numOfVideos }) => ({
			trainingVideos: trainingVideos.filter(video => video.url !== url),
			numOfVideos: numOfVideos - 1
		}))

		// const trainingVideos = [...this.state.trainingVideos]

		// trainingVideos.splice(index, 1)

		// this.setState({
		// 	trainingVideos,
		// 	numOfVideos: numOfVideos - 1,
		// })
	}

	handleUrlInputChange = (event, index) => {
		const { name, value } = event.target
		const trainingUrls = [...this.state.trainingUrls]

		trainingUrls[index] = {
			...trainingUrls[index],
			[name]: value
		}

		this.setState({ trainingUrls })
	}

	handleAddNewUrl = () => {
		this.setState(({ numOfUrls }) => ({ numOfUrls: numOfUrls + 1 }))
	}

	handleRemoveUrl = (url) => {
		this.setState(({ trainingUrls, numOfUrls }) => ({
			trainingUrls: trainingUrls.filter(video => video.url !== url),
			numOfUrls: numOfUrls - 1
		}))

		// const trainingUrls = [...this.state.trainingUrls]

		// trainingUrls.splice(index, 1)

		// this.setState({
		// 	trainingUrls,
		// 	numOfUrls: numOfUrls - 1,
		// })
	}

	handleAddAttachment = (event) => {
		const { files } = event.target

		this.setState({
			attachments: this.state.attachments.concat(Array.from(files))
		})
	}

	handleAttachmentInputChange = (event, index) => {
		const { name, value } = event.target
		const attachments = [...this.state.attachments]

		if(name === 'title') attachments[index].title = value
		if(name === 'description') attachments[index].description = value

		this.setState({ attachments })
	}

	handleRemoveAttachment = async (index) => {
		const confirmDelete = window.confirm('This will delete the attachment! Confirm?')

		if(!confirmDelete) return

		const attachments = [...this.state.attachments]

		if(!attachments[index].size) {
			try {
				await axios.delete(
					`${config.coreAPI}/fieldDayAdmin/training/plan/materials?trainingPlanId=${this.props.selectedTraining.id}&materialUrl=${encodeURIComponent(attachments[index].url)}`
				)

				attachments.splice(index, 1)

				this.setState({
					attachments
				})
			} catch(e) {
				console.error(e)
				alert('Error deleting the asset')
			}
		} else {
			attachments.splice(index, 1)

			this.setState({
				attachments
			})
		}
	}

	handleQuizInputChange = (event, questionIndex, answerIndex) => {
		const { name, value } = event.target
		const quiz = [...this.state.quiz]

		if(name === 'question') {
			quiz[questionIndex] = {
				...quiz[questionIndex],
				[name]: value
			}
		}

		if(name === 'answer') {
			quiz[questionIndex].answers = quiz[questionIndex].answers ? [...quiz[questionIndex].answers] : []
			quiz[questionIndex].answers[answerIndex] = value
			quiz[questionIndex].correctAnswers = quiz[questionIndex].correctAnswers.filter(answer => quiz[questionIndex].answers.includes(answer))
		}

		this.setState({ quiz })
	}

	handleAnswerClick = (questionIndex, answerIndex) => {
		const quiz = [...this.state.quiz]

		quiz[questionIndex].correctAnswers = quiz[questionIndex].correctAnswers.includes(quiz[questionIndex].answers[answerIndex])
			? quiz[questionIndex].correctAnswers.filter(ans => ans !== quiz[questionIndex].answers[answerIndex])
			: quiz[questionIndex].correctAnswers.concat(quiz[questionIndex].answers[answerIndex])


		this.setState({ quiz })
	}

	handleAddNewQuestion = () => {
		if(this.state.quiz.length > 9) return

		this.setState(({ quiz, numOfQuestions }) => ({
			numOfQuestions: numOfQuestions + 1,
			quiz: quiz.concat([{
				question: '',
				answers: [],
				correctAnswers: []
			}])
		}))

		// const newQuestion = {
		// 	question: '',
		// 	answers: [],
		// 	correctAnswers: []
		// }

		// this.setState({
		// 	quiz: this.state.quiz.concat([newQuestion])
		// })
	}

	handleRemoveQuestion = (index) => {
		const quiz = [...this.state.quiz]
		quiz.splice(index, 1)

		this.setState(({ numOfQuestions }) => ({
			quiz,
			numOfQuestions: numOfQuestions - 1
		}))
	}

	validateForm = () => {
		const { brandId, trainingTitle, trainingVideos, trainingUrls, quiz, attachments } = this.state
		const errorObj = { response: { data: { message: '' } } }

		if(!brandId) {
			errorObj.response.data.message = 'Brand is required'

			this.handleErrors(errorObj)
			alert(errorObj.response.data.message)
			return false
		}

		if(!trainingTitle) {
			errorObj.response.data.message = 'Training Title is required'

			this.handleErrors(errorObj)
			alert(errorObj.response.data.message)
			return false
		}

		if(!trainingVideos.every(video => video.title)) {
			errorObj.response.data.message = 'Video URL and Title are required'

			this.handleErrors(errorObj)
			alert(errorObj.response.data.message)
			return false
		}

		if(!trainingUrls.every(url => url.title)) {
			errorObj.response.data.message = 'Link URL and Title are required'

			this.handleErrors(errorObj)
			alert(errorObj.response.data.message)
			return false
		}

		if(!attachments.every(att => att.title)) {
			errorObj.response.data.message = 'Attachment title is required'

			this.handleErrors(errorObj)
			alert(errorObj.response.data.message)
			return false
		}

		if(!quiz.every(question => (question.correctAnswers && question.correctAnswers.length > 0))) {
			errorObj.response.data.message = 'You need to select at least one correct answer for each quiz questions'

			this.handleErrors(errorObj)
			alert(errorObj.response.data.message)
			return false
		}

		return true
	}

	handleSubmit = async () => {
		if(!this.validateForm()) return

		const trainingMaterials = []
		const {
			trainingTitle,
			brandId,
			brandName,
			emailSubject,
			trainingDescription,
			trainingVideos,
			trainingUrls,
			quiz,
			minScore,
			attachments
		} = this.state

		// const newQuiz = [...quiz].reduce((memo, el) => memo.concat([{
		// 	question: el.question,
		// 	answers: el.answers
		// }]), [])

		const answerKey = quiz.map(q => ({
			question: q.question,
			answers: q.correctAnswers
		}))

		const params = {
			name: trainingTitle,
			description: trainingDescription,
			brand_id: brandId,
			brand_name: brandName,
			email_subject: emailSubject,
			training_videos: trainingVideos,
			training_urls: trainingUrls,
			quiz: {
				question_key: quiz.map(q =>({ question: q.question, answers: q.answers })),
				answer_key: quiz.map(q =>({ question: q.question, answers: q.correctAnswers })),
				min_score: Number(minScore)
			}
		}

		for(let i = 0; i < attachments.length; i++) {
			if(!attachments[i].size) {
				trainingMaterials.push(attachments[i])
			}
		}

		params.training_materials = trainingMaterials

		console.log('params: ', params)

		try {
			this.setState({ working: true })

			const response = this.props.selectedTraining
				? await axios.put(`${config.coreAPI}/fieldDayAdmin/training/plan?trainingPlanId=${this.props.selectedTraining.id}`, params)
				: await axios.post(`${config.coreAPI}/fieldDayAdmin/training/plan`, params)

			const trainingPlanId = response.data.id

			if(!attachments.length) {
				this.setState({
					working: false,
					created: 2
				}, () => this.props.onCloseModal())
			} else {
				for(let i = 0; i < attachments.length; i++) {
					if(attachments[i].size) {
						const formData = new FormData()

						console.log('att title: ', attachments[i].title)
						console.log('att description: ', attachments[i].description)

						formData.append('file', attachments[i], attachments[i].name)
						formData.set('title', attachments[i].title)
						formData.set('description', attachments[i].description)

						await axios({
							method: 'post',
							url: `${config.coreAPI}/fieldDayAdmin/training/plan/materials?trainingPlanId=${trainingPlanId}`,
							data: formData,
							config: { headers: {'Content-Type': 'multipart/form-data' } }
						})

						if(i === attachments.length - 1) {
							this.setState({
								working: false,
								created: 2
							}, () => this.props.onCloseModal())
						}
						// await this.sleep(10000)
					} else if(i === attachments.length - 1) {
						this.setState({
							working: false,
							created: 2
						}, () => this.props.onCloseModal())
					}
				}
			}

		} catch(e) {
			alert(e.response.data.message)
			this.setState({
				working: false,
				created: 3
			})
		}
	}

	// sleep = (ms) => {
	// 	return new Promise(resolve => setTimeout(resolve, ms))
	// }
}

class VideoInput extends Component {
	render() {
		const { url, title, description, onVideoInputChange, index, onRemoveVideo } = this.props

		return (
			<InputGroup className='mb-2 mt-1'>
				<Form.Control
					type='text'
					name='url'
					placeholder='Video URL'
					value={url || ''}
					onChange={(e) => onVideoInputChange(e, index)}
				/>
				<Form.Control
					type='text'
					name='title'
					placeholder='Video Title'
					value={title}
					onChange={(e) => onVideoInputChange(e, index)}
				/>
				<Form.Control
					as='textarea'
					rows='1'
					name='description'
					placeholder='Video Description'
					value={description || ''}
					onChange={(e) => onVideoInputChange(e, index)}
				/>
				<InputGroup.Append>
					<Button variant='danger' onClick={() => onRemoveVideo(url)}>-</Button>
				</InputGroup.Append>
			</InputGroup>
		)
	}
}

class UrlInput extends Component {
	render() {
		const { url, title, description, onUrlInputChange, index, onRemoveUrl } = this.props

		return (
			<InputGroup className='mb-2 mt-1'>
				<Form.Control
					type='text'
					name='url'
					placeholder='Link URL'
					value={url || ''}
					onChange={(e) => onUrlInputChange(e, index)}
				/>
				<Form.Control
					type='text'
					name='title'
					placeholder='Link Title'
					value={title}
					onChange={(e) => onUrlInputChange(e, index)}
				/>
				<Form.Control
					as='textarea'
					rows='1'
					name='description'
					placeholder='Link Description'
					value={description || ''}
					onChange={(e) => onUrlInputChange(e, index)}
				/>
				<InputGroup.Append>
					<Button variant='danger' onClick={() => onRemoveUrl(url)}>-</Button>
				</InputGroup.Append>
			</InputGroup>
		)
	}
}

function Attachments({ file, onRemoveAttachment, index , title, description, onAttachmentInputChange }) {
	return (
		<InputGroup className="mb-1 mt-1">
			<InputGroup.Prepend>
				<InputGroup.Text style={{backgroundColor: '#17a2b8' , color: 'white'}}>
					{file.name ? file.name : file.url.slice(file.url.lastIndexOf('/') + 1)}
				</InputGroup.Text>
			</InputGroup.Prepend>
			<Form.Control
				type='text'
				name='title'
				placeholder='Title'
				value={title || ''}
				onChange={(e) => onAttachmentInputChange(e, index)}
			/>
			<Form.Control
				as='textarea'
				rows='1'
				name='description'
				placeholder='Description'
				value={description || ''}
				onChange={(e) => onAttachmentInputChange(e, index)}
			/>
			<InputGroup.Append>
				<Button variant='danger' onClick={() => onRemoveAttachment(index)}>-</Button>
			</InputGroup.Append>
		</InputGroup>
	)
}

function QuestionCard({ index, question, answers, correctAnswers, onQuizInputChange, onAnswerClick, onRemoveQuestion }) {
	return (
		<Card bg='light' text='info'>
			<Card.Header>Question {index + 1}
				<Button variant='danger' onClick={() => onRemoveQuestion(index)} className='float-right'>-</Button>
			</Card.Header>
			<Card.Body>
				<Row>
					<Col>
						<InputGroup>
							<InputGroup.Text>Question</InputGroup.Text>
							<Form.Control
								type='text'
								name='question'
								value={question}
								onChange={(e) => onQuizInputChange(e, index)}
							/>
						</InputGroup>
					</Col>

					<Col>
						<InputGroup className='mb-1'>
							<InputGroup.Text onClick={() => onAnswerClick(index, 0)} className='pointer' style={{backgroundColor: `${correctAnswers.includes(answers[0]) ? '#28a745' : '#e9ecef'}`}}>a</InputGroup.Text>
							<Form.Control
								type='text'
								name='answer'
								// disabled={answersDisabled}
								value={answers[0]}
								onChange={(e) => onQuizInputChange(e, index, 0)}
							/>
						</InputGroup>
						<InputGroup className='mb-1'>
							<InputGroup.Text onClick={() => onAnswerClick(index, 1)} className='pointer' style={{backgroundColor: `${correctAnswers.includes(answers[1]) ? '#28a745' : '#e9ecef'}`}}>b</InputGroup.Text>
								<Form.Control
									type='text'
									name='answer'
									// disabled={answersDisabled}
									value={answers[1]}
									onChange={(e) => onQuizInputChange(e, index, 1)}
								/>
						</InputGroup>
						<InputGroup className='mb-1'>
							<InputGroup.Text onClick={() => onAnswerClick(index, 2)} className='pointer' style={{backgroundColor: `${correctAnswers.includes(answers[2]) ? '#28a745' : '#e9ecef'}`}}>c</InputGroup.Text>
								<Form.Control
									type='text'
									name='answer'
									// disabled={answersDisabled}
									value={answers[2]}
									onChange={(e) => onQuizInputChange(e, index, 2)}
								/>
						</InputGroup>
						<InputGroup className='mb-1'>
							<InputGroup.Text onClick={() => onAnswerClick(index, 3)} className='pointer' style={{backgroundColor: `${correctAnswers.includes(answers[3]) ? '#28a745' : '#e9ecef'}`}}>d</InputGroup.Text>
								<Form.Control
									type='text'
									name='answer'
									// disabled={answersDisabled}
									value={answers[3]}
									onChange={(e) => onQuizInputChange(e, index, 3)}
								/>
						</InputGroup>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	)
}

export default TrainingForm
