import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import DatePicker from 'react-datepicker'
import moment from 'moment'

function GigSettings({ gigSettings, onGigSettingsChange }) {
	const daysOfWeek = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']

	const handleGigSettingsChange = (name, value) => {
		onGigSettingsChange({
			...gigSettings,
			[name]: value
		})
	}

	const handleDaysOfWeekSelect = (day) => {
		let newState = []

		if(gigSettings.daysOfWeek.includes(day)) {
			newState = gigSettings.daysOfWeek.filter(d => d !== day)
		} else {
			newState = gigSettings.daysOfWeek.concat([day])
		}

		handleGigSettingsChange('daysOfWeek', newState)
	}

	const handleExcludedDateSelect = (date) => {
		const newState = [ ...gigSettings.excludedDates ]

		if(!gigSettings.excludedDates.includes(date)) {
			newState.push(date)

			handleGigSettingsChange('excludedDates', newState.sort())
		}
	}

	const removeExcludedDate = (date) => {
		const newState = gigSettings.excludedDates.filter(d => d !== date)

		handleGigSettingsChange('excludedDates', newState.sort())
	}

	return (
		<div className='pt-3'>
			<Row>
				<Col className='d-flex flex-column'>
					<b>Start Date/Time</b>
					<DatePicker
						selected={gigSettings.startDate}
						onChange={date => handleGigSettingsChange('startDate', date.valueOf())}
						shouldCloseOnSelect={false}
						timeInputLabel='Time:'
			      dateFormat='MM/dd/yyyy h:mm aa'
			      showTimeInput
						customInput={<Form.Control />}
						// onBlur={e => console.log(e)}
						// showTimeSelect
						// timeFormat='h:mma'
						// timeIntervals={15}
						// dateFormat='MMM d, yyyy h:mma'
						// timeCaption='time'
						// timeFormat='p'
						// dateFormat='Pp'
					/>
				</Col>
				<Col className='d-flex flex-column'>
					<b>Gig Duration</b>
					<Form.Control
						type='number'
						value={gigSettings.duration}
						onChange={e => handleGigSettingsChange('duration', e.target.value)}
					/>
				</Col>
				<Col className='d-flex flex-column'>
					<b>Hourly Rate</b>
					<Form.Control
						type='number'
						value={gigSettings.rate}
						onChange={e => handleGigSettingsChange('rate', e.target.value)}
					/>
				</Col>
				<Col className='d-flex flex-column'>
					<b>Enable Simultaneous Gigs</b>
					<Form.Check
						type='checkbox'
						// label='This is a master gig'
						checked={gigSettings.simultaneousGigs}
						onChange={e => handleGigSettingsChange('simultaneousGigs', e.target.checked)}
					/>
				</Col>
				{gigSettings.simultaneousGigs &&
					<Col className='d-flex flex-column'>
						<b>Max Gigs per Day</b>
						<Form.Control
							type='number'
							value={gigSettings.maxGigsPerDay}
							onChange={e => handleGigSettingsChange('maxGigsPerDay', e.target.value)}
						/>
					</Col>
				}
			</Row>

			<Row className='mt-5 align-items-center'>
				<Col>
					<b>Days of Week:</b>
					{daysOfWeek.map(day =>
						<span
							className={`mr-1 ml-1 rounded-circle border ${gigSettings.daysOfWeek.includes(day) ? 'border-danger' : 'border-dark'} p-2 pointer`}
							onClick={() => handleDaysOfWeekSelect(day)}
						>
							{day}
						</span>
					)}
				</Col>
				<Col className='d-flex flex-column'>
					<b>Date Exclusions</b>
					<DatePicker
						// selected={gigSettings.startDate}
						onChange={date => handleGigSettingsChange('startDate', date.valueOf())}
						onChange={date => handleExcludedDateSelect(date.valueOf())}
						shouldCloseOnSelect={false}
			      dateFormat='MM/dd/yyyy'
						customInput={<Form.Control />}
						// onBlur={e => console.log(e)}
					/>
				</Col>
				<Col className='d-flex flex-column' md={3}>
					<b>Excluded Dates</b>
					{gigSettings.excludedDates.map(date =>
						<div>
							{(moment(date).format('MM/DD/YYYY'))}
							<span className='ml-2 text-danger pointer' onClick={() => removeExcludedDate(date)}>[x]</span>
						</div>
					)}
				</Col>
			</Row>
		</div>
	)
}

export default GigSettings
