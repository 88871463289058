import React, { Component, Fragment } from 'react'
import moment from 'moment'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'

class TimeFilters extends Component {
	state = {
		hover: false,
		hoverButton: null,
		activeRange: 'present',
		activeSubRange: 'thisWeek'
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClick)
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClick)
	}

	render() {
		const { hover, activeRange } = this.state
		const { size } = this.props

		return (
			<div className='d-flex flex-column align-items-center position-relative w-100' ref={node => this.timeFilters = node}>
				<ButtonGroup size={size}>
					<Button className={activeRange === 'past' && 'active'} name='past' onMouseOver={this.handleMouseOver} variant='info'>Past</Button>
					<Button className={activeRange === 'present' && 'active'} name='present' onMouseOver={this.handleMouseOver} variant='info'>Present</Button>
					<Button className={activeRange === 'future' && 'active'} name='future' onMouseOver={this.handleMouseOver} variant='info'>Future</Button>
				</ButtonGroup>
				{hover && this.getSubFilters()}
			</div>
		)
	}

	handleMouseOver = (event) => {
		this.setState({
			hover: true,
			hoverButton: event.target.name
		})
	}

	handleClick = (e) => {
		if(this.timeFilters && this.timeFilters.contains(e.target)) {
			// click is inside
			return
		}

		this.setState({
			hover: false,
			hoverButton: null
		})
	}

	getSubFilters = () => {
		const { hover, hoverButton, activeSubRange } = this.state
		const { size } = this.props

		return (
			<ButtonGroup size={size} style={{position: 'absolute', top: '38px', zIndex: '100'}}>
				{hover && hoverButton === 'past' &&
					<Fragment>
						<Button className={activeSubRange === 'yesterday' && 'active'} name='past' onMouseOver={this.handleMouseOver} onClick={this.handleYesterday} variant='info'>Yesterday</Button>
						<Button className={activeSubRange === 'lastWeek' && 'active'} name='past' onMouseOver={this.handleMouseOver} onClick={this.handleLastWeek} variant='info'>Last Week</Button>
						<Button className={activeSubRange === 'lastMonth' && 'active'} name='past' onMouseOver={this.handleMouseOver} onClick={this.handleLastMonth} variant='info'>Last Month</Button>
					</Fragment>
				}
				{hover && hoverButton === 'present' &&
					<Fragment>
						<Button className={activeSubRange === 'today' && 'active'} name='present' onMouseOver={this.handleMouseOver} onClick={this.handleToday} variant='info'>Today</Button>
						<Button className={activeSubRange === 'thisWeek' && 'active'} name='present' onMouseOver={this.handleMouseOver} onClick={this.handleThisWeek} variant='info'>This Week</Button>
						<Button className={activeSubRange === 'thisMonth' && 'active'} name='present' onMouseOver={this.handleMouseOver} onClick={this.handleThisMonth} variant='info'>This Month</Button>
					</Fragment>
				}
				{hover && hoverButton === 'future' &&
					<Fragment>
						<Button className={activeSubRange === 'tomorrow' && 'active'} name='future' onMouseOver={this.handleMouseOver} onClick={this.handleTomorrow} variant='info'>Tomorrow</Button>
						<Button className={activeSubRange === 'nextWeek' && 'active'} name='future' onMouseOver={this.handleMouseOver} onClick={this.handleNextWeek} variant='info'>Next Week</Button>
						<Button className={activeSubRange === 'nextMonth' && 'active'} name='future' onMouseOver={this.handleMouseOver} onClick={this.handleNextMonth} variant='info'>Next Month</Button>
					</Fragment>
				}
			</ButtonGroup>
		)
	}

	handleDateChange = (start, end) => {
		const { onStartChange, onEndChange } = this.props

		// onStartChange(start).then(() => {
		// 	this.setState({
		// 		hover: false,
		// 		hoverButton: null
		// 	})

		// 	onEndChange(end)
		// })

		onStartChange(start)
		onEndChange(end)
	}

	handleYesterday = () => {
		const start = new Date(moment().startOf('day').subtract(1, 'days'))
		const end = new Date(moment().endOf('day').subtract(1, 'days'))

		this.setState({
			activeRange: 'past',
			activeSubRange: 'yesterday',
		})

		this.handleDateChange(start, end)
	}

	handleLastWeek = () => {
		const start = new Date(moment().startOf('week').subtract(1, 'weeks'))
		const end = new Date(moment().endOf('week').subtract(1, 'weeks'))

		this.setState({
			activeRange: 'past',
			activeSubRange: 'lastWeek',
		})

		this.handleDateChange(start, end)
	}

	handleLastMonth = () => {
		const start = new Date(moment().startOf('month').subtract(1, 'months'))
		const end = new Date(moment().endOf('month').subtract(1, 'months'))

		this.setState({
			activeRange: 'past',
			activeSubRange: 'lastMonth',
		})

		this.handleDateChange(start, end)
	}

	handleToday = () => {
		const start = new Date(moment().startOf('day'))
		const end = new Date(moment().endOf('day'))

		this.setState({
			activeRange: 'present',
			activeSubRange: 'today',
		})
		this.handleDateChange(start, end)
	}

	handleThisWeek = () => {
		const start = new Date(moment().startOf('week'))
		const end = new Date(moment().endOf('week'))

		this.setState({
			activeRange: 'present',
			activeSubRange: 'thisWeek',
		})
		this.handleDateChange(start, end)
	}

	handleThisMonth = () => {
		const start = new Date(moment().startOf('month'))
		const end = new Date(moment().endOf('month'))

		this.setState({
			activeRange: 'present',
			activeSubRange: 'thisMonth',
		})
		this.handleDateChange(start, end)
	}

	handleTomorrow = () => {
		const start = new Date(moment().startOf('day').add(1, 'days'))
		const end = new Date(moment().endOf('day').add(1, 'days'))

		this.setState({
			activeRange: 'future',
			activeSubRange: 'tomorrow',
		})

		this.handleDateChange(start, end)
	}

	handleNextWeek = () => {
		const start = new Date(moment().startOf('week').add(1, 'weeks'))
		const end = new Date(moment().endOf('week').add(1, 'weeks'))

		this.setState({
			activeRange: 'future',
			activeSubRange: 'nextWeek',
		})

		this.handleDateChange(start, end)
	}

	handleNextMonth = () => {
		const start = new Date(moment().startOf('month').add(1, 'months'))
		const end = new Date(moment().endOf('month').add(1, 'months'))

		this.setState({
			activeRange: 'future',
			activeSubRange: 'nextMonth',
		})

		this.handleDateChange(start, end)
	}
}

export default TimeFilters
