import axios from 'axios'
// import { API } from 'aws-amplify'
import _ from 'underscore'
import moment from 'moment'

import config from '../config'

export const HANDLE_ERRORS = 'HANDLE_ERRORS'
export const RECEIVE_APPLIED_GIGS = 'RECEIVE_APPLIED_GIGS'
export const RECEIVE_UNASSIGNED_GIGS = 'RECEIVE_UNASSIGNED_GIGS'
export const RECEIVE_UNFULFILLED_GIGS = 'RECEIVE_UNFULFILLED_GIGS'
export const RECEIVE_CANCELED_GIGS = 'RECEIVE_CANCELED_GIGS'
export const RECEIVE_UNCONFIRMED_GIGS = 'RECEIVE_UNCONFIRMED_GIGS'
export const RECEIVE_CONFIRMED_GIGS = 'RECEIVE_CONFIRMED_GIGS'
export const RECEIVE_ASSIGNED_GIGS = 'RECEIVE_ASSIGNED_GIGS'
export const RECEIVE_UNPAID_GIGS = 'RECEIVE_UNPAID_GIGS'
export const RECEIVE_COMPLETED_GIGS = 'RECEIVE_COMPLETED_GIGS'
export const RECEIVE_UPCOMING_GIGS = 'RECEIVE_UPCOMING_GIGS'
export const RECEIVE_IN_REVIEW_GIGS = 'RECEIVE_IN_REVIEW_GIGS'
export const RECEIVE_ALL_GIGS_FOR_BRAND = 'RECEIVE_ALL_GIGS_FOR_BRAND'
export const CHANGE_DASHBOARD_START_DATE = 'CHANGE_DASHBOARD_START_DATE'
export const CHANGE_DASHBOARD_END_DATE = 'CHANGE_DASHBOARD_END_DATE'
export const SET_DASHBOARD_SELECTED_GIG_STATUS = 'SET_DASHBOARD_SELECTED_GIG_STATUS'
export const CHANGE_MASTER_TOGGLE = 'CHANGE_MASTER_TOGGLE'
export const CHANGE_DASHBOARD_PAGEFROM = 'CHANGE_DASHBOARD_PAGEFROM'
export const CHANGE_DASHBOARD_PAGESIZE = 'CHANGE_DASHBOARD_PAGESIZE'
export const CHANGE_DASHBOARD_TABLE_FILTER = 'CHANGE_DASHBOARD_TABLE_FILTER'
export const CHANGE_DASHBOARD_TABLE_SORT = 'CHANGE_DASHBOARD_TABLE_SORT'
export const RECEIVE_DASHBOARD_ACTIVE_BRANDS = 'RECEIVE_DASHBOARD_ACTIVE_BRANDS'
export const SET_DASHBOARD_SELECTED_BRAND = 'SET_DASHBOARD_SELECTED_BRAND'
export const RECEIVE_ALL_BRAND_GIGS = 'RECEIVE_ALL_BRAND_GIGS'
export const SET_DASHBOARD_AMBASSADOR_FILTER = 'SET_DASHBOARD_AMBASSADOR_FILTER'
export const CHANGE_DASHBOARD_GIGS_VIEW = 'CHANGE_DASHBOARD_GIGS_VIEW'

function handleErrors(error) {
	return {
		type: HANDLE_ERRORS,
		error
	}
}

function getParams(state, unfulfilledGigs) {
	const {
		startDate,
		endDate,
		masterToggle,
		pageFrom,
		pageSize,
		brandNameSearchString,
		titleSearchString,
		campaignIdSearchString,
		addressSearchString,
		sortFieldName,
		sortDirection,
		selectedBrand,
		ambassadorFilter
	} = state

	const filters = {
		dueDateFromMillis: moment(startDate).valueOf(),
		dueDateToMillis: unfulfilledGigs ? Date.now() : moment(endDate).valueOf(),
		noMasters: masterToggle,
		noChildren: !masterToggle,
		pageFrom: pageFrom * pageSize,
		pageSize,

	}

	if(brandNameSearchString) filters.brandNameSearchString = brandNameSearchString
	if(titleSearchString) filters.titleSearchString = titleSearchString
	if(campaignIdSearchString) filters.campaignId = campaignIdSearchString
	if(addressSearchString) filters.addressSearchString = addressSearchString
	if(sortFieldName) filters.sortFieldName = sortFieldName
	if(sortDirection) filters.sortDirection = sortDirection
	if(selectedBrand) filters.brandNameSearchString = selectedBrand
	if(ambassadorFilter) filters.ambassadorId = ambassadorFilter

	return _.map(filters, (val, key) => `${key}=${val}`).join('&')
}

export function getAppliedGigs() {
	return (dispatch, getState) => {
		const state = getState().dashboard
		const params = getParams(state)

		return axios.get(`${config.coreAPI}/fieldDayAdmin/activities?applied=true&accepted=false&${params}`)
			.then(res => dispatch(receiveAppliedGigs(res.data.responses[0])))
			.catch(e => dispatch(handleErrors(e)))
	}
}

function receiveAppliedGigs(gigs) {
	return {
		type: RECEIVE_APPLIED_GIGS,
		gigs
	}
}

export function getUnassignedGigs() {
	return (dispatch, getState) => {
		const state = getState().dashboard
		const params = getParams(state)

		// if(state.selectedBrand) {
		// 	params.brandNameSearchString = state.selectedBrand
		// }
		return axios.get(`${config.coreAPI}/fieldDayAdmin/activities?accepted=false&${params}`)
			.then(res => dispatch(receiveUnassignedGigs(res.data.responses[0])))
			.catch(e => dispatch(handleErrors(e)))
	}
}

function receiveUnassignedGigs(gigs) {
	return {
		type: RECEIVE_UNASSIGNED_GIGS,
		gigs
	}
}

export function getUnfulfilledGigs() {
	return (dispatch, getState) => {
		const state = getState().dashboard
		const params = getParams(state, 'unfulfilledGigs')

		return axios.get(`${config.coreAPI}/fieldDayAdmin/activities?accepted=true&started=false&${params}`)
			.then(res => dispatch(receiveUnfulfilledGigs(res.data.responses[0])))
			.catch(e => dispatch(handleErrors(e)))
	}
}

function receiveUnfulfilledGigs(gigs) {
	return {
		type: RECEIVE_UNFULFILLED_GIGS,
		gigs
	}
}

export function getCanceledGigs() {
	return (dispatch, getState) => {
		const state = getState().dashboard
		const params = getParams(state)

		return axios.get(`${config.coreAPI}/fieldDayAdmin/activities/cancelled?ignoreAssigned=true&${params}`)
			.then(res => dispatch(receiveCanceledGigs(res.data.responses[0])))
			.catch(e => dispatch(handleErrors(e)))
	}
}

function receiveCanceledGigs(gigs) {
	return {
		type: RECEIVE_CANCELED_GIGS,
		gigs
	}
}

export function getUnconfirmedGigs() {
	return (dispatch, getState) => {
		const state = getState().dashboard
		const params = getParams(state)

		return axios.get(`${config.coreAPI}/fieldDayAdmin/activities?smsConfirmed=false&${params}`)
			.then(res => dispatch(receiveUnconfirmedGigs(res.data.responses[0])))
			.catch(e => dispatch(handleErrors(e)))
	}
}

function receiveUnconfirmedGigs(gigs) {
	return {
		type: RECEIVE_UNCONFIRMED_GIGS,
		gigs
	}
}

export function getConfirmedGigs() {
	return (dispatch, getState) => {
		const state = getState().dashboard
		const params = getParams(state)

		return axios.get(`${config.coreAPI}/fieldDayAdmin/activities?smsConfirmed=true&${params}`)
			.then(res => dispatch(receiveConfirmedGigs(res.data.responses[0])))
			.catch(e => dispatch(handleErrors(e)))
	}
}

function receiveConfirmedGigs(gigs) {
	return {
		type: RECEIVE_CONFIRMED_GIGS,
		gigs
	}
}

export function getAssignedGigs() {
	return (dispatch, getState) => {
		const state = getState().dashboard
		const params = getParams(state)

		// if(state.selectedBrand) {
		// 	params.brandNameSearchString = state.selectedBrand
		// }

		return axios.get(`${config.coreAPI}/fieldDayAdmin/activities?accepted=true&completed=false&${params}`)
			.then(res => dispatch(receiveAssignedGigs(res.data.responses[0])))
			.catch(e => dispatch(handleErrors(e)))
	}
}

function receiveAssignedGigs(gigs) {
	return {
		type: RECEIVE_ASSIGNED_GIGS,
		gigs
	}
}

export function getUnpaidGigs() {
	return (dispatch, getState) => {
		const state = getState().dashboard
		const params = getParams(state)

		return axios.get(`${config.coreAPI}/fieldDayAdmin/activities?finished=true&paid=false&${params}`)
			.then(res => dispatch(receiveUnpaidGigs(res.data.responses[0])))
			.catch(e => dispatch(handleErrors(e)))
	}
}

function receiveUnpaidGigs(gigs) {
	return {
		type: RECEIVE_UNPAID_GIGS,
		gigs
	}
}

export function getCompletedGigs() {
	return (dispatch, getState) => {
		const state = getState().dashboard
		const params = getParams(state)

		return axios.get(`${config.coreAPI}/fieldDayAdmin/activities?started=true&finished=true&${params}`)
			.then(res => dispatch(receiveCompletedGigs(res.data.responses[0])))
			.catch(e => dispatch(handleErrors(e)))
	}
}

function receiveCompletedGigs(gigs) {
	return {
		type: RECEIVE_COMPLETED_GIGS,
		gigs
	}
}

export function getInReviewGigs() {
	return (dispatch, getState) => {
		const state = getState().dashboard
		const params = getParams(state)

		return axios.get(`${config.coreAPI}/fieldDayAdmin/activities?hasReview=true&paid=false&completed=false&${params}`)
			.then(res => dispatch(receiveInReviewGigs(res.data.responses[0])))
			.catch(e => dispatch(handleErrors(e)))
	}
}

function receiveInReviewGigs(gigs) {
	return {
		type: RECEIVE_IN_REVIEW_GIGS,
		gigs
	}
}

export function getUpcomingGigs() {
	return (dispatch, getState) => {
		const state = getState().dashboard
		const params = getParams(state)

		// if(state.selectedBrand) {
		// 	params.brandNameSearchString = state.selectedBrand
		// }

		return axios.get(`${config.coreAPI}/fieldDayAdmin/activities?finished=false&completed=false&${params}`)
			.then(res => dispatch(receiveUpcomingGigs(res.data.responses[0])))
			.catch(e => dispatch(handleErrors(e)))
	}
}

function receiveUpcomingGigs(gigs) {
	return {
		type: RECEIVE_UPCOMING_GIGS,
		gigs
	}
}

export function getAllGigsForBrand() {
	return (dispatch, getState) => {
		const state = getState().dashboard

		if(!state.selectedBrand) return

		const params = getParams(state)

		return axios.get(`${config.coreAPI}/fieldDayAdmin/activities?${params}&noMasters=${state.masterToggle}&noChildren=${!state.masterToggle}`)
			.then(res => dispatch(receiveAllGigsForBrand(res.data.responses[0])))
			.catch(e => dispatch(handleErrors(e)))
	}
}

function receiveAllGigsForBrand(gigs) {
	return {
		type: RECEIVE_ALL_GIGS_FOR_BRAND,
		gigs
	}
}

export function handleStartDateChange(date) {
	return {
		type: CHANGE_DASHBOARD_START_DATE,
		date
	}
}

export function handleEndDateChange(date) {
	return {
		type: CHANGE_DASHBOARD_END_DATE,
		date
	}
}

export function handleGigStatusClick(status) {
	return {
		type: SET_DASHBOARD_SELECTED_GIG_STATUS,
		status
	}
}

export function handleMasterToggleChange(e, data) {
	return {
		type: CHANGE_MASTER_TOGGLE,
		masterToggle: data.checked
	}
}

export function handlePageChange(pageFrom) {
	return {
		type: CHANGE_DASHBOARD_PAGEFROM,
		pageFrom
	}
}

export function handlePageSizeChange(pageFrom, pageSize, total) {
	const numOfPages = total <= pageSize ? 1 : Math.ceil(total / pageSize)
	const newPageIndex = numOfPages <= pageFrom ? numOfPages - 1 : pageFrom

	return {
		type: CHANGE_DASHBOARD_PAGESIZE,
		pageFrom: newPageIndex,
		pageSize
	}
}

export function handleTableFilterChange(name, value) {
	return {
		type: CHANGE_DASHBOARD_TABLE_FILTER,
		name,
		value
	}
}

export function handleTableSortChange(sortFieldName, descSort) {
	return {
		type: CHANGE_DASHBOARD_TABLE_SORT,
		sortFieldName,
		descSort
	}
}

export function getActiveBrandsStats() {
	return (dispatch, getState) => {
		const state = getState().dashboard
		const params = `dueDateFromMillis=${moment(state.startDate).valueOf()}&dueDateToMillis=${moment(state.endDate).valueOf()}&noMasters=${state.masterToggle}&noChildren=${!state.masterToggle}`

		return axios.get(`${config.coreAPI}/fieldDayAdmin/activities/assignmentCounts?${params}`)
			.then(res => dispatch(receiveActiveBrandsStats(res.data)))
			.catch(e => dispatch(handleErrors(e)))
	}
}

function receiveActiveBrandsStats(activeBrands) {
	return {
		type: RECEIVE_DASHBOARD_ACTIVE_BRANDS,
		activeBrands
	}
}

export function handleSelectActiveBrand(brandName, status) {
	// return (dispatch, getState) => {
	// 	getSelectedBrandGigs(brandName)

		return {
			type: SET_DASHBOARD_SELECTED_BRAND,
			brandName,
			status
		}
	// }
}

export function handleAmbassadorFilter(ambassadorId) {
	return {
		type: SET_DASHBOARD_AMBASSADOR_FILTER,
		ambassadorFilter: ambassadorId
	}
}

export function handleGigsViewChange(view) {
	return {
		type: CHANGE_DASHBOARD_GIGS_VIEW,
		gigsView: view
	}
}

// export function getSelectedBrandGigs() {
// 	return (dispatch, getState) => {
// 		const state = getState().dashboard
// 		const filters = {
// 			dueDateFromMillis: moment(state.startDate).valueOf(),
// 			dueDateToMillis: moment(state.endDate).valueOf(),
// 			brandNameSearchString: state.selectedBrand,
// 			noMasters: true,
// 			pageFrom: 0,
// 			pageSize: state.pageSize
// 		}

// 		const params = _.map(filters, (val, key) => `${key}=${val}`).join('&')

// 		return axios.get(`${config.coreAPI}/fieldDayAdmin/activities?${params}`)
// 			.then(res => dispatch(receiveSelectedBrandGigs(res.data.responses[0])))
// 			.catch(e => dispatch(handleErrors(e)))
// 	}
// }

// function receiveSelectedBrandGigs(gigs) {
// 	return {
// 		type: RECEIVE_ALL_BRAND_GIGS,
// 		gigs
// 	}
// }
