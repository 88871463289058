import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { connect } from 'react-redux'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Alert from 'react-bootstrap/Alert'

import '../../styles/filters.css'
import config from '../../config'
import {
	_getAmbassadors,
	toggleFilters,
	handleStatusChange,
	updateFilterInput,
	updateTags,
	setView,
	resetFilters
} from '../../actions/ambassadors'

class AmbassadorFilters extends Component {
	state = {
		errors: [],
		dmaSearchResults: null,
		autoCompleteResults: null,
		showActions: false,
		tagsList: []
	}

	componentDidMount() {
		this.getTagsList()
		// document.querySelector('#searchStr').addEventListener('keydown', this.handleKeyDown)
		document.addEventListener('mousedown', this.handleClick)
	}

	componentWillUnmount() {
		// document.querySelector('#searchStr').removeEventListener('keydown', this.handleKeyDown)
		document.removeEventListener('mousedown', this.handleClick)
		// this.props.dispatch(resetFilters())
	}

	componentDidUpdate(prevProps) {
		const { filters, dispatch } = this.props

		if(prevProps.filters.approved !== filters.approved || prevProps.filters.rejected !== filters.rejected) {
			// const params = `approved=${filters.approved}&pageFrom=${0}&pageSize=${20}`

			// if(this.radiusInMiles) this.radiusInMiles.value = ''
			// if(this.zipCodeInput) this.zipCodeInput.value = ''

			this.handleSubmit()
			// dispatch(_getAmbassadors(params))
			// dispatch(resetFilters())
		}

		// if(prevProps.filters.rejected !== filters.rejected) {
		// 	this.handleSubmit()
		// }

		if(prevProps.filters.tags.length !== filters.tags.length) {
			this.handleSubmit()
		}

		if(prevProps.filters.searchString !== filters.searchString) {
			this.handleSubmit()
		}

		if(prevProps.filters.dmaNames !== filters.dmaNames) {
			this.handleSubmit()
		}
	}

	render() {
		const { errors, tagsList, autoCompleteResults, dmaSearchResults } = this.state
		const { dispatch, filters, showAdvancedFilters } = this.props

		return (
			<div>
				{errors.map((err, i) => <Alert key={i} variant={'danger'}>{err}</Alert>)}
				<form className='ambassadors-filters-form' onSubmit={this.handleSubmit}>
					<Row className='d-flex align-items-center text-white mb-3'>
						<Col lg={3} className='d-flex align-items-center'>
							<b className='filters-labels'>STATUS</b>&nbsp;&nbsp;
							<Dropdown>
								<Dropdown.Toggle size='sm' variant='light' id='dropdown-basic'>
									{this.getStatus()}
								</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item onClick={() => dispatch(handleStatusChange('approved'))}>Approved</Dropdown.Item>
									<Dropdown.Item onClick={() => dispatch(handleStatusChange('pending'))}>Pending</Dropdown.Item>
									<Dropdown.Item onClick={() => dispatch(handleStatusChange('rejected', true))}>Rejected</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</Col>

						<Col lg={6} className='d-flex align-items-center'>
							<span
								onClick={() => dispatch(toggleFilters(false))}
								className='filters-labels'
								style={{textDecoration: !showAdvancedFilters && 'underline', cursor: 'pointer', fontWeight: showAdvancedFilters ? '100' : '600'}}>SIMPLE
							</span>
							<span>&nbsp;|&nbsp;</span>
							<span
								onClick={() => dispatch(toggleFilters(true))}
								className='filters-labels'
								style={{textDecoration: showAdvancedFilters && 'underline', cursor: 'pointer', fontWeight: !showAdvancedFilters ? '100' : '600'}}>ADVANCED
							</span>
							&nbsp;
							<Col>
								<InputGroup>
									<Form.Control
										value={this.props.filters.searchString}
										onChange={this.handleSearchStrInput}
										name='searchString'
										type='text'
										id='searchStr'
										autoComplete="off"
									/>
									<InputGroup.Append>
										<InputGroup.Text className='bg-white'>
											<i className="fas fa-search pointer" onClick={this.handleSubmit}></i>&nbsp;
										</InputGroup.Text>
									</InputGroup.Append>
								</InputGroup>
								{autoCompleteResults &&
									<div className='search-result' ref={node => this.searchBox = node}>
										{autoCompleteResults.map((res, i) => (
											<Link key={i} to={`/ambassador/${res.id}`}>
												<div className='search-result-item'>
													{res.ambassador_name}
												</div>
											</Link>
										))}
									</div>
								}
							</Col>
							&nbsp;<i className="fas fa-redo-alt fa-lg pointer" onClick={() => this.props.dispatch(resetFilters())}></i>
						</Col>

						<Col lg={3} className='d-flex align-items-center justify-content-end'>
							<b className='filters-labels' style={{marginRight: '7px'}}>VIEW: </b>
							<i onClick={() => this.props.dispatch(setView('table'))} className='fas fa-bars fa-lg pointer' style={{marginRight: '7px', color: `${this.props.view === 'table' ? 'white' : '#95C6CB'}`}}></i>&nbsp;
							<i onClick={() => this.props.dispatch(setView('grid'))} className='fas fa-th-large fa-lg pointer' style={{marginRight: '7px', color: `${this.props.view === 'grid' ? 'white' : '#95C6CB'}`}}></i>&nbsp;
							<i onClick={() => this.props.dispatch(setView('map'))} className='fas fa-map-marker-alt fa-lg pointer' style={{marginRight: '7px', color: `${this.props.view === 'map' ? 'white' : '#95C6CB'}`}}></i>&nbsp;
						</Col>
					</Row>
					{showAdvancedFilters &&
						<div className='bg-white p-4'>
							<Row>
								<Col>{this.getCheckBoxInput('registrationVideoUploaded', 'Video')}</Col>
								<Col>{this.getCheckBoxInput('stripeSetup', 'Stripe Setup')}</Col>
								<Col>{this.getCheckBoxInput('zipCodes', 'Zip Code', 'zipCodeInput')}</Col>
								<Col>{this.getCheckBoxInput('radius', 'Radius (mi)', 'radiusInMiles')}</Col>
							</Row>
							<Row className='mt-3'>
								<Col lg={3}>
									<Form.Control
										placeholder='Search DMA names'
										onChange={this.handleDMASearch}
										ref={input => this.dmaSearchInput = input}
									/>
									{dmaSearchResults &&
										<div className='search-result' ref={node => this.dmaSearchBox = node}>
											{dmaSearchResults.map((res, i) => (
												<div key={res.dma_name} className='search-result-item' onClick={() => this.handleDMAClick(res.dma_name)}>
													{res.dma_name}
												</div>
											))}
										</div>
									}
								</Col>
								<Col lg={3} className='d-flex align-items-center'>
									<DropdownButton title='Tags' variant='outline-secondary' size='sm'>
										{tagsList.map((tag, i) => (
											<Dropdown.Item key={i} onClick={() => dispatch(updateTags(tag))}>{tag}</Dropdown.Item>
										))}
									</DropdownButton>
									&nbsp;&nbsp;
									{filters.tags.map((tag, i) => (
										<span key={i}>
											{tag}
											{i < filters.tags.length - 1 && <span>,&nbsp;</span>}
										</span>
									))}
								</Col>
								<Col>{this.getCheckBoxInput('waitlisted', 'Wait-listed')}</Col>
							</Row>
							{/*<Row>
								<Col>{this.getCheckBoxInput('rating', 'Rating', 'ratingNumber')}</Col>
								<Col>{this.getCheckBoxInput('age', 'Age', 'ageFrom', 'ageTo')}</Col>
							</Row>*/}
							<hr />
							<Row className="justify-content-lg-end">
								<Button variant='primary' type='submit' className='btn-blue'>Update Results</Button>
							</Row>
						</div>
					}
				</form>
			</div>
		)
	}

	getStatus = () => {
		const { filters } = this.props

		if(filters.approved && !filters.rejected) return 'Approved'
		if(!filters.approved && !filters.rejected) return 'Pending'
		if(filters.rejected) return 'Rejected'
	}

	// handleSuccess = () => {
	// 	window.scrollTo(0, 0)

	// 	this.setState({
	// 		done: true
	// 	})

	// 	setTimeout(() => this.setState({
	// 			done: false
	// 		}), 3000)
	// }

	handleErrors = (e) => {
		window.scrollTo(0, 0)

		this.setState({
			errors: this.state.errors.includes(e.response.data.message) ? [...this.state.errors] : this.state.errors.concat([`${e.response.data.message}`])
		})

		setTimeout(() => this.setState({
			errors: []
		}), 5000)
	}

	// handleKeyDown = (e) => {
	// 	if(e.which === 32) {
	// 		return false
	// 	}
	// }

	handleClick = (e) => {
		if(this.searchBox && this.searchBox.contains(e.target)) {
			// click is inside
			return
		}

		if(this.dmaSearchBox && this.dmaSearchBox.contains(e.target)) {
			// click is inside
			return
		}

		this.setState({
			autoCompleteResults: null,
			dmaSearchResults: null
		})
	}

	handleDMASearch = (event) => {
		const value = event.target.value
		const e = {}
		const { dispatch } = this.props

		e.target = {
			name: 'dmaNames',
			value: null
		}

		if(!value) {
			dispatch(updateFilterInput(e))
			return
		}

		axios.get(`${config.coreAPI}/dma/autocomplete?searchString=${value}&numResults=1000`)
			.then(res => this.setState({ dmaSearchResults: res.data.responses[0].results }))
			.catch(err => this.handleErrors(err))
	}

	handleDMAClick = (dma) => {
		const event = {}
		const { dispatch } = this.props

		event.target = {
			name: 'dmaNames',
			value: dma.replace(/&/g, '%26')
		}

		this.dmaSearchInput.value = dma

		this.setState({
			dmaSearchResults: null
		}, () => {
			dispatch(updateFilterInput(event))
		})
	}

	getTagsList = async () => {
		try {
			const tags = await axios.get(`${config.coreAPI}/fieldDayAdmin/ambassador/tags`)

			this.setState({
				tagsList: tags.data
			})
		} catch(e) {
			console.log(e)
			this.handleErrors(e)
		}
	}

	handleSearchStrInput = (e) => {
		this.props.dispatch(updateFilterInput(e))

		if(!e.target.value) return

		axios.get(`${config.coreAPI}/fieldDayAdmin/ambassadors`, {
			params: {
				searchString: e.target.value,
				approved: this.props.filters.approved,
				autoComplete: true,
				pageFrom: 0,
				pageSize: 20
			}
		})
		.then(res => this.setState({ autoCompleteResults: res.data.responses[0].results }))
		.catch(err => this.handleErrors(err))
	}

	handleSubmit = (e) => {
		e && e.preventDefault()

		this.setState({
			autoCompleteResults: null
		})

		const { filters, dispatch } = this.props
		const pageIndex = 0
		const pageSize = 20
		const paramFilters = []

		if(filters.rejected) {
			paramFilters.push(`rejected=${filters.rejected}`)
		}

		if(!filters.rejected) {
			paramFilters.push(`approved=${filters.approved}`)
		}

		if(filters.searchString) {
			paramFilters.push(`searchString=${filters.searchString}`)
		}

		if(filters.registrationVideoUploaded) {
			paramFilters.push('registrationVideoUploaded=true')
		}

		if(filters.stripeSetup) {
			paramFilters.push('stripeSetup=true')
		}

		if(filters.zipCodes) {
			paramFilters.push(`zipCodes=${filters.zipCodeInput}`)
		}

		if(filters.radius) {
			paramFilters.push(`radiusInMiles=${filters.radiusInMiles}`)
		}

		if(filters.tags && filters.tags.length) {
			paramFilters.push(`tags=${filters.tags.map(tag => tag).join('|')}`)
		}

		if(filters.waitlisted) {
			paramFilters.push('waitlisted=true')
		}

		if(filters.dmaNames) {
			paramFilters.push(`dmaNames=${filters.dmaNames}`)
		}

		const params = paramFilters.concat([`pageFrom=${pageIndex}`], [`pageSize=${pageSize}`]).join('&')

		dispatch(_getAmbassadors(params, pageIndex, pageSize))
	}

	getCheckBoxInput = (name, label, extraInput1, extraInput2) => {
		const { filters } = this.props

		return (
			<div style={{display: 'flex', justifyContent: 'space-between', fontWeight: '100'}}>
				<label className='checkmark-container'>
					<input
						name={name}
						type='checkbox'
						checked={filters[name] ? true : false}
						onChange={e => this.props.dispatch(updateFilterInput(e))}
					/>
					<span className="checkmark"></span>
					&nbsp;
					{label}
				</label>
				{extraInput1 &&
					<input
						name={extraInput1}
						onChange={e => this.props.dispatch(updateFilterInput(e))}
						style={{backgroundColor: '#F1F1F1', border: 'none', height: '25px', fontWeight: '100', fontSize: '14px'}}
						value={filters[extraInput1]}
						type='number'
						ref={input => this[extraInput1] = input}
						// disabled={this.props.filters[name] ? true : false}
					/>
				}
				{name === 'age' && ' to '}
				{extraInput2 &&
					<input
						name={extraInput2}
						onChange={e => this.props.dispatch(updateFilterInput(e))}
						style={{backgroundColor: '#F1F1F1', border: 'none', height: '25px', fontWeight: '100', fontSize: '14px'}}
						type='number'
						// disabled={this.props.filters[name] ? true : false}
					/>
				}
			</div>
		)
	}
}

function mapStateToProps({ ambassadors }) {
	return {
		filters: ambassadors.filters,
		pageIndex: ambassadors.pageIndex,
		pageSize: ambassadors.pageSize,
		queryParams: ambassadors.queryParams,
		view: ambassadors.view,
		showAdvancedFilters: ambassadors.showAdvancedFilters
	}
}

export default connect(mapStateToProps)(AmbassadorFilters)
