import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import {
	handleGigStatusClick
} from '../../actions/gigs'

class GigsSideBar extends Component {
	render() {
		const { dispatch } = this.props

		return (
			<Fragment>
				<div className='mb-5 pl-3'>
					<i className='fas fa-plus-circle text-light'></i>&nbsp;&nbsp;
					<span style={{ fontSize: '15px'}} className='font-weight-bold'>Active Brands</span>
				</div>
				<div className='pl-3'>
					<i className='fas fa-hand-holding-usd text-light pointer'></i>&nbsp;&nbsp;
					<span
						style={{ fontSize: '15px'}}
						className='font-weight-bold pointer'
						onClick={() => dispatch(handleGigStatusClick('unpaidGigs'))}
					>
						Unpaid Gigs
					</span>&nbsp;
					<span className='font-weight-bold'>({this.props.unpaidGigsTotal})</span>
				</div>
				<hr />
				<div className='pl-3'>
					<i className='fas fa-walking text-light'></i>&nbsp;&nbsp;
					<span
						style={{ fontSize: '15px'}}
						className='font-weight-bold pointer'
						onClick={() => dispatch(handleGigStatusClick('inProgressGigs'))}
					>
						In-Progress Gigs
					</span>&nbsp;
					<span className='font-weight-bold'>({this.props.inProgressGigsTotal})</span>
				</div>
				<hr />
				<div className='pl-3'>
					<i className='fas fa-comment-dots text-light'></i>&nbsp;&nbsp;
					<span
						style={{ fontSize: '15px'}}
						className='font-weight-bold pointer'
						onClick={() => dispatch(handleGigStatusClick('inReviewGigs'))}
					>
						In-Review Gigs
					</span>&nbsp;
					<span className='font-weight-bold'>({this.props.inReviewGigsTotal})</span>
				</div>
				<hr />
				<div className='pl-3'>
					<i className='fas fa-check-circle text-light'></i>&nbsp;&nbsp;
					<span
						style={{ fontSize: '15px'}}
						className='font-weight-bold pointer'
						onClick={() => dispatch(handleGigStatusClick('completedGigs'))}
					>
						Completed Gigs
					</span>&nbsp;
					<span className='font-weight-bold'>({this.props.completedGigsTotal})</span>
				</div>
				<hr />
				<div className='pl-3'>
					<i className='fas fa-check-circle text-light'></i>&nbsp;&nbsp;
					<span
						style={{ fontSize: '15px'}}
						className='font-weight-bold pointer'
						onClick={() => dispatch(handleGigStatusClick('completionApprovedGigs'))}
					>
						Completion Approved Gigs
					</span>&nbsp;
					<span className='font-weight-bold'>({this.props.completionApprovedGigsTotal})</span>
				</div>
			</Fragment>
		)
	}
}

function mapStateToProps({ gigs }) {
	return {}
}

export default connect(mapStateToProps)(GigsSideBar)
