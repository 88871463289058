import React, { Component } from 'react'
import { Redirect } from 'react-router'
import axios from 'axios'
import { API } from 'aws-amplify'
import _ from 'underscore'
import moment from 'moment'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
// import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
// import DropdownButton from 'react-bootstrap/DropdownButton'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Spinner from 'react-bootstrap/Spinner'
import html2canvas from 'html2canvas'

import config from '../../config'
import { getAmbassadorAssetsUrl } from '../../actions/shared'

class PayReport extends Component {
	state = {
		errors: [],
		editPay: false,
		working: false,
		done: false,
		paying: false,
		payErrors: [],
		adminOverride: false
	}

	render() {
		// const { gig, brand } = this.props
		const { gig } = this.props
		const { editPay, working, done, errors, paid, paying, payErrors, adminOverride, paymentProvider, paymentCategory, paymentDescription } = this.state

		const paymentProviders = [
			'DUMMY',
			'STRIPE',
			'PUZZL',
			'TRINET',
			'PAYPAL'
		]

		const paymentCategories = [
			'ACTIVITY',
			'PARKING',
			'BONUS',
			'INSUFFICIENT_MATERIALS',
			'SCHEDULING_ERROR',
			'INCORRECT_HOURLY_RATE',
			'OTHER'
		]
		// console.log('PayReport PROPS: ', this.props)
		// console.log('PayReport STATE: ', this.state)
		console.log('PayReport: ', payErrors)

		return (
			<div>
				<Row className='d-flex align-items-center'>
					<Col lg={3}>To be paid: </Col>
					{editPay
						? <div><Form.Control
								ref={input => this.payAmount = input}
								defaultValue={gig.field_day_operation_details.ambassador_remuneration_to_pay}
							/></div>
						: <div>${gig.field_day_operation_details.ambassador_remuneration_to_pay}</div>
					}
					&nbsp;<i className="fas fa-edit pointer" title='Edit' onClick={() => this.setState(({ editPay }) => ({ editPay: !editPay }))}></i>
					&nbsp;{editPay && <i className="far fa-save pointer" title='Save' onClick={this.handleEditPay}></i>}
					&nbsp;{working && <Spinner animation='border' variant='info' size='sm' />}
					&nbsp;{done && <i className="fas fa-check" style={{color: '#28a745'}}></i>}
					&nbsp;{errors.map((err, i) => <span key={i} style={{color: 'red'}}>{err}</span>)}
					<Col className='text-right'>
						<Dropdown>
							<Dropdown.Toggle variant='outline-dark'>{paymentProvider || 'Payment Provider'}</Dropdown.Toggle>
							<Dropdown.Menu style={{maxHeight: '350px', overflow: 'auto'}}>
								{paymentProviders.map(provider => (
									<Dropdown.Item
										key={provider}
										onClick={() => this.setState({ paymentProvider: provider })}
									>
										{provider}
									</Dropdown.Item>
								))}
							</Dropdown.Menu>
						</Dropdown>
					</Col>
					<Col className='text-right'>
						<Dropdown>
							<Dropdown.Toggle variant='outline-dark'>{paymentCategory || 'Payment Category'}</Dropdown.Toggle>
							<Dropdown.Menu style={{maxHeight: '350px', overflow: 'auto'}}>
								{paymentCategories.map(category => (
									<Dropdown.Item
										key={category}
										onClick={() => this.setState({ paymentCategory: category })}
									>
										{category}
									</Dropdown.Item>
								))}
							</Dropdown.Menu>
						</Dropdown>
					</Col>
					<Col>
						<Form.Control
							name='paymentDescription'
							as='textarea'
							placeholder='Payment description'
							onChange={(e) => this.setState({ paymentDescription: e.target.value })}
							value={paymentDescription}
						/>
					</Col>
				</Row>
				<Row>
					<Col lg={3}>Eligible for Bonus:</Col>
					<div>
						{gig.field_day_management.field_day_remuneration.bonus_eligible ? 'Yes' : 'No'}&nbsp;
						<Button onClick={this.handleBonusEligible} size='sm'>Change</Button>
					</div>
				</Row>
				<br />
				<Col className='d-flex align-items-center justify-content-between'>
					{payErrors.map((err, i) => <span key={i} style={{color: 'red'}}>{err}</span>)}&nbsp;
					{paying && <Spinner animation='border' variant='info' />}&nbsp;
					{paid && <Redirect to='/gigs' />}
					{gig.field_day_operation_details.finished_gig && !gig.field_day_management.field_day_workflow.approved_field_day_completion &&
						<Button variant='primary' onClick={this.handleGigCompeletion}>Approve Completion</Button>
					}
					{gig.field_day_operation_details.finished_gig
						&& gig.field_day_management.field_day_workflow.approved_field_day_completion
						&& !gig.field_day_management.field_day_workflow.paid_ambassador
						&& <div className='d-flex'>
								{/*{true &&
									<label className='checkmark-container'>
										<input
											name='adminOverride'
											type='checkbox'
											checked={adminOverride}
											onChange={e => this.setState(({ adminOverride }) => ({ adminOverride: !adminOverride }))}
										/>
										<span className="checkmark"></span>
										Admin Override
									</label>
								}*/}
								<Button variant='success' onClick={this.handlePayAmbassador} className='ml-3'>Pay Ambassador</Button>
							</div>
					}
					{gig.field_day_management.field_day_workflow.paid_ambassador &&
						<div className='text-success font-italic font-weight-bold'>Ambassador Paid</div>
					}
				</Col>
				<hr />
				<EmailReport {...this.props} />
			</div>
		)
	}

	handleGigCompeletion = async () => {
		const gigId = this.props.gig.id

		try {
			// const gig = await axios.post(`${config.coreAPI}/fieldDayAdmin/activity/${gigId}/complete`)
			await axios.post(`${config.coreAPI}/fieldDayAdmin/activity/${gigId}/complete`)

			this.props.onRefreshGigInfo()
		} catch(e) {
			console.error(e)
			e.response && e.response.data && alert(e.response.data.message)
		}
	}

	handleBonusEligible = async () => {
		const gigId = this.props.gig.id
		const eligible = !this.props.gig.field_day_management.field_day_remuneration.bonus_eligible

		try {
			await axios.post(`${config.coreAPI}/fieldDayAdmin/activity/${gigId}/bonusEligible?eligible=${eligible}`)

			this.props.onRefreshGigInfo()
		} catch(e) {
			console.error(e)
			e.response && e.response.data && alert(e.response.data.message)
		}
	}

	handlePayAmbassador = async () => {
		const { paymentProvider, paymentCategory, paymentDescription } = this.state
		const amountToPay = this.props.gig.field_day_operation_details.ambassador_remuneration_to_pay
		const ambassadorId = this.props.gig.field_day_operation_details.assigned_ambassador_id
		const gigId = this.props.gig.id
		// const adminOverride = this.state.adminOverride ? `?adminOverride=true` : ''

		if(!ambassadorId) {
			alert('No ambassador is assigned to this gig')
			return
		}

		if(!this.props.gig.field_day_operation_details.finished_gig_timestamp) {
			alert('Gig is not finished')
			return
		}

		if(!window.confirm(`You are paying $${(Number(amountToPay) * 100) / 100} to this ambassador. If this amount is incorrect cancel and correct the amount.`)) {
			return
		}

		this.setState({
			paying: true,
			payErrors: []
		})

		try {
			// const gig = await axios.post(`${config.coreAPI}/fieldDayAdmin/activity/${gigId}/pay${adminOverride}`)
			const gig = await axios.post(`${config.coreAPI}/payments`, {}, {
				params: {
					provider: paymentProvider,
					category: paymentCategory,
					description: paymentDescription,
					amountInCents: Number(amountToPay) * 100,
					activityId: gigId,
					ambassadorId
				}
			})

			const activityStatus = {
				'status': 'DONE',
				'completed_date': new Date(this.props.gig.field_day_operation_details.finished_gig_timestamp * 1000).toISOString()
			}

			await axios.post(`${config.coreAPI}/activity/${gigId}`, activityStatus)

			await axios.post(`${config.coreAPI}/activity/${gigId}/paid`, {}, {
				params: {
					paid: true,
					paidMillis: Date.now(),
					amountInCents: Number(amountToPay) * 100
				}
			})

			// this.setState({ paying: false }, () => this.props.onRefreshGigInfo())

			setTimeout(() => this.setState({
				paying: false,
				paid: true
			}), 1000)

			// this.setState({
			// 	paying: false,
			// 	paid: true
			// })

		} catch(e) {
			alert('Error')

			if(e.response && e.response.data && e.response.data.message) {
				this.setState(({ payErrors }) => ({
					payErrors: e.response.data.message,
					paying: false
				}))
			}
		}
	}

	handleEditPay = async () => {
		const gigId = this.props.gig.id
		const payAmount = this.payAmount.value

		this.setState({ working: true })

		try {
			await axios.patch(`${config.coreAPI}/fieldDayAdmin/activity/${gigId}?payment=${payAmount}`)

			this.setState({
				working: false,
				done: true,
				editPay: false,
				errors: []
			}, () => this.props.onRefreshGigInfo())

		} catch(e) {
			console.log('e: ', this.state.errors.filter(err => err !== e.response.data.message))
			this.setState(({ errors }) => ({
				working: false,
				errors: errors.includes(e.response.data.message) ? [...errors] : errors.concat([e.response.data.message])
			}))
		}
	}
}

class EmailReport extends Component {
	state = {
		errors: null,
		brandUsers: {},
		emailList: this.props.gig.created_by_user.email_address === this.props.gig.assigned_to_user.email_address
			? [{
					name: `${this.props.gig.created_by_user.first_name} ${this.props.gig.created_by_user.last_name}`,
					emailAddress: this.props.gig.created_by_user.email_address
				}]
			: [{
					name: `${this.props.gig.created_by_user.first_name} ${this.props.gig.created_by_user.last_name}`,
					emailAddress: this.props.gig.created_by_user.email_address
				}, {
					name: `${this.props.gig.assigned_to_user.first_name} ${this.props.gig.assigned_to_user.last_name}`,
					emailAddress: this.props.gig.assigned_to_user.email_address
				}]
	}

	componentDidMount() {
		this.getBrandUsers()
		this.getCustomEmailList()
	}

	render() {
		// const { gig } = this.props
		const { brandUsers, emailList, errors, working, done } = this.state

		console.log('EMAIL STATE: ', this.state)
		console.log('EMAIL props: ', this.props)
		return (
			<div>
				<Row className='d-flex align-items-center mb-3'>
					<Col lg={3}><b>Recipients: </b></Col>
					<Col lg={2}>
						<Dropdown>
							<Dropdown.Toggle variant='outline-dark'>Add more</Dropdown.Toggle>
							<Dropdown.Menu style={{maxHeight: '350px', overflow: 'auto'}}>
								{_.map(brandUsers, user => <Dropdown.Item key={user.user_id} onClick={() => this.handleSelectUser(user)}>{user.email_address}</Dropdown.Item>)}
							</Dropdown.Menu>
						</Dropdown>
					</Col>
					<Col>
						<InputGroup>
							<Form.Control
								placeholder='add email'
								ref={input => this.emailRecipient = input}
								autoComplete='on'
							/>
							<InputGroup.Append>
								<Button variant='dark' onClick={() => this.handleSelectUser({ email_address: this.emailRecipient.value })}>+</Button>
							</InputGroup.Append>
						</InputGroup>
					</Col>
				</Row>
				<Col>
					{emailList.map(user =>
						<div key={Math.random() * Math.random()}>
							{user.name} {`<${user.emailAddress}>`}
							[<span className='pointer text-danger' onClick={() => this.handleRemoveUser(user)}>x</span>]
						</div>
					)}
				</Col>
				<Col className='d-flex align-items-center justify-content-end'>
					<span className='text-danger'>{errors}</span>
					{working && <Spinner animation='border' variant='info' />}&nbsp;
					{done && <i className="fas fa-check" style={{color: '#28a745'}}></i>}&nbsp;
					<Button variant='dark' disabled={emailList.length === 0} onClick={this.handleSendReport}>Email Report</Button>
				</Col>
			</div>
		)
	}

	getBrandUsers = async () => {
		const brandId = this.props.brand.id

		try {
			const users = await axios.get(`${config.coreAPI}/users?brandId=${brandId}&pageFrom=0&pageSize=1000`)

			this.setState({
				brandUsers: users.data.users
			})
		} catch(e) {
			console.error(e)
		}
	}

	getCustomEmailList = async () => {
		const brandId = this.props.brand.id
		const regionName = this.props.gig.region_name

		try {
			const customEmailList = await axios.get(`${config.coreAPI}/lists/emailLists?brandId=${brandId}&regionName=${regionName}&pageFrom=0&pageSize=1000`)
			const newEmailList =  _.flatten(customEmailList.data.map(list => list.recipients))

			this.setState(({ emailList }) => ({
				customEmailList: customEmailList.data,
				emailList: emailList.concat(newEmailList.map(recipient => ({
					name: '',
					emailAddress: recipient
				})))
			}))

		} catch(e) {
			console.error(e)
		}
	}

	handleSelectUser = (user) => {
		this.setState({
			emailList: this.state.emailList.some(u => u.emailAddress === user.email_address) ? [...this.state.emailList] : this.state.emailList.concat([{
				name: `${user.first_name || ''} ${user.last_name || ''}`,
				emailAddress: user.email_address
			}])
		})
	}

	handleRemoveUser = (user) => {
		this.setState(({ emailList }) => ({
			emailList: emailList.filter(u => u.emailAddress !== user.emailAddress)
		}))
	}

	// handlePhoneReport = async () => {
	// 	if(sendGridUsername && sendGridApiKey && sendGridData) {
	// 		try {
	// 			await axios.post('https://ddnofdks8d.execute-api.us-west-2.amazonaws.com/dev/sendEmail', {
	// 				recipients: [{ email: recipient }],
	// 				sendGridUsername,
	// 				sendGridApiKey,
	// 				sendGridData
	// 			})

	// 			job.stop()
	// 			return

	// 		} catch(e) {
	// 			job.stop()
	// 			console.log(e)
	// 			// res.send({ message: e })

	// 			return
	// 		}

	// 		return
	// 	}
	// }

	handleSendReport = async () => {
		const { gig, ambassadorPhoto, meetingSpotPhoto, materialsPhoto, businessCardPhoto, assignedAmbassador, brand, businessesVisited, materialsHandedout } = this.props
		const storeGeoPoints = `${gig.loc_point[1]},${gig.loc_point[0]}`

		if(gig.execution_type === 'PHONE') {
			// this.handlePhoneReport()
			return
		}

		const businessCards = gig.field_day_operation_details.business_card_photos
		const numOfHotLeads = businessCards ? `${businessCards.filter(bizCard => bizCard.top_lead).length}` : 0
		const hotLeadsString = numOfHotLeads > 0
			? `HOT <a style="display: inline-block; width: 23px; height: 23px"><img src="https://s3-us-west-2.amazonaws.com/fd-website-assets/fire-icon.png" alt="" style="display:inline-block; max-width: 100%; max-height: 100%"/></a>
				<a style="font-weight: bold; text-decoration: none; font-size: 18px; color: #2f79b9">(${numOfHotLeads})</a>`
	  	: ''

		this.setState({ working: true })

		const reqBody = {
			gigId: this.props.gig.id,
			brandLogo: await getAmbassadorAssetsUrl(this.props.brand.brand_logo_url),
			ambassadorPhoto,
			meetingSpotPhoto,
			materialsPhoto,
			businessCardPhoto,
			storeLocation: `https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyBhtWWcwYZxMZy-JM7VifXSBQ98A_wE_XQ&zoom=14&size=250x250&markers=${storeGeoPoints}`,
			storeAddress: `${gig.address}, ${gig.city} ${gig.state}`,
			gigName: gig.title,
			dueDate: moment(gig.due_date_millis).format("MMMM Do, YYYY"),
			numOfLeads: businessCards ? businessCards.length : 0,
			hotLeadsString,
			businessesVisited,
			materialsHandedout,
			duration: gig.field_day_management.field_day_remuneration.max_hours || '',
			ambassadorName: assignedAmbassador.ambassador_name,
			// createdBy: gig.created_by_user.email_address,
			// assignedTo: gig.assigned_to_user.email_address,
			brandName: brand.brand_name,
			recipients: this.state.emailList,
			trackingMap: await this.getMapScreenshot()
		}

		console.log('REQ BODY: ', reqBody)

		try {
			// const result = await API.post('sendReport', '/sendReport', {
			// 	body: {
			// 		params: reqBody
			// 	}
			// })

			// TODO; find out why above aws-amplify does not work here
			const result = await axios.post(`${config.apiGateway.URL}/sendReport`, reqBody)

			this.setState({
				errors: null,
				working: false,
				done: true
			})

			return result
		} catch(e) {
			console.error(e.response)
			this.setState({
				errors: e.response.data,
				working: false
			})
		}
	}

	// getSignedUrl = async (key) => {
	// 	try {
	// 		const result = await API.post('getSignedUrl', '/getSignedUrl', {
	// 			body: { key }
	// 		})

	// 		return result
	// 	}
	// 	catch(e) {
	// 		console.error(e)
	// 	}
	// }

	getMapScreenshot = async () => {
		try {
			const canvas = await html2canvas(document.querySelector('.gm-style div:first-child'), {
				// width: 200,
				// height: 200,
				useCORS: true,
				allowTaint: false,
				imageTimeout: 70000
			})

			return canvas.toDataURL()
		} catch(e) {
			console.error(e)
		}
	}
}

export default PayReport
