import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Dropdown, Label, Segment, Icon } from 'semantic-ui-react'
import { map } from 'underscore'

import store_icon from '../../assets/img/store_icon.png'
import user_icon from '../../assets/img/noImage.png'
import Targets from './Targets'

export default function UsersStores({ gigState, onInputChange, users, stores, gettingTargetCount, onOpenSecondModal }) {
	// console.log('users: ', users)
	// console.log('stores: ', stores)

	return (
		<Row>
			<Col>
				<Users
					users={users}
					stores={stores}
					gigState={gigState}
					onInputChange={onInputChange}
					onOpenSecondModal={onOpenSecondModal}
				/>
			</Col>
			<Col>
				<Targets
					gigState={gigState}
					onInputChange={onInputChange}
					gettingTargetCount={gettingTargetCount}
				/>
			</Col>
		</Row>
	)
}

function Users({ users, stores, gigState, onInputChange, onOpenSecondModal }) {
	const usersDropdown = map(users, (user, i) => ({
		key: user.id,
		value: user.username,
		text: `${user.first_name} ${user.last_name}`,
	}))

	const storesDropdown = map(stores, (store, i) => ({
		key: store.store_id,
		value: store,
		text: `${store.store_id}- ${store.address}, ${store.city} ${store.state}`,
	}))

	return (
		<Segment raised>
			<Label size='large' color='orange' ribbon>
				Users & Stores
			</Label>
				<Row className='mb-2 mt-1'>
					<Col>
						<div>
							<Dropdown
								clearable
								search
								selection
								placeholder='Assign to User'
								className='ws-100'
								style={{ backgroundColor: '#0797a6' , fontWeight: '700', color: 'white' }}
								name='assignedUser'
								options={usersDropdown}
								value={gigState.assignedUser}
								onChange={(e, { name, value }) => onInputChange(name, value)}
							/>
						</div>
					</Col>
					<Col>
						<div>
							<Dropdown
								clearable
								search
								selection
								// multiple
								placeholder='Select Stores'
								className='ws-100'
								style={{ backgroundColor: '#0797a6' , fontWeight: '700', color: 'white' }}
								name='selectedStores'
								options={storesDropdown}
								// value={gigState.selectedStores || []}
								value={''}
								onChange={(e, { name, value }) => onInputChange(name, [...new Set(gigState.selectedStores.concat([value]))])}
							/>
						</div>
					</Col>
					<Col className='m-auto'>
						<Icon
							name='list'
							size='big'
							title='Add all stores'
							className='pointer'
							onClick={() => onInputChange('selectedStores', map(stores, store => store))}
						/>
					</Col>
				</Row>
				<Row>
					<Col className='overflow-auto' style={{ height: '153px' }}>
						<Row className='font-weight-bold border-bottom'>
							<Col md={1}>#</Col>
							<Col md={2}>Name</Col>
							<Col>Address</Col>
							<Col md={3}>Target Count</Col>
						</Row>
						{gigState.selectedStores.map((store, i) => (
							<Row
								key={store.store_id + Math.random()}
								className='border-bottom pointer'
							>
								<Col md={1} onClick={() => onOpenSecondModal(`Targets - ${store.store_name} (${store.targetsCount})`, 'targets', i)}>{store.store_id}</Col>
								<Col md={2} onClick={() => onOpenSecondModal(`Targets - ${store.store_name} (${store.targetsCount})`, 'targets', i)}>{store.store_name}</Col>
								<Col onClick={() => onOpenSecondModal(`Targets - ${store.store_name} (${store.targetsCount})`, 'targets', i)}>{store.address}, {store.city} {store.state}</Col>
								<Col md={3} className='d-flex justify-content-between'>
									({store.targetsCount})
									<span
										className='text-danger pointer'
										onClick={() => onInputChange('selectedStores', gigState.selectedStores.filter(s => s.store_id !== store.store_id))}
									>
										X
									</span>
								</Col>
							</Row>
						))}
					</Col>
				</Row>
		</Segment>
	)
}

// function Stores({ stores, gigState, onInputChange }) {
// 	const storesDropdown = map(stores, (store, i) => ({
// 		key: store.store_id,
// 		value: store.store_id,
// 		text: `${store.store_id}- ${store.address}, ${store.city} ${store.state}`,
// 	}))

// 	return (
// 		<Segment raised>
// 			<Label size='large' color='orange' ribbon>
// 				Stores
// 			</Label>
// 			<Row className='pd-card'>
// 				<Col>
// 					<div className='mb-2 mt-3'>
// 						<Dropdown
// 							clearable
// 							search
// 							selection
// 							placeholder='Select Stores'
// 							className='w-100'
// 							style={{ backgroundColor: '#0797a6' , fontWeight: '700', color: 'white' }}
// 							name='selectedStores'
// 							options={storesDropdown}
// 							value={gigState.selectedStores}
// 							onChange={(e, { name, value }) => onInputChange(name, value)}
// 						/>
// 					</div>
// 				</Col>
// 			</Row>
// 		</Segment>
// 	)
// }
