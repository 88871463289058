import React, { useState } from 'react'
import axios from 'axios'
import { Button, Checkbox } from 'semantic-ui-react'

import config from '../../config'

function CustomRoutingUpload({ brandId, gig }) {
	const [uploadingFile, setUploadingFile] = useState(false)
	const [fileUploaded, setFileUploaded] = useState(false)
	const [deletingRoute, setDeletingRoute] = useState(false)
	const [routeDeleted, setRouteDeleted] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [selectedAll, setSelectedAll] = useState(true)
	const [selectedGigs, setSelectedGigs] = useState(gig.activity_group && gig.activity_group.child_activities && gig.activity_group.child_activities.map(childGig => childGig.activity_id))

	return (
		<div>
			{gig.activity_group && gig.activity_group.group_master &&
				<div className='mb-4'>
					<b>Generate routes for these gigs only:</b>
					<Checkbox
						key='all'
						className='d-block mt-3'
						label='All'
						checked={selectedAll}
						onChange={(e, { name, checked }) => {
							setSelectedAll(checked)
							checked ? setSelectedGigs(gig.activity_group.child_activities.map(childGig => childGig.activity_id)) : setSelectedGigs([])
						}}
					/>
					{gig.activity_group.child_activities.map(childGig => (
						<Checkbox
							key={childGig.activity_id}
							className='d-block'
							label={`${childGig.title} (${childGig.activity_id})`}
							checked={selectedGigs.includes(childGig.activity_id)}
							disabled={selectedAll}
							onChange={ (e, { name, checked }) => checked
								? setSelectedGigs([...new Set(selectedGigs.concat([childGig.activity_id]))])
								: setSelectedGigs(selectedGigs.filter(id => id !== childGig.activity_id))
							}
						/>
					))}
				</div>
			}
			<div className='d-flex justify-content-around'>
				<div className='position-relative'>
				  <input type='file' className='custom-file-input' id='customFile' onChange={uploadFile} />
				  <Button primary loading={uploadingFile}>Upload CSV</Button>
				  {fileUploaded && <i className='fas fa-check text-success'></i>}
				</div>
				<div>
				  <Button negative onClick={deleteRouteHistory} loading={deletingRoute}>Delete Route History</Button>
				  {routeDeleted && <i className='fas fa-check text-success'></i>}
				</div>
			</div>
			<div className='text-danger'>
				{errorMessage}
			</div>
		</div>
	)

	async function uploadFile(e) {
		const file = e.target.files[0]

		if(!file) return

		const formData = new FormData()
		let gigIds

		if(!gig.activity_group || !gig.activity_group.group_master) {
			gigIds = gig.id
		} else if(gig.activity_group.group_master && selectedAll) {
			gigIds = gig.activity_group.group_child_ids.join('|')
		} else if(gig.activity_group.group_master && !selectedAll && selectedGigs.length) {
			gigIds = selectedGigs.join('|')
		} else if(gig.activity_group.group_master && !selectedAll && !selectedGigs.length) {
			setErrorMessage('You must select a gig')
			return
		} else {
			setErrorMessage('No activity ID provided')
			return
		}

		try {
			setErrorMessage('')
			setUploadingFile(true)

			const brandObject = await axios.get(`${config.coreAPI}/brand/${brandId}`)
			const routeSettings = brandObject.data.responses[0].results[0].activity_route_settings[0]

			if(!routeSettings) {
				alert('The brand does not have route settings set up')
				return
			}

			formData.append('custom_places_csv', file)
			formData.append('settings', JSON.stringify(routeSettings))

			await axios.post(`${config.coreAPI}/route/customJob/submit?activityIds=${gigIds}`, formData, {
				headers: {
					'content-type': 'multipart/form-data'
				}
			})

			setUploadingFile(false)
			setFileUploaded(true)

		} catch(e) {
			console.error(e)
			setUploadingFile(false)

			if(e && e.response && e.response.data) {
				setErrorMessage(e.response.data.message)
			} else {
				setErrorMessage('Error submitting the list')
			}
		}
	}

	async function deleteRouteHistory() {
		let isMaster

		if(!gig.activity_group || !gig.activity_group.group_master) {
			isMaster = false
		} else if(gig.activity_group.group_master) {
			isMaster = true
		} else {
			setErrorMessage('No master status provided')
			return
		}

		try {
			setErrorMessage('')
			setDeletingRoute(true)

			await axios.delete(`${config.coreAPI}/route/activity/${gig.id}?deleteHistory=true&deleteChildRoutes=${isMaster}`)

			setDeletingRoute(false)
			setRouteDeleted(true)

		} catch(e) {
			console.error(e)
			setDeletingRoute(false)

			if(e && e.response && e.response.data) {
				setErrorMessage(e.response.data.message)
			} else {
				setErrorMessage('Error deleting the history')
			}
		}
	}
}

export default CustomRoutingUpload
