import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { API } from 'aws-amplify'
import axios from 'axios'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import { reduce, map, each } from 'underscore'
import moment from 'moment'

import '../styles/brands.css'
import config from '../config'
import SideBar from '../components/SideBar'
import BrandsSideBar from '../components/brands/BrandsSideBar'
import BrandsOverview from '../components/brands/BrandsOverview'
import BrandOnboard from '../components/brands/BrandOnboard'
import BrandProfile from '../components/brands/brandProfile/BrandProfile'
import QuestionGroupsList from '../components/brands/brandSettings/businessCardQuestions/QuestionGroupsList'
import CampaignOverview from '../components/brands/campaignOverview/CampaignOverview'
import PerformanceOverview from '../components/brands/PerformanceOverview'
import EmailList from '../components/brands/EmailList'
import DateFilter from '../components/shared/DateFilter'
import excelExporter from '../utils/excelExporter'

import {
	getBrands,
	resetState,
	getBrandsExpenses,
	getBrandsActivityStats
} from '../actions/brands'

class Brands extends Component {
	state = {}

	componentDidMount() {
		this.props.dispatch(getBrands())
		// this.doIt()
		// this.sendEmail()
		// this.props.dispatch(getBrandsExpenses())
		// this.props.dispatch(getBrandsActivityStats())
	}

	componentWillUnmount() {
		this.props.dispatch(resetState())
	}

	render() {
		const { view, selectedBrand, selectedBrandLogo } = this.props
console.log(this.props)
console.log(this.state)
		return (
			<Row>
				<SideBar>
					<BrandsSideBar />
				</SideBar>
				<Col className='p-4 bg-light'>
					{window.location.href.includes('localhost') &&
						<Fragment>
							<Button onClick={this.doIt.bind(this)}>DO NOT CLICK</Button>
							<h4>Select 1st to 1st if you want to select a month. End date is NOTTTTT inclusive</h4>
							<DateFilter
								startDate={this.state.startDate}
								endDate={this.state.endDate}
								onStartDateChange={d => this.setState({ startDate: d })}
								onEndDateChange={d => this.setState({ endDate: d })}
							/>
						</Fragment>
					}
					{/*<div className='brand-logo-container' style={{ backgroundImage: `url(${selectedBrandLogo})` }}></div>*/}
					{view === 'brandsOverview' && <BrandsOverview />}
					{view === 'brandOnboard' && <BrandOnboard />}
					{view === 'brandProfile' && <BrandProfile />}
					{view === 'businessCardQuestions' && <QuestionGroupsList brandId={this.props.selectedBrand.brand_id} />}
					{view === 'campaignOverview' && <CampaignOverview />}
					{view === 'performanceOverview' && <PerformanceOverview brandId={this.props.selectedBrand.brand_id} />}
					{view === 'emailList' && <EmailList />}
				</Col>
			</Row>
		)
	}

	async doIt() {
		const { startDate, endDate } = this.state

		try {
			// const gigs = await axios.get(`https://core.api.fieldday.app/coreAPI/fieldDayAdmin/activities?pageFrom=0&pageSize=1000&brandId=279`)
			// const gigs = await axios.get(`https://core.api.fieldday.app/coreAPI/fieldDayAdmin/activities?pageFrom=0&pageSize=100000&brandId=270`)
			const gigs = await axios.get(`https://core.api.fieldday.app/coreAPI/fieldDayAdmin/activities?pageFrom=0&pageSize=10000000&dueDateFromMillis=${startDate.valueOf()}&dueDateToMillis=${endDate.valueOf()}&finished=true&completed=true`)
			// const gigs = await axios.get(`https://core.api.fieldday.app/coreAPI/fieldDayAdmin/activities/appliedBy/AWpqwVbdA1DgR2TxCWyx?pageFrom=0&pageSize=1000000&finished=true&completed=true`)

			// const allChildGigs = gigs.data.responses[0].results.filter(gig => !gig.brand_user_activity.activity_group || !gig.brand_user_activity.activity_group.group_master)
			const allChildGigs = gigs.data.responses[0].results.filter(gig => (!gig.brand_user_activity.activity_group || !gig.brand_user_activity.activity_group.group_master) && gig.brand_user_activity.execution_type === 'PHONE')

			const gigz = reduce(allChildGigs, (accum, gig) => {
				accum[gig.brand_user_activity.id] = gig.brand_user_activity
				return accum
			}, {})

			// const totalPhoneHoursAssigned = allChildGigs.reduce((accum, el) => {
			// 	if(el.brand_user_activity.execution_type === 'PHONE') {
			// 		accum += Number(el.brand_user_activity.field_day_management.field_day_remuneration.max_hours)
			// 	}

			// 	return accum
			// }, 0)

			const gigIds = allChildGigs.map(gig => gig.brand_user_activity.id)
			// const gigIds = allChildGigs.map(gig => gig.brand_user_activity.id).filter(id => [
			// 	// 'AXy5MnoyKWd5lIwdi0wH',
			// 	// 'AXy5RJ7DKWd5lIwdi4CZ',
			// 	// 'AXy5MnpFKWd5lIwdi0wI',
			// 	// 'AXy5RJ7LKWd5lIwdi4Ca',
			// 	// 'AXy5MnoQKWd5lIwdi0wF',
			// 	// 'AXy5RJ7aKWd5lIwdi4Cc',
			// 	// 'AXy5RJ7gKWd5lIwdi4Cd',
			// 	// 'AXy5MnodKWd5lIwdi0wG',
			// 	// 'AXy5RJ7mKWd5lIwdi4Ce',
			// 	// 'AXy5RJ6yKWd5lIwdi4CX',
			// 	// 'AXy5MnpNKWd5lIwdi0wJ',
			// 	// 'AXy5MnpUKWd5lIwdi0wK',
			// 	// 'AXy5RJ7sKWd5lIwdi4Cf',
			// 	// 'AXy5RJ7yKWd5lIwdi4Cg',
			// 	// 'AXy5MnpgKWd5lIwdi0wL',
			// 	// 'AXy5RJ67KWd5lIwdi4CY',
			// 	// 'AXy5RJ74KWd5lIwdi4Ch',
			// 	// 'AXy5Mnp0KWd5lIwdi0wM',
			// 	// 'AXy5RJ7-KWd5lIwdi4Ci',
			// 	// 'AXy5MnqLKWd5lIwdi0wN',
			// 	// 'AXy5RJ8EKWd5lIwdi4Cj',
			// 	// 'AXy5MnqeKWd5lIwdi0wO',
			// 	// 'AXy5RJ7SKWd5lIwdi4Cb',
			// 	// 'AXy5RJ8RKWd5lIwdi4Cl',
			// 	// 'AXy5RJ8XKWd5lIwdi4Cm',
			// 	// 'AXy5RJ8dKWd5lIwdi4Cn',
			// 	// 'AXy5RJ8LKWd5lIwdi4Ck',
			// 	// 'AXy5MnmzKWd5lIwdi0v-',
			// 	// 'AXy5RJ9GKWd5lIwdi4Co',
			// 	// 'AXy5RJ9NKWd5lIwdi4Cp',
			// 	// 'AXy5RJ9TKWd5lIwdi4Cq',
			// 	// 'AX0lit1JKWd5lIwdn8sx',
			// 	// 'AXy5RJ9aKWd5lIwdi4Cr',
			// 	// 'AXy5RJ9mKWd5lIwdi4Ct',
			// 	// 'AXy5RJ9sKWd5lIwdi4Cu',
			// 	// 'AXy5RJ9yKWd5lIwdi4Cv',
			// 	// 'AXy5RJ9gKWd5lIwdi4Cs',
			// 	].includes(id))

			console.log('allChildGigs: ', allChildGigs)
			console.log('gigIds: ', gigIds)

///// INFO GROUP CALCULATION
			const finalResults = {}
			// activity_route_id
			// activity_route > activity_destinations

			// const allGigs = gigs.data.responses[0].results.filter(g => g.brand_user_activity && g.brand_user_activity.activity_group && !g.brand_user_activity.activity_group.group_master && g.brand_user_activity.execution_type === 'STREET')
			const allGigs = gigs.data.responses[0].results.map(g => g.brand_user_activity)
			console.log(allGigs)

			allGigs.forEach(async (gig) => {

				if(gig.field_day_operation_details.phone_targets) {
					// const calledTargets = gig.field_day_operation_details.phone_targets.filter(target => target.was_called && target.phone_exception_reason !== 'INVALID_NUMBER')
					const calledTargets = gig.field_day_operation_details.phone_targets.filter(target => target.was_called)

					if(finalResults[gig.brand_name]) {
						// finalResults[gig.brand_name] += gig.field_day_operation_details.phone_targets.length
						finalResults[gig.brand_name] += calledTargets.length
					} else {
						// finalResults[gig.brand_name] = gig.field_day_operation_details.phone_targets.length
						finalResults[gig.brand_name] = calledTargets.length
					}
				}


				if(gig.activity_route) {
					if(finalResults[gig.brand_name]) {
						finalResults[gig.brand_name] += gig.activity_route.activity_destinations.length
					} else {
						finalResults[gig.brand_name] = gig.activity_route.activity_destinations.length
					}
				}

				if(gig.activity_route_id) {
					const activityRoute = await axios.get(`https://core.api.fieldday.app/coreAPI/route/${gig.activity_route_id}`)

					if(finalResults[gig.brand_name]) {
						finalResults[gig.brand_name] += activityRoute.data.activity_destinations.length
					} else {
						finalResults[gig.brand_name] = activityRoute.data.activity_destinations.length
					}

					console.log("RESULTS: ", finalResults)
					// console.log("RESULTS: ", JSON.stringify(finalResults))
				}


				// gig.activity_route && console.log(gig.brand_name, gig.activity_route)
				// gig.activity_route_id && console.log(gig.activity_route_id)
			})

			setTimeout(() => this.exportAsExcel(finalResults, startDate, endDate), 7000)
/////
			// const contacts = []

			// gigIds.forEach(async (id) => {
			// 	const res = await axios.get(`https://core.api.fieldday.app/coreAPI/contact?activityId=${id}&pageFrom=0&pageSize=10000`)
			// 	contacts.push(res.data.responses[0].results)
			// 	// 1606867200
			// })
/////
				// const emails = ["kasra@fieldday.app"]

				// emails.forEach(email => {
				// 	const sendGridUsername = "LucillesSmokehouse"
				// 	const sendGridApiKey = "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"
				// 	const sendGridData = {
    //         "template_id": "d-7828cc5e7b50419ebd8026bb4b9df5b4",
    //         "template_data": null,
    //         "sender_email_address": "rirwin@hofmangroup.com",
    //         "email_subject": "Lucille’s Smokehouse Bar-B-Que: Let’s Feast!"
    //       }

    //       sendGridData.categories = ['qualified leads']

    //       this.sendEmail(email, null, null, null, 256, null, null, sendGridUsername, sendGridApiKey, sendGridData)
				// })

			// let emailCounter = 0
			// // const recipients = []

			// gigIds.forEach(async (id) => {
			// 	const res = await axios.get(`https://core.api.fieldday.app/coreAPI/contact?activityId=${id}&pageFrom=0&pageSize=100000`)
			// 	const contacts = res.data.responses[0].results

			// 	console.log(contacts)

			// 	contacts.forEach(contact => {
			// 		if(contact.contact_email && this.validateEmail(contact.contact_email.trim())) {
			// 			const email = contact.contact_email.trim()
			// 			const attachments = gigz[contact.related_activity_id].attachments
			// 			const city = gigz[contact.related_activity_id].city
			// 			const campaign = gigz[contact.related_activity_id].campaign
			// 			const brandId = gigz[contact.related_activity_id].brand_id
			// 			const title = gigz[contact.related_activity_id].title
			// 			const storeNameIds = gigz[contact.related_activity_id].stores.map(store => `${store.store_name} (${store.store_id})`)

			// 			const sendGridUsername = "CondadoTacos"
			// 			const sendGridApiKey = "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"
			// 			const sendGridData = {
   //            "template_id": "d-27352e8ba608495db6b38461cc512141",
   //            "template_data": null,
   //            "sender_email_address": "catering@condadotacos.com",
   //            "email_subject": "Condado Tacos - Here is the Information We Promised! Thank You!"
   //          }

			// 			storeNameIds.unshift(`Campaign ID: ${campaign && campaign.campaign_id}`)

			// 			if(sendGridData && sendGridData.template_data) {
			// 				sendGridData.template_data = JSON.parse(sendGridData.template_data)
			// 			}

			// 			sendGridData.categories = storeNameIds

			// 			// console.log(contact.contact_email, contact.brand_id, contact.updated_date)
			// 			// console.log(contact)
			// 			// console.log(gigz[contact.related_activity_id])

			// 			// console.log(contact)
			// 			// this.sendEmail(email, attachments)
			// 			// this.sendEmail(email)
			// 			// recipients.push(email)

			// 			// if(contact.updated_date > 1626159600000 && contact.updated_date < 1627109940000) {
			// 			// if(campaign && campaign.campaign_id === 15108) {
			// 			if(true) {
			// 				emailCounter += 1
			// 				console.log('totalEmailsCollected: ', emailCounter, city, contact, campaign, storeNameIds, gigz[contact.related_activity_id])
			// 				// this.sendEmail(email, attachments, city, campaign, brandId, storeNameIds, title, sendGridUsername, sendGridApiKey, sendGridData, emailCounter)
			// 			}
			// 		}
			// 	})
			// 	// console.log(gigz[contacts.data.responses[0].results.related_activity_id])
			// })
/////
			// console.log(emailCounter)

			// const contactsPerGigProms = gigIds.map(id => axios.get(`https://core.api.fieldday.app/coreAPI/contact?activityId=${id}&pageFrom=0&pageSize=10000`))

			// Promise.all(contactsPerGigProms)
			// 	.then(contactsPerGigValues => {
			// 		let totalContactsWithEmail = []

			// 		contactsPerGigValues.map(res => {
			// 			const contactsPerGig = res.data.responses[0].results

			// 			// console.log('CONTACTS PER GIG: ', contactsPerGig)

			// 			const contactsPerGigWithEmail = contactsPerGig.filter(r => r.contact_email)

			// 			// console.log('contactsPerGigWithEmail: ', contactsPerGigWithEmail)

			// 			totalContactsWithEmail = totalContactsWithEmail.concat(contactsPerGigWithEmail)
			// 		})
					
			// 		console.log('totalContactsWithEmail: ', totalContactsWithEmail)

			// 		const validatedEmails = totalContactsWithEmail.filter(contact => this.validateEmail(contact.contact_email))

			// 		console.log('validatedEmails: ', validatedEmails)

			// 		each(validatedEmails, obj => {
			// 			if()
			// 			gigz[obj.related_activity_id].contacts = obj
			// 		})

			// 		console.log('+++FINAL: ', gigz)

			// 		// totalContactsWithEmail.forEach(contact => {
			// 		// 	console.log(contact.contact_email)
			// 		// 	console.log(this.validateEmail(contact.contact_email))
			// 		// })
			// 	})
////
			// const leadActions = []
			// let totalCalls = 0
			// let totalCallsDuration = 0
			// let totalCallsOver10s = 0
			// let totalCallsDurationOver10s = 0
			// let totalCallsOver30s = 0
			// let totalCallsDurationOver30s = 0

			// gigIds.forEach(async (id) => {
			// 	// const res = await axios.get(`https://core.api.fieldday.app/coreAPI/lead-actions?brand_id=187&activity_id=${id}&page_from=0&page_size=10000`)
			// 	const res = await axios.get(`https://core.api.fieldday.app/coreAPI/lead-actions?activity_id=${id}&page_from=0&page_size=10000`)
			// 	// leadActions.push(res.data.responses[0].results)
			// 	// console.log('leadActions: ', res.data.responses[0].results)

			// 	res.data.responses[0].results.forEach(action => {
			// 		if(action.dial_call_status === 'completed' && action.duration >= 0) {
			// 			totalCalls = totalCalls + 1
			// 			totalCallsDuration += action.duration
			// 		}

			// 		if(action.dial_call_status === 'completed' && action.duration >= 10) {
			// 			totalCallsOver10s = totalCallsOver10s + 1
			// 			totalCallsDurationOver10s += action.duration
			// 		}

			// 		if(action.dial_call_status === 'completed' && action.duration >= 30) {
			// 			totalCallsOver30s = totalCallsOver30s + 1
			// 			totalCallsDurationOver30s += action.duration
			// 		}
			// 	})

			// 	console.log('----START----')
			// 	console.log('totalCalls: ', totalCalls)
			// 	console.log('totalCallsDuration: ', totalCallsDuration, 'seconds')
			// 	console.log('averageTotalCallsDuration: ', Math.round(totalCallsDuration / totalCalls), 'seconds')

			// 	console.log('totalCallsOver10s: ', totalCallsOver10s)
			// 	console.log('totalCallsDurationOver10s: ', totalCallsDurationOver10s, 'seconds')
			// 	console.log('averageTotalCallsDurationOver10s: ', Math.round(totalCallsDurationOver10s / totalCallsOver10s), 'seconds')

			// 	console.log('totalCallsOver30s: ', totalCallsOver30s)
			// 	console.log('totalCallsDurationOver30s: ', totalCallsDurationOver30s, 'seconds')
			// 	console.log('averageTotalCallsDurationOver30s: ', Math.round(totalCallsDurationOver30s / totalCallsOver30s), 'seconds')
			// 	console.log('----END----')
			// })
			// // const leadActions = await axios.get(`https://core.api.fieldday.app/coreAPI/web/leadActions?contactId=AXYbpYQ4KWd5lIwd0jGM`)

			// // console.log('leadActions: ', leadActions)

		} catch(e) {
			console.error(e)
		}
	}

	exportAsExcel = async (d, startDate, endDate) => {
		console.log('GIGS EXPORT: ', d)
		const data = { ...d }

		delete data['Field Day']
		delete data['Field Day Test']
		delete data['Schulze']

		const headerRow = [
			'Brand',
			'Records'
		].map(cell => ({ value: cell, type: 'string'}))

		const contentRows = map(data, (v, k) => {
			return [
				{
					value: k,
					type: 'string'
				},
				{
					value: v,
					type: 'number'
				},
			]
		})

		contentRows.unshift(headerRow)

		const config = {
		  filename: `${moment(startDate.valueOf()).format('MM/DD/YYYY')} - ${moment(endDate.valueOf()).format('MM/DD/YYYY')}`,
		  sheet: {
		    data: contentRows
		  }
		}

		excelExporter(config)
	}

	validateEmail(email) {
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    return regex.test(email)
	}

	// DO NOT USE THIS UNLESS YOU KNOW WHAT YOU'RE DOING
	async sendEmail(email, attachments, city, campaign, brandId, storeNameIds, gigTitle, sendGridUsername, sendGridApiKey, sendGridData, emailCounter) {
		const campaignId = campaign && campaign.campaign_id
		let subject = ''
		let body = ''

		return

// Condados Tacos
		// if(brandId === 279) {
		// 	// if(campaign && campaign.campaign_id === 15324 && sendGridUsername && sendGridApiKey && sendGridData) {
		// 	if(true) {

		// 		console.log(emailCounter, email, campaign, sendGridUsername, sendGridApiKey, sendGridData)

		// 		try {
		// 			// if(email === 'Mbarnia@westernenterprise.com') {
		// 			// 	await axios.post('https://ddnofdks8d.execute-api.us-west-2.amazonaws.com/dev/sendEmail', {
		// 			// 		recipients: [{ email: 'kasra@fieldday.app' }],
		// 			// 		sendGridUsername,
		// 			// 		sendGridApiKey,
		// 			// 		sendGridData
		// 			// 	})
		// 			// }

		// 			await axios.post('https://ddnofdks8d.execute-api.us-west-2.amazonaws.com/dev/sendEmail', {
		// 				recipients: [{ email: email }],
		// 				sendGridUsername,
		// 				sendGridApiKey,
		// 				sendGridData
		// 			})

		// 		} catch(e) {
		// 			console.log(e)
		// 		}

		// 	} else {
		// 		console.log("CONDADOS ELSE")
		// 		return ''
		// 	}
		// }

// Luccille's smokehouse bbq
		// if(brandId === 256) {
		// 	// if(campaign && campaign.campaign_id === 15324 && sendGridUsername && sendGridApiKey && sendGridData) {
		// 	if(true) {

		// 		console.log(campaign, email, sendGridUsername, sendGridApiKey, sendGridData)

		// 		try {
		// 			// if(email === 'gxi4723@lausc.net') {
		// 			// 	await axios.post('https://ddnofdks8d.execute-api.us-west-2.amazonaws.com/dev/sendEmail', {
		// 			// 		recipients: [{ email: 'kasra@fieldday.app' }],
		// 			// 		sendGridUsername,
		// 			// 		sendGridApiKey,
		// 			// 		sendGridData
		// 			// 	})
		// 			// }

		// 			await axios.post('https://ddnofdks8d.execute-api.us-west-2.amazonaws.com/dev/sendEmail', {
		// 				recipients: [{ email: email }],
		// 				sendGridUsername,
		// 				sendGridApiKey,
		// 				sendGridData
		// 			})

		// 		} catch(e) {
		// 			console.log(e)
		// 		}

		// 	} else {
		// 		console.log("LUCCILLE ELSE")
		// 		return ''
		// 	}
		// }
	}

	getImgAttachments(imgAttachments) {
		if(!imgAttachments) return ''

		let img1 = ''
		let img2 = ''
		let img3 = ''

		imgAttachments.forEach(img => {
			if(img.name === 'Panera Flatbread.jpg') {
				img2 = `<img src="${img.url}" alt="" />`
			} else if(img.name === 'Panera offer.jpg') {
				img3 = `<img src="${img.url}" alt="" />`
			} else {
				img1 = `<img src="${img.url}" alt="" />`
			}
		})

		return `
			<a>
				<a href="https://delivery.panerabread.com/">${img1}</a>
				<a href="https://www.panerabread.com/en-us/home.html">${img2}</a>
				<a href="https://www.panerabread.com/en-us/gifting-at-panera.html">${img3}</a>
			</a>
		`
	}
}

function mapStateToProps({ brands }) {
	return {
		view: brands.view,
		selectedBrand: brands.selectedBrand,
		selectedBrandLogo: brands.selectedBrandLogo,
	}
}

export default connect(mapStateToProps)(Brands)


// chopThem(data) {
	// 	// const recipients = data
	// 	const recipients = data.slice(1000, 2000)
	// 	// const recipients = data.slice(2000, 3000)
	// 	// const recipients = data.slice(3000, 4000)
	// 	// const recipients = data.slice(4000, 5000)
	// 	// const recipients = data.slice(5000, 6000)
	// 	// const recipients = data.slice(6000, 7000)
	// 	// const recipients = data.slice(7000, 8000)
	// 	// const recipients = data.slice(8000, 9000)
	// 	// const recipients = data.slice(9000, 10000)
	// 	// const recipients = data.slice(10000, 11000)
	// 	// const recipients = data.slice(11000, 12000)
	// 	// const recipients = data.slice(12000, 13000)
	// 	// const recipients = data.slice(13000, 14000)
	// 	// const recipients = data.slice(14000, 15000)
	// 	// const recipients = data.slice(15000, 16000)
	// 	// const recipients = data.slice(16000, 17000)
	// 	// const recipients = data.slice(17000, 18000)
	// 	// const recipients = data.slice(18000)

	// 	console.log('CHOP: ', recipients)

	// 	// recipients.forEach(recipient => this.sendEmail(recipient))
	// }
