import React, { Component, Fragment } from 'react'
import axios from 'axios'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import InputGroup from 'react-bootstrap/InputGroup'

import config from '../../config'
import BrandSelector from '../BrandSelector'

class PayAmbassador extends Component {
	state = {
		selectedPaymentCategory: 'Categories',
		adminOverride: false
	}

	render() {
		const { brandName, brandId, selectedPaymentCategory, adminOverride } = this.state
		const { onTogglePay, paymentCategories } = this.props

		return (
			<Fragment>
				<Col lg={12}>
					<Form.Control
						ref={input => this.payInput = input}
						placeholder='$'
					/>
				</Col>
				<Col lg={12} className='mt-1'>
					<Form.Control
						ref={input => this.payTitle = input}
						placeholder='Title'
					/>
				</Col>
				<Col lg={12} className='mt-1'>
					<Form.Control
						ref={input => this.payDescription = input}
						placeholder='Description'
					/>
				</Col>
				<Col lg={12} className='mt-1'>
					<BrandSelector onBrandClick={this.handleBrandClick}/>
				</Col>
				<Col lg={12} className='mt-1'>
					<Form.Control
						ref={input => this.storeId = input}
						placeholder='Store ID'
					/>
				</Col>
				<Col lg={12} className='mt-1'>
					<Form.Control
						ref={input => this.activityId = input}
						placeholder='Activity ID'
					/>
				</Col>

				<Col lg={12} className='mt-1'>
					<Dropdown size='sm'>
						<Dropdown.Toggle variant='outline-secondary' className='w-100 text-left d-flex justify-content-between align-items-center'>
							{selectedPaymentCategory}
						</Dropdown.Toggle>
						<Dropdown.Menu>
							{paymentCategories.map((category, i) => (
								<Dropdown.Item key={i} onClick={() =>  this.setState({ selectedPaymentCategory: category })}>{category.toLowerCase()}</Dropdown.Item>
							))}
						</Dropdown.Menu>
					</Dropdown>
				</Col>
				<Col lg={12} className='d-flex justify-content-between flex-wrap mt-3'>
					<label className='checkmark-container'>
						<input
							name='adminOverride'
							type='checkbox'
							checked={adminOverride}
							onChange={e => this.setState(({ adminOverride }) => ({ adminOverride: !adminOverride }))}
						/>
						<span className="checkmark"></span>
						Admin Override
					</label>
					<div>
						<Button variant='success' size='sm' onClick={this.handlePayAmbassador}>Pay</Button>
						<Button variant='danger' size='sm' onClick={onTogglePay}>X</Button>
					</div>
				</Col>
			</Fragment>
		)
	}

	handleBrandClick = (brandName, brandId) => {
		this.setState({
			selectedBrandName: brandName,
			selectedBrandId: brandId
		})
	}

	handlePayAmbassador = async () => {
		const { adminOverride, selectedPaymentCategory } = this.state
		const { ambassador, onSuccess, onError, onUpdateAmbassadorData } = this.props
		const amount = this.payInput.value
		const title = this.payTitle.value
		const description = this.payDescription.value
		const brandId = this.state.selectedBrandId
		const storeId = this.storeId.value
		const activityId = this.activityId.value
		const category = selectedPaymentCategory === 'Categories' ? '' : selectedPaymentCategory
		// const adminOverride = this.state.adminOverride ? 'true' : 'false'

		if(!amount) return

		const confirm = window.confirm(`Are you sure you want to pay $${amount} to ${ambassador.ambassador_name}?`)
		const ambassadorId = ambassador.id

		if(confirm) {
			try {
				await axios.post(`${config.coreAPI}/fieldDayAdmin/ambassador/${ambassadorId}/pay`, {}, {
					params: {
						amount,
						category,
						title,
						description,
						brandId,
						storeId,
						activityId,
						adminOverride
					}
				})

				this.setState({
					payAmbassador: false
				}, () => {
					onSuccess()
					onUpdateAmbassadorData()
				})

			} catch(e) {
				onError(e)
			}
		}
	}
}

export default PayAmbassador
