import React, { Fragment } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Badge from 'react-bootstrap/Badge'
import { map } from 'underscore'

function FiltersBar({ filters, filters2, onRemoveFilter, onRemoveFilter2, count, onExportList }) {
	const filtersMapping = {
		place_type: 'Place Type',
		business_type_ids: 'Business Type ID',
		sic_code_ids: 'SIC Code',
		primary_sic_code_id: 'Primary SIC Code',
		estimated_location_employee_count: 'Estimated Employee Count',
		// postal_code: 'Postal Code',
		zip: 'Zip',
		city: 'City',
		state: 'State',
		'contacts.email': 'Email',
		'phone': 'Phone',
		'geo_distance': 'Geo',
		'geo_polygon': 'Polygon',
		'headquarters_id': 'Headquarters',
	}

	const badgeStyles = {
		whiteSpace: 'break-spaces'
	}

	const getValue = (relation, value) => {
		if(relation !== 'geo_distance') return value

		return `${value.distance} from ${value.postal_codes ? value.postal_codes[0] : 'each store'}`
	}

	return (
		<div className='p-4 d-flex justify-content-between' style={{ fontSize: '17px' }}>
			<div>
				<b>Total: </b>({count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")})
				<br />
				<br />
				{filters.length
					? <div>
							{filters.map((filter, i) => {
								if(filter.relation === 'geo_polygon') {
									return (
										<Badge style={badgeStyles} key={i} className='p-2 mr-2' variant='secondary'>
											Polygon
											<span className='text-danger ml-2 pointer' onClick={() => onRemoveFilter(i)}>[x]</span>
										</Badge>
									)
								}

								return (
									<Badge style={badgeStyles} key={i} className='p-2 mr-2' variant='secondary'>
										{filtersMapping[filter.attribute] || filtersMapping[filter.relation]}: {filter.negated ? 'excluding' : ''} {Array.isArray(filter.value) ? filter.value.join(' - ') : getValue(filter.relation, filter.value)}
										<span className='text-danger ml-2 pointer' onClick={() => onRemoveFilter(i)}>[x]</span>
									</Badge>
								)
							})}
							{filters2.length ? <span>AND&nbsp;</span> : null}
							{filters2.length
									? filters2.map((filter, i) => (
										<Badge style={badgeStyles} key={i} className='p-2 mr-2' variant='secondary'>
											{filtersMapping[filter.attribute] || filtersMapping[filter.relation]}: {Array.isArray(filter.value) ? filter.value.join(' - ') : getValue(filter.relation, filter.value)}
											<span className='text-danger ml-2 pointer' onClick={() => onRemoveFilter2(i)}>[x]</span>
										</Badge>
									))
									: null
							}
						</div>
					: <Badge style={badgeStyles} className='p-2' variant='secondary'>No Filters</Badge>
				}
			</div>
			<i className='fas fa-file-download fa-lg pointer' onClick={onExportList}></i>
		</div>
	)
}

export default FiltersBar
