import React, { Fragment, useState } from 'react'
import { Form, Col, InputGroup } from 'react-bootstrap'
import { Checkbox, Button } from 'semantic-ui-react'

function Question(props) {
	const {
		index,
		question,
		answers,
		onAddAnswer,
		onDeleteQuestion,
		onQuestionInputChange,
		onAnswerInputChange,
		onDeleteAnswer,
		characterMin,
		onCharMinChange,
		shortAnswerEnabled,
		onShortAnswerEnableChange
	} = props

	return (
		<Fragment>
			<Button icon='minus' size='mini' color='red' className='mt-2' onClick={e => onDeleteQuestion(e, index)}></Button>
			<Form.Row className='align-items-center justify-content-around'>
				<Col lg={4}>
					<Form.Group>
						<Form.Label>Character Minimum</Form.Label>
						<Form.Control
						  type='number'
						  name='characterMin'
						  value={characterMin}
						  onChange={e => onCharMinChange(e.target.value, index)}
						/>
						<Form.Text className='text-muted'>
							If set > 0, this will be a required question
						</Form.Text>
					</Form.Group>
				</Col>
				<Col lg={3}>
					<Checkbox
						label='Enable Short Answer'
						checked={shortAnswerEnabled}
						onChange={(e, { name, checked }) => onShortAnswerEnableChange(checked, index)}
					/>
				</Col>
			</Form.Row>
			<Form.Row>
				<Col>
					<Form.Group>
						<Form.Label>Question</Form.Label>
						<Form.Control
						  type='text'
						  name='question'
						  value={question}
						  onChange={e => onQuestionInputChange(e.target.value, index)}
						/>
					</Form.Group>
				</Col>
			</Form.Row>
			<Form.Row>
				<Col>
					<Form.Group>
						<Form.Label>
							Answers
							<Button
								className='ml-2'
								color='black'
								icon='add'
								size='mini'
								onClick={e => onAddAnswer(e, index)}
							/>
						</Form.Label>
						{answers.map((answer, i) => (
							<InputGroup className='mb-3' key={i}>
								<Form.Control
									className='mb-1'
								  type='text'
								  name='question'
								  placeholder={`answer ${i + 1}..`}
								  value={answers[i]}
								  onChange={e => onAnswerInputChange(e.target.value, index, i)}
								  // onChange={e => {
								  // 	const temp = [...answers]
								  // 	temp[i] = e.target.value
								  // 	setAnswers(temp)
								  // }}
								/>
								<InputGroup.Append>
									<Button
										icon='trash'
										color='red'
										size='mini'
										onClick={e => onDeleteAnswer(e, index, i)}
										// onClick={e => {
										// 	e.preventDefault()
										// 	setAnswers(answers.filter((answer, j) => i !== j))
										// }}
									/>
								</InputGroup.Append>
							</InputGroup>
						))}
					</Form.Group>
				</Col>
			</Form.Row>
			<hr className='border-danger' />
		</Fragment>
	)
}

export default Question
