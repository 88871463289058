import React, { Component } from 'react'
import axios from 'axios'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

import config from '../../config'

class GigReviw extends Component {
	state = {}

	render() {
		const { working, done, error } = this.state

		return (
			<div>
				<Form.Control
					as='textarea'
					rows='7'
					ref={input => this.input = input}
					style={{
						// border: `${editMode ? '1px solid' : 'none'}`,
						resize: 'none',
						// width: '100%',
					}}
				/>
				<hr />
				{working && <Spinner animation='border' size='sm' variant='info' />}
				{done && <i className="fas fa-check text-success" style={{color: '#28a745'}}></i>}
				<span className='text-danger'>{error}</span>
				<Button
					variant='dark'
					className='float-right'
					onClick={this.handleSubmitForReview}
					disabled={working}
				>
					Submit
				</Button>
			</div>
		)
	}

	handleSubmitForReview = async () => {
		const review = this.input.value
		const { gigId, onSubmitSuccess } = this.props

		try {
			this.setState({ working: true })

			await axios.post(`${config.coreAPI}/fieldDayAdmin/activity/${gigId}/review?feedback=${review}`)

			this.setState({
				working: false,
				done: true
			}, onSubmitSuccess)
		} catch(e) {
			console.error(e)

			this.setState({
				working: false,
				error: e.response.data.message
			})
		}
	}
}

export default GigReviw
