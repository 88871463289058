const dev = {
	s3: {
		BUCKET: "dev.assets.localid.io",
		REGION: "us-west-2"
	},
	apiGateway: {
		REGION: "us-west-2",
		URL: "https://ojq6mx52gj.execute-api.us-west-2.amazonaws.com/dev"
	},
	cognito: {
		IDENTITY_POOL_ID: 'us-west-2:b40f5182-c790-4cbe-b3f6-9126e3cf8023'
	},
	coreAPI: "https://dev.core.api.fieldday.app/coreAPI",
	analyticsAPI: "https://dev.analytics.api.fieldday.app",
}

const prod = {
	s3: {
		BUCKET: "assets.localid.io",
		REGION: "us-west-2"
	},
	apiGateway: {
		REGION: "us-west-2",
		URL: "https://1506t6rb4k.execute-api.us-west-2.amazonaws.com/prod"
	},
	cognito: {
		IDENTITY_POOL_ID: 'us-west-2:b40f5182-c790-4cbe-b3f6-9126e3cf8023'
	},
	coreAPI: "https://core.api.fieldday.app/coreAPI",
	analyticsAPI: "https://analytics.api.fieldday.app",
}

const config = process.env.REACT_APP_STAGE === 'prod'
	? prod
	: dev

export default {
	// common config values
	GOOGLE_API_KEY: "AIzaSyBzt4U31ue3BGvUsNj0v4MSKjm87sQLSzc",
	...config
}
