import React, { Component } from 'react'
import { connect } from 'react-redux'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Dropdown from 'react-bootstrap/Dropdown'
import { List } from 'semantic-ui-react'

import { SideBarMenuItem } from '../SideBar'
import {
	getBrands,
	handleBrandSelect,
	setView
} from '../../actions/brands'

class BrandsSideBar extends Component {
	state = {}

	componentDidMount() {
	}

	render() {
		const { dispatch, brands, selectedBrand } = this.props

		// console.log('BRANDS SIDEBAR STATE: ', this.state)
		// console.log('BRANDS SIDEBAR PROPS: ', this.props)
		return (
			<div>
				<Button variant='info' className='d-flex justify-content-center w-100 mb-3' onClick={() => dispatch(setView('brandsOverview')) }>Brands Overview</Button>
				<Button variant='info' className='d-flex justify-content-center w-100 mb-3' onClick={() => dispatch(setView('brandOnboard')) }>Onboard New Brand</Button>
				<hr />
				{/*<Dropdown>
					<Dropdown.Toggle size='lg' variant='outline-light' className='d-flex justify-content-between align-items-center w-100'>Select Brand</Dropdown.Toggle>
					<Dropdown.Menu style={{maxHeight: '300px', overflow: 'auto'}}>
						{brands && brands.results.map(brand => (
							<Dropdown.Item
								key={brand.brand_id}
								onClick={() => dispatch(handleBrandSelect(brand))}
							>
								{brand.brand_name}
							</Dropdown.Item>
						))}
					</Dropdown.Menu>
				</Dropdown>*/}
				{selectedBrand &&
					<div>
						<h3 className='text-center mt-4 mb-4'>{selectedBrand.brand_name}</h3>
						<SideBarMenuItem
							icon={<i className='fas fa-info-circle text-muted'></i>}
							title={'Brand Profile'}
							onClick={() => dispatch(setView('brandProfile'))}
						/>
						<List inverted>
							<List.Item>
								<List.Icon name='setting' />
								<List.Content>
									<List.Header className='pointer'>Brand Settings</List.Header>
									<List.List>
										<List.Item>
											<List.Icon name='question circle' />
											<List.Content>
												<List.Header className='pointer' onClick={() => dispatch(setView('businessCardQuestions'))}>Business Card Questions</List.Header>
											</List.Content>
										</List.Item>
									</List.List>
								</List.Content>
							</List.Item>
						</List>
						<SideBarMenuItem
							icon={<i className='fas fa-store-alt text-muted'></i>}
							title={'Campaign Overview'}
							onClick={() => dispatch(setView('campaignOverview'))}
						/>
						<SideBarMenuItem
							icon={<i className='fas fa-chart-line text-muted'></i>}
							title={'Performance Overview'}
							onClick={() => dispatch(setView('performanceOverview'))}
						/>
						<SideBarMenuItem
							icon={<i className='far fa-envelope text-muted'></i>}
							title={'Email Lists'}
							onClick={() => dispatch(setView('emailList'))}
						/>
					</div>
				}
			</div>
		)
	}
}

function mapStateToProps({ brands }) {
	// console.log('BRANDS brands mapStateToProps: ', brands)
	return {
		brands: brands.brands,
		selectedBrand: brands.selectedBrand
	}
}

export default connect(mapStateToProps)(BrandsSideBar)
