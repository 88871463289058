import React from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'

import 'react-big-calendar/lib/css/react-big-calendar.css'

function GigsOverview({ selectedStores, selectedExecution, gigSettings, targetSettings, onGetSchedule }) {
	const localizer = momentLocalizer(moment)
	console.log('selectedStores: ', selectedStores)
	console.log('gigSettings: ', gigSettings)

	return (
		<div>
			<div className='d-flex justify-content-end mb-2'>
				<Button onClick={onGetSchedule}>
					Get Schedule
				</Button>
			</div>
			<div>
				{selectedStores.map(store => {
					let estimatedNumOfGigs = 0
					let events = []

					{/*if(selectedExecution === 'PHONE') {
						let minTargetCount = 0

						if(store.targetSettings) {
							minTargetCount = Number(store.targetSettings.reachGoal) + (Number(store.targetSettings.reachGoal) * (Number(store.targetSettings.targetCushion) / 100))
						}

						if(!store.targetSettings) {
							minTargetCount = Number(targetSettings.reachGoal) + (Number(targetSettings.reachGoal) * (Number(targetSettings.targetCushion) / 100))
						}

						estimatedNumOfGigs = minTargetCount / Number(gigSettings.duration)
					}*/}

					if(store.targetSettings) {
						estimatedNumOfGigs = Number(store.targetSettings.reachGoal) / Number(store.targetSettings.reach_per_hour) / Number(gigSettings.duration)
					}

					if(!store.targetSettings) {
						estimatedNumOfGigs = Number(targetSettings.reachGoal) / Number(targetSettings.reach_per_hour) / Number(gigSettings.duration)
					}

					if(store.schedule) {
						events = store.schedule.map((date, i) => ({
							id: i,
							title: `Gig ${i + 1}`,
					    start: moment(date).toDate(),
					    end: moment(date).add(gigSettings.duration, 'hours').toDate(),
						}))
					}

					console.log("EVENTSSSSS: ", events)

					return (
						<Row key={store.store_id} md={4} className='mb-3 pb-3' style={{ borderBottom: '1px solid' }}>
							<Col className='d-flex flex-column'>
								<h3>{store.store_name} ({store.store_id})</h3>
								<span>Estimated Number of Gigs: <b>{Math.ceil(estimatedNumOfGigs)}</b></span>
								{/*<span>Est. BA Payout: xx</span>*/}
							</Col>
							<Col style={{ height: '500px', overflow: 'auto' }}>
								<Calendar
								  localizer={localizer}
								  events={events}
								  startAccessor="start"
								  endAccessor="end"
								  views={['month', 'week', 'day']}
								/>
							</Col>
						</Row>
					)
				})}
			</div>
		</div>
	)
}

export default GigsOverview
