import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import ReactTable from 'react-table'
import selectTableHOC from "react-table/lib/hoc/selectTable";
import 'react-table/react-table.css'
import moment from 'moment'
import _ from 'underscore'
import { API } from "aws-amplify"
import axios from 'axios'
import Modal from 'react-bootstrap/Modal'

// import Modal from '../containers/Modal'
import config from '../../config'
import Email from '../shared/Email'
import PushNotification from '../shared/PushNotification'
import EmailTraining from '../shared/EmailTraining'
import playIcon from '../../assets/img/play-icon.png'
import noPlayIcon from '../../assets/img/no-play-icon.png'
import noImage from '../../assets/img/noImage.png'
import excelExporter from '../../utils/excelExporter'
import { _getAmbassadors, handlePageChange, handlePageSizeChange, handleSortColumns } from '../../actions/ambassadors'

const SelectTable = selectTableHOC(ReactTable)

class AmbassadorsTable extends Component {
	state = {
		selected: [],
		selectAll: false,
		showActions: false,
		showModal: false,
		modalName: null
	}

	componentDidUpdate(prevProps, prevState) {
		const { dispatch, pageIndex, pageSize } = this.props

		if(prevProps.pageIndex !== pageIndex && prevProps.pageSize === pageSize) {
			// on page index change
			dispatch(_getAmbassadors(this.getParams()))
		}

		if(prevProps.pageSize !== pageSize) {
			// on page size change
			dispatch(_getAmbassadors(this.getParams()))
		}

		if(prevProps.sortField !== this.props.sortField || prevProps.sortDirection !== this.props.sortDirection) {
			console.log('onSortedChange: ', this.props)
			// on sort change
			dispatch(_getAmbassadors(this.getParams()))
		}
	}

	handleShowModal = (modalName) => this.setState({
		showModal: true,
		modalName
	})

	handleCloseModal = () => this.setState({
		showModal: false,
		modalName: null
	})

	render() {
		const { toggleSelection, toggleAll, isSelected } = this
		const { ambassadors, total, pageIndex, pageSize, dispatch } = this.props
		const { selectAll, allAmbassadors } = this.state
		const numOfPages = total <= pageSize ? 1 : Math.ceil(total / pageSize)

		const extraProps = {
      isSelected,
      selectAll,
      toggleAll,
      toggleSelection
    }

		return (
			<div>
				{this.state.showModal &&
					<Modal
						show={this.state.showModal}
						onHide={this.handleCloseModal}
						size='lg'
						aria-labelledby="contained-modal-title-vcenter"
						centered
					>
						{this.state.modalName === 'email' &&
							<Fragment>
								<Modal.Header closeButton>
									<Modal.Title id="contained-modal-title-vcenter">
										Email Form
									</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<Email
										recipients={this.state.selected}
										onSendEmail={this.handleSendEmail}
										sending={this.state.sendingEmail}
									/>
								</Modal.Body>
							</Fragment>
						}
						{this.state.modalName === 'emailAll' &&
							<Fragment>
								<Modal.Header closeButton>
									<Modal.Title id="contained-modal-title-vcenter">
										Email Form
									</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<Email
										recipients={allAmbassadors}
										onSendEmail={this.handleSendEmailAll}
										onRemoveRecipient={this.handleRemoveRecipient}
										sending={this.state.sendingEmail}
									/>
								</Modal.Body>
							</Fragment>
						}
						{Array.isArray(this.state.modalName) && this.state.modalName[0] === 'video' &&
							<Fragment>
								<Modal.Header closeButton>
									<Modal.Title id="contained-modal-title-vcenter">
										Ambassador Video
									</Modal.Title>
								</Modal.Header>
								<video src={this.state.modalName[1]} controls autoPlay />
							</Fragment>
						}
						{this.state.modalName === 'push' &&
							<Fragment>
								<Modal.Header closeButton>
									<Modal.Title id="contained-modal-title-vcenter">
										Push Notification
									</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<PushNotification
										selected={this.state.selected}
										onSendPushNotification={this.handlePushNotification}
									/>
								</Modal.Body>
							</Fragment>
						}
						{this.state.modalName === 'training' &&
							<Fragment>
								<Modal.Header closeButton>
									<Modal.Title id="contained-modal-title-vcenter">
										Training
									</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<EmailTraining ambassadors={this.state.selected} />
								</Modal.Body>
							</Fragment>
						}
					</Modal>
				}
				<AmbassadorsActions
					showActions={this.state.showActions}
					onShowActions={() => this.setState({ showActions: !this.state.showActions })}
					onActionClick={this.handleShowModal}
					onExportAmbassadors={this.handleExportAmbassadors}
					onExportAllAmbassadors={this.handleExportAllAmbassadors}
					onEmailAllAmbassadors={this.handleEmailAllAmbassadors}
				/>
				<div className='ambassadors-table'>
					<SelectTable
						{...extraProps}
						className='-striped -highlight table-style'
						selectType='checkbox'
						keyField='id'
						ref={r => this.checkboxTable = r}
						data={ambassadors}
						pages={numOfPages}
						manual
						page={pageIndex}
						pageSize={pageSize}
						onPageChange={pageIndex => dispatch(handlePageChange(pageIndex))}
						onPageSizeChange={(pageSize, pageIndex) => dispatch(handlePageSizeChange(pageIndex, pageSize, total))}
						onSortedChange={(newSorted, column, shiftKey) => dispatch(handleSortColumns(newSorted, column, shiftKey))}
						// loading={this.state.loading}
						// selectAll={true}
						// renderTotalPagesCount={() => Math.floor(this.props.total / 20)}
						// showPageJump={false}
						// renderPageJump={({ onChange, value }) => component}
						// onSortedChange={(newSorted, column, shiftKey) => console.log("SORTED: ", newSorted, column, shiftKey)}
						columns={[
							{
								Header: <i className="far fa-image fa-2x"></i>,
								id: 'image',
								width: 50,
								headerClassName: 'table-header',
								className: 'table-column',
								sortable: false,
								accessor: d => <div style={{
									backgroundImage: `url(${d.profileImage ? d.profileImage : noImage})`,
									height: '25px',
									backgroundPosition: 'center',
									backgroundRepeat: 'no-repeat',
									backgroundSize: 'cover'
								}}></div>
							},
							{
								Header: <i className="fab fa-youtube fa-2x"></i>,
								id: 'video',
								width: 50,
								headerClassName: 'table-header',
								className: 'table-column',
								sortable: false,
								accessor: d => <VideoIcon video={d.profileVideo} onVideoClick={this.handleShowModal} />
							},
							{
								Header: 'NAME',
								id: 'ambassador_name',
								headerClassName: 'table-header',
								className: 'table-column',
								sortable: false,
								accessor: d => <div style={{margin: 'auto'}}><Link to={`/ambassador/${d.id}`}>{d.ambassador_name}</Link></div>
							},
							{
								Header: 'EMAIL',
								id: 'email_address',
								headerClassName: 'table-header',
								className: 'table-column',
								accessor: d => <div onClick={() => this.handleShowModal('email')} style={{margin: 'auto'}}>{d.email_address}</div>
							},
							{
								Header: 'PHONE',
								id: 'phone',
								headerClassName: 'table-header',
								className: 'table-column',
								accessor: d => <div style={{margin: 'auto'}}>{d.phone}</div>
							},
							{
								Header: 'ZIP CODE',
								id: 'zip_code',
								// width: 85,
								headerClassName: 'table-header',
								className: 'table-column',
								accessor: d => <div style={{margin: 'auto'}}>{d.zip_code}</div>
							},
							{
								Header: 'STATE',
								id: 'state',
								// width: 70,
								headerClassName: 'table-header',
								className: 'table-column',
								accessor: d => <div style={{margin: 'auto'}}>{d.state}</div>
							},
							{
								Header: 'CITY',
								id: 'city',
								headerClassName: 'table-header',
								className: 'table-column',
								accessor: d => <div style={{margin: 'auto'}}>{d.city}</div>
							},
							{
								Header: 'AGE',
								id: 'date_of_birth',
								// width: 60,
								headerClassName: 'table-header',
								className: 'table-column',
								accessor: d => <div style={{margin: 'auto'}}>{d.date_of_birth && moment().diff(d.date_of_birth, 'years')}</div>
							},
							{
								Header: 'RATING',
								id: 'rating',
								// width: 80,
								headerClassName: 'table-header',
								className: 'table-column',
								sortable: false,
								accessor: d => <div style={{margin: 'auto'}}><div style={{color: '#1f77b4'}}><i className="fas fa-star fa-xs"></i><i className="fas fa-star fa-xs"></i><i className="fas fa-star fa-xs"></i><i className="fas fa-star fa-xs"></i><i className="fas fa-star fa-xs"></i></div></div>
							},
							{
								Header: <i className="far fa-check-square fa-lg" title='Gigs Completed' />,
								id: 'total_gigs_completed',
								// width: 50,
								headerClassName: 'table-header',
								className: 'table-column',
								accessor: d => <div style={{margin: 'auto'}}>{d.total_gigs_completed}</div>
							},
							{
								Header: <i className="fas fa-dollar-sign fa-lg" title='Total Earnings'></i>,
								id: 'total_earnings_cents',
								// width: 70,
								headerClassName: 'table-header',
								className: 'table-column',
								accessor: d => <div style={{margin: 'auto'}}>${d.total_earnings_cents ? d.total_earnings_cents / 100 : 0}</div>
							},
							{
								Header: 'JOINED',
								id: 'created_date',
								headerClassName: 'table-header',
								className: 'table-column',
								accessor: d => <div style={{margin: 'auto'}}>{d.created_date && moment(d.created_date * 1000).format('MMM Do, YYYY')}</div>
							},
						]}
					/>
				</div>
			</div>
		)
	}

	getParams = () => {
		const { filters, pageIndex, pageSize, sortField, sortDirection } = this.props
		const filterParams = []

		if(filters.rejected) {
			filterParams.push(`rejected=${filters.rejected}`)
		}

		if(!filters.rejected) {
			filterParams.push(`approved=${filters.approved}`)
		}

		if(filters.searchString) {
			filterParams.push(`searchString=${filters.searchString}`)
		}

		if(filters.registrationVideoUploaded) {
			filterParams.push('registrationVideoUploaded=true')
		}

		if(filters.stripeSetup) {
			filterParams.push('stripeSetup=true')
		}

		if(filters.zipCodes) {
			filterParams.push(`zipCodes=${filters.zipCodeInput}`)
		}

		if(filters.radius) {
			filterParams.push(`radiusInMiles=${filters.radiusInMiles}`)
		}

		if(filters.sortField || sortDirection) {
			filterParams.push(`sortField=${sortField}&sortDirection=${sortDirection}&waitlisted=${filters.waitlisted}`)
		}

		const params = filterParams.concat([`pageFrom=${pageIndex * pageSize}`], [`pageSize=${pageSize}`]).join('&')

		return params
	}

	toggleSelection = (key, shift, row) => {
		console.log("ITEM: ", key, shift, row)
		let selected = [...this.state.selected]
		const keyIndex = selected.findIndex(item => item.id === key.replace(/select-/g, ''))
		console.log("selected: ", selected)

console.log('keyIndex:' , keyIndex)
console.log('keyIndex2:' , _.find(selected, item => item.id === key.replace(/select-/g, '')))
		if(keyIndex >= 0) {
			selected = [
				...selected.slice(0, keyIndex),
				...selected.slice(keyIndex + 1)
			]
		} else {
			selected.push(row)
		}
console.log('selected:' , selected)
		this.setState({ selected })
	}

	toggleAll = () => {
		const { pageIndex, pageSize } = this.props
		const wrappedInstance = this.checkboxTable.getWrappedInstance()
		const currentRecords = wrappedInstance.getResolvedState().sortedData
		const startIndex = pageIndex * pageSize
		const selectAll = this.state.selectAll ? false : true
		let selected = []

		console.log('INSIDE1', currentRecords)
		if(selectAll) {
			for(let i = startIndex; i < pageSize + startIndex; i++) {
				currentRecords[i] && selected.push(currentRecords[i]._original)
			}
		}
console.log(selectAll, selected)
		this.setState({
			selectAll,
			selected
		})
	}

	isSelected = (key) => {
		return _.find(this.state.selected, item => item.id === key) === undefined ? false : true
		// return this.state.selected.includes(key)
	}

	handleSendEmail = ({ subject, message }) => {
		const recipients = []
		this.state.selected.forEach(item => item.email_address && recipients.push({ email: item.email_address }))

		const body = {
			subject,
			message,
			recipients
		}

		this.setState({ sendingEmail: true })

		console.log(body)
		API.post('sendEmail', '/sendEmail', { body })
		 .then(() => {
		 	alert('Email sent successfully')
		 	this.setState({ sendingEmail: false })
		 	this.handleCloseModal()
		 })
		 .catch(e => {
		 		alert('There was an error sending email')
		 		this.setState({ sendingEmail: false })
			})
	}

	handleSendEmailAll = ({ subject, message }) => {
		console.log("STATE: ", this.state)
		const { allAmbassadors } = this.state

		// removing duplicate emails
		const recipients = [...new Set(allAmbassadors.filter(amb1 => amb1.email_address).map(amb2 => amb2.email_address))].map(email => ({ email }))

		const body = {
			subject,
			message,
			recipients
		}

		this.setState({ sendingEmail: true })

		console.log(body)
		API.post('sendEmail', '/sendEmail', { body })
		 .then(() => {
		 	alert('Email sent successfully')
		 	this.setState({ sendingEmail: false })
		 	this.handleCloseModal()
		 })
		 .catch(e => {
		 		alert('There was an error sending email')
		 		this.setState({ sendingEmail: false })
			})
	}

	handlePushNotification = async ({ title, message }) => {
		const recipients = this.state.selected.map(user => user.id).join('|')

		try {
			await axios.post(`${config.coreAPI}/fieldDayAdmin/ambassadors/push?ambassadorIds=${recipients}&title=${title}&message=${message}`)

			alert('sent')
			this.handleCloseModal()
		} catch(e) {
			alert('Error in sending Push Notification, check console')
			console.log("ERROR IN PUSH Notification: ", e)
		}
	}

	handleExportAllAmbassadors = async () => {
		const { filters } = this.props
		let statusFilter = `approved=${filters.approved}`

		if(filters.rejected) {
			statusFilter = `rejected=${filters.rejected}`
		}

		try {
			const ambassadors = await axios.get(`${config.coreAPI}/fieldDayAdmin/ambassadors?${statusFilter}&pageFrom=0&pageSize=10000`)
			const data = ambassadors.data.responses[0].results

			const headerRow = [
				'Name',
				'Email',
				'Phone',
				'City',
				'State',
				'Zip',
				// 'Bio', causing issue on excel file when exported
				'Car',
				'Shirt Size',
				'Joined',
				'Account Status',
				'Stripe Connected',
				'Has Video',
				'Gigs Completed',
				'$Total Earnings',
				'Link to Profile'
			].map(cell => ({ value: cell, type: 'string'}))

			const contentRows = _.map(data, ambassador => {
				return [
					{
						value: ambassador.ambassador_name || '',
						type: 'string'
					},
					{
						value: ambassador.email_address || '',
						type: 'string'
					},
					{
						value: ambassador.phone || '',
						type: 'string'
					},
					{
						value: ambassador.city || '',
						type: 'string'
					},
					{
						value: ambassador.state || '',
						type: 'string'
					},
					{
						value: ambassador.zip_code || '',
						type: 'string'
					},
					// {
					// 	value: ambassador.bio && typeof ambassador.bio === 'string' ? ambassador.bio : '',
					// 	type: 'string'
					// },
					{
						value: ambassador.has_car ? 'Yes' : 'No',
						type: 'string'
					},
					{
						value: ambassador.shirt_size || '',
						type: 'string'
					},
					{
						value: ambassador.created_date ? moment(ambassador.created_date * 1000).format('MM/DD/YYYY') : '',
						type: 'string'
					},
					{
						value: ambassador.approved ? 'Approved' : 'Pending',
						type: 'string'
					},
					{
						value: ambassador.stripe_profile ? 'Yes' : 'No',
						type: 'string'
					},
					{
						value: ambassador.registration_video_uploaded && ambassador.registration_video_url ? 'Yes' : 'No',
						type: 'string'
					},
					{
						value: ambassador.total_gigs_completed && typeof ambassador.total_gigs_completed === 'number' ? ambassador.total_gigs_completed : '',
						type: 'number'
					},
					{
						value: ambassador.total_earnings_cents && typeof ambassador.total_earnings_cents === 'number' ? ambassador.total_earnings_cents / 100 : '',
						type: 'number'
					},
					{
						value: process.env.REACT_APP_STAGE === 'dev' ? `https://dev.central.fieldday.app/ambassador/${ambassador.id}` : `https://central.fieldday.app/ambassador/${ambassador.id}`,
						type: 'string'
					},
				]
			})

			contentRows.unshift(headerRow)

			const excelConfig = {
			  filename: 'ambassadors',
			  sheet: {
			    data: contentRows
			  }
			}

			excelExporter(excelConfig)
		} catch(e) {
			console.error(e)
		}
	}

	handleEmailAllAmbassadors = async () => {
		const { filters } = this.props

		try {
			const ambassadors = await axios.get(`${config.coreAPI}/fieldDayAdmin/ambassadors?approved=${filters.approved}&pageFrom=0&pageSize=20000`)

			this.setState({
				// allAmbassadors: ambassadors.data.responses[0].results.slice(0, 998)
				allAmbassadors: ambassadors.data.responses[0].results
			}, () => this.handleShowModal('emailAll'))
		} catch(e) {
			alert("Error")
			console.error(e)
		}
	}

	handleRemoveRecipient = (id) => {
		this.setState(({ allAmbassadors }) => ({
			allAmbassadors: allAmbassadors.filter(ambassador => ambassador.id !== id)
		}))
	}

	handleExportAmbassadors = () => {
		const data = this.state.selected
		const headerRow = [
			'Name',
			'Email',
			'Phone',
			'City',
			'State',
			'Zip',
			// 'Bio', causing issue on excel file when exported
			'Car',
			'Shirt Size',
			'Joined',
			'Account Status',
			'Stripe Connected',
			'Has Video',
			'Gigs Completed',
			'$Total Earnings',
			'Link to Profile'
		].map(cell => ({ value: cell, type: 'string'}))

		const contentRows = _.map(data, ambassador => {
			return [
				{
					value: ambassador.ambassador_name,
					type: 'string'
				},
				{
					value: ambassador.email_address,
					type: 'string'
				},
				{
					value: ambassador.phone,
					type: 'string'
				},
				{
					value: ambassador.city,
					type: 'string'
				},
				{
					value: ambassador.state,
					type: 'string'
				},
				{
					value: ambassador.zip_code,
					type: 'string'
				},
				// {
				// 	value: ambassador.bio,
				// 	type: 'string'
				// },
				{
					value: ambassador.has_car ? 'Yes' : 'No',
					type: 'string'
				},
				{
					value: ambassador.shirt_size,
					type: 'string'
				},
				{
					value: ambassador.created_date ? moment(ambassador.created_date * 1000).format('MM/DD/YYYY') : '',
					type: 'string'
				},
				{
					value: ambassador.approved ? 'Approved' : 'Pending',
					type: 'string'
				},
				{
					value: ambassador.stripe_profile ? 'Yes' : 'No',
					type: 'string'
				},
				{
					value: ambassador.registration_video_uploaded && ambassador.registration_video_url ? 'Yes' : 'No',
					type: 'string'
				},
				{
					value: ambassador.total_gigs_completed >= 0 ? ambassador.total_gigs_completed : '',
					type: 'number'
				},
				{
					value: ambassador.total_earnings_cents >= 0 ? ambassador.total_earnings_cents / 100 : '',
					type: 'number'
				},
				{
					value: process.env.REACT_APP_STAGE === 'dev' ? `https://dev.central.fieldday.app/ambassador/${ambassador.id}` : `https://central.fieldday.app/ambassador/${ambassador.id}`,
					type: 'string'
				},
			]
		})

		contentRows.unshift(headerRow)

		const config = {
		  filename: 'ambassadors',
		  sheet: {
		    data: contentRows
		  }
		}

		excelExporter(config)
	}
}

export function AmbassadorsActions({ showActions, onShowActions, onActionClick, onExportAmbassadors, onExportAllAmbassadors, onEmailAllAmbassadors }) {
	return (
		<div className='ambassadors-actions mt-1' onClick={onShowActions}>
			<i style={{marginRight: '20px', color: '#1b94a0', cursor: 'pointer'}} className="far fa-dot-circle fa-2x"></i>
			{showActions &&
				<div className='ambassadors-actions-box'>
					<div onClick={() => onActionClick('email')}>Email</div>
					<div onClick={() => onActionClick('push')}>Push Notification</div>
					<div onClick={() => onActionClick('training')}>Send Training</div>
					<div onClick={onExportAmbassadors}>Export Selected as Excel</div>
					<div onClick={onExportAllAmbassadors}>Export All as Excel</div>
					<div onClick={onEmailAllAmbassadors}>Email All Ambassadors</div>
					<div style={{color: 'grey'}}>Text</div>
					<div style={{color: 'grey'}}>Save As Group</div>
					<div style={{color: 'grey'}}>Move To Group</div>
					<div style={{color: 'grey'}}>Remove</div>
				</div>
			}
		</div>
	)
}

function mapStateToProps({ ambassadors }) {
	return {
		ambassadors: ambassadors.results,
		total: ambassadors.total,
		pageIndex: ambassadors.pageIndex,
		pageSize: ambassadors.pageSize,
		sortField: ambassadors.sortField,
		sortDirection: ambassadors.sortDirection,
		filters: ambassadors.filters
	}
}

function VideoIcon({ video, onVideoClick }) {
	return (
		<div className='media-icon-container pointer'>
			{video
				? <img src={playIcon} onClick={() => onVideoClick(['video', video])} alt='' />
				: <img src={noPlayIcon} alt='' />
			}
		</div>
	)
}

export default connect(mapStateToProps)(AmbassadorsTable)
