import React from 'react'
import { Form, Button } from 'react-bootstrap'

function StoreForm({ index, values, onChange, autoGenerateId, onRemoveStore }) {
	return (
		<Form.Row className='justify-content-around align-items-center border-bottom border-secondary mb-3'>
			<Form.Group>
				<Form.Label>Store ID</Form.Label>
				<Form.Control
					required={!autoGenerateId}
				  type='number'
				  name='store_id'
				  value={values.store_id}
				  onChange={e => onChange(index, e)}
				  disabled={autoGenerateId}
				/>
				<Form.Control.Feedback type='invalid'>Required</Form.Control.Feedback>
			</Form.Group>
			<Form.Group>
				<Form.Label>Name</Form.Label>
				<Form.Control
					required
				  type='text'
				  name='store_name'
				  value={values.store_name}
				  onChange={e => onChange(index, e)}
				/>
				<Form.Control.Feedback type='invalid'>Required</Form.Control.Feedback>
			</Form.Group>
			<Form.Group>
				<Form.Label>Address</Form.Label>
				<Form.Control
					required
				  type='text'
				  name='street_address'
				  value={values.street_address}
				  onChange={e => onChange(index, e)}
				/>
				<Form.Control.Feedback type='invalid'>Required</Form.Control.Feedback>
			</Form.Group>
			<Form.Group>
				<Form.Label>Address 2</Form.Label>
				<Form.Control
				  type='text'
				  name='street_address2'
				  value={values.street_address2}
				  onChange={e => onChange(index, e)}
				/>
				<Form.Control.Feedback type='invalid'>Required</Form.Control.Feedback>
			</Form.Group>
			<Form.Group>
				<Form.Label>City</Form.Label>
				<Form.Control
					required
				  type='text'
				  name='city'
				  value={values.city}
				  onChange={e => onChange(index, e)}
				/>
				<Form.Control.Feedback type='invalid'>Required</Form.Control.Feedback>
			</Form.Group>
			<Form.Group>
				<Form.Label>State</Form.Label>
				<Form.Control
					required
				  type='text'
				  name='state'
				  value={values.state}
				  onChange={e => onChange(index, e)}
				/>
				<Form.Control.Feedback type='invalid'>Required</Form.Control.Feedback>
			</Form.Group>
			<Form.Group>
				<Form.Label>Zip</Form.Label>
				<Form.Control
					required
				  type='text'
				  name='postal_code'
				  value={values.postal_code}
				  onChange={e => onChange(index, e)}
				/>
				<Form.Control.Feedback type='invalid'>Required</Form.Control.Feedback>
			</Form.Group>
			<Form.Group>
				<Form.Label>Country</Form.Label>
				<Form.Control
					required
				  type='text'
				  name='country'
				  value={values.country}
				  onChange={e => onChange(index, e)}
				/>
				<Form.Control.Feedback type='invalid'>Required</Form.Control.Feedback>
			</Form.Group>
			<Form.Group>
				<Form.Label>Region</Form.Label>
				<Form.Control
					required
				  type='text'
				  name='region_name'
				  value={values.region_name}
				  onChange={e => onChange(index, e)}
				/>
				<Form.Control.Feedback type='invalid'>Required</Form.Control.Feedback>
			</Form.Group>
			{index !== 0 && <i className='fas fa-minus-circle text-danger pointer' onClick={() => onRemoveStore(index)}></i>}
		</Form.Row>
	)
}

export default StoreForm
