// src/components/UserProfile.js

import React from 'react'
// import axios from 'axios'
import { useAuth0 } from '../react-auth0-wrapper'

const UserProfile = () => {
  // const { loading, user, getTokenSilently } = useAuth0()
  const { loading, user } = useAuth0()

  // const getToken = async () => {
  //   try {
  //     const token = await getTokenSilently({
  //       audience: 'core',
  //       scope: 'admin'
  //     })

  //     // await axios({
  //     //         method: 'post',
  //     //         url: `${config.coreAPI}/fieldDayAdmin/training/plan/materials?trainingPlanId=${trainingPlanId}`,
  //     //         data: formData,
  //     //         config: { headers: {'Content-Type': 'multipart/form-data' } }
  //     //       })

  //     const response = await axios({
  //       method: 'get',
  //       url: `https://dev.core.api.fieldday.app/coreAPI/fieldDayAdmin/activities`,
  //       // headers: {
  //       //   Authorization: `Bearer ${token}`
  //       // }
  //     })

  //     console.log('TOKEN: ', token)
  //     console.log('res: ', response)
  //     alert(token)
  //   } catch(e) {
  //     console.error(e)
  //   }
  // }

  if (loading || !user) {
    return (
      <div>Loading...</div>
    )
  }

  return (
    <div>
      <img src={user.picture} alt='UserProfile' />

      <h2>{user.name}</h2>
      <p>{user.email}</p>
      <code>{JSON.stringify(user, null, 2)}</code>
      {/*<button onClick={() => getToken()}>Get Token</button>*/}
    </div>
  )
}

export default UserProfile
