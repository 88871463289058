import React, { Fragment } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import BsForm from 'react-bootstrap/Form'
import { Dropdown, Form, Button, List, Image, Transition } from 'semantic-ui-react'
import { map } from 'underscore'

export default function Email({ emailTemplateId, dynamicEmailTemplateData, onInputChange, gigState, selectedBrand }) {
	const templateData = gigState.dynamicEmailTemplateData
	const counter = gigState.dynamicEmailTemplateData.length

	if(!selectedBrand) return <em>Select a brand first</em>

	const handleAdd = (i) => {
		onInputChange('dynamicEmailTemplateData', [
			...gigState.dynamicEmailTemplateData,
			['', '']
		])
	}

	const handleRemove = (i) => {
		const newState = [...gigState.dynamicEmailTemplateData]

		newState.pop()

		onInputChange('dynamicEmailTemplateData', newState)
	}

	const handleInputChange = (e, i) => {
		const { name, value } = e.target

		if(name === 'key') {
			const state = [...gigState.dynamicEmailTemplateData]

			state[i][0] = value

			onInputChange('dynamicEmailTemplateData', state)

		} else if(name === 'val') {
			const state = [...gigState.dynamicEmailTemplateData]

			state[i][1] = value

			onInputChange('dynamicEmailTemplateData', state)

		} else {
			onInputChange(name, value)
		}
	}

	return (
		<div>
			<BsForm.Check
				type='checkbox'
				label='Send Follow Up Email'
				className='mb-4'
				checked={gigState.send_follow_up_email ? true : false}
				onChange={e => {
					if(!selectedBrand.sendgrid_api_key && !gigState.send_follow_up_email) {
						alert("This brand does not have a SendGrid API key. Make sure you generate one on brand's profile page before you create the campaign.")
					}

					onInputChange('send_follow_up_email', e.target.checked)
				}}
			/>
			<Row>
				{gigState.send_follow_up_email &&
					<Fragment>
						<Col md={5} style={{ height: '220px', overflow: 'auto' }}>
							<Form.Input
								fluid
								name='emailTemplateId'
								value={gigState.emailTemplateId}
								onChange={handleInputChange}
								label='Template ID'
							/>
							<Form.Input
								fluid
								name='sender_name'
								value={gigState.sender_name}
								onChange={handleInputChange}
								label='Sender Name'
							/>
							<Form.Input
								fluid
								name='sender_email_address'
								value={gigState.sender_email_address}
								onChange={handleInputChange}
								label='Sender Email Address'
							/>
							<Form.Input
								fluid
								name='email_subject'
								value={gigState.email_subject}
								onChange={handleInputChange}
								label='Email Subject'
							/>
							<Form.Input
								fluid
								name='reply_to'
								value={gigState.reply_to}
								onChange={handleInputChange}
								label='Reply To'
							/>
						</Col>
						<Col style={{ height: '220px', overflow: 'auto' }}>
							<h5>Dynamic Template Data</h5>
							<Button.Group className='mb-3'>
							  <Button
							    // disabled={gigState.dynamicEmailTemplateData.length === 0}
							    icon='minus'
							    onClick={handleRemove}
							  />
							  <Button
							    // disabled={items.length === users.length}
							    icon='plus'
							    onClick={() => handleAdd(counter)}
							  />
							</Button.Group>
						  {map(gigState.dynamicEmailTemplateData, (keyValPair, i) => (
						  	  <Row className='mb-1'>
						  	  	<Col>
							  	  	<Form.Input fluid name='key' value={keyValPair[0]} onChange={e => handleInputChange(e, i)} placeholder='Key' />
						  	  	</Col>
						  	  	<Col>
							  	  	<Form.Input fluid name='val' value={keyValPair[1]} onChange={e => handleInputChange(e, i)} placeholder='Value' />
						  	  	</Col>
						  	  </Row>
						  ))}
						</Col>
					</Fragment>
				}
			</Row>
		</div>
	)
}
