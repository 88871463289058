import React, { Component } from 'react'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps'
import { compose, withProps, withStateHandlers, lifecycle } from 'recompose'

const googleApiKey = 'AIzaSyBzt4U31ue3BGvUsNj0v4MSKjm87sQLSzc'

const PhoneTargetsMap = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  const store = props.store
  const bounds = new window.google.maps.LatLngBounds()

  const markers = props.targetList.map((target, i) => {
    if(!target.coordinates) return

    bounds.extend({ lng: target.coordinates[0], lat: target.coordinates[1] })

    return (
      // <Marker key={i} position={target.coordinates} label={String(target.order)} />
      <Marker key={i} position={{ lat: target.coordinates[1], lng: target.coordinates[0] }} />
    )
  })

  const storeIcon = {
    path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
    scale: 1,
    fillColor: "#0699a8",
    fillOpacity: 1,
    strokeWeight: 1
  }

  markers.push(
    <Marker
      key={1231243234}
      position={{ lat: store.store_location.latitude, lng: store.store_location.longitude }}
      icon={storeIcon}
    />
  )

  bounds.extend({ lat: store.store_location.latitude, lng: store.store_location.longitude })

  return (
    <GoogleMap
      ref={map => map && map.fitBounds(bounds)}
      // defaultZoom={15}
      // defaultCenter={props.geoPoints[0]}
    >
    {markers}
    </GoogleMap>
  )
})

export default PhoneTargetsMap
