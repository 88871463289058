import React, { Fragment, useState } from 'react'
import { List } from 'semantic-ui-react'

import FilterModal from './FilterModal'

function CoreInformation({ onFilterChange }) {
	const [showModal, setShowModal] = useState(false)
	const [filterName, setFilterName] = useState('')

	const handleShowModal = (filterName) => {
		setFilterName(filterName)
		setShowModal(true)
	}

	const onCloseModal = () => {
		setShowModal(false)
	}

	return (
		<Fragment>
			{showModal &&
				<FilterModal
					showModal={showModal}
					filterName={filterName}
					onClose={onCloseModal}
					onFilterChange={onFilterChange}
				/>
			}
			<div><b>Core Information</b></div>
			<List bulleted>
				<List.Item className='pointer' onClick={() => handleShowModal('place_type')}>Place Type</List.Item>
				<List.Item className='pointer' onClick={() => handleShowModal('business_type_ids')}>Business Type ID</List.Item>
				<List.Item className='pointer' onClick={() => handleShowModal('headquarters_id')}>Headquarters ID</List.Item>
				<List.Item className='pointer' onClick={() => handleShowModal('sic_code_ids')}>SIC Codes</List.Item>
				<List.Item className='pointer' onClick={() => handleShowModal('primary_sic_code_id')}>Primary SIC Code</List.Item>
				<List.Item className='pointer' onClick={() => handleShowModal('estimated_location_employee_count')}>Employee Count</List.Item>
				{/*<List.Item className='pointer' onClick={() => handleShowModal('postal_code')}>Postal Code</List.Item>*/}
				<List.Item className='pointer' onClick={() => handleShowModal('zip')}>Zip</List.Item>
				<List.Item className='pointer' onClick={() => handleShowModal('city')}>City</List.Item>
				<List.Item className='pointer' onClick={() => handleShowModal('contacts.email')}>Email</List.Item>
				<List.Item className='pointer' onClick={() => handleShowModal('phone')}>Phone</List.Item>
				<List.Item className='pointer' onClick={() => handleShowModal('geo_distance')}>Geo</List.Item>
				{/*<List.Item className='pointer' onClick={() => handleShowModal('state')}>State</List.Item>*/}
			</List>
		</Fragment>
	)
}

export default CoreInformation
