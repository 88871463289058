import React, { Fragment } from 'react'
import ReactTable from 'react-table'
import moment from 'moment'

function AmbassadorScoreTable({ scores, loading }) {console.log(scores)
	const tableStyle = {
		fontSize: '13px',
		fontWeight: '100',
		border: '1px solid #C1C1C1'
	}

	const headerStyle = {
		borderRight: 'none',
		fontWeight: '600',
		backgroundColor: '#f1f1f1',
		padding: '8px',
		textAlign: 'center',
		borderBottom: '1px solid #C1C1C1'
	}

	const columnStyle = {
		padding: '8px',
		textAlign: 'center',
		borderRight: '1px solid #C1C1C1'
	}

	return (
		<div>
			<ReactTable
				data={scores}
				columns={[
					{
						Header: 'TRAINING NAME',
						id: 'trainingName',
						accessor: d => <div>coming soon</div>,
						headerStyle: headerStyle,
						style: columnStyle
					},
					{
						Header: 'BRAND',
						id: 'brand',
						accessor: d => <div>coming soon</div>,
						headerStyle: headerStyle,
						style: columnStyle
					},
					{
						Header: 'RESULTS',
						// columns: [
						// 	{
						// 		Header: 'Date',
						// 		id: 'date',
						// 		accessor: d => {
						// 			console.log('DATE ACC: ', d)
						// 			return <div>DATE</div>
						// 		}
						// 	},
						// 	{
						// 		Header: 'Score',
						// 		id: 'score',
						// 		accessor: d => {
						// 			console.log('SCORE ACC: ', d)
						// 			return <div>SCORE</div>
						// 		}
						// 	}
						// ],
						id: 'results',
						accessor: d => d.quiz_results && d.quiz_results.map((result, i) => (
							<Fragment key={result.timestamp_millis}>
								<div className='d-flex justify-content-around'>
									<span>{moment(result.timestamp_millis).format('MM/DD/YYYY')}</span>
									<b>{result.score}</b>
								</div>
								<hr />
							</Fragment>
						)),
						headerStyle: headerStyle,
						style: columnStyle
					},
				]}
				defaultSortMethod={(a, b, desc) => {
					// force null and undefined to the bottom
					a = a.props.children === null || a.props.children === undefined ? '' : a.props.children
					b = b.props.children === null || b.props.children === undefined ? '' : b.props.children
					// force any string values to lowercase
					a = typeof a === 'string' ? a.toLowerCase() : a
					b = typeof b === 'string' ? b.toLowerCase() : b
					// Return either 1 or -1 to indicate a sort priority
					if (a > b) {
					  return 1
					}
					if (a < b) {
					  return -1
					}
					// returning 0, undefined or any falsey value will use subsequent sorts or
					// the index as a tiebreaker
					return 0
				}}
				className="-striped -highlight"
				defaultPageSize={8}
				showPageSizeOptions={false}
				style={tableStyle}
				noDataText={'No gigs found'}
				loading={loading}
			/>
		</div>
	)
}

export default AmbassadorScoreTable
