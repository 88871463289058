import React, { useState } from 'react'
import { Search, Label, Segment } from 'semantic-ui-react'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'

import dollar_icon from '../../assets/img/dollar_icon.png'
import clock_icon from '../../assets/img/clock_icon.png'
import calendar_icon from '../../assets/img/calendar_icon.png'

export default function PayDate({ onInputChange, gigState }) {
	return (
		<Row className='d-flex justify-content-around pd-container'>
			<Col>
				<Pay
					onInputChange={onInputChange}
					rate={gigState.remuneration_rate_per_hour}
					hours={gigState.max_hours}
				/>
			</Col>
			<Col>
				<Date
					onInputChange={onInputChange}
					isMasterGig={gigState.isMasterGig}
					dueDates={gigState.dueDates}
				/>
			</Col>
		</Row>
	)
}

function Pay({ rate, hours, onInputChange }) {
	return (
		<Segment raised>
			<Label size='large' color='orange' ribbon>
				Ambassador Pay
			</Label>
			<Row style={{ height: '200px' }}>
				<Col className='d-flex m-auto'>
					<img src={dollar_icon} alt='' className='mr-3' />

					<Form.Group>
						<Form.Label>Rate per hour</Form.Label>
						<Form.Control
							size='sm'
							type='number'
							value={rate}
							onChange={e => onInputChange('remuneration_rate_per_hour', e.target.value)}
						/>
					</Form.Group>
				</Col>
				<Col className='d-flex m-auto'>
					<img src={clock_icon} alt='' className='mr-3' />

					<Form.Group>
						<Form.Label>Hours</Form.Label>
						<Form.Control
							size='sm'
							type='number'
							value={hours}
							onChange={e => onInputChange('max_hours', e.target.value)}
						/>
					</Form.Group>
				</Col>
			</Row>
		</Segment>
	)
}

function Date({ onInputChange, isMasterGig, dueDates }) {
	const [selectedDate, setSelectedDate] = useState(null)

	const handleDateChange = () => {console.log('1: ', selectedDate.valueOf())
		if(!selectedDate || (dueDates && dueDates.includes(selectedDate.valueOf()))) return
console.log('2: ', selectedDate)
		const timestamp = selectedDate.valueOf()

		onInputChange('dueDates', [].concat(dueDates || [], [timestamp]).sort())
	}

	const handleRemoveDate = (date) => {
		const newDates = dueDates.filter(d => d !== date)

		onInputChange('dueDates', newDates)
	}

	return (
		<Segment raised>
			<Label size='large' color='red' ribbon>
				Date
			</Label>
			<Row style={{ height: '200px' }}>
				<Col md={7} className='d-flex m-auto'>
					<img src={calendar_icon} alt='' className='mr-1' />
					<div>
						<Form.Check
							type='checkbox'
							label='This is a master gig'
							checked={isMasterGig ? true : false}
							onChange={e => onInputChange('isMasterGig', e.target.checked)}
						/>
						<DatePicker
							selected={selectedDate}
							onChange={setSelectedDate}
							onBlur={handleDateChange}
							shouldCloseOnSelect={false}
							className='mt-2'
							showTimeSelect
							timeFormat='h:mma'
							timeIntervals={15}
							dateFormat='MMM d, yyyy h:mma'
							timeCaption="time"
							customInput={<Form.Control />}
							// timeFormat="p"
							// dateFormat="Pp"
						/>
					</div>
				</Col>
				<Col className='no-gutter' style={{ height: '200px', overflow: 'auto' }}>
					{dueDates &&
						dueDates.map(date => (
							<div key={date}>
								{moment(date).format('MMM D, YYYY - h:mma')}
								<span className='text-danger pointer' onClick={() => handleRemoveDate(date)}> [X]</span>
							</div>
						))
					}
				</Col>
			</Row>
		</Segment>
	)
}
