import React, { Component } from 'react'
import axios from 'axios'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Badge from 'react-bootstrap/Badge'
// import Spinner from 'react-bootstrap/Spinner'

import config from '../../config'

class ReviewNotes extends Component {
	state = {}

	render() {
		const { review, gigId } = this.props
		const { editMode, working, done, error } = this.state

		if(!review) return null

		return (
			<div>
				<h4 className='text-center'><Badge pill variant='warning' className='p-3'>In Review</Badge></h4>
				<Form.Control
					as='textarea'
					rows='4'
					ref={input => this.input = input}
					defaultValue={review.review_feedback}
					disabled={!editMode}
					style={{
						border: `${editMode ? '1px solid' : 'none'}`,
						resize: 'none',
						backgroundColor: 'white',
					}}
				/>
				<Button className='mt-2' variant='dark' size='sm' onClick={() => this.setState(({ editMode }) => ({ editMode: !editMode }))}>Edit</Button>
				{editMode &&
					<Button
						className='mt-2 ml-1'
						variant='info'
						size='sm'
						onClick={this.handleSubmitReview}
					>
						Save
					</Button>
				}
				{editMode &&
					<Button
						className='mt-2 ml-1'
						variant='danger'
						size='sm'
						onClick={this.handleDeleteReview}
					>
						Delete
					</Button>
				}
			</div>
		)
	}

	handleSubmitReview = async () => {
		const { gigId, onRefreshGigInfo, onSuccess, onError } = this.props
		console.log(this.input.value)
		try {
			await axios.patch(`${config.coreAPI}/fieldDayAdmin/activity/${gigId}/review`, {
				review_feedback: this.input.value
			})

			this.setState({
				editMode: false
			}, () => {
				onSuccess()
				onRefreshGigInfo()
			})

		} catch(e) {
			console.error(e)
			onError(e)
		}
	}

	handleDeleteReview = async () => {
		const { gigId, onRefreshGigInfo, onSuccess, onError } = this.props

		try {
			await axios.delete(`${config.coreAPI}/fieldDayAdmin/activity/${gigId}/review`)

			this.setState({
				editMode: false
			})

			onSuccess()
			onRefreshGigInfo()
		} catch(e) {
			console.error(e)
			onError(e)
		}
	}
}

export default ReviewNotes
