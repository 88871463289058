import React, { Fragment, useState, useEffect } from 'react'
import axios from 'axios'
import { API } from 'aws-amplify'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Dropdown } from 'semantic-ui-react'

import config from '../../config'

function Brands({ brands, onSelectBrand, selectedBrand }) {
	const [logoUrl, setLogoUrl] = useState(null)
	const [brandLogo, setBrandLogo] = useState(null)

	const options = brands.map(brand => ({
		key: brand.brand_id,
		value: brand.brand_id,
		text: brand.brand_name,
	}))

	useEffect(() => {
		if(selectedBrand && !logoUrl) {
			getLogoUrl(selectedBrand.brand_logo_url)
		}
	}, [])

	const handleBrandSelect = async (brandId) => {
		if(!brandId) {
			setBrandLogo(null)
			onSelectBrand(null)
			return
		}

		try {
			const brand = await axios.get(`${config.coreAPI}/brand/${brandId}`)

			setLogoUrl(brand.data.responses[0].results[0].brand_logo_url)
			getLogoUrl(brand.data.responses[0].results[0].brand_logo_url)

			onSelectBrand(brand.data.responses[0].results[0])
		} catch(e) {
			console.error(e)
		}
	}

	const getLogoUrl = async (url) => {
		try {
			const logo = await API.post('getSignedUrl', '/getSignedUrl', {
				body: { key: url }
			})

			setBrandLogo(logo)
		} catch(e) {
			setBrandLogo(null)
			console.error(e)
		}
	}

	return (
		<Fragment>
			<Row>
				<Col className='d-flex justify-content-center'>
					<Dropdown
						clearable
						search
						selection
						placeholder='Select Brand'
						className='w-50'
						name='brand_id'
						options={options}
						value={selectedBrand && selectedBrand.brand_id}
						onChange={(e, { name, value }) => handleBrandSelect(value)}
						// onChange={(e, { name, value }) => onStateChange(name, value)}
					/>
				</Col>
			</Row>
			<Row>
				<Col className='d-flex flex-column align-items-center m-5'>
					<img src={brandLogo} alt='' style={{ maxHeight: '200px', maxWidth: '200px' }} />
					<h2>
						{selectedBrand && brands.filter(brand => brand.brand_id === selectedBrand.brand_id)[0].brand_name}
					</h2>
				</Col>
			</Row>
		</Fragment>
	)
}

export default Brands
