import React, { Fragment, useEffect, useState } from 'react'
import axios from 'axios'
import { Button } from 'semantic-ui-react'
import { Col } from 'react-bootstrap'

import config from '../../../../config'
import QuestionGroupPreview from './QuestionGroupPreview'
import QuestionGroup from './QuestionGroup'

function QuestionGroupsList({ brandId }) {
	const [questionGroups, setQuestionGroups] = useState([])
	const [showQuestionGroupModal, setShowQuestionGroupModal] = useState(false)
	const [selectedGroup, setSelectedGroup] = useState(false)

	useEffect(() => {
		getQuestionGroups()
	}, [])

	console.log('questionGroups: ', questionGroups)

	return (
		<Fragment>
			{showQuestionGroupModal &&
				<QuestionGroup
					showModal={showQuestionGroupModal}
					onClose={handleModalClose}
					brandId={brandId}
					group={selectedGroup}
					allGroups={questionGroups}
				/>
			}
			<div className='d-flex justify-content-between'>
				<h2>Brand Settings > Business Card Questions</h2>
				<Button color='blue' icon='add' title='New Question Group' onClick={() => setShowQuestionGroupModal(true)} />
			</div>
			<hr />
			<Col className='d-flex flex-wrap'>
				{questionGroups.map((group, i) => (
					<div className='mr-3'>
						<QuestionGroupPreview
							key={i}
							allGroups={questionGroups}
							group={group}
							onQuestionGroupClick={handleQuestionGroupClick}
						/>
					</div>
				))}
			</Col>
		</Fragment>
	)

	async function getQuestionGroups() {
		try {
			const fieldDaySettings = await axios.get(`${config.coreAPI}/brand/${brandId}/fieldDaySettings`)

			if(fieldDaySettings.data.business_card_question_settings) {
				setQuestionGroups(fieldDaySettings.data.business_card_question_settings)
			}
		} catch(e) {
			console.error(e)
		}
	}

	function handleModalClose() {
		setSelectedGroup(null)
		setShowQuestionGroupModal(false)

		setTimeout(() => getQuestionGroups(), 1000)
	}

	function handleQuestionGroupClick(group) {
		setSelectedGroup(group)
		setShowQuestionGroupModal(true)
	}
}

export default QuestionGroupsList
