import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Dropdown } from 'semantic-ui-react'
import { Col, Row } from 'react-bootstrap'
import { map } from 'underscore'

import config from '../../config'
import excelExporter from '../../utils/excelExporter'

export default function BrandStores({ stores, onSetStores }) {console.log("++++++STORES: ", stores)
	const [brands, setBrands] = useState([])
	const [selectedBrandId, setSelectedBrandId] = useState(null)

	const getBrands = async () => {
		try {
			const brands = await axios.get(`${config.coreAPI}/brand/all/names?pageSize=1000`)

			setBrands(brands.data.responses[0].results)
		} catch(e) {
			console.error(e)
		}
	}

	useEffect(() => {
		getBrands()
	}, [])

	const getStores = async () => {
		console.log('selectedBrandId: ', selectedBrandId)
		if(!selectedBrandId) return

		try {
			const response = await axios.get(`${config.coreAPI}/stores?brandId=${selectedBrandId}&pageSize=1000`)

			onSetStores(response.data.stores)
		} catch(e) {
			console.error(e)
		}
	}

	useEffect(() => {
		getStores()
	}, [selectedBrandId])

	const options = brands.map(brand => ({
		key: brand.brand_id,
		text: brand.brand_name,
		value: brand.brand_id,
	}))

	const handleExportList = () => {
		const headerRow = [
			'Franchise ID',
			'Street Address',
			'City',
			'State',
			'Postal Code',
			'Places Count',
			// 'Total Employees Targeted'
		].map(cell => ({ value: cell, type: 'string'}))

		const contentRows = map(stores, doc => {
			return [
				{
					value: doc.store_name,
					type: 'string'
				},
				{
					value: doc.address,
					type: 'string'
				},
				{
					value: doc.city,
					type: 'string'
				},
				{
					value: doc.state,
					type: 'string'
				},
				{
					value: doc.zip,
					type: 'string'
				},
				{
					value: doc.places ? (doc.places.documents ? doc.places.documents.length : doc.places.length) : '',
					type: 'number'
				},
				// {
				// 	value: doc.places && doc.places.documents ? getTotalEmployeeSize(doc.places.documents) : '',
				// 	type: 'number'
				// }
			]
		})

		contentRows.unshift(headerRow)

		const config = {
		  filename: 'Places',
		  sheet: {
		    data: contentRows
		  }
		}

		excelExporter(config)
	}

	const getTotalEmployeeSize = (places) => {
		return places.reduce((accum, el) => {
			if(Number.isInteger(el.estimated_location_employee_count)) {console.log('getTotalEmployeeSize: ', accum, el)
				accum += el.estimated_location_employee_count
				return accum
			}
		}, 0)
	}

	return (
		<div>
			<div><b>Store Proximity Total Results</b></div>
			<div className='mt-3 mb-4'>
				<Dropdown
					fluid
					clearable
					selection
					search
					placeholder='Select brand'
					options={options}
					onChange={(e, { name, value }) => setSelectedBrandId(value)}
				/>
			</div>
			<div style={{ height: '600px', overflow: 'auto' }}>
				{stores && <i className='fas fa-file-download fa-lg pointer' onClick={handleExportList}></i>}
				{stores &&
					map(stores, (store, key) => (
						<div key={key} className='d-flex justify-content-between mb-4 border-bottom'>
							<div>{store.store_name}</div>
							<div className='mr-2'>{store.places && (store.places.documents ? store.places.documents.length : store.places.length)}</div>
						</div>
					))
				}
			</div>
		</div>
	)
}
